import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewHamburgerMenuComponent } from './new-hamburger-menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { SvgIconModule } from '@app/shared/components/svg-icon/svg-icon.module';
import { RouterModule } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { SharedModule } from '@app/shared/shared.module';
import { AvatarProfileModule } from '@app/shared/components/avatar-profile/avatar-profile.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [NewHamburgerMenuComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    SvgIconModule,
    RouterModule,
    MatListModule,
    MatExpansionModule,
    SharedModule,
    AvatarProfileModule,
    MatTooltipModule,
  ],
  exports: [NewHamburgerMenuComponent],
})
export class NewHamburgerMenuModule {}
