import { Injectable } from '@angular/core';
import {
  HttpBackend,
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { CustomHttpError } from 'src/app/shared/interface/custom-http-error.model';
import { UserDetailsService } from '@app/shared/services/user-details.service';
import { SessionService } from './session.service';
import { CommonHeaderService } from './common-header.service';
import { ApiConstants } from 'src/app/shared/constants/api.constants';

@Injectable({ providedIn: 'root' })
export class CrudService {
  private url: string;
  private httpOptions: any;
  http: HttpClient;

  constructor(
    private httpClient: HttpClient,
    private userDetailsService: UserDetailsService,
    private httpBackend: HttpBackend,
    private sessionService: SessionService,
    private commonHeaderService: CommonHeaderService
  ) {
    this.http = new HttpClient(httpBackend);
  }

  private _refreshNeeded$ = new Subject<void>();
  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  get(endpoint: string, requestParam?, admin = true, skipLoading = false) {
    const headers = new HttpHeaders({
      [ApiConstants.SKIP_LOADING_HEADER.KEY]: String(skipLoading),
    });
    if (requestParam) {
      return this.httpClient
        .get(`${endpoint}`, {
          params: requestParam,
          headers,
        })
        .pipe(
          map((res) => res),
          catchError(this.handleHttpError)
        );
    } else {
      return this.httpClient.get(`${endpoint}`, { headers }).pipe(
        map((res) => res),
        catchError(this.handleHttpError)
      );
    }
  }

  post(newObject, endpoint: string, admin = true) {
    return this.httpClient.post(`${endpoint}`, newObject).pipe(
      map((res) => res),
      catchError(this.handleHttpError)
    );
  }
  postFormWithDefaultContentType(newObject, endpoint) {
    let headers = this.commonHeaderService.getCommonHeaders();
    headers = headers.delete('Content-Type');
    return this.http
      .post(`${endpoint}`, newObject, {
        headers,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleHttpError)
      );
  }
  postForm(newObject, endpoint, withHeaders?) {
    if (withHeaders) {
      return this.http
        .post(`${endpoint}`, newObject, {
          headers: this.commonHeaderService.getCommonHeaders(),
        })
        .pipe(
          map((res) => res),
          catchError(this.handleHttpError)
        );
    }

    return this.http.post(`${endpoint}`, newObject).pipe(
      map((res) => res),
      catchError(this.handleHttpError)
    );
  }

  put(endpoint: string, requestParam, admin = true) {
    return this.httpClient.put(`${endpoint}`, requestParam).pipe(
      map((res) => res),
      catchError(this.handleHttpError)
    );
  }

  delete<T>(endpoint: string, admin = true): Observable<T | CustomHttpError> {
    return this.httpClient.delete<T>(`${endpoint}`).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      }),
      map((res) => res),
      catchError(this.handleHttpError)
    );
  }

  customDelete<T>(
    endpoint: string,
    requesParams
  ): Observable<T | CustomHttpError> {
    const options = {
      headers: this.commonHeaderService.getCommonHeaders(),
      ...(requesParams && { body: requesParams }),
    };
    return this.http.delete<T>(`${endpoint}`, options).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      }),
      map((res) => res),
      catchError(this.handleHttpError)
    );
  }

  customPut<T>(
    endpoint: string,
    requesParams
  ): Observable<T | CustomHttpError> {
    const options = {
      headers: this.commonHeaderService.getCommonHeaders(),
    };
    return this.http.put<T>(`${endpoint}`, requesParams, options).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      }),
      map((res) => res),
      catchError(this.handleHttpError)
    );
  }

  downloadFile<T>(endpoint: string) {
    return this.http
      .get(endpoint, {
        headers: this.commonHeaderService.getCommonHeaders(),
        observe: 'response',
        responseType: 'arraybuffer',
      })
      .pipe(map((res) => res));
  }

  import<T, O>(
    newObject: T,
    endpoint: string
  ): Observable<any | CustomHttpError> {
    return this.httpClient
      .post<O>(`${this.url}${endpoint}`, newObject, {
        responseType: 'blob' as 'json',
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => res),
        catchError(this.handleHttpError)
      );
  }

  private handleHttpError(
    errorResponse: HttpErrorResponse
  ): Observable<CustomHttpError> {
    const { status, message, limit } = errorResponse.error;
    return throwError({ status, message, limit });
  }

  downloadTemplate(endpoint: string) {
    return this.get(endpoint).subscribe((res: any) => {
      if (res.result) {
        window.location.href = res.url;
      }
    });
  }
}
