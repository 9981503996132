import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SearchFiltersRequest } from 'src/app/shared/interface/learning-paths.interface';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { searchValueKey } from '@app/shared/constants/global.constants';
import { takeUntil, tap } from 'rxjs/operators';
import { SubscribingComponent } from '@app/_core/abstract-components/subscribing.component';

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchService
  extends SubscribingComponent
  implements OnDestroy
{
  globalSearchValue$ = new BehaviorSubject<SearchFiltersRequest>({
    searchParam: this.localStorageService.getItem(searchValueKey),
  });

  constructor(private localStorageService: LocalStorageService) {
    super();
    this.globalSearchValue$
      .pipe(
        tap((value) =>
          this.localStorageService.setItem(searchValueKey, value.searchParam)
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }
}
