<div class="card roleMapActivity">
  <div class="no-padding">
    <div class="group-image-container">
      <div
        class="group-image"
        [ngStyle]="{ backgroundImage: 'url(' + group.imgUrl + ')' }"
      ></div>

      <div class="group-details-container">
        <p class="group-type" *ngIf="url == 'groups'">
          {{ "manageGroup.skillgrp" | translate }}
          <!-- {{ "manageGroup.group" | translate }} -->
        </p>
        <p class="group-type" *ngIf="url == 'programs'">
          {{ "managePrograms.skillprgm" | translate }}
        </p>
        <p class="group-name" style="float: left" [matTooltip]="group.name">
          {{ group.name }}
        </p>
        <p class="group-status" [ngSwitch]="group.currSttsID">
          <span class="draft" *ngSwitchCase="groupStatus.DRAFT">{{
            "manageGroup.cardStatus.draft" | translate
          }}</span>
          <span class="published" *ngSwitchCase="groupStatus.PUBLISHED">{{
            "manageGroup.cardStatus.published" | translate
          }}</span>
          <span class="unpublished" *ngSwitchCase="groupStatus.UNPUBLISHED">{{
            "manageGroup.cardStatus.unpublished" | translate
          }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
