<textarea
  [ngClass]="{ error: error }"
  [(ngModel)]="textareaValue"
  [maxlength]="maxlength"
  #textarea
  (ngModelChange)="contentChanged(textarea)"
  spellcheck="false"
  name="textarea"
  [placeholder]="placeholder"
  class="custom-textarea graphik fs-14 custom-scroll"
  >{{ control.value }}</textarea
>
