export const learningPathCardsLimit = 2;

export const smallSliderSize = 3;
export const longSliderSize = 4;

const slidesQuantity = 3;

export const cardsLimitByInitialSize = slidesQuantity * smallSliderSize;
export const cardsLimitByLongSize = slidesQuantity * longSliderSize;

export const DEFAULT_HOMEPAGE_LP_LIMIT = 12;
export const DEFAULT_HOMEPAGE_LP_OFFSET = 0;

export const learningPathCardsKey = 'learningPathCardsKey';
export const skillViewsLimit = 2;
