import { UnitsConstants } from '@app/shared/constants/global.constants';
import { DateTimeHelper } from 'src/app/shared/helpers/date-time.helper';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataConvertUtils {
  static addingMinutes = (duration: number): string => {
    const min =
      duration <= DateTimeHelper.secondsInMinute
        ? UnitsConstants.MINUTE
        : UnitsConstants.MINUTES;
    return `${DateTimeHelper.getMinutesFromSecondsRound(duration)} ${min}`;
  };
  static px2rem(size: string): string {
    return `${parseInt(size) / 16}rem`;
  }
}
