import { DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Inject,
  HostListener,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICONS } from 'src/app/shared/components/svg-icon/icons-list';
import { HeaderDetailsApiService } from '../components/header/services/header-details.api.service';
import { eula } from '../constants/terms-conditions';
import { SCREEN_SIZES } from '../enums/screen-size.enum';
@Component({
  selector: 'aftp-eula-popup',
  templateUrl: './eula-popup.component.html',
  styleUrls: ['./eula-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EulaPopupComponent implements OnInit {
  public ICONS = ICONS;
  logoImg = '../../../../assets/images/logo.png';
  headerTitle = '';
  eula = eula;
  displayProgramName = false;
  isMobile: boolean;
  constructor(
    private modal: NgbModal,
    private headerDetailsApiService: HeaderDetailsApiService,
    private changeDetection: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {}
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isMobile = window.innerWidth <= SCREEN_SIZES.mobileScreen;
    this.logoWidhthChange();
  }
  ngOnInit(): void {
    this.onResize();
    this.setHeaderData();
    this.logoImg = localStorage.getItem('colored_logo');
    this.logoWidhthChange();
  }

  logoWidhthChange() {
    const logoWidthMobile = localStorage.getItem('logoWidthMobile');
    const logoWidthDesktop = localStorage.getItem('logoWidthDesktop');
    if (logoWidthDesktop && !this.isMobile) {
      this.document.documentElement.style.setProperty(
        '--logo-width-desktop',
        logoWidthDesktop
      );
    } else if (logoWidthMobile && this.isMobile) {
      this.document.documentElement.style.setProperty(
        '--logo-width-mobile',
        logoWidthDesktop
      );
    }
  }
  closeTerms() {
    this.modal.dismissAll();
  }
  setHeaderData() {
    this.headerDetailsApiService
      .getHeaderDetails()
      .subscribe(({ data: { programName, displayProgramName } }) => {
        this.headerTitle = programName;
        this.displayProgramName = displayProgramName;
        this.changeDetection.detectChanges();
      });
  }
}
