<div class="logo" [class.disabled]="isUnauthorizedLogo">
  <a
    class="logo-link"
    [routerLink]="link"
    [class.inactive]="isLinkInactive"
    [class.inActiveHome]="isHome"
    tabindex="0"
  >
    <img
      *ngIf="logoSrc"
      [src]="logoSrc"
      alt="Future Talent Platform"
      class="logo-image"
      [class.inactive]="isLinkInactive"
      [class.inActiveHome]="isHome"
    />
  </a>
  <h1
    class="{{ titleLogoName == true ? 'logo-title-sm' : 'logo-title' }}"
    *ngIf="displayProgramName"
  >
    {{ titleLogo }}
  </h1>
</div>
