import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import {
  AUTH_ROUTER_PATHS,
  SITE_ACCESS_PATHS,
  USER_ROUTER_PATHS,
} from 'src/app/shared/enums/router-paths.enum';
import { AvatarProfile } from '@app/shared/components/avatar-profile/types/avatar-profile.type';
import { HeaderConstants } from 'src/app/shared/constants/global.constants';
import { HEADER_BREAKPOINTS } from 'src/app/shared/enums/global.enums';
import { HEADER_ICON_SIZE } from 'src/app/shared/enums/screen-size.enum';
import { HeaderDetailsApiService } from '@app/shared/components/header/services/header-details.api.service';
import { LearningObjectsSearchApiService } from '@app/shared/components/header/services/learning-objects-search.api.service';
import { HamburgerMenuData } from '@app/shared/components/header/types/hamburger-menu-response.interface';
import { LearningObjectsSearchUtils } from '@app/shared/components/header/utils/learning-objects-search-utils';
import { SearchCollapsedResultItem } from '@app/shared/components/search-collapsed/types/search-collapsed-result-item.type';
import { RegexpConstants } from '@app/shared/constants/regexp.constants';
import { SessionService } from '@app/shared/services/session.service';
import { BadgeAnimationService } from '@app/shared/components/badge-pop-up/services/badge-animation.service';
import { BehaviorSubject, combineLatest, from, of } from 'rxjs';
import { filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SubscribingComponent } from '@app/_core/abstract-components/subscribing.component';
import { GlobalSearchService } from '@app/shared/services/global-search.service';
import { CollapsedSearchService } from '@app/shared/components/search-collapsed/services/collapsed-search.service';
import { UserRolesDetailResponse } from '@app/shared/components/header/types/header-details.type';
import { LearningObjectButtonsStateService } from '@app/layouts/user/pages/learning-object-page/services/learning-object-buttons-state.service';
import { BadgesList } from '@app/shared/components/badge-pop-up/types/badge-popup-details.type';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { searchValueKey } from '@app/shared/constants/global.constants';
import { USER_ROLE } from 'src/app/shared/enums/user-profile-configs.enum';
import { FileService } from '@app/shared/services/file.service';
import { AvatarService } from 'src/app/shared/services/avatar.service';
import { TranslateService } from '@ngx-translate/core';
import { AppRoutes } from '@app/shared/constants/app-routes.constant';
import { Service2siteAccessOptionsService } from 'src/app/shared/services/service-2site-access-options.service';
import { ClientThemeConfigurationService } from '@app/shared/services/client-theme-configuration.service';
import { ClientThemeBannerConfiguration } from 'src/app/shared/interface/common-model';
import { MatMenuTrigger } from '@angular/material/menu';
import { DOCUMENT } from '@angular/common';
import { AdminAccessService } from 'src/app/layouts/user/services/admin-access.service';
import { LearningObjectDetailService } from 'src/app/layouts/user/pages/learning-object-detail/services/learning-object-detail.service';
import { UserDetailsService } from '../../services/user-details.service';
import { EulaServiceService } from '../../services/eula-service.service';
@Component({
  selector: 'aftp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    LearningObjectsSearchApiService,
    HeaderDetailsApiService,
    FileService,
  ],
})
export class HeaderComponent
  extends SubscribingComponent
  implements OnInit, OnChanges
{
  readonly USER_ROUTER_PATHS = USER_ROUTER_PATHS;
  private _menu: HamburgerMenuData[];
  private _userId: string;
  private _isUserLayoutPage = false;

  isMobile = true;
  logo_colored: string;
  logoStore: string;
  isMobileView: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenWidth = window.innerWidth;
    if (this.router.url.includes(AUTH_ROUTER_PATHS.CHANGE_PASSWORD)) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  @Input() isTransparent: boolean;
  @Input() isLoginTransparent: boolean;
  @Input() isSiteLoginTransparent: boolean;
  @Input() isHome: boolean;
  @Input() isLightTheme: boolean;
  @Input() isTranscript: boolean;
  @Input() isError: boolean;
  @Input() isUnauthorizedHeaderVisible = false;
  @Input() isAssessmentPage: boolean;
  @Input() route: string;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  showbadgentfcn = false;
  badgentfcndot = false;
  multiplebadges: string;
  isAiccClient: string;
  userName: string;
  get iconSize(): string {
    return this.screenWidth >= HEADER_BREAKPOINTS.XL
      ? HEADER_ICON_SIZE.LARGE
      : HEADER_ICON_SIZE.MEDIUM;
  }

  @Input()
  set menu(val: HamburgerMenuData[]) {
    this._menu = val;
  }

  get menu(): HamburgerMenuData[] {
    return this._menu;
  }

  linkToHomePage = `/${USER_ROUTER_PATHS.HOME}`;
  logoImg: string;
  badgeimageUrl: string;
  public iconDiameter = 21.5;
  public screenWidth: number;
  resultList: SearchCollapsedResultItem[] = [];
  searchControl = new FormControl('', [this.searchControlValidator]);
  showNoResultsLabel$ = new BehaviorSubject(false);
  isSearchExpanded = false;
  isShowAllResultsVisible = false;
  notViewedNotificationsCount = 0;
  badgeInformation: BadgesList;
  displayProgramName = true;
  headerTitle$ = new BehaviorSubject('');
  profileData$ = new BehaviorSubject<AvatarProfile>({
    avatarURL: '',
    userFirstName: '',
    userLastName: '',
    isActive: '',
  });
  clientThemeBannerCongfiguration: ClientThemeBannerConfiguration;
  constructor(
    private learningObjectsSearchApiService: LearningObjectsSearchApiService,
    private headerDetailsApiService: HeaderDetailsApiService,
    private sessionService: SessionService,
    private router: Router,
    private _badgeAnimationService: BadgeAnimationService,
    private changeDetection: ChangeDetectorRef,
    private globalSearchService: GlobalSearchService,
    private learningObjectsButtonsService: LearningObjectButtonsStateService,
    private localStorageService: LocalStorageService,
    readonly collapsedSearchService: CollapsedSearchService,
    private fileService: FileService,
    private avatarService: AvatarService,
    private _translateService: TranslateService,
    private serviceSiteAccess: Service2siteAccessOptionsService,
    private clientThemeConfigurationService: ClientThemeConfigurationService,
    private adminService: AdminAccessService,
    private loDetailService: LearningObjectDetailService,
    private useDetailsService: UserDetailsService,
    private eulaservice: EulaServiceService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();

    router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      tap(() => {
        if (this.isGoingToUserLayout) {
          this.initUserLayoutData();
        }
        this._isUserLayoutPage = this.router.url.includes(
          USER_ROUTER_PATHS.USER
        );
      }),
      takeUntil(this.unsubscribe)
    );
  }

  async ngOnInit(): Promise<void> {
    if (this.router.url.includes(AUTH_ROUTER_PATHS.LOGIN)) {
      this.logoImg = '../../../../assets/images/logo.png';
    }
    const mobileWidth = 576;
    if (window.innerWidth <= mobileWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.isAiccClient = this.serviceSiteAccess.getAiccClient();
    if (this.router.url === AppRoutes.USER_SEARCH) {
      this.searchControl.setValue(
        this.localStorageService.getItem(searchValueKey)
      );
    }
    this.screenWidth = window.innerWidth;

    this._badgeAnimationService.getBadgeInformation().subscribe((data) => {
      if (data) {
        this.onAnimationComplete(data); // function to show header notification on completion of badge animation
      }
    });
    this.useDetailsService.getHeaderData().subscribe((val) => {
      this.setHeaderData();
    });
    this.avatarService.userAvatar.subscribe(() => {
      const profileDataCopy = this.profileData$.getValue();
      this.profileData$.next({
        avatarURL: this.avatarService.avatarLocalStorage,
        userFirstName: profileDataCopy.userFirstName,
        userLastName: profileDataCopy.userLastName,
        isActive: 'true',
      });
      this.changeDetection.detectChanges();
    });
    this.clientThemeConfigurationService
      .getClientThemeBannerConfiguration()
      .subscribe((data: ClientThemeBannerConfiguration) => {
        if (data == null) return;
        if (data) {
          this.logoImg = data.logo;
          this.logoStore = data?.logo;
          this.logo_colored = data?.logo_colored;
          if (this.router.url.includes(USER_ROUTER_PATHS.EULA)) {
            this.logoImg = this.logo_colored;
          }
          if (this.router.url.includes(USER_ROUTER_PATHS.USER_ERROR)) {
            this.logoImg = this.logo_colored;
            if (!this.logo_colored) {
              this.logoImg = '../../../../assets/images/logo.png';
            }
          } else if (
            (this.isUnauthorizedHeaderVisible &&
              this.router.url.includes(AUTH_ROUTER_PATHS.CHANGE_PASSWORD)) ||
            this.router.url.includes(USER_ROUTER_PATHS.ERROR) ||
            this.router.url.includes(AUTH_ROUTER_PATHS.TERMS_AND_CONDITIONS) ||
            this.router.url.includes(USER_ROUTER_PATHS.SQMaintanance)
          ) {
            this.logoImg = '../../../../assets/images/logo.png';
          } else if (
            this.router.url.includes(USER_ROUTER_PATHS.HOME) ||
            this.router.url.includes(USER_ROUTER_PATHS.PROGRAM_HOME_PAGE)
          ) {
            this.logoImg = this.logoStore;
          } else if (
            !this.router.url.includes(USER_ROUTER_PATHS.HOME) &&
            !this.router.url.includes(USER_ROUTER_PATHS.PROGRAM_HOME_PAGE) &&
            !this.router.url.includes(AUTH_ROUTER_PATHS.LOGIN) &&
            !this.router.url.includes(AUTH_ROUTER_PATHS.MFA) &&
            !this.router.url.includes(AUTH_ROUTER_PATHS.FORGOT_PASSWORD) &&
            !this.router.url.includes(SITE_ACCESS_PATHS.SITE_ACCESS)
          ) {
            this.logoImg = this.logo_colored;
          } else if (
            this.router.url.includes(AUTH_ROUTER_PATHS.LOGIN) ||
            this.router.url.includes(AUTH_ROUTER_PATHS.MFA) ||
            this.router.url.includes(AUTH_ROUTER_PATHS.FORGOT_PASSWORD) ||
            this.router.url.includes(SITE_ACCESS_PATHS.SITE_ACCESS) ||
            this.router.url.includes(USER_ROUTER_PATHS.SQMaintanance)
          ) {
            this.logoImg = '../../../../assets/images/logo.png';
          }
        } else {
          this.logoImg = '../../../../assets/images/logo.png';
        }
        this.changeDetection.detectChanges();
      });
  }

  ngOnChanges() {
    if (
      this.router.url.includes(AUTH_ROUTER_PATHS.LOGIN) ||
      this.router.url.includes(SITE_ACCESS_PATHS.SITE_ACCESS)
    ) {
      const defaultLogoWidth = '35px';
      const logoWidthMobile = '25px';
      this.document.documentElement.style.setProperty(
        '--logo-width-desktop',
        defaultLogoWidth
      );
      this.document.documentElement.style.setProperty(
        '--logo-width-mobile',
        logoWidthMobile
      );
    }
    if (
      this.router.url.includes(AUTH_ROUTER_PATHS.FORGOT_PASSWORD) ||
      this.router.url.includes(USER_ROUTER_PATHS.SQMaintanance)
    ) {
      this.headerTitle$.next('Future Talent Platform');
    }
    if (this.router.url.includes('search?searchType=')) {
      this.searchControl.setValue(
        this.localStorageService.getItem(searchValueKey)
      );
    }
    if (this.router.url.includes(USER_ROUTER_PATHS.USER_ERROR)) {
      this.logoImg = this.logo_colored;
      if (!this.logo_colored) {
        this.logoImg = '../../../../assets/images/logo.png';
      }
    } else if (
      (this.isUnauthorizedHeaderVisible &&
        this.router.url.includes(AUTH_ROUTER_PATHS.CHANGE_PASSWORD)) ||
      this.router.url.includes(USER_ROUTER_PATHS.ERROR) ||
      this.router.url.includes(AUTH_ROUTER_PATHS.TERMS_AND_CONDITIONS) ||
      this.router.url.includes(USER_ROUTER_PATHS.SQMaintanance)
    ) {
      this.logoImg = '../../../../assets/images/logo.png';
    } else if (
      this.router.url.includes(USER_ROUTER_PATHS.HOME) ||
      this.router.url.includes(USER_ROUTER_PATHS.PROGRAM_HOME_PAGE)
    ) {
      this.logoImg = this.logoStore;
    } else if (
      !this.router.url.includes(USER_ROUTER_PATHS.HOME) &&
      !this.router.url.includes(USER_ROUTER_PATHS.PROGRAM_HOME_PAGE) &&
      !this.router.url.includes(AUTH_ROUTER_PATHS.LOGIN) &&
      !this.router.url.includes(AUTH_ROUTER_PATHS.MFA) &&
      !this.router.url.includes(AUTH_ROUTER_PATHS.FORGOT_PASSWORD) &&
      !this.router.url.includes(SITE_ACCESS_PATHS.SITE_ACCESS)
    ) {
      this.logoImg = this.logo_colored;
    } else if (
      this.router.url.includes(AUTH_ROUTER_PATHS.LOGIN) ||
      this.router.url.includes(AUTH_ROUTER_PATHS.MFA) ||
      this.router.url.includes(AUTH_ROUTER_PATHS.FORGOT_PASSWORD) ||
      this.router.url.includes(SITE_ACCESS_PATHS.SITE_ACCESS) ||
      this.router.url.includes(USER_ROUTER_PATHS.SQMaintanance)
    ) {
      this.logoImg = '../../../../assets/images/logo.png';
    }
    if (this.router.url.includes(USER_ROUTER_PATHS.USER)) {
      this._isUserLayoutPage = true;
      this.initUserLayoutData();
    }
    if (
      this.router.url.includes('user/error') &&
      localStorage.getItem('isFTPAPPClient') == 'true'
    ) {
      const programName = localStorage.getItem('ProgramName');
      console.log(programName);
      if (programName != undefined && programName != null) {
        this.headerTitle$.next(programName);
      } else {
        this.headerTitle$.next('Future Talent Platform');
      }
    } else if (
      this.router.url.includes(AUTH_ROUTER_PATHS.LOGIN) ||
      this.router.url.includes(SITE_ACCESS_PATHS.SITE_ACCESS) ||
      this.router.url.includes(USER_ROUTER_PATHS.ERROR) ||
      this.router.url.includes(AUTH_ROUTER_PATHS.MFA) ||
      this.router.url.includes(AUTH_ROUTER_PATHS.TERMS_AND_CONDITIONS) ||
      this.router.url.includes(USER_ROUTER_PATHS.SQMaintanance)
    ) {
      this.headerTitle$.next('Future Talent Platform');
    }
    if (this.router.url.includes(AUTH_ROUTER_PATHS.CHANGE_PASSWORD)) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }

    if (
      localStorage.getItem('isFTPAPPClient') == 'true' &&
      (this.router.url.includes(AUTH_ROUTER_PATHS.ERROR) ||
        this.router.url.includes(
          `${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.ERROR}`
        ) ||
        this.router.url.includes(AUTH_ROUTER_PATHS.FTP_APP_EULA))
    ) {
      this.eulaservice.eulaBranding().subscribe((res: any) => {
        if (res.result) {
          localStorage.setItem('colored_logo', res.data.logo_colored);
          localStorage.setItem('logoWidthDesktop', res.data.logoWidthDesktop);
          localStorage.setItem('logoWidthMobile', res.data.logoWidthMobile);
          localStorage.setItem('headerlogo', res.data.headerLogo);
          localStorage.setItem(
            'displayProgramName',
            res.data.displayProgramName
          );
          localStorage.setItem('ProgramName', res.data.programName);
          this.checkFTPAPPCilentLogout();
        }
      });
    }
    if (this.router.url.includes(AUTH_ROUTER_PATHS.LOGOUT)) {
      this.checkFTPAPPCilentLogout();
    }
    this.isAiccClient = this.serviceSiteAccess.getAiccClient();
  }

  checkFTPAPPCilentLogout() {
    const bannerData = localStorage.getItem('colored_logo');
    const logoWidthDesktop = localStorage.getItem('logoWidthDesktop');
    const logoWidthMobile = localStorage.getItem('logoWidthMobile');

    if (!this.isMobileView && logoWidthDesktop) {
      this.document.documentElement.style.setProperty(
        '--logo-width-desktop',
        logoWidthDesktop
      );
    } else if (logoWidthMobile) {
      this.document.documentElement.style.setProperty(
        '--logo-width-mobile',
        logoWidthMobile
      );
    }
    this.logoImg = bannerData;
    if (!this.logoImg) {
      this.logoImg = '../../../../assets/images/logo.png';
      this.headerTitle$.next('Future Talent Platform');
    } else {
      this.displayProgramName = Boolean(
        localStorage.getItem('displayProgramName')
      );
      this.headerTitle$.next(localStorage.getItem('ProgramName'));
    }
    document.getElementsByTagName('link')[0]['href'] =
      localStorage.getItem('headerlogo');
  }

  onAnimationComplete(data: any): void {
    this.badgeInformation = data;
    this.badgeimageUrl = data?.Animation[0].badgeUrl;
    if (this.badgeInformation.badgeCount > 1) {
      const badgeCount: string = this.badgeInformation.badgeCount.toString();
      const multiplebadgesMsg: string = this._translateService.instant(
        'badgePopUp.earnedbadges'
      );
      this.multiplebadges = badgeCount + ' ' + multiplebadgesMsg;
    }
    setTimeout(() => {
      this.showbadgentfcn = true;
      this.badgentfcndot = true;
      this.changeDetection.detectChanges();
      setTimeout(() => {
        this.showbadgentfcn = false;
        this.changeDetection.detectChanges();
      }, 2000);
    }, 1000);
  }

  search(searchValue: string): void {
    if (this.searchControl.value.endsWith(' ')) {
      searchValue = searchValue.trim();
    }
    this.learningObjectsSearchApiService
      .getLearningObjectsSearchResultsAutosuggestions({
        searchParam: searchValue,
      })
      .pipe(
        finalize(() => {
          this.showNoResultsLabel$.next(true);
          this.changeDetection.detectChanges();
        })
      )
      .subscribe(
        (searchResult) => {
          this.resultList = searchResult.data
            .slice(0, HeaderConstants.SEARCH_RESULTS_MAX_SIZE)
            .map(LearningObjectsSearchUtils.mapLearningObjectsResultToCard);
          if (this.searchControl.value.length < 3) {
            this.resultList = [];
          }
          this.isShowAllResultsVisible =
            searchResult.data.length > HeaderConstants.SEARCH_RESULTS_MAX_SIZE;
        },
        () => {
          this.resultList = [];
        }
      );
  }
  logoNavigate(): void {
    localStorage.setItem('DropdownSelect', 'false');
    localStorage.setItem('NavigationClick', 'true');
    localStorage.setItem('SearchIcon', 'false');
  }
  toggleSearchContainer(state: boolean): void {
    this.isSearchExpanded = state;
  }

  navigateToSearchPage(): void {
    this.collapsedSearchService.isSearchResultsPanelOpened.next(false);
    this.collapsedSearchService.isSearchPageNavigation.next(true);
    if (this.searchControl.value) {
      this.searchControl.setValue(this.searchControl.value.trim());
    }
    this.globalSearchService.globalSearchValue$.next({
      searchParam: this.searchControl.value,
    });
    this.router.navigateByUrl(
      `${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.SEARCH}?searchType=LO`
    );
  }

  setNotificationsCount(): void {
    this.headerDetailsApiService
      .getCountOfNotifications(this._userId)
      .subscribe(
        (result) => {
          this.notViewedNotificationsCount = result.data;
        },
        () => (this.notViewedNotificationsCount = 0)
      );
  }

  resetCounter(): void {
    this.headerDetailsApiService.setNotificationIconClicked(true);
    if (!this.isBadgeHidden()) {
      this.headerDetailsApiService.resetCounter(this._userId).subscribe(() => {
        this.notViewedNotificationsCount = 0;
        this.changeDetection.detectChanges();
      });
    }
  }

  isBadgeHidden(): boolean {
    return this.notViewedNotificationsCount === 0;
  }
  // remove user/home after successful creation on new home page
  noBorder(): boolean {
    return (
      this.router.url.includes('program-home-page') ||
      this.router.url.includes('user/home') ||
      this.router.url.includes('programs/groups')
    );
  }

  navigateToLoPage(id: string): void {
    this.adminService.loClickedFromGlobalSearch = true;
    this.adminService.previousUrl = window.location.href;
    this.router.navigate([
      `${USER_ROUTER_PATHS.USER}`,
      `${USER_ROUTER_PATHS.LEARNING_OBJECT_DETAIL}`,
      `${id}`,
    ]);
  }

  setHeaderData() {
    this.useDetailsService
      .getHeaderData()
      .pipe(
        switchMap((response) => {
          if (response) {
            this.useDetailsService.discussionBoard$.next(
              String(response.data.disableDiscussionBoard)
            );
            this.useDetailsService.PeopleSearch$.next(
              response?.data?.disablePeopleSearch
            );
            this.useDetailsService.Like$.next(response?.data?.disableLike);
            this.useDetailsService.PrivacyStatement$.next(
              response?.data?.displayPrivacyStatement
            );
            this.useDetailsService.SocialProof$.next(
              response?.data?.thresholdValue
            );
            this.userName = `${response.data.profile.userFirstName} ${response.data.profile.userLastName}`;
            const userAvatarImg$ = response.data.profile.avatarURL
              ? from(
                  this.fileService.getBase64Image(
                    response.data.profile.avatarURL
                  )
                )
              : of(null);
            return combineLatest([of(response.data), userAvatarImg$]);
          } else {
            return of([]);
          }
        })
      )
      .subscribe(([responseData, userAvatarImg]) => {
        if (responseData) {
          this.learningObjectsButtonsService.showCreateButton$.next(
            this.isCurator(responseData.profile.userRolesDetail)
          );
          this.headerTitle$.next(responseData.programName);
          if (this.router.url === `/${USER_ROUTER_PATHS.ERROR}`) {
            this.displayProgramName = true;
          } else {
            this.displayProgramName = responseData.displayProgramName;
          }
          this.avatarService.avatarLocalStorage = userAvatarImg;
          this.profileData$.next({
            avatarURL: userAvatarImg,
            userFirstName: responseData.profile.userFirstName,
            userLastName: responseData.profile.userLastName,
            isActive: 'true',
          });
          window.localStorage.setItem('userName', this.userName);
          this.avatarService.avatarLocalStorage = userAvatarImg;
          window.localStorage.setItem(
            'userFirstName',
            responseData.profile.userFirstName
          );
          window.localStorage.setItem(
            'userLastName',
            responseData.profile.userLastName
          );
          this.changeDetection.detectChanges();
        }
      });
  }

  isCurator(roles: UserRolesDetailResponse[]): boolean {
    const userRoles: Array<string> = roles.map(({ name }) => name);
    return userRoles.includes(USER_ROLE.CURATOR);
  }

  profileRoute(): void {
    this.loDetailService.setExploreOrProfileBtnClicked(true);
    localStorage.setItem('DropdownSelect', 'false');
    localStorage.setItem('NavigationClick', 'true');
    localStorage.setItem('SearchIcon', 'false');
    if (this.badgentfcndot) {
      this.badgentfcndot = false;
    }
    this.router.navigate([AppRoutes.USER_PROFILE]);
  }

  exploreRoute(): void {
    this.loDetailService.setExploreOrProfileBtnClicked(true);
    localStorage.setItem('DropdownSelect', 'false');
    localStorage.setItem('NavigationClick', 'true');
    localStorage.setItem('SearchIcon', 'false');
    this.router.navigate([
      `${USER_ROUTER_PATHS.USER}`,
      `${USER_ROUTER_PATHS.EXPLORE}`,
    ]);
  }

  openMenu(): void {
    this.menuTrigger.openMenu();
  }

  closeMenu(): void {
    this.menuTrigger.closeMenu();
  }

  private searchControlValidator(
    control: AbstractControl
  ): Record<string, boolean> | null {
    if (
      Boolean(control.value) &&
      control.value.length > HeaderConstants.SEARCH_LENGTH_VALIDATION_VALUE &&
      RegexpConstants.TWO_SYMBOLS_EXCLUDING_SPACE.test(control.value)
    ) {
      return null;
    }

    return {
      invalid: true,
    };
  }

  private loadUserDetails(): void {
    this._userId = this.sessionService.userId;
  }

  private initUserLayoutData(): void {
    if (this.avatarService.avatarLocalStorage) {
      this.setInitialProfileData();
    }
    this.loadUserDetails();
    this.setHeaderData();
    this.setNotificationsCount();
  }

  private setInitialProfileData(): void {
    this.profileData$.next({
      avatarURL: this.avatarService.avatarLocalStorage,
      userFirstName: '',
      userLastName: '',
      isActive: '',
    });
  }

  // private isFormatLabelsEqual(
  //   learningObjects: LearningObjectsResultItem[]
  // ): boolean {
  //   if (learningObjects) {
  //     const formatLabel = learningObjects[0]?.formatLabel;
  //     return learningObjects.every((learningObject) => {
  //       return learningObject.formatLabel === formatLabel;
  //     });
  //   }
  //   return false;
  // }

  get isGoingToUserLayout(): boolean {
    return (
      !this._isUserLayoutPage &&
      this.router.url.includes(USER_ROUTER_PATHS.USER)
    );
  }
}
