import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { FullScreenErrorPopupComponent } from '@app/layouts/user/pages/edit-learning-path/full-screen-error/full-screen-error-popup.component';
import { ListedErrorPopupComponent } from '@app/layouts/user/pages/edit-learning-path/full-screen-error/error-popup-template/listed-error-popup.component';
import { TableErrorPopupComponent } from '@app/layouts/user/pages/edit-learning-path/full-screen-error/error-popup-template/table-error-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { BackgroundPageErrorComponent } from '@app/layouts/user/pages/edit-learning-path/full-screen-error/error-popup-template/background-page-error.component';
import { BigPageErrorPopupComponent } from '@app/layouts/user/pages/edit-learning-path/full-screen-error/error-popup-template/big-page-error-popup.component';

@NgModule({
  declarations: [
    ListedErrorPopupComponent,
    TableErrorPopupComponent,
    FullScreenErrorPopupComponent,
    BackgroundPageErrorComponent,
    BigPageErrorPopupComponent,
  ],
  imports: [CommonModule, TranslateModule, MatTableModule],
})
export class TemplateErrorPopupModule {}
