import { LEARNING_PATH_SKILLS_TYPE } from 'src/app/shared/enums/learning-path-skills-source.status';
import { LearningObjectsResultItem } from '@app/shared/components/header/types/learning-objects-search.type';
import { LearningPathsSkill } from '@app/shared/components/lo-card/types/learning-object.interface';
import { SearchCollapsedResultItem } from '@app/shared/components/search-collapsed/types/search-collapsed-result-item.type';
import { DataConvertUtils } from '@app/shared/utils/data-convert-utils';

const MOCKED_ORDER_VALUE = 1;

export class LearningObjectsSearchUtils {
  static mapLearningObjectsResultToCard = (
    item: LearningObjectsResultItem
  ): SearchCollapsedResultItem => {
    return {
      id: item.loID,
      title: item.title,
      duration: DataConvertUtils.addingMinutes(item.duration),
      isLock: item.isLock,
    };
  };

  static mapLearningObjectsResultToLpSkill = ({
    loID,
    title,
    duration,
    status,
    formatLabel,
    desc: description,
    targetDate,
    countUsersLikes,
    skill,
    skillLevel,
    skillLevelName,
    subjects,
    source,
    subscribers,
    completedDuration,
    userScore,
    completionDate,
    rag,
    isLocked,
    isSkillSoftLbl,
    isPlaylist,
    laDetails,
    certificateId,
    certAcquiredDetails,
  }: LearningObjectsResultItem): LearningPathsSkill => {
    return {
      isLocked,
      completionDate,
      userScore,
      completedDuration,
      cardLabel: formatLabel,
      loID,
      title,
      duration,
      type: LEARNING_PATH_SKILLS_TYPE.LEARNING_OBJECT,
      status,
      description,
      subscribers: subscribers,
      order: MOCKED_ORDER_VALUE,
      targetDate,
      countUsersLikes,
      rag,
      skill,
      skillLevel: skillLevel || skillLevelName,
      skillLevelName,
      subjects,
      source,
      isSkillSoftLbl,
      isPlaylist,
      laDetails,
      certificateId,
      certAcquiredDetails,
    };
  };
}
