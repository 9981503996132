export const LAContentTypes = {
  AccentureSource: 'SRC175bc016-1b8f-40b4-87b9-6d5dbdb79d2b',
  VideoFormat: 'FRMT8cd1ff52-2059-4eac-82a1-a52d3188c1f0',
  YouTube: 'FRMT8yu8ue22-2059-4eac-82a1-o68t8138bxy0',
  LimelightFormat: 'FRMT5lm6lt54-2059-4eac-82a1-i43e8148ihy8',
  ExternalSource: 'SRC5835ad59-81e8-4792-83ff-04c0d2c11595',
  PDFFormat: 'FRMT8cce134e-b015-4362-af38-a0a490d1db27',
  DOCFormat: 'FRMT7f06f403-1331-4538-b189-c62747493a1b',
  EXCELFormat: 'FRMT1626060d-0d81-4f53-9fd1-b2eec74c5655',
};

export const LearningActivityType = {
  NoCourse: 0,
  Course: 1,
  StartAssessment: 2,
  TakeAssessmnet: 3,
  SummartAssessment: 4,
  ReviewAssessment: 5,
  Survey: 6,
  AutoPlayLA: 7,
  AutoPlayLO: 8,
  Assessment: 9,
};

export const LearningActivityStatus = {
  NotCompleted: 0,
  Completed: 1,
  Progress: 2,
};
export const TranscriptConstants = {
  ubiqus: 'ubiqus',
  google: 'google',
};

export const LearningActivityLikeStatus = {
  Like: 1,
  UnLike: 2,
};

export const AssessmentToOtherPages = {
  start: 'start',
  back: 'back',
  survey: 'survey',
};

export const LASourceTypes = {
  ACCENTURE_ACADEMY: 1,
  ACCENTURE: 2,
  SKILLSOFT: 3,
  PUBLIC_WEB: 4,
  SIMPLILEARN: 5,
  INTERNAL: 6,
  YOUTUBE: 7,
  CONTENTUPLOAD: 8,
};

export const LASourceNames = {
  ACCENTURE_ACADEMY: 'Accenture Academy',
};

export const LAFormatTypes = {
  EXCEL: 1,
  PDF: 2,
  POWERPOINT: 3,
  SCORM: 4,
  VIDEO: 5,
  WORD: 6,
  AICC: 7,
  WEB_PAGE: 8,
  TEXT: 11,
  HTML: 12,
  CSV: 13,
  LIMELIGHT_VIDEO: 14,
  IMAGE: 15,
  EXTERNAL_HTML: 16,
  SKILLSOFT: [18, 19, 20, 21, 22],
};
