import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { BadgeAnimationService } from '@app/shared/components/badge-pop-up/services/badge-animation.service';
import { BadgesList } from '@app/shared/components/badge-pop-up/types/badge-popup-details.type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'aftp-badge-pop-up',
  templateUrl: './badge-pop-up.component.html',
  styleUrls: ['./badge-pop-up.component.scss'],
})
export class BadgePopUpComponent implements OnInit {
  @Input() badgeInformation: BadgesList;
  imageUrl: string;

  constructor(
    public activeModal: NgbActiveModal,
    private _badgeAnimationService: BadgeAnimationService,
    private _translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.imageUrl = this.badgeInformation.Animation[0].badgeUrl;
    this.badgeClosingFunction();
  }

  badgeClosingFunction(): void {
    setTimeout(() => {
      $('.achieved-badge').addClass('startanim');
    }, 100);

    setTimeout(() => {
      $('.badge-popup .badge-achieved-popup').css('display', 'none');
      $('.modal-content').css('border', 'none');
    }, 5000);

    setTimeout(() => {
      this.activeModal.close();
      this._badgeAnimationService.setBadgeInformation(this.badgeInformation);
      this._badgeAnimationService.setbadgeCompletion(true);
      window.scroll(0, 0);
    }, 6000);
  }
}
