export const backgroundProfile = new Map([
  ['A', '#FF2929'],
  ['B', '#D32110'],
  ['C', '#CE4609'],
  ['D', '#A51A26'],
  ['E', '#5C5679'],
  ['F', '#13669F'],
  ['G', '#1B587B'],
  ['H', '#512481'],
  ['I', '#6E6630'],
  ['J', '#915938'],
  ['K', '#503F31'],
  ['L', '#446A34'],
  ['M', '#E89700'],
  ['N', '#FF2929'],
  ['O', '#D32110'],
  ['P', '#CE4609'],
  ['Q', '#A51A26'],
  ['R', '#5C5679'],
  ['S', '#13669F'],
  ['T', '#1B587B'],
  ['U', '#512481'],
  ['V', '#6E6630'],
  ['W', '#915938'],
  ['X', '#503F31'],
  ['Y', '#446A34'],
  ['Z', '#E89700'],
]);
