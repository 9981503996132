import { HttpHeaders } from '@angular/common/http';

export class ApiConstants {
  static readonly SKIP_LOADING_HEADER = {
    KEY: 'angular-skip-loading',
    VALUE: 'true',
  };
  static readonly SKIP_LOADING_HTTP_HEADERS = new HttpHeaders({
    [ApiConstants.SKIP_LOADING_HEADER.KEY]:
      ApiConstants.SKIP_LOADING_HEADER.VALUE,
  });
  static readonly SHOW_REMINDER_QUERY_PARAM = {
    KEY: 'show-reminder',
    VALUE: 'true',
  };
  static readonly MAX_REQUEST_RESULT_ITEMS = 30;
  static readonly MAX_REQUEST_RESULT_ITEMS_LIKES = 15;
  static readonly MAX_REQUEST_RESULT_COMPLETIONS = 15;

  static readonly PARSE_SKIP_LOADING_HTTP_HEADERS = (skipLoading = false) =>
    new HttpHeaders({
      [ApiConstants.SKIP_LOADING_HEADER.KEY]: String(skipLoading),
    });
}

const baseUrl =
  'https://5365b344-5077-42c9-8228-ab2390aea332.mock.pstmn.io/api';
export class LearningObjectCardApiConstants {
  static readonly LEARNING_OBJECT_LIST = `${baseUrl}/learning-objects-list/?learningPathId=11`;
}
