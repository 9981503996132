import { Component, HostListener, OnInit } from '@angular/core';

declare const $;
@Component({
  selector: 'aftp-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss'],
})
export class HamburgerMenuComponent implements OnInit {
  isMobile: boolean; // to check the screen width and change the width of ham menu accordingly
  menuList = [
    { id: 1, menuText: 'history', hasChildMenu: false, path: '' },
    { id: 2, menuText: 'notebook', hasChildMenu: false, path: '' },
    { id: 3, menuText: 'dashboard', hasChildMenu: false, path: '' },
    {
      id: 4,
      menuText: 'manage',
      hasChildMenu: true,
      childMenu: [
        { id: 1, menuText: 'learning objects', path: '' },
        { id: 2, menuText: 'learning paths', path: '' },
        { id: 3, menuText: 'Assessments', path: '' },
        { id: 4, menuText: 'scheduled events', path: '' },
      ],
    },
    {
      id: 5,
      menuText: 'configure',
      hasChildMenu: true,
      childMenu: [
        {
          id: 1,
          menuText: 'competency',
          hasChildMenu: false,
          path: 'user/competency',
        },
      ],
    },
    {
      id: 6,
      menuText: 'administer',
      hasChildMenu: true,
      childMenu: [
        { id: 1, menuText: 'submenu1', hasChildMenu: false, path: '' },
      ],
    },
    { id: 7, menuText: 'settings', hasChildMenu: false, path: '' },
    { id: 8, menuText: 'logout', path: '' },
  ];
  ngOnInit(): void {
    this.mobileCheck();
  }
  mobileCheck(): void {
    const mobileWidth = 576;
    if (window.innerWidth <= mobileWidth) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
  clickOutside(): void {
    $('ul .collapse').collapse('hide');
    $('#hamBurgerMenu').collapse('hide');
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.mobileCheck();
  }
}
