<div class="rounded w-100 bg-white p-3 p-4" ngbAutofocus>
  <div class="success-modal" *ngIf="isSuccess">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6 success-modal__icon"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#528135"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
    <p class="success-modal__text">
      {{ "supportRequestModal.getInTouch" | translate }}
    </p>
    <div class="button-container">
      <button class="custom-btn primary-btn" (click)="ok()">
        {{ "mapLearning.buttons.ok" | translate }}
      </button>
    </div>
  </div>
  <ng-container *ngIf="!isSuccess">
    <span
      class="text-semi-gray close-btn fs-24 cursor-pointer absolute"
      (click)="activeModal.dismiss('Cross click')"
      aria-hidden="true"
      >&times;</span
    >
    <span class="graphik-medium title">
      {{ "supportRequestForm.title" | translate }}
    </span>

    <div class="mail">
      {{ "supportRequestForm.text" | translate }}
      <a href="mailto:FTP.support@accenture.com">FTP.support@accenture.com</a>.
      {{ "supportRequestForm.text1" | translate }}
    </div>

    <!-- <form class="col-xs-12 form-container" [formGroup]="contactUsFormGroup">
      <div class="row">
        <div class="form-group col-sm-12">
          <label
            [ngClass]="{ 'gray-color': isEmailDisabled }"
            for="email"
            class="block control-label"
          >
            {{ "supportRequestModal.email" | translate }}
            <span class="asterisk" [ngClass]="{ 'gray-color': isEmailDisabled }"
              >*</span
            >
          </label>
          <input
            type="email"
            id="email"
            class="bordered-1 w-100 rounded label input-text"
            formControlName="email"
            [readonly]="isEmailDisabled"
            [ngClass]="[
              isSubmitted && contactUsFormGroup.get('email').errors
                ? 'error-input'
                : '',
              isEmailDisabled ? 'disabled-input' : ''
            ]"
          />
        </div>
        <div class="form-group col-sm-12">
          <label for="" class="block control-label">
            {{ "supportRequestModal.problem" | translate }}
            <span class="asterisk">*</span>
          </label>
          <div class="custom-dropdown">
            <div class="custom-dropdown__input">
              <input
                formControlName="category"
                type="text"
                class="bordered-1 w-100 rounded label input-text cursor-pointer"
                (click)="isVisible = !isVisible; $event.stopPropagation()"
                readonly
              />
              <span
                class="arrow"
                [ngClass]="[isVisible ? 'up' : 'down']"
              ></span>
            </div>
            <div
              class="custom-dropdown__list bordered-1 w-100 rounded label"
              [ngClass]="{ dropAnimate: isVisible }"
            >
              <div class="list-container">
                <ng-container *ngIf="!isLoadingCompleted">
                  <p class="text-align-center fs-14 graphik">
                    {{ "supportRequestModal.loading" | translate }}
                  </p>
                </ng-container>
                <ng-container *ngIf="isLoadingCompleted">
                  <p
                    [ngClass]="{
                      active:
                        contactUsFormGroup.get('category').value ==
                        item.subcatSntc
                    }"
                    (click)="addCategory(item)"
                    *ngFor="let item of subCats"
                  >
                    {{ item.subcatSntc }}
                  </p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group col-sm-12">
          <label for="" class="block control-label">
            {{ "supportRequestModal.description" | translate }}
            <span class="asterisk">*</span>
          </label>
          <textarea
            formControlName="description"
            class="block bordered-1 rounded text-area input-text"
            rows="3"
            [ngClass]="{
              'error-input':
                isSubmitted && contactUsFormGroup.get('description').errors
            }"
          >
          </textarea>
        </div>
        <div class="button-container">
          <button
            class="custom-btn primary-btn graphik-semibold"
            [ngClass]="{
              disabled:
                !contactUsFormGroup.controls.email.valid ||
                !contactUsFormGroup.controls.category.valid ||
                !contactUsFormGroup.controls.description.valid
            }"
            (click)="submit()"
          >
            {{ "supportRequestModal.submit" | translate }}
          </button>
        </div>
      </div>
    </form>  -->
  </ng-container>
</div>
