<div
  class="search-collapsed-container"
  clickOutside
  (clickOutside)="onOutsideClickReset($event)"
  (keydown.escape)="onEscapeKeydown()"
>
  <div class="search-collapsed" [class.collapsed]="isSearchVisible">
    <mat-form-field appearance="outline">
      <input
        type="text"
        matInput
        #searchInput="matInput"
        maxlength="160"
        tabindex="-1"
        [formControl]="searchControl"
        [matAutocomplete]="auto"
        [matTooltip]="'header.search.placeholder' | translate"
        [matTooltipDisabled]="disableSearchTooltip"
        [placeholder]="placeholder"
        (keydown.enter)="emitSearchControlEvent()"
        (keydown.tab)="onEscapeKeydown()"
      />

      <mat-autocomplete
        #auto="matAutocomplete"
        class="search-collapsed-autocomplete"
        (opened)="onOpenPanel()"
        (closed)="onClosePanel()"
      >
        <ng-container *ngIf="resultList.length && !this.hideSearchFlag">
          <mat-option
            *ngFor="let option of resultList"
            (mouseover)="getTitle(option)"
            [matTooltip]="showTitleList"
            [value]="option"
            class="result-item"
          >
            <span class="result-item-primary-val">
              {{ option.title }}
            </span>

            <span class="result-item-secondary-val">
              &bull; {{ option.duration }}
            </span>
          </mat-option>

          <mat-option
            *ngIf="isShowAllResultsVisible"
            class="show-all-results"
            [value]="SEARCH_OPTIONS.SHOW_ALL"
          >
            <span id="show-all-span">
              {{ "searchCollapsed.seeAllResultsFor" | translate }} "{{
                searchControl.value
              }}"
            </span>
          </mat-option>
        </ng-container>

        <mat-option
          *ngIf="isVisibleNoResults"
          class="no-results"
          [matTooltip]="noResultsText"
          [value]="SEARCH_OPTIONS.NOTHING_FOUND"
          [matTooltip]="noResultsText"
        >
          {{ noResultsText }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <hero-icon
    class="search-icon"
    role="button"
    name="search"
    tabindex="0"
    (click)="onIconClick()"
    (keydown.enter)="onIconClick()"
    [attr.aria-label]="'icon.title.search' | translate"
  ></hero-icon>
</div>
