<aftp-modal class="modal-container" (closeAction)="onCloseAction()">
  <aftp-modal-header class="title">{{
    "learningPaths.preSkillAssessmentPopupTitle" | translate
  }}</aftp-modal-header>
  <aftp-modal-content>
    <div class="assessment-header">{{ data.description }}</div>
    <div class="assessment-duration">
      <span class="header">{{
        "preSkillAssessmentPopup.duration" | translate
      }}</span>
      <span
        >{{ durationDetails }}{{ "dateTimeValues.minutes" | translate }}</span
      >
    </div>
    <div class="assessment-instructions">
      <div class="assessment-instructions__header">
        {{ "preSkillAssessmentPopup.instructions" | translate }}
      </div>
      <div>{{ data.instructions }}</div>
    </div>
  </aftp-modal-content>
  <aftp-modal-footer>
    <aftp-button (click)="onStartAssessment()">
      {{ "button.start" | translate }}
    </aftp-button>
  </aftp-modal-footer>
</aftp-modal>
