export const eula = {
  headers: ['ACCENTURE', 'FUTURE TALENT PLATFORM', 'PRIVACY STATEMENT'],
  terms_intro:
    'This Privacy Statement (“<b>Privacy Statement</b>”) explains how and for what purpose Accenture will process ' +
    'certain personal data belonging to the authorized users of Future Talent Platform (“<b>Platform</b>”) when they access and use it.',
  terms_intro1:
    'Authorized users can be either Accenture Enterprise ID holders (“<strong>Accenture Users</strong>”) or Accenture clients’ personnel (“<strong>Non-Accenture Users</strong>”). Depending on the capacity in which you are accessing this Platform, slightly different provisions might apply to you as detailed below.',
  termsExhibitA: {
    term: `WHY ARE YOU INTERACTING WITH ACCENTURE IN THE CONTEXT OF FUTURE TALENT PLATFORM – FOR NON-ACCENTURE USERS ONLY`,
    content: {
      p1: `Accenture is providing certain services to an entity, organization, or business you are affiliated with (“<strong>Your Organization</strong>”). In order to provide such services, Accenture and Your Organization have agreed that you will access and use this Platform.`,
      p2: `Your Organization has provided Accenture with your contact details when designating you as an authorized user of the Platform. If you have any doubts or queries relating to why your personal data has been shared with Accenture or you have been selected to access and use the Platform, you should contact Your Organization first.`,
    },
  },
  termsExhibitB: {
    term: `WHICH PERSONAL DATA IS ACCENTURE PROCESSING ABOUT YOU AS A DATA CONTROLLER`,
    content: {
      p1: `When you use and access the Platform, Accenture will process your personal data to enable you to access and use the Platform and to receive technical support. Further details are set out in below table.`,
    },
    table: {
      Headers: {
        row1: `TYPE OF PERSONAL DATA PROCESSED`,
        row2: `PURPOSE OF THE PROCESSING`,
        row3: `LEGAL BASIS`,
      },
      content: {
        p1: `Account data / Login credentials`,
        List: ['First Name', 'Last Name', 'Business E-mail', 'Enterprise ID'],
        p2: `Data related to your interactions with the Platform`,
        p3: `-`,
        List1: [
          'IP Address',
          'Device ID',
          'Browser used',
          'logs file search data',
          'caches of viewed content and courses',
          'language preference.',
        ],
        List2: [
          'Creating your account in the Platform',
          'Enabling you to access and use the Platform',
          'Providing you with technical support during your use of the Platform.',
          'Exercising or defending Accenture’s rights in the context of legal claims/disputes.',
        ],
        List3: [
          'Ensuring the operation and security of the Platform',
          'Exercising or defending Accenture rights in the context of legal claims/disputes.',
        ],
        List4: [
          'Legitimate interest of Accenture to provide the services as contracted.',
          'Legitimate interest of Accenture for ensuring the proper functioning of its business operations and exercise its legal rights in case of claims/disputes with clients.',
          'Your consent when required by mandatory law.',
        ],
        List5: [
          `In respect to Accenture legitimate interests mentioned in the table above, Accenture is of the opinion that your interests, rights or freedoms do not override its legitimate interests given: (i) the transparency that Accenture provides on its processing activities; (ii) Accenture’s privacy by design approach; (iii) Accenture’s regular privacy reviews; and (iv) the rights you have in relation to Accenture’s processing activities.`,
          `To the extent you are asked to click on/check “I accept”, “I agree” or similar buttons/checkboxes/functionalities in relation to this Privacy Statement, doing so will be considered as providing your consent to process your personal data, only in those countries where such consent is required by mandatory law. In all other countries, such action will be considered as a mere acknowledgement and the legal basis of the processing of your personal data and will not be your consent, but any other applicable legal basis as detailed above.`,
        ],
        List6: [
          'the <a tabindex="-1" target="_blank" href="https://www.accenture.com/us-en/about/privacy-policy">Accenture Privacy Statement</a> if you are a Non-Accenture User.',
          'the <a tabindex="-1" target="_blank" href="https://in.accenture.com/protectingaccenture/5422-2/#section5">Accenture Global Data Privacy Statement</a> if you are an Accenture User.',
        ],
      },
    },
  },
  termsExhibitC: {
    term: `DISCLOSURE OF YOUR PERSONAL DATA`,
    content: {
      p1: `Where there is a need, Accenture may share your personal data with third parties, such as service providers. Before doing so, Accenture takes steps to protect your personal data.Any third-party service providers and professional advisors to whom your personal data is disclosed, are expected, and required to protect the confidentiality and security of your personal data and may only use your personal data in compliance with applicable data privacy laws.`,
      p2: `Your personal data might also be shared with public and governmental authorities as required by applicable law. Furthermore, if you are a non-Accenture User, Accenture may provide your personal data to Your Organization, if so requested or when necessary pursuant to the contract it has in place with Accenture.`,
    },
  },
  termsExhibitD: {
    term: 'INTERNATIONAL TRANSFERS OF YOUR PERSONAL DATA',
    para: [
      `Any transfer of your personal data to other Accenture entities 
        (including transfers from within the EEA to outside the EEA) will take place in
        accordance with the applicable data privacy laws and governed by Accenture’s <a tabindex="-1" target="_blank" href="https://www.accenture.com/us-en/about/binding-corporate-rules">BINDING
        CORPORATE RULES FOR CONTROLLERS</a>. Transfers to third party recipients outside the EEA 
        will be based on an adequacy decision by the EU Commission or be governed by the applicable 
        standard contractual clauses (Non-Accenture Users where Accenture is acting as a data 
        controller can refer to the <a tabindex="-1" target="_blank" href="https://www.accenture.com/us-en/about/privacy-policy">Accenture Privacy Statement</a> and Accenture Users can refer 
        to the <a tabindex="-1" target="_blank" href="https://in.accenture.com/protectingaccenture/5422-2/#section5">Accenture Global Data Privacy Statement</a>). Any other non-EEA personal data transfer 
        will take place in accordance with the appropriate international data transfer mechanisms
        and 
        standards.`,
    ],
  },
  termsExhibitE: {
    term: `PERSONAL DATA RETENTION SCHEDULE`,
    content: {
      p1: `Accenture maintains specific records management and retention policies and procedures, so that personal data is deleted after a reasonable time according to the following retention criteria.`,
      p2: `If you are a Non-Accenture User, your personal data will be retained for the duration of our contractual relationship with Your Organization with respect to the services and, after its expiration or termination, for as long as needed in order to comply with our global legal and contractual obligations. In case of disputes or claims, Accenture may retain your personal data as necessary for exercising or defending Accenture’s rights until the resolution of the dispute/claim.`,
      p3: `If you are an Accenture User, the retention policy described in <a tabindex="-1" target="_blank" href="https://in.accenture.com/protectingaccenture/5422-2/#section5">Accenture Global Data Privacy Statement</a> will apply to your personal data.`,
    },
  },
  termsExhibitF: {
    term: `ADDITIONAL INFORMATION`,
    content: {
      p1: `For additional information as to how Accenture processes and secures your personal data and what rights you have in relation to your personal data, review:`,
    },
  },
  termsExhibitG: {
    term: `COOKIES`,
    content: {
      p1: `In addition to the information set out above, please see the <a tabindex="-1" target="_blank" href="https://www.accenture.com/il-en/support/company-cookies-similar-technology">COOKIES STATEMENT </a>that describes how Accenture uses cookies.`,
    },
  },
  agreeTerms: `<strong>Accenture can process my personal data in accordance with this privacy statement.<strong>`,
  agreeTerms1: `Consent is required to enable you to access and use this platform. Kindly contact your manager, if you do not agree.`,
  agreeText: 'Agree',
};
