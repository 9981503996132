import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserDetailsService } from '@app/shared/services/user-details.service';

@Component({
  selector: 'app-faq-card',
  templateUrl: './faq-card.component.html',
  styleUrls: ['./faq-card.component.scss'],
})
export class FaqCardComponent implements OnChanges {
  @Input() data;
  @Input() url;
  constructor(public userDetailService: UserDetailsService) {}
  finalArray = [];
  categorized = false;
  selectedItem: any = null;

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('data')) {
      this.finalArray = [];
      this.selectedItem = null;
      const c: any = changes.data.currentValue;
      if (
        changes.data.currentValue.header == 'Managing personal data' &&
        this.data.qas[1].a
      ) {
        this.data.qas[1].a = this.data.qas[1].a.replace(
          './faq/GDPR_DOC.pdf',
          this.url
        );
      }
      if (c.qas[0].category) {
        this.groupArray(c.qas);
        this.categorized = true;
      } else {
        this.finalArray = c.qas;
        this.categorized = false;
      }
    }
  }

  closeAllAccordion(id) {
    const x = document.querySelectorAll('.collapse.show');
    if (x.length) {
      setTimeout(() => {
        x.forEach((element) => {
          if (element.id !== id) {
            element.classList.remove('show');
          }
        });
      }, 300);
    }
  }

  async groupArray(arr) {
    await arr.forEach((e) => {
      const found = this.finalArray.find(
        (el) => el.category.normalize() === e.category.normalize()
      );
      if (found) {
        found.items.push(e);
      } else {
        this.finalArray.push({
          category: e.category,
          items: [e],
        });
      }
    });
  }

  clickItem(item, cat = false) {
    const obj = {
      category: item.category,
      index: null,
    };
    if (cat) {
      this.selectedItem =
        this.selectedItem && obj.category === this.selectedItem.category
          ? null
          : obj;
    } else {
      this.selectedItem =
        this.selectedItem && item.index === this.selectedItem.index
          ? item.category === this.selectedItem.category
            ? obj
            : null
          : item;
    }
  }

  createUID(header, index = -1) {
    let id = '';
    if (header) {
      id =
        index > -1
          ? `${header.replace(/\s|-/g, '').toLowerCase()}${index}`
          : header.replace(/\s|-/g, '').toLowerCase();
    } else {
      id = `nocat${index}`;
    }
    return id;
  }

  shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  }
}
