export enum ERROR_MESSAGES {
  PICTURE_FORMAT_ERROR = 'manageLearningCreateObject.titles.formatError',
  NOT_ALLOWED_PICTURE_SIZE = 'manageLearningCreateObject.titles.sizeError',
}

export enum EXCLUDED_FORMAT_LABELS {
  LEARNING_BOARD = 'Learning Board',
  COLLECTION = 'Collection',
}

export const EXCLUDED_FORMAT_LABELS_ARRAY: string[] = Object.values(
  EXCLUDED_FORMAT_LABELS
);

export enum EXTERNAL_PUBLISHERS {
  ACCENTURE_ACADEMY = 'Accenture Academy',
}

export enum FILE_TYPE {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  JPG = 'image/jpg',
}

export enum TITLE {
  DEFAULT = 'manageLearningCreateObject.titles.default',
  SELECTED = 'manageLearningCreateObject.titles.selected',
}

export enum LA_MODE {
  ADDING,
  EDITING,
  VIEW,
}

export enum LA_ADD_BUTTON_STATE {
  ACTIVE,
  INACTIVE,
}

export enum LA_FORM_CREATED_STATUS {
  MANUALLY,
  DROPPED,
}

export enum TITLE_ERROR {
  NOT_UNIQUE = 'not unique',
  MAPPED_PROGRAM = 'Error: Group is tagged to published Program',
}

export enum CARD_SOURCE_TYPE {
  COURSE,
  LINK,
}

export enum DIALOG_SIZES {
  WIDTH = '386px',
}

export enum FORMAT_LABELS {
  LEARNING_BOARD = 'Learning Board',
  COLLECTION = 'Collection',
  OTHERS = 'Others',
  LESSON = 'Lesson',
  TUTORIAL = 'Tutorial',
  SECTION = 'Section',
}

export enum LEARNING_TYPE {
  LEARNING_OBJECT = 'LO',
  LEARNING_PATH = 'LP',
}

export enum SRC_NAME {
  ACCENTURE_ACADEMY = 'accenture',
  PUBLIC_WEB = 'public web',
}

export enum SEARCH_TYPES {
  ACCENTURE_ACADEMY = 'academy',
  YOUTUBE = 'youtube',
  GOOGLE = 'google',
}

export enum SEARCH_ACCENTURE_ACADEMY_ERROR_RESPONSES {
  ERR_MAPPING_EXISTS = 'ERR_MAPPING_EXISTS',
  ERR_TITLE_NOT_UNIQUE = 'ERR_TITLE_NOT_UNIQUE',
}

export enum SORT_PARAM {
  MOST_COMPLETED = 'mostCompleted',
  ALPHABETICALLY = 'alphabetically',
  RELEVANCE = 'relevance',
  DEFAULT = 'default',
}

export enum SORTING_OPTIONS {
  ASC = 'asc',
  DESC = 'desc',
}

export enum FormStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
}

export enum DIALOG_OPTIONS {
  YES_OPTION = 'YES_OPTION',
  DOWNLOAD = 'DOWNLOAD',
  TIMER_COMPLETED = 'TIMER_COMPLETED',
}

export enum AXIS_FONT {
  X_AXIS_FONT_DESKTOP = 10,
  Y_AXIS_FONT_DESKTOP = 10,
  X_AXIS_FONT = 8,
  Y_AXIS_FONT = 8,
}

export enum ELEMENT_SUBSTR {
  PARAM_1 = 8,
  PARAM_2 = 0,
  PARAM_3 = 1,
}

export enum FORM_VALUE_KEYS {
  DURATION = 'duration',
  FORMAT_LABEL = 'formatLabels',
  SORT = 'sort',
}

export enum DISABLED_INPUT_TYPES {
  PRIMARY_DISABLED = 'primary-disabled',
  SECONDARY_DISABLED = 'secondary-disabled',
}

export enum HEADER_BREAKPOINTS {
  XL = 1920,
}

export enum LIKE_LIST {
  LIMIT = 'limit',
  PAGE_NUMBER = 'pageNumber',
}

export enum OVERDUE_VALUES {
  MIN_OVERDUE_DAYS = 1,
  MAX_OVERDUE_DAYS = 999,
}

export enum SUBJECT_REQUEST_ENTITY_PARAMS {
  SUBJECT_CATEGORY = 'subjectCategory',
  SUBJECT = 'subject',
}

export enum PROGRAM_GROUP_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum FILE_POPUP_MSG_STATUS {
  INSTRUCTIONS = 'instructions',
  FILE_ERRORS = 'fileErrors',
  FILE_WAERING = 'fileWaering',
  CERTIFICATE_INSTRUCTIONS = 'certificateInstructions',
}

export enum REPORT_TYPES {
  ALL_REPORTS = 'All Reports',
  CUSTOM_REPORTS = 'Custom Reports',
  CUSTOMIZED_REPORTS = 'Customized Reports',
}
