import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsModalComponent } from 'src/app/shared/components/modals/contact-us-modal/contact-us-modal.component';
import { Location } from '@angular/common';
import { AUTH_ROUTER_PATHS } from 'src/app/shared/enums/router-paths.enum';

@Component({
  selector: 'app-footer-before-auth',
  templateUrl: './footer-before-auth.component.html',
  styleUrls: ['./footer-before-auth.component.scss'],
})
export class FooterBeforeAuthComponent implements OnInit {
  isBeforeAuth: boolean;
  copyrightYear: any;
  constructor(private modal: NgbModal, private location: Location) {}

  ngOnInit(): void {
    this.copyrightYear = new Date().getFullYear();
    const currentPath = this.location.path();
    if (currentPath === `/${AUTH_ROUTER_PATHS.MFA}`) {
      this.isBeforeAuth = true;
    }
  }

  gotoContactUs() {
    this.modal.open(ContactUsModalComponent, {
      windowClass: 'contact-us-modal',
    });
  }
}
