import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Endpoints } from '@app/shared/constants/endpoints.constant';
import { BaseApiService } from '@app/shared/services/base-api.service';
import { HamburgerMenuResponse } from '@app/shared/components/header/types/hamburger-menu-response.interface';

@Injectable({
  providedIn: 'root',
})
export class UserLayoutApiService extends BaseApiService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getHamburgerMenuTree(): Observable<HamburgerMenuResponse> {
    return this.get<HamburgerMenuResponse>(Endpoints.HEADER.hamburgerMenu);
  }
}
