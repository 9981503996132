import { USER_ROUTER_PATHS } from 'src/app/shared/enums/router-paths.enum';

export class AppRoutes {
  static readonly DEFAULT = '';

  // Errors
  static readonly ERRORS_BASE = `${USER_ROUTER_PATHS.ERROR}`;

  // Home
  static readonly HOME_BASE = `${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.HOME}`;

  // Profile
  static readonly USER_PROFILE = `${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.PROFILE}`;

  // Search
  static readonly USER_SEARCH = `${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.SEARCH}`;
}
