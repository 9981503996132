<div class="badge-popup">
  <div class="badge-achieved-popup">
    <div class="congos-container">
      <div class="achivement-title">
        {{ "badgePopUp.congratulationsText" | translate }}
      </div>
    </div>
    <div>
      <div class="achivement-msg-box"></div>
      <div class="achivement-msg">
        <p>{{ badgeInformation.Animation[0].badgeMessage }}</p>
        <p class="secound-msg">
          {{ badgeInformation.badgeMessage2 }}
        </p>
      </div>
    </div>
  </div>
</div>
<div class="achieved-badge">
  <img class="achieved-badge-img" [src]="imageUrl" alt="" />
</div>
