import { environment } from '@env/environment';
import { EXPLORE_LEARNING_PATH_SORT } from 'src/app/shared/enums/explore-learning-path-sorn.enum';

export const noMfa = 'NOMFA';
export const softwareTokenMfa = 'SOFTWARE_TOKEN_MFA';
export const newPasswordRequired = 'NEW_PASSWORD_REQUIRED';
export const searchValueKey = 'searchValue';
export const userAvatar = 'userAvatar';
export class PopupMessage {
  static readonly CHANGE_PASSWORD_SUCCESS = 'Bingo! Your password was changed.';
}

export class BrowserTabTitleConstants {
  static readonly CONTACT_US_PAGE = {
    CONTACT_US: 'Contact Us - Future Talent Platform',
  };
  static readonly FAQ_PAGE = {
    FAQ: 'Help - Future Talent Platform',
  };
}

export class Days {
  static readonly LAST_DAY_WEEK = 7;
  static readonly FIRST_DAY_WEEK = 1;
}

export class ClientThemeConfigurationConstant {
  static readonly BASE_GET = `${environment.apiURL.baseUrl}`;
  // '/assets/js/client-theme/client-theme-configuration.json';
  static readonly CLIENT_THEME_CONFIGURATION_API =
    '/assets/js/client-theme/client-theme-configuration.json';
  static readonly CLIENT_THEME_BANNER_API = `${ClientThemeConfigurationConstant.BASE_GET}/menu/client/banner`;
}

export class ConfirmationPopUpConstants {
  static readonly LANGUAGE = {
    LANG_ID: 'languageID',
  };
  static readonly LANG_TYPE = {
    LANG_ID_TYPE: 'en',
  };
  static readonly MODAL_INDICATE = {
    CONFIRMATION_MODAL: 'confirmation-modal',
  };
}

export class ReviewAssessmentsConstants {
  static readonly LANGUAGE = {
    LANG_ID: 'languageID',
  };
  static readonly LANG_TYPE = {
    LANG_ID_TYPE: 'en',
  };
  static readonly MODAL_INDICATE = {
    CONFIRMATION_MODAL: 'confirmation-modal',
  };
}

export class DasboardPageApiConstants {
  static readonly BASE_GET = `${environment.apiURL.baseUrl}`;
  static readonly DAILY_ACTIVE_USERS_LIST = (langId: string) =>
    `${DasboardPageApiConstants.BASE_GET}/${EndpointsConstants.dailyactiveuserPath}?langID=${langId}`;
  static readonly ADOPTION_DETAILS = (langId: string) =>
    `${DasboardPageApiConstants.BASE_GET}/${EndpointsConstants.adoptionDetailsPath}&langID=${langId}`;
  static readonly LEARNING_COMPLETION = (langId: string) =>
    `${DasboardPageApiConstants.BASE_GET}/${EndpointsConstants.learningCompletionPath}?langID=${langId}`;
}

export class EndpointsConstants {
  static dailyactiveuserPath = 'dashboard/report/dailyactiveuser/list';
  static adoptionDetailsPath = 'dashboard/report/adoption/detail?masterID=1';
  static learningCompletionPath = 'dashboard/report/learningcompletion';
}

export const sortOptionsList = [
  {
    value: EXPLORE_LEARNING_PATH_SORT.DEFAULT,
    viewValue: EXPLORE_LEARNING_PATH_SORT.DEFAULT,
  },
  {
    value: EXPLORE_LEARNING_PATH_SORT.ALPHABETICALLY,
    viewValue: EXPLORE_LEARNING_PATH_SORT.ALPHABETICALLY,
  },
  {
    value: EXPLORE_LEARNING_PATH_SORT.MOST_COMPLETED,
    viewValue: EXPLORE_LEARNING_PATH_SORT.MOST_COMPLETED,
  },
];

export const learningPathIdParamName = 'learningPathId';
export const subjectIdParamName = 'subjectId';
export const subjectCategoryIdParamName = 'subjectCategoryId';
export const subjectCategoryNameParamName = 'subjectCategoryName';
export const subjectTitleParamName = 'title';
export const SkillCategoryMaxLength = 40;
export const SkillNameMaxLength = 40;
export const roleCategoryMaxLength = 40;
export const roleNameMaxLength = 40;
export const PAGINATION_LIMIT = 4;
export const PAGINATION_LIMIT_FOR_API_CALL = 6;
export const VIRTUAL_SCROLL_DEFAULT_START_INDEX = -1;
export const SEARCH_MAX_SIZE = 100;
export const SEARCH_VALUE_FROM = 0;
export const HOURS = 'hrs';
export const SKILL_LEVEL_TEXT_MAX_LENGTH = 20;
export const SHOW_ALL_BUTTON_ID = 'show-all-span';

export class HeaderConstants {
  static readonly SEARCH_RESULTS_MAX_SIZE = 7;
  static readonly LO_GLOBAL_SEARCH_MAX_SIZE = String(
    HeaderConstants.SEARCH_RESULTS_MAX_SIZE + 1
  );
  static readonly SEARCH_LENGTH_VALIDATION_VALUE = 2;
}

export class InfiniteScrollConstants {
  static readonly DEFAULT_INFINITE_SCROLL_THROTTLE_TIME = 0;
  static readonly DEFAULT_INFINITE_SCROLL_DISTANCE = 0.1;
}

export class LearningPathsApiConstants {
  static readonly BASE_GET = `${environment.apiURL.baseUrl}/learning/path/list`;
}

export class LocalStorageConstants {
  static readonly HIDE_GENERAL_REMINDER_BANNER = {
    KEY: 'isGeneralReminderBannerVisible',
    VALUE: 'false',
  };
  static readonly IS_ESO = 'isEso';
  static readonly LANGUAGE_ID = 'languageID';
}

export const mfaVerificationError =
  'Incorrect verification code entered, please try again!';

export const mockedData = {
  consumers: 0,
  isNew: false,
  targetEndDate: 0,
  targetStartDate: 0,
  skillLevelName: 'skill',
};

export class NotForMobile {
  static readonly ROUTE_LIST = {
    NOTEBOOK: 'NOTEBOOK',
    MANAGE: 'MANAGE',
    CONFIGURE: 'CONFIGURE',
    ADMINISTER: 'ADMINISTER',
  };
}

export class ReportsConstants {
  static readonly REPORT_TYPE = {
    LEARNING: 'learning',
    ADOPTION: 'adoption',
    OTHERS: 'others',
    TEAMREPORTS: 'team reports',
  };
}

export class RoleConstants {
  static readonly Role_TYPE = {
    LEARNER: 'Learner',
    ADMINISTRATOR: 'Administrator',
    CURATOR: 'Curator',
    BUSINESSLEADER: 'Business Leader',
    MANAGER: 'manager',
  };
}

export class Scroll {
  static readonly SCROLL_MINUITE_EXPIRATION = `1m`;
}

export class UnitsConstants {
  static readonly MINUTE = 'min';
  static readonly MINUTES = 'mins';
}
export interface ReportStatusApiResponse {
  isSeen: null | boolean;
  reportLastRunTime: number | null;
  reportStatus: string | null;
  subType: string;
  title: string;
}
