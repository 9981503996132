<aftp-full-screen-error-popup>
  <div class="col-sm-7 v-center centerAlign">
    <div>
      <div class="errorTitle">
        {{ "errorMessages.errorTitleWeAreSorry" | translate }}
      </div>
      <div class="errorMessage">
        {{ "editLearningPathMessages.Weresorry" | translate }}
        <a class="clickHere" (click)="onDoneAction()"
          >{{ "editLearningPathMessages.clickhere" | translate }}
        </a>
        {{ "editLearningPathMessages.explorelp" | translate }}
      </div>
    </div>
  </div>
</aftp-full-screen-error-popup>
