export class RegexpConstants {
  static readonly THREE_SYMBOLS_EXCLUDING_SPACE = /\s*(\S\s*){1,}/;
  static readonly TWO_SYMBOLS_EXCLUDING_SPACE = /\s*(\S\s*){2,}/;
  static readonly ONE_SYMBOL_EXCLUDING_SPACE = /[^ ]/;
  static readonly LEADING_ZERO = /^0+/;
  static readonly EMAIL =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static readonly NUMERICAL_VALUES = /^[0-9]*$/;
  static readonly NUMERICAL_VALUES_AND_LATIN_LETTERS = /^[0-9,A-Za-z]*$/;
  static readonly ONLY_NUMERICAL_VALUES = /[^0-9]/g;
  static readonly ONLY_NUMERICAL_VALUES_AND_LATIN_LETTERS = /[^0-9_A-Za-z- ]/g;
  static readonly HTTP_HTTPS_URLS = /^http(s?):\/\//;

  static testForOnlyNumericalValues = (toTest: string): boolean => {
    const result = RegexpConstants.ONLY_NUMERICAL_VALUES.test(toTest);
    RegexpConstants.ONLY_NUMERICAL_VALUES.lastIndex = 0;
    return result;
  };

  static testForNumberAndLatinSymbols = (toTest: string): boolean => {
    const result =
      RegexpConstants.ONLY_NUMERICAL_VALUES_AND_LATIN_LETTERS.test(toTest);
    RegexpConstants.ONLY_NUMERICAL_VALUES_AND_LATIN_LETTERS.lastIndex = 0;
    return result;
  };
}
