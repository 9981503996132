export const imageUrls = {
  randomWebImage: [
    { id: 1, imageUrl: '/assets/images/login/login_bg_01.jpeg' },
    { id: 2, imageUrl: '/assets/images/login/login_bg_02.jpeg' },
    { id: 3, imageUrl: '/assets/images/login/login_bg_06.png' },
    { id: 4, imageUrl: '/assets/images/login/login_bg_04.jpeg' },
    { id: 5, imageUrl: '/assets/images/login/login_bg_05.jpeg' },
  ],
  random1920Image: [
    { id: 1, imageUrl: '/assets/images/login/login_bg_01.jpeg' },
    { id: 2, imageUrl: '/assets/images/login/login_bg_02.jpeg' },
    { id: 3, imageUrl: '/assets/images/login/login_bg_06.png' },
    { id: 4, imageUrl: '/assets/images/login/login_bg_04.jpeg' },
    { id: 5, imageUrl: '/assets/images/login/login_bg_05.jpeg' },
  ],
};
export const randomValueGenerated = Math.floor(Math.random() * 5);
