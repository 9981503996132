import { environment } from '@env/environment';

export class SettingsApiConstants {
  static readonly BASE_GET = `${environment.apiURL.baseUrl}/user`;
  static readonly BASE_GET_LIST = `${SettingsApiConstants.BASE_GET}/config`;
  static readonly LANGUAGE_LIST = `${SettingsApiConstants.BASE_GET_LIST}/language/list`;
  static readonly TIMEZONE_LIST = `${SettingsApiConstants.BASE_GET_LIST}/timezone/list`;

  static getSettingsUrl(userId: string): string {
    return `${SettingsApiConstants.BASE_GET}/${userId}/setting`;
  }
  static saveSettingsUrl(userId: string): string {
    return `${SettingsApiConstants.BASE_GET}/${userId}/settings/set`;
  }

  static saveSubscriptionUrl(userId: string, subscriptionId: string): string {
    return `${SettingsApiConstants.BASE_GET}/${userId}/subscription/${subscriptionId}`;
  }
}
