import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientThemeBannerConfiguration } from 'src/app/shared/interface/common-model';
@Injectable({
  providedIn: 'root',
})
export class ClientThemeConfigurationService {
  private _clientThemeConfiguration$: BehaviorSubject<any> =
    new BehaviorSubject(null);
  private _clientThemeBannerConfiguration$: BehaviorSubject<ClientThemeBannerConfiguration> =
    new BehaviorSubject(null);
  setClientThemeConfiguration(clientThemeConfigurationModel: any) {
    this._clientThemeConfiguration$.next(clientThemeConfigurationModel);
  }

  getClientThemeConfiguration(): Observable<any> {
    return this._clientThemeConfiguration$.asObservable();
  }

  setClientThemeBannerConfiguration(
    ClientThemeBannerSpecs: ClientThemeBannerConfiguration
  ) {
    this._clientThemeBannerConfiguration$.next(ClientThemeBannerSpecs);
  }

  getClientThemeBannerConfiguration(): Observable<ClientThemeBannerConfiguration> {
    return this._clientThemeBannerConfiguration$.asObservable();
  }
}
