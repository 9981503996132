import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  Group,
  GroupStatus,
} from 'src/app/shared/interface/manage-groups.model';
import { USER_ROUTER_PATHS } from '../../enums/router-paths.enum';
@Component({
  selector: 'aftp-group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupCardComponent implements OnInit {
  @Input() group: Group;
  url;
  constructor(private router: Router) {}
  ngOnInit() {
    // To do
    this.checkurl();
  }
  groupStatus = GroupStatus;
  checkurl() {
    if (this.router.url.includes(USER_ROUTER_PATHS.MANAGE_GROUPS)) {
      this.url = 'groups';
    } else if (this.router.url.includes(USER_ROUTER_PATHS.MANAGE_PROGRAMS)) {
      this.url = 'programs';
    }
  }
}
