import { Injectable } from '@angular/core';
import { AppRoutes } from '@app/shared/constants/app-routes.constant';

export class DefaultErrorTypes {
  static readonly ACCESS_DENIED = 'access-denied';
  static readonly NOT_FOUND = 'not-found';
  static readonly FTP_APP_ERROR = 'ftp-app-error';
  static readonly FTP_APP_LOGOUT = 'ftp-app-logout';
  static readonly FTP_APP_SESSION_EXPIRED = 'ftp-app-session-expired';
}

export class ErrorData {
  error: string;
  description: string;
  redirectUrl?: string;

  constructor(error: string, description: string, redirectUrl?: string) {
    this.error = error;
    this.description = description;
    this.redirectUrl = redirectUrl;
  }
}

@Injectable()
export class DefaultErrorProvider {
  private errorHolder: { [key: string]: ErrorData } = {};

  constructor() {
    this.errorHolder[DefaultErrorTypes.ACCESS_DENIED] = new ErrorData(
      'errors.access.header',
      'errors.access.description',
      AppRoutes.HOME_BASE
    );
    this.errorHolder[DefaultErrorTypes.NOT_FOUND] = new ErrorData(
      'errors.notFound.header',
      'errors.notFound.description',
      AppRoutes.HOME_BASE
    );
    this.errorHolder[DefaultErrorTypes.FTP_APP_ERROR] = new ErrorData(
      'errorMessages.errorTitleSorry',
      'errors.temporaryIssue.description',
      AppRoutes.ERRORS_BASE
    );
    this.errorHolder[DefaultErrorTypes.FTP_APP_LOGOUT] = new ErrorData(
      'errors.logout.header',
      'errors.logout.description',
      AppRoutes.ERRORS_BASE
    );
    this.errorHolder[DefaultErrorTypes.FTP_APP_SESSION_EXPIRED] = new ErrorData(
      'errorMessages.errorOhNo',
      'errorMessages.sessionExpired',
      AppRoutes.ERRORS_BASE
    );
  }

  getErrorDataByType(type: string): ErrorData {
    return this.errorHolder[type];
  }
}
