import {
  Component,
  Input,
  ViewChild,
  OnInit,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { USER_ROUTER_PATHS } from 'src/app/shared/enums/router-paths.enum';

import { CrudService } from 'src/app/shared/services/crud.service';
import { BadgesList } from '@app/shared/components/badge-pop-up/types/badge-popup-details.type';
import { BadgePopupApiService } from '@app/shared/components/badge-pop-up/services/badge-popup.api.service';
import { BadgePopUpComponent } from '@app/shared/components/badge-pop-up/badge-pop-up.component';
import { LoadingScreenServiceService } from '@app/shared/services/loading-screen-service.service';
import { AssessmentPageService } from '../../../services/assessment-page.service';
import { AdminAccessService } from 'src/app/layouts/user/services/admin-access.service';
import { AssessmentSummary } from 'src/app/shared/interface/assessment-summary.model';
import { SessionExpiryService } from 'src/app/shared/services/session-expiry.service';

@Component({
  selector: 'aftp-assessment-summary-modal',
  templateUrl: './assessment-summary-modal.component.html',
  styleUrls: ['./assessment-summary-modal.component.scss'],
})
export class AssessmentSummaryModalComponent implements OnInit, AfterViewInit {
  @Input() responseData: AssessmentSummary;
  @Input() assessmentID: string;
  @Input() learningID: string;
  @Input() fromHome = false;
  @ViewChild('review') review;
  public summaryData: AssessmentSummary;
  public isSummaryDataLoaded: boolean;
  public realtimeBadgeResponse: boolean;
  constructor(
    public activeModal: NgbActiveModal,
    private crudService: CrudService,
    private translate: TranslateService,
    private route: Router,
    private modelService: NgbModal,
    readonly badgePopupApiService: BadgePopupApiService,
    private loader: LoadingScreenServiceService,
    public assessmentPageService: AssessmentPageService,
    private readonly _ngbModal: NgbModal,
    private adminAccessService: AdminAccessService,
    private sessionexpirtserv: SessionExpiryService
  ) {}

  ngAfterViewInit(): void {
    this.review?.nativeElement?.focus();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'Escape') this.navigateToHome();
  }

  ngOnInit(): void {
    this.getLanguage();
    if (this.assessmentID) {
      this.initializeSummaryByID();
    }
  }

  initializeSummaryByID(): void {
    this.summaryData = this.responseData;
    this.isSummaryDataLoaded = true;
  }
  goBack(): void {
    this.assessmentPageService.setPreAssessmentClickedFromHome(false);
    this.assessmentPageService.setpreAssessmentClickedFromExplore(false);
    this.assessmentPageService.setpreAssessmentClickedFromSearch(false);
    this.assessmentPageService.setpreAssessmentClickedFromHistory(false);
    this.assessmentPageService.setAssessmentClickedFromGroups(false);
  }
  navigateToHome(): void {
    this.sessionexpirtserv.assessmentSummarypopupOpened = false;
    this.realTimeBadgesCall();
    if (this.assessmentPageService.takeAssmntPage) {
      const home = this.assessmentPageService.getPreAssessmentClickedFromHome();
      const explore =
        this.assessmentPageService.getpreAssessmentClickedFromExplore();
      const search =
        this.assessmentPageService.getpreAssessmentClickedFromSearch();
      const History =
        this.assessmentPageService.getpreAssessmentClickedFromHistory();
      const groups =
        this.assessmentPageService.getAssessmentClickedFromGroups();
      this.assessmentPageService.expandLP = true;
      this.assessmentPageService.takeAssmntPage = false;
      if (home) {
        this.goBack();
        this.route.navigate([
          `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.HOME}/${USER_ROUTER_PATHS.LEARNING_PATH}/${this.assessmentPageService.activeLearningPath}`,
        ]);
      } else if (explore) {
        this.goBack();
        this.route.navigate([
          `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.EXPLORE_LEARNING_PATHS}/${this.assessmentPageService.explorePath}`,
        ]);
      } else if (search) {
        this.goBack();
        this.route.navigateByUrl(
          `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.SEARCH}?searchType=LP`
        );
      } else if (History) {
        this.goBack();
        this.adminAccessService.setGoingBackToHistory(true);
        this.route.navigate([
          `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.HISTORY}`,
        ]);
      } else if (groups) {
        this.goBack();
        this.route.navigateByUrl(
          `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.PROGRAM}/${this.assessmentPageService.programID}/${this.assessmentPageService.groupID}?${this.assessmentPageService.type}`
        );
      } else {
        this.route.navigate([
          `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.HOME}/${USER_ROUTER_PATHS.LEARNING_PATH}/${this.assessmentPageService.activeLearningPath}`,
        ]);
      }
    }
    this.ok();
  }

  navigateToReviewPage(): void {
    this.sessionexpirtserv.assessmentSummarypopupOpened = false;
    this.realTimeBadgesCall();
    console.log(this.route.url);
    if (this.route.url.includes('explore-learning-paths')) {
      this.assessmentPageService.setpreAssessmentClickedFromExplore(true);
    } else if (this.route.url.includes('searchType=LP')) {
      this.assessmentPageService.setpreAssessmentClickedFromSearch(true);
    } else if (this.route.url.includes('home')) {
      this.assessmentPageService.setPreAssessmentClickedFromHome(true);
    } else if (this.route.url.includes('history')) {
      this.assessmentPageService.setpreAssessmentClickedFromHistory(true);
    } else if (this.route.url.includes('programs')) {
      this.adminAccessService.currentPage = Number(
        localStorage.getItem('currentPage')
      );
      const myArray = this.route.url.split('/');
      this.assessmentPageService.programID = myArray[3];
      const groupID = myArray[4].split('?');
      this.assessmentPageService.groupID = groupID[0];
      this.assessmentPageService.type = groupID[1];
      this.assessmentPageService.setPreAssessmentClickedFromGroups(true);
    }
    this.route.navigate(
      [
        `user/view-assessments/${this.assessmentID}/${this.assessmentPageService.activeLearningPath}`,
      ],
      {
        queryParams: { isFromPath: false },
      }
    );
    this.ok();
  }

  ok(): void {
    this.activeModal.dismiss('Cross click');
  }

  getLanguage(): void {
    const languageID = localStorage.getItem('languageID');
    this.translate.use(languageID ?? 'en');
  }

  realTimeBadgesCall(): void {
    this.badgePopupApiService
      .getRealTimebadgeDetails()
      .subscribe((response) => {
        this.realtimeBadgeResponse = response.result;
        if (this.realtimeBadgeResponse) {
          this.getUnseenBadgesDetails();
        }
      });
  }

  getUnseenBadgesDetails(): void {
    const LangID = localStorage.getItem('languageID') ?? 'en';
    this.badgePopupApiService
      .getUnseenBadgeDetails(LangID)
      .subscribe((result) => {
        if (result?.data?.badgesList?.badgeCount !== 0) {
          this.openBadge(result.data.badgesList);
        }
      });
  }

  openBadge(badgeInformation: BadgesList): void {
    if (badgeInformation.Animation.length) {
      const modelRef = this._ngbModal.open(BadgePopUpComponent, {
        centered: true,
        backdrop: 'static',
        keyboard: false,
      });
      modelRef.componentInstance.badgeInformation = badgeInformation;
    }
  }
}
