export enum SEARCH_TYPES {
  LEARNING_OBJECTS = 'LO',
  LEARNING_PATHS = 'LP',
  PEOPLE = 'user',
}

export enum DROPDOWN_PAGE_TYPES {
  SEARCH = 'search',
  EXPLORE = 'explore',
}

export const searchTypesArray = Object.values(SEARCH_TYPES);
export const searchTypesArrayLastIndex = searchTypesArray.length - 2;
