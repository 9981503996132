import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseApiService {
  protected constructor(protected httpClient: HttpClient) {}

  get<T>(
    url: string,
    params?: HttpParams,
    headers?: HttpHeaders
  ): Observable<T> {
    return this.httpClient
      .get<T>(url, { params, headers })
      .pipe(catchError(this.handleError));
  }

  post<R, T>(url: string, resource: T, headers?: HttpHeaders): Observable<R> {
    return this.httpClient
      .post<R>(url, resource, { headers })
      .pipe(catchError(this.handleError));
  }

  put<R, T>(url: string, resource: T, headers?: HttpHeaders): Observable<R> {
    return this.httpClient
      .put<R>(url, resource, { headers })
      .pipe(catchError(this.handleError));
  }

  delete<R>(url: string, headers?: HttpHeaders): Observable<R> {
    return this.httpClient
      .delete<R>(url, { headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error(error);
    return throwError(error);
  }
}
