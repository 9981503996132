import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { LAFormHeaderData } from '@app/shared/components/learning-activity-form-editing-header/types/la-form-header-data.interface';
import { LA_MODE } from 'src/app/shared/enums/global.enums';
import { ICONS } from '@app/shared/components/svg-icon/icons-list';
import { LA_PUBLISHING_STATUS } from 'src/app/shared/enums/lo-statuses.enums';
import { LearningObjectFormService } from '@app/layouts/user/pages/learning-object-page/services/learning-object-form-service';
@Component({
  selector: 'aftp-learning-activity-form-editing-header',
  templateUrl: './learning-activity-form-editing-header.component.html',
  styleUrls: ['./learning-activity-form-editing-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LearningActivityFormEditingHeaderComponent implements OnInit {
  private _data: LAFormHeaderData;
  private _visible = false;
  private _mode: LA_MODE;
  private _currentLAStatusID: LA_PUBLISHING_STATUS;

  public PUBLISHING_STATUS = LA_PUBLISHING_STATUS;
  isNonPlaylist = false;

  constructor(private learningObjectFormService: LearningObjectFormService) {}

  get publishTranslate(): string {
    return this.currentLAStatusID === this.PUBLISHING_STATUS.PUBLISHED
      ? 'manageLearningCreateObject.learningActivityForm.buttons.unpublish'
      : 'manageLearningCreateObject.learningActivityForm.buttons.publish';
  }

  get iconName(): string {
    return this.currentLAStatusID === this.PUBLISHING_STATUS.PUBLISHED
      ? this.ICONS.CLOUD_DOWNLOAD
      : this.ICONS.CLOUD_UPLOAD;
  }

  get currentLAStatusID(): LA_PUBLISHING_STATUS {
    return this._currentLAStatusID;
  }

  @Input()
  set currentLAStatusID(value: LA_PUBLISHING_STATUS) {
    this._currentLAStatusID = value;
  }

  @Input()
  set data(val: LAFormHeaderData) {
    this._data = val;
  }

  get data(): LAFormHeaderData {
    return this._data;
  }

  @Input()
  set visible(val: boolean) {
    this._visible = val;
  }

  get visible() {
    return this._visible;
  }

  @Input()
  set mode(val: LA_MODE) {
    this._mode = val;
  }

  get mode(): LA_MODE {
    return this._mode;
  }

  @Input() isSkillSoft: boolean;
  @Output() removeIconClicked = new EventEmitter();
  @Output() publishingIconClicked = new EventEmitter();
  @Output() closeIconClicked = new EventEmitter();
  @Output() editIconClicked = new EventEmitter();

  public LA_MODE = LA_MODE;
  public ICONS = ICONS;

  ngOnInit(): void {
    this.learningObjectFormService.formatLabelValueSubj.subscribe(
      (value: string) => {
        if (
          value != 'Learning Board' &&
          value != 'Collection' &&
          value != 'Section'
        ) {
          this.isNonPlaylist = true;
        }
      }
    );
  }

  onRemove(): void {
    this.removeIconClicked.emit('remove');
  }

  onEdit(): void {
    this.editIconClicked.emit('edit');
  }

  onClose(): void {
    this.closeIconClicked.emit('close');
  }

  onPublishingAction() {
    this.publishingIconClicked.emit(this._currentLAStatusID);
  }

  get isEditMode(): boolean {
    return this.data.mode === LA_MODE.EDITING;
  }

  get tabindex(): string {
    return this.isEditMode ? '-1' : '0';
  }
}
