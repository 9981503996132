import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AssessmentSummaryData } from 'src/app/shared/interface/assessment-summary.model';
import { ICONS } from 'src/app/shared/components/svg-icon/icons-list';
import { AssessmentPageService } from '../../services/assessment-page.service';
import { DateTimeHelper } from 'src/app/shared/helpers/date-time.helper';
import * as moment from 'moment';
import { USER_ROUTER_PATHS } from 'src/app/shared/enums/router-paths.enum';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LearningActivityType } from 'src/app/shared/constants/lo-detail.constants';
import { LearningObjectDetailService } from '@app/layouts/user/pages/learning-object-detail/services/learning-object-detail.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { SessionExpiryService } from 'src/app/shared/services/session-expiry.service';
import { AdminAccessService } from 'src/app/layouts/user/services/admin-access.service';

@Component({
  selector: 'aftp-start-assessment-modal',
  templateUrl: './start-assessment-modal.component.html',
  styleUrls: ['./start-assessment-modal.component.scss'],
})
export class StartAssessmentModalComponent implements OnInit {
  @Input() public assessmentSummaryDetails: AssessmentSummaryData;
  targetDate: string;
  public ICONS = ICONS;
  laType: number;
  displayRemainingAttempts: boolean;
  displayLastAttemptMessage: boolean;
  isFTPAPPClient;
  constructor(
    private modal: NgbModal,
    private translate: TranslateService,
    private router: Router,
    public assessmentPageService: AssessmentPageService,
    private loDetailService: LearningObjectDetailService,
    private _confirmationPopupService: ConfirmationPopupService,
    private session: SessionExpiryService,
    private adminAccessService: AdminAccessService
  ) {}
  private readonly ngUnsubscribe = new Subject<void>();
  ngOnInit(): void {
    this.isFTPAPPClient = localStorage.getItem('isFTPAPPClient');
    if (
      this.isFTPAPPClient === 'true' &&
      this.router.url.includes(`${USER_ROUTER_PATHS.LEARNING_OBJECT_DETAIL}`)
    ) {
      this.session.autoplayLoAssessment = true;
    }
    this.assessmentPageService.preassessment = false;
    if (this.assessmentSummaryDetails?.targetDate) {
      this.targetDate = moment(this.assessmentSummaryDetails?.targetDate)
        .utc()
        .format('D MMM Y');
    }
    if (this.assessmentSummaryDetails) {
      if (this.assessmentSummaryDetails.noOfAttemptsLeft !== undefined) {
        if (
          this.assessmentSummaryDetails.noOfAttemptsLeft < 0 &&
          this.assessmentSummaryDetails.noOfAttemptsLeft !== -100
        ) {
          this.assessmentSummaryDetails.noOfAttemptsLeft = 0;
        }
      }
      const isNoOfAttemptsLeft =
        this.assessmentSummaryDetails.noOfAttemptsLeft !== undefined;
      this.displayRemainingAttempts = isNoOfAttemptsLeft
        ? this.assessmentSummaryDetails.noOfAttemptsLeft >= 0
        : this.assessmentSummaryDetails.noOfAttempts > 0;
      this.displayLastAttemptMessage = isNoOfAttemptsLeft
        ? this.assessmentSummaryDetails.noOfAttemptsLeft === 1
        : this.assessmentSummaryDetails.noOfAttempts === 1;
    }
  }

  closeModal() {
    this.loDetailService
      .getLearningActivityType()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((courseType: number) => {
        this.laType = courseType; //=== LearningActivityType.Course;
      });
    if (this.laType === LearningActivityType.Assessment) {
      this.loDetailService.setLearningActivityType(LearningActivityType.Course);
      this.modal.dismissAll();
      this.router.navigate([`/${USER_ROUTER_PATHS.USER}/home`]);
    } else {
      this.modal.dismissAll();
    }
  }

  getLanguage(): void {
    const languageID = localStorage.getItem('languageID');
    this.translate.use(languageID ?? 'en');
  }

  get durationInMinutes(): number {
    return DateTimeHelper.getMinutesFromSecondsRound(
      this.assessmentSummaryDetails.duration
    );
  }
  checkisFTPAPPClient() {
    this.modal.dismissAll();
    const isFTPAPPClient = localStorage.getItem('isFTPAPPClient');
    const FTPAPPsessionExpires = localStorage.getItem('FTPAPPsessionExpires');
    if (
      isFTPAPPClient &&
      this.assessmentSummaryDetails.duration * 1000 >
        Number(FTPAPPsessionExpires) - new Date().getTime()
    ) {
      if (
        this.router.url.includes(`${USER_ROUTER_PATHS.LEARNING_OBJECT_DETAIL}`)
      ) {
        this.session.autoplayLoAssessment = true;
      }
      setTimeout(() => {
        this._confirmationPopupService.showWarningWithActionWithTranslation(
          'assessmentModal.runOutOfTime',
          () => {
            this.session.autoplayLoAssessment = false;
            this.onClickAction();
          },
          () => {
            this.modal.dismissAll();
            if (
              this.router.url.includes(
                `${USER_ROUTER_PATHS.LEARNING_OBJECT_DETAIL}`
              )
            ) {
              if (!this.session.isTimerPopupshown) {
                this.router.navigateByUrl(`${USER_ROUTER_PATHS.HOME}`);
                this.session.autoplayLoAssessment = false;
              }
            } else {
              this.session.autoplayLoAssessment = false;
            }
          }
        );
      }, 10);
    } else {
      this.session.autoplayLoAssessment = false;
      this.onClickAction();
    }
  }

  onClickAction() {
    if (this.router.url.includes('explore-learning-paths')) {
      this.assessmentPageService.setpreAssessmentClickedFromExplore(true);
    } else if (this.router.url.includes('searchType=LP')) {
      this.assessmentPageService.setpreAssessmentClickedFromSearch(true);
    } else if (this.router.url.includes('home')) {
      this.assessmentPageService.setPreAssessmentClickedFromHome(true);
    } else if (this.router.url.includes('history')) {
      this.assessmentPageService.setpreAssessmentClickedFromHistory(true);
    } else if (this.router.url.includes('programs')) {
      this.adminAccessService.currentPage = Number(
        localStorage.getItem('currentPage')
      );
      const myArray = this.router.url.split('/');
      this.assessmentPageService.programID = myArray[3];
      const groupID = myArray[4].split('?');
      this.assessmentPageService.groupID = groupID[0];
      this.assessmentPageService.type = groupID[1];
      this.assessmentPageService.setAssessmentClickedFromGroups(true);
    }
    if (
      !('noOfAttemptsLeft' in this.assessmentSummaryDetails) ||
      !this.assessmentSummaryDetails.completionStatus
    ) {
      this.assessmentPageService.countAttempt = true;
      this.router.navigate([
        `user/assessment/take/${this.assessmentSummaryDetails.assessmentID}/${this.assessmentPageService.activeLearningPath}`,
      ]);
    } else {
      this.router.navigate([
        `user/view-assessments/${this.assessmentSummaryDetails.assessmentID}/${this.assessmentPageService.activeLearningPath}`,
      ]);
    }
    this.modal.dismissAll();
  }
}
