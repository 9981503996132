export enum ICONS {
  ANGULAR = 'angular',
  ADD = 'add',
  ACADEMIC_CAP = 'academic-cap',
  SEARCH = 'search',
  CURSOR = 'cursor',
  BELL = 'bell',
  LIST = 'list',
  PERSON = 'person',
  SIDEARROW = 'side-arrow',
  TRASH = 'trash',
  CLOSE = 'close',
  CLOCK = 'clock',
  PENCIL = 'pencil',
  CLOUD_UPLOAD = 'cloud-upload',
  CLOUD_DOWNLOAD = 'cloud-download',
  PLAY_ICON = 'play-icon',
  PLAY_ICON_2 = 'play-icon-v2',
  ARROW_RIGHT = 'arrow-right',
  ARROW_LEFT = 'arrow-left',
  SMILE = 'smile',
  LOCK_CLOSED = 'lock-closed',
  BADGE_CHECK = 'badge-check',
  EXTERNAL_LINK = 'external-link',
  MENU = 'menu',
  DOCUMENT_TEXT = 'document-text',
  CHART_BAR = 'chart-bar',
  CHEVRON_DOWN = 'chevron-down',
  COG = 'cog',
  LOGOUT = 'logout',
  USER = 'user',
  ACTIVE_REMINDER = 'active-reminder',
  EXPIRED_REMINDER = 'expired-reminder',
  PLUS = 'plus',
  THUMB_UP = 'thumb-up',
  THUMB_UP_SOLID = 'thumb-up-solid',
  EXCLAMATION = 'exclamation',
  HOME_EXPLORE = 'home-explore',
  BELL_PLUS = 'bell-plus',
  PIE = 'pie',
}
