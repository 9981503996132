import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BadgesList } from '@app/shared/components/badge-pop-up/types/badge-popup-details.type';

@Injectable({
  providedIn: 'root',
})
export class BadgeAnimationService {
  private _badgeInformation$ = new Subject<BadgesList>();
  private _badgeCompletion$ = new Subject<boolean>();

  setBadgeInformation(data: BadgesList): void {
    this._badgeInformation$.next(data);
  }

  getBadgeInformation(): Observable<BadgesList> {
    return this._badgeInformation$.asObservable();
  }

  setbadgeCompletion(data: boolean): void {
    this._badgeCompletion$.next(data);
  }

  getbadgeCompletion(): Observable<boolean> {
    return this._badgeCompletion$.asObservable();
  }
}
