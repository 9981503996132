import {
  Component,
  HostListener,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  ViewEncapsulation,
  Input,
} from '@angular/core';
import { ErrorMessagesService } from 'src/app/shared/services/full-screen-error/error-messages.service';
import { Service2siteAccessOptionsService } from '@app/shared/services/service-2site-access-options.service';
import { SCREEN_SIZES } from '@app/shared/enums/screen-size.enum';
import { HeaderDetailsApiService } from '@app/shared/components/header/services/header-details.api.service';
import { TabledErrorPopupData } from 'src/app/layouts/user/pages/edit-learning-path/full-screen-error/error-popup-template/table-error-popup.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'aftp-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HeaderDetailsApiService],
})
export class ErrorPageComponent implements OnInit {
  showGoBackButton: boolean;
  isMobile: boolean;
  pageName: string;
  logoImg = '../../../../assets/images/logo.png';
  headerTitle = '';

  @Input() errorObjects: TabledErrorPopupData[] = [];
  @Input() errorMessage: string;
  @Input() doNotShowTable = false;
  @Input() fromAssessment = false;

  constructor(
    private changeDetection: ChangeDetectorRef,
    private headerDetailsApiService: HeaderDetailsApiService,
    private errorValues: ErrorMessagesService,
    private siteService: Service2siteAccessOptionsService,
    public _activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.setHeaderData();
    this.showGoBackButton = this.siteService.getAccessToMultipleSites();
    this.pageName = 'lp-group-restriction';
    this.isMobile = window.innerWidth < SCREEN_SIZES.mobileScreen;
  }

  setHeaderData() {
    this.headerDetailsApiService
      .getHeaderDetails()
      .subscribe(({ data: { programName } }) => {
        this.headerTitle = programName;
        this.changeDetection.detectChanges();
      });
  }
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isMobile = window.innerWidth < SCREEN_SIZES.mobileScreen;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this._activeModal.close();
  }

  readonly displayedColumns: string[] = ['skill', 'level', 'curator'];

  onDoneAction(): void {
    this._activeModal.close();
  }
}
