import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PreSkillAssessmentData } from 'src/app/shared/interface/learning-path-card-data.interface';

@Component({
  selector: 'aftp-pre-skill-assessment-modal',
  templateUrl: './pre-skill-assessment-modal.component.html',
  styleUrls: ['./pre-skill-assessment-modal.component.scss'],
})
export class PreSkillAssessmentModalComponent {
  constructor(
    private readonly _dialogRef: MatDialogRef<PreSkillAssessmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PreSkillAssessmentData
  ) {}

  get durationDetails(): string {
    return ` ${this.data.duration} `;
  }

  onCloseAction(): void {
    this._dialogRef.close();
  }

  onStartAssessment(): void {
    if (this.data.assessmentLink) {
      window.open(this.data.assessmentLink, '_blank', 'noopener');
      this._dialogRef.close();
    }
  }
}
