import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AvatarProfileModule } from '@app/shared/components/avatar-profile/avatar-profile.module';
import { NotificationCardComponent } from '@app/shared/components/notification-panel/notification-card/notification-card.component';
import { NotificationPanelComponent } from '@app/shared/components/notification-panel/notification-panel.component';
import { PaginationService } from '@app/shared/components/notification-panel/services/pagination.service';
import { SvgIconModule } from '@app/shared/components/svg-icon/svg-icon.module';
import { VirtualScrollPreloaderModule } from '@app/shared/components/virtual-scroll-preloader/virtual-scroll-preloader.module';
import { ConfirmationPopupService } from '@app/shared/services/confirmation-popup.service';
import { TranslateModule } from '@ngx-translate/core';
import {
  checkCircle,
  dotsVerticalSolid,
  exclamation,
  HeroIconsModule,
  xCircle,
} from 'ng-heroicons';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  declarations: [NotificationPanelComponent, NotificationCardComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    SvgIconModule,
    MatListModule,
    AvatarProfileModule,
    MatExpansionModule,
    MatButtonModule,
    MarkdownModule.forRoot(),
    MatToolbarModule,
    TranslateModule,
    HeroIconsModule.withIcons({
      dotsVerticalSolid,
      exclamation,
      checkCircle,
      xCircle,
    }),
    MatCardModule,
    VirtualScrollPreloaderModule,
    InfiniteScrollModule,
  ],
  exports: [NotificationPanelComponent],
  providers: [ConfirmationPopupService, PaginationService],
})
export class NotificationPanelModule {}
