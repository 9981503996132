<div class="filter">
  <p
    class="
      color-gray--4
      label-status
      fs-14
      group-font
      graphik
      status-level-label
    "
  >
    {{ "ManageReport.customRange" | translate }}
  </p>
  <mat-form-field
    [ngClass]="{ 'disabled-datepicker': disabled }"
    (click)="toggleDatePicker(datepicker)"
    [appearance]="appearance"
    class="aftp-datepicker"
  >
    <mat-date-range-input
      [dateFilter]="myFilter"
      [rangePicker]="datepicker"
      [class.separator]="_showplaceholder"
    >
      <input
        (dateChange)="onChangeValue($event, 'StartDate')"
        matStartDate
        [placeholder]="'ManageReport.select' | translate"
        readonly
      />
      <input
        (dateChange)="onChangeValue($event, 'EndDate')"
        matEndDate
        readonly
      />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="datepicker">
      <mat-icon matDatepickerToggleIcon>
        <hero-icon name="calendar" style="color: #9e98aa"></hero-icon>
      </mat-icon>
    </mat-datepicker-toggle>

    <mat-date-range-picker
      panelClass="aftp-calendar"
      #datepicker
    ></mat-date-range-picker>
  </mat-form-field>
</div>
