<div class="wrapper" *ngIf="visible">
  <div class="left-block">
    <span *ngIf="isNonPlaylist; else elseBlock"></span>
    <ng-template #elseBlock
      ><span class="number">{{ data?.order }}</span>
    </ng-template>
  </div>
  <div class="right-block">
    <svg-icon
      [name]="ICONS.TRASH"
      [matTooltip]="
        'manageLearningCreateObject.learningActivityForm.buttons.delete'
          | translate
      "
      width="20px"
      height="20px"
      (click)="isSkillSoft ? '' : onRemove()"
      tabindex="0"
      (keydown.enter)="onRemove()"
      [class.disabled]="isSkillSoft"
    ></svg-icon>
    <svg-icon
      [name]="iconName"
      [matTooltip]="publishTranslate | translate"
      width="20px"
      height="20px"
      [class.disabled]="isEditMode || isSkillSoft"
      (click)="isEditMode || isSkillSoft ? '' : onPublishingAction()"
      [tabindex]="tabindex"
      (keydown.enter)="onPublishingAction()"
    ></svg-icon>
    <ng-container [ngSwitch]="data?.mode">
      <ng-template [ngSwitchCase]="LA_MODE.EDITING">
        <svg-icon
          [name]="ICONS.CLOSE"
          [matTooltip]="
            'manageLearningCreateObject.learningActivityForm.buttons.cancel'
              | translate
          "
          width="24px"
          height="24px"
          (click)="onClose()"
          tabindex="0"
          (keydown.enter)="onClose()"
        ></svg-icon>
      </ng-template>
      <ng-template [ngSwitchCase]="LA_MODE.VIEW">
        <svg-icon
          [name]="ICONS.PENCIL"
          [matTooltip]="
            'manageLearningCreateObject.learningActivityForm.buttons.edit'
              | translate
          "
          width="20px"
          height="20px"
          (click)="onEdit()"
          tabindex="0"
          (keydown.enter)="onEdit()"
        ></svg-icon>
      </ng-template>
    </ng-container>
  </div>
</div>
