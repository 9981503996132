import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchCollapsedResultItem } from '@app/shared/components/search-collapsed/types/search-collapsed-result-item.type';
import { RegexpConstants } from '@app/shared/constants/regexp.constants';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  pairwise,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { BehaviorSubject, iif, of, Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { CollapsedSearchService } from '@app/shared/components/search-collapsed/services/collapsed-search.service';
import { MatInput } from '@angular/material/input';
import { SHOW_ALL_BUTTON_ID } from 'src/app/shared/constants/global.constants';
import { GlobalSearchService } from '@app/shared/services/global-search.service';
import { SCREEN_SIZES } from 'src/app/shared/enums/screen-size.enum';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BackgroundPageErrorComponent } from 'src/app/layouts/user/pages/edit-learning-path/full-screen-error/error-popup-template/background-page-error.component';
import { AdminAccessService } from 'src/app/layouts/user/services/admin-access.service';
enum SEARCH_OPTIONS {
  NOTHING_FOUND,
  SHOW_ALL,
}

@Component({
  selector: 'aftp-search-collapsed',
  templateUrl: './search-collapsed.component.html',
  styleUrls: ['./search-collapsed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchCollapsedComponent implements OnInit, OnDestroy, OnChanges {
  @Input() searchControl: FormControl;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() isShowAllResultsVisible: boolean;
  @Input() resultList: SearchCollapsedResultItem[] = [];
  @Input() noResultsText = '';
  @Input() showNoResultsLabel$ = new BehaviorSubject(false);
  @Input() specialRoute = '';

  @Output() search = new EventEmitter<string>();
  @Output() searchControlEvent = new EventEmitter<void>();
  @Output() sectItem = new EventEmitter<string>();
  @Output() toggleSearchContainer = new EventEmitter<boolean>();
  hideSearchFlag = false;
  firstSearchURLFlag = false;

  @ViewChild('searchInput') searchInput: MatInput;
  private modalRef: NgbModalRef;

  canOpenResultsPanel: boolean;
  isResultsPanelOpened: boolean;
  isSearchVisible: boolean;
  showTitleList;
  disableSearchTooltip = false;
  readonly SEARCH_OPTIONS = SEARCH_OPTIONS;

  private readonly unSubscribe$ = new Subject<void>();
  isMobile: boolean;
  screenWidth: number;
  public errorBoards = [];
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isMobile = window.innerWidth <= SCREEN_SIZES.mobileScreen;
  }
  constructor(
    private modal: NgbModal,
    private router: Router,
    private collapsedSearchService: CollapsedSearchService,
    private globalSearchService: GlobalSearchService,
    private changeDetection: ChangeDetectorRef,
    private adminAccessService: AdminAccessService
  ) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          if (event.url.includes('search')) {
            this.firstSearchURLFlag = true;
          }
          if (!event.url.includes(this.specialRoute)) {
            this.reset();
            return;
          }

          this.searchControl.enable();
          this.searchControl.setValue(
            this.globalSearchService.globalSearchValue$.value.searchParam,
            { emitEvent: false }
          );
          this.isSearchVisible = true;
          this.toggleSearchContainer.emit(true);
        }),
        takeUntil(this.unSubscribe$)
      )
      .subscribe();
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.isMobile = this.screenWidth < SCREEN_SIZES.mobileScreen;
    const nonSearchOptions = (
      searchValue: string,
      optionValue: SEARCH_OPTIONS
    ) =>
      of<[string, SEARCH_OPTIONS]>([searchValue, optionValue]).pipe(
        tap(([search, option]) => {
          this.searchControl.setValue(search);

          if (option === SEARCH_OPTIONS.SHOW_ALL) {
            this.searchControlEvent.emit();
          }
        })
      );

    const searchOptions = (lo) =>
      of(lo).pipe(
        tap((id) => {
          if (lo.isLock) {
            this.modalRef = this.modal.open(BackgroundPageErrorComponent, {
              windowClass: 'modal-delete-full',
            });
            this.modalRef.componentInstance.errorboards = this.errorBoards;
            this.errorBoards = [];
            this.searchControl.setValue('');
          } else {
            this.searchControl.setValue('');
            this.sectItem.emit(lo.id);
          }
        })
      );

    this.searchControl.valueChanges
      .pipe(
        pairwise(),
        filter(({ 1: option }) => typeof option !== 'string'),
        switchMap(([searchValue, option]) =>
          iif(
            () => !!option?.id,
            searchOptions(option),
            nonSearchOptions(searchValue, option)
          )
        ),
        takeUntil(this.unSubscribe$)
      )
      .subscribe();
    this.collapsedSearchService.isSearchResultsPanelOpened
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((openResultsPanel) => {
        if (!openResultsPanel) {
          this.onClosePanel();
        }
      });

    if (this.isSpecialPage) {
      this.isSearchVisible = true;
    }
    this.searchControlValueChanges();
  }
  searchControlValueChanges() {
    this.searchControl.valueChanges
      .pipe(
        filter((searchValue) => typeof searchValue === 'string'),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.collapsedSearchService.isSearchPageNavigation.next(false);
        if (this.searchControl.value == '') {
          this.disableSearchTooltip = false;
        } else {
          this.disableSearchTooltip = true;
        }
        if (this.searchControl.valid) {
          this.canOpenResultsPanel = true;
          this.search.emit(this.searchControl.value);
        } else {
          this.resultList = [];
          this.showNoResultsLabel$.next(false);
          this.canOpenResultsPanel = false;
          this.changeDetection.detectChanges();
        }
      });
  }
  getTitle(data): void {
    this.showTitleList = data.title;
  }
  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  onOpenPanel(): void {
    this.isResultsPanelOpened = true;
  }

  onClosePanel(): void {
    this.canOpenResultsPanel = false;
    this.resultList = [];
    this.isResultsPanelOpened = false;
  }

  get isVisibleNoResults(): boolean {
    return (
      this.showNoResultsLabel$.getValue() &&
      this.canOpenResultsPanel &&
      !this.resultList.length &&
      this.searchControl.valid
    );
  }

  toggleSearchVisibility(): void {
    this.isSearchVisible = !this.isSearchVisible;
    this.toggleSearchContainer.emit(this.isSearchVisible);
    if (this.isSearchVisible) {
      this.searchControl.enable();
      this.searchInput.focus();
    } else {
      this.searchControl.disable();
    }
  }

  emitSearchControlEvent(): void {
    if (this.isReadyForEmitSearchControlEvent()) {
      this.searchControlEvent.emit();
    }
  }

  onIconClick(): void {
    localStorage.setItem('SearchIcon', 'true');
    const searchIcon = localStorage.getItem('SearchIcon');
    this.adminAccessService.sendlearningObjectData(searchIcon);
    const isToggleable =
      !this.isSpecialPage &&
      (!this.isSearchVisible || !this.isReadyForEmitSearchControlEvent());

    if (isToggleable) {
      this.toggleSearchVisibility();
    } else {
      this.emitSearchControlEvent();
    }
  }

  reset(): void {
    this.showNoResultsLabel$.next(false);
    this.isSearchVisible = false;
    this.toggleSearchContainer.emit(this.isSearchVisible);
    this.searchControl.setValue('');
    this.searchControl.disable();
  }

  onOutsideClickReset(targetElement: HTMLElement): void {
    if (!this.isSpecialPage && targetElement.id !== SHOW_ALL_BUTTON_ID) {
      this.reset();
    }
  }

  onEscapeKeydown(): void {
    if (!this.isSpecialPage) {
      this.reset();
    }
  }

  private get isSpecialPage(): boolean {
    if (this.isMobile && this.specialRoute == 'search') {
      return false;
    }
    return this.specialRoute && this.router.url.includes(this.specialRoute);
  }

  private isReadyForEmitSearchControlEvent(): boolean {
    return (
      Boolean(this.searchControl.value) &&
      RegexpConstants.ONE_SYMBOL_EXCLUDING_SPACE.test(this.searchControl.value)
    );
  }
  ngOnChanges() {
    let onChangeFlag = false;
    if (this.firstSearchURLFlag) {
      this.hideSearchFlag = true;
      this.firstSearchURLFlag = false;
      onChangeFlag = true;
    }
    if (!onChangeFlag) {
      this.hideSearchFlag = false;
    }
  }
}
