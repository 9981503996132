class ManageLearningObjects {
  static readonly DEFAULT_PAGE_SIZE = 8;
  static readonly INITIAL_PAGE_NUMBER = 1;
}

class ManageLearningPaths {
  static readonly DEFAULT_PAGE_SIZE = 8;
  static readonly INITIAL_PAGE_NUMBER = 1;
}
class PeopleSearch {
  static readonly PEOPLE_SEARCH_MAX_SIZE = 30;
  static readonly INITIAL_OFFSET = 0;
}

class LearningObjectsSearch {
  static readonly LEARNING_OBJECTS_SEARCH_MAX_SIZE = 15;
  static readonly INITIAL_OFFSET = 0;
}

class LearningPathsSearch {
  static readonly LEARNING_PATHS_SEARCH_MAX_SIZE = 15;
  static readonly INITIAL_OFFSET = 0;
}

class Notifications {
  static readonly DEFAULT_NOTIFICATION_ID = '';
  static readonly DEFAULT_PAGE_SIZE = 8;
  static readonly INITIAL_PAGE_NUMBER = 1;
}

class ManageGroups {
  static readonly DEFAULT_PAGE_SIZE = 8;
}
export class PaginationConstants {
  static readonly MANAGE_LEARNING_OBJECTS = ManageLearningObjects;
  static readonly MANAGE_LEARNING_PATHS = ManageLearningPaths;
  static readonly PEOPLE_SEARCH = PeopleSearch;
  static readonly LEARNING_OBJECTS = LearningObjectsSearch;
  static readonly LEARNING_PATHS = LearningPathsSearch;
  static readonly NOTIFICATIONS = Notifications;
  static readonly MANAGE_GROUPS = ManageGroups;
}

export const PRELOADER_SHOW_TIMEOUT = 500;
