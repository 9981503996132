import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { AUTH_ROUTER_PATHS } from 'src/app/shared/enums/router-paths.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Cookies } from 'src/app/shared/constants/endpoints.constant';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  public _logoutStatus = new BehaviorSubject<boolean>(null);
  sessionExpired: boolean;
  constructor(
    private route: Router,
    private httpClient: HttpClient,
    private _dialog: MatDialog,
    private modal: NgbModal
  ) {
    this._logoutStatus.asObservable();
  }

  logout(): void {
    this._logoutStatus.next(true);
    if (localStorage.getItem('isEso') === 'true') {
      if (localStorage.getItem('isFTPAPPClient') === 'true') {
        this._dialog.closeAll();
        this.modal.dismissAll();
        this.route.navigateByUrl(
          `/${AUTH_ROUTER_PATHS.LOGOUT}?type=ftp-app-logout`
        );
      } else if (localStorage.getItem('isFTPAPPClient') !== 'true') {
        Auth.signOut();
      }
      this.deleteCookies();
      this.clearLocalStorage();
    } else {
      Auth.signOut().then(() => {
        this.deleteCookies();
        this.route.navigateByUrl(`/${AUTH_ROUTER_PATHS.EMPTY}`).then(() => {
          this.clearLocalStorage();
          window.location.reload();
        });
      });
    }
  }
  logoutAfterSessionTimeout(): void {
    this.sessionExpired = true;
    this._dialog.closeAll();
    this.modal.dismissAll();
    this.route.navigateByUrl(
      `/${AUTH_ROUTER_PATHS.LOGOUT}?type=ftp-app-session-expired`
    );
    this.clearLocalStorage();
  }

  killsession() {
    const isTenantSpecClient = false;
    this._logoutStatus.next(true);
    if (localStorage.getItem('isEso') === 'true') {
      this.deleteCookies();
      this.clearLocalStorage();
    } else {
      Auth.signOut().then(() => {
        this.deleteCookies();
        this.clearLocalStorage();
      });
    }
  }

  clearLocalStorage(): void {
    localStorage.removeItem('tenatUserpoolDetails');
    localStorage.removeItem('isEso');
    localStorage.removeItem('tenantID');
    localStorage.removeItem('tenantName');
    localStorage.removeItem('userId');
    localStorage.removeItem('PoolId');
    localStorage.removeItem('isMFAEnabled');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('isAiccClient');
    localStorage.removeItem('sessionTimeout');
    localStorage.removeItem('loginImageRotationNumber');
    localStorage.removeItem('isMFAVerificationDone');
    localStorage.removeItem('isEulaAccepted');
    localStorage.removeItem('SearchIcon');
    localStorage.removeItem('NavigationClick');
    localStorage.removeItem('DropdownSelect');
    localStorage.removeItem('learningPathCardsKey');
    localStorage.removeItem('isTenantSpecClient');
    localStorage.removeItem('isFTPAPPClient');
    localStorage.removeItem('FTPAPPsessionExpires');
    localStorage.removeItem('isFTPAPPClientWarningPopUpShown');
    localStorage.removeItem('FTPAPPsessionExpiryWarningTimer');
    localStorage.removeItem('eid');
    sessionStorage.removeItem('shownPopup');
    sessionStorage.removeItem('shownPopupLogout');
    sessionStorage.removeItem('FTPAPPClientshownPopupLogout');
    localStorage.removeItem('hcmTokenValidity');
    sessionStorage.removeItem('FtpEulaAccepted');
    sessionStorage.removeItem('ftpSessionExipred');
    sessionStorage.removeItem('ftpNewSession');
  }
  deleteCookies() {
    this.deleteCookieInfo().subscribe();
  }
  deleteCookieInfo(): Observable<void> {
    return this.httpClient.delete<void>(`${Cookies.deleteCookies}`, {
      withCredentials: true,
    });
  }

  logoutTenantSpecificUser(logoutURL: string): void {
    // Client specific logout page
    this._logoutStatus.next(true);
    this.deleteCookies();
    this.clearLocalStorage();
    window.location.replace(logoutURL);
  }
}
