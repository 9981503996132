import { Injectable } from '@angular/core';
import { BaseApiService } from '@app/shared/services/base-api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from '@app/shared/services/session.service';
import { Endpoints } from '@app/shared/constants/endpoints.constant';
import { REMINDER_PROMPT_ACTION } from 'src/app/shared/enums/reminder-prompt-message.enum';
import {
  GetReminderPromptResponse,
  SetReminderPromptResponse,
} from 'src/app/shared/interface/learning-path-card-data.interface';

@Injectable()
export class ReminderPromptApiService extends BaseApiService {
  constructor(
    protected httpClient: HttpClient,
    private sessionService: SessionService
  ) {
    super(httpClient);
  }

  getPrompt(): Observable<GetReminderPromptResponse> {
    return this.get(
      Endpoints.REMINDER_PROMPT.retrievePrompt(this.sessionService.userId)
    );
  }

  setPrompt(
    action: REMINDER_PROMPT_ACTION
  ): Observable<SetReminderPromptResponse> {
    const parameters = new HttpParams().set(
      Endpoints.REMINDER_PROMPT.QUERY_PARAMS.ACTION,
      action
    );
    return this.get(
      Endpoints.REMINDER_PROMPT.setPrompt(this.sessionService.userId),
      parameters
    );
  }
}
