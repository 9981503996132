import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { ICONS } from '@app/shared/components/svg-icon/icons-list';
import { Router } from '@angular/router';
import { LogoutService } from '@app/shared/services/logout.service';
import { HamburgerMenuData } from '@app/shared/components/header/types/hamburger-menu-response.interface';
import { HAMBURGER_MENU_ITEMS } from 'src/app/shared/enums/hamburger-menu-item.enum';
import {
  MenuNameIconMap,
  MenuNameRouteMap,
} from '@app/shared/components/new-hamburger-menu/utils/hamburger-menu-serializer';
import { SCREEN_SIZES } from 'src/app/shared/enums/screen-size.enum';
import { NotForMobile } from 'src/app/shared/constants/global.constants';
import { DOCUMENT } from '@angular/common';
import { AppRoutes } from '../../constants/app-routes.constant';
import { BehaviorSubject } from 'rxjs';
import { AvatarProfile } from '../avatar-profile/types/avatar-profile.type';
import { AdminAccessService } from 'src/app/layouts/user/services/admin-access.service';
import { AvatarService } from 'src/app/shared/services/avatar.service';
import { TenantUserPool } from '../../interface/common-model';
import { IdleService } from '../../services/idleService';

@Component({
  selector: 'aftp-new-hamburger-menu',
  templateUrl: './new-hamburger-menu.component.html',
  styleUrls: ['./new-hamburger-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NewHamburgerMenuComponent implements OnInit {
  private _data: HamburgerMenuData[];
  private _iconSize = '20px';
  screenWidth: number;
  isMobileView: boolean;
  notebook: string;
  configure: string;
  manage: string;
  adminster: string;
  userName: string;
  profileData$ = new BehaviorSubject<AvatarProfile>({
    avatarURL: '',
    userFirstName: '',
    userLastName: '',
    isActive: '',
  });
  userFirstName: string;
  showMenu = new BehaviorSubject<boolean>(false);
  bc = new BroadcastChannel('AFTP_BROWSER_SESSION_EXPIRY_CHANNEL');
  bcFTPAPPClientSession = new BroadcastChannel(
    'AFTP_FTP_APP_CLIENTS_SESSION_EXPIRY_CHANNEL'
  );
  isManageFilesPage = '';

  @Input()
  set data(val: HamburgerMenuData[]) {
    this._data = val;
  }

  get data(): HamburgerMenuData[] {
    return this._data;
  }

  @Input()
  set iconSize(val: string) {
    this._iconSize = val;
  }

  get iconSize(): string {
    return this._iconSize;
  }
  @Input() badgentfcndot: boolean;

  getDisplayName(name: string): string {
    return `header.hamburgerMenu.${name}`;
  }

  getMenuIconName(menuItem: HamburgerMenuData): ICONS {
    if (menuItem?.children.length) {
      return ICONS.CHEVRON_DOWN;
    }
    return MenuNameIconMap.get(HAMBURGER_MENU_ITEMS[menuItem.menuKey]);
  }

  public ICONS = ICONS;
  public activeMenuItem: HamburgerMenuData | unknown = {};

  constructor(
    private router: Router,
    private logoutService: LogoutService,
    private adminAccessService: AdminAccessService,
    private avatarService: AvatarService,
    private idle: IdleService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.checkMobileView();
    this.activeMenuItem = {};
    this.userName = window.localStorage.getItem('userName');
    this.userFirstName = window.localStorage.getItem('userFirstName');
    this.profileData$.next({
      avatarURL: this.avatarService.avatarLocalStorage,
      userFirstName: window.localStorage.getItem('userFirstName'),
      userLastName: window.localStorage.getItem('userLastName'),
      isActive: 'true',
    });
    this.showMenu.subscribe((value) => {
      if (!value) {
        this.onCloseMenu();
      }
    });
    this.isManageFilesPage = localStorage.getItem('isManageFilesPage');
  }

  async onClick(
    event: Event,
    menuItem: HamburgerMenuData,
    index: number
  ): Promise<void> {
    if (!(menuItem.children && menuItem.children.length)) {
      if (
        HAMBURGER_MENU_ITEMS[menuItem.menuKey] !== HAMBURGER_MENU_ITEMS.LOGOUT
      ) {
        localStorage.setItem('DropdownSelect', 'false');
        localStorage.setItem('NavigationClick', 'true');
        localStorage.setItem('SearchIcon', 'false');
        if (
          HAMBURGER_MENU_ITEMS[menuItem.menuKey] ===
          HAMBURGER_MENU_ITEMS.HISTORY
        ) {
          this.adminAccessService.historyFromHamBurger = true;
        }
        await this.router.navigateByUrl(
          `/user/${MenuNameRouteMap.get(
            HAMBURGER_MENU_ITEMS[menuItem.menuKey]
          )}`
        );
        if (
          HAMBURGER_MENU_ITEMS[menuItem.menuKey] ===
          HAMBURGER_MENU_ITEMS.REPORTS
        ) {
          await this.router.navigateByUrl(
            `/user/${MenuNameRouteMap.get(
              HAMBURGER_MENU_ITEMS[menuItem.menuKey]
            )}`
          );
        }
      } else {
        this.checkPopupLogout();
      }
    } else {
      event.stopPropagation();
      this.checkactiveMenuItem(index, menuItem);
    }
  }

  checkPopupLogout() {
    this.avatarService.avatarLocalStorage = '';
    if (localStorage.getItem('isTenantSpecClient') === 'true') {
      const poolDetails: TenantUserPool = JSON.parse(
        localStorage.getItem('tenatUserpoolDetails')
      );
      this.logoutService.logoutTenantSpecificUser(poolDetails.signOut);
    } else if (localStorage.getItem('isFTPAPPClient') === 'true') {
      this.idle.clearIntravel();
      this.logoutService.logout();
      sessionStorage.setItem('FTPAPPClientshownPopupLogout', 'true');
      this.bcFTPAPPClientSession.postMessage('FTPAPPClientshownPopupLogout');
    } else {
      sessionStorage.setItem('shownPopupLogout', 'true');
      this.bc.postMessage('shownPopupLogout');
      this.document.documentElement.style.setProperty(
        '--primary-color',
        '#a100ff'
      );
      this.logoutService.logout();
    }
  }

  checkactiveMenuItem(index: number, menuItem: HamburgerMenuData) {
    const activeMenuItemIndex = Object.keys(this.activeMenuItem)[0];
    const noExpandedMenuItems = isNaN(Number(activeMenuItemIndex));
    if (this.isManageFilesPage == 'false') {
      menuItem.children.forEach((item, i) => {
        if (HAMBURGER_MENU_ITEMS[item.menuKey] == HAMBURGER_MENU_ITEMS.FILES) {
          menuItem.children.splice(i, 1);
        }
      });
    }
    if (noExpandedMenuItems) {
      this.activeMenuItem[index] = menuItem;
    } else {
      this.activeMenuItem = {};

      if (Number(activeMenuItemIndex) !== index) {
        this.activeMenuItem[index] = menuItem;
      }
    }
  }

  onCloseMenu(): void {
    this.activeMenuItem = {};
  }

  @HostListener('window:resize', ['$event'])
  checkMobileView() {
    this.notebook = NotForMobile.ROUTE_LIST.NOTEBOOK;
    this.manage = NotForMobile.ROUTE_LIST.MANAGE;
    this.configure = NotForMobile.ROUTE_LIST.CONFIGURE;
    this.adminster = NotForMobile.ROUTE_LIST.ADMINISTER;
    const mediumMobileWidth = SCREEN_SIZES.mobileScreen;
    this.screenWidth = window.innerWidth;
    if (window.innerWidth < mediumMobileWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
  profileRoute(): void {
    localStorage.setItem('NavigationClick', 'true');
    if (this.isMobileView && this.badgentfcndot) {
      this.badgentfcndot = false;
    }
    this.router.navigate([AppRoutes.USER_PROFILE]);
  }
}
