export class Size {
  static readonly SKILL_EDIT_POPUP_SIZE = '744px';
  static readonly CONFIRMATION_POPUP_MEDIUM_SIZE = '344px';
  static readonly CONFIRMATION_POPUP_LARGE_SIZE = '440px';
  static readonly TRANSCRIPT_PAGE_HEIGHT = '1040px';
  static readonly TRANSCRIPT_PAGE_WIDTH = '1800px';
  static readonly SKILL_PRE_ASSESSMENT_POPUP_WIDTH = '544px';
  static readonly SKILL_PRE_ASSESSMENT_POPUP_HEIGHT = '428px';
  static readonly CONFIRMATION_POPUP_HEIGHT = '221px';
}
