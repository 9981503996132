<div
  [class.new-grid-container]="flatFotter()"
  [class.grid-container]="!flatFotter()"
  [ngStyle]="{ 'background-image': imageUrl }"
  [ngClass]="showBackgroundImage ? 'showImg' : 'notshowImg'"
  *ngIf="hideContentLandscape"
>
  <!-- pls make [isTransparent] this falg dynamic when we need white clr icons -->
  <aftp-header
    *ngIf="isHeaderVisible"
    class="header-component non-printable"
    [isTransparent]="isHomePage"
    [isLoginTransparent]="isLoginPage"
    [isSiteLoginTransparent]="isSiteLoginPage"
    [isHome]="isHomePage"
    [menu]="hamburgerMenuTree$ | async"
    [isTranscript]="isTranscriptPage"
    [isError]="isErrorPage"
    [isUnauthorizedHeaderVisible]="isUnauthorizedHeaderVisible"
    [isAssessmentPage]="isAssessmentPage"
    [route]="route"
  ></aftp-header>
  <div
    class="main"
    [ngClass]="{ notranslate: !isAICCPage && !isTranscriptPage }"
  >
    <router-outlet></router-outlet>
  </div>
  <aftp-footer
    *ngIf="footerVisibility"
    [isUnauthorizedFooterVisible]="isUnauthorizedFooterVisible"
    [isTranscript]="isTranscriptPage"
    [flatFotter]="flatFotter()"
  ></aftp-footer>
</div>
<aftp-preloader
  aria-busy="true"
  aria-live="assertive"
  *ngIf="
    (showOverlayHttpReq$ | async) ||
    (showOverlay$ | async) ||
    tenantspec ||
    FTPAPPGatewayuser
  "
>
</aftp-preloader>
<div *ngIf="showErrorLandScape">
  <div class="tab-button-container d-flex flex-column">
    <p
      class="
        align-center
        fs-14
        graphik-medium
        text-semi-gray text-center
        semi-black
      "
    >
      {{ "historyPage.landscapeMessage" | translate }}
    </p>
  </div>
</div>
<div *ngIf="showErrorPortrait">
  <div class="tab-button-container d-flex flex-column">
    <p
      class="
        align-middle
        fs-14
        graphik-medium
        text-semi-gray text-center
        semi-black
      "
    >
      {{ "historyPage.portraitMessage" | translate }}
    </p>
  </div>
</div>
