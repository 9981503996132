export enum REMINDER_PROMPT_MESSAGE {
  ZERO_PROMPT_WEB,
  ZERO_PROMPT_MOB,
  FIRST_PROMPT_WEB_LOW,
  FIRST_PROMPT_WEB_MEDIUM,
  FIRST_PROMPT_WEB_RAG_RED,
  FIRST_PROMPT_WEB_RAG_OVERDUE,
  FIRST_PROMPT_WEB_RAG_AMBER,
  FIRST_PROMPT_MOB_LOW,
  FIRST_PROMPT_MOB_MEDIUM,
  FIRST_PROMPT_MOB_RAG_RED,
  FIRST_PROMPT_MOB_RAG_OVERDUE,
  FIRST_PROMPT_MOB_RAG_AMBER,
  SECOND_PROMPT_WEB_LOW,
  SECOND_PROMPT_WEB_MEDIUM,
  SECOND_PROMPT_WEB_RAG_RED,
  SECOND_PROMPT_WEB_RAG_OVERDUE,
  SECOND_PROMPT_WEB_RAG_AMBER,
  SECOND_PROMPT_MOB_LOW,
  SECOND_PROMPT_MOB_MEDIUM,
  SECOND_PROMPT_MOB_RAG_RED,
  SECOND_PROMPT_MOB_RAG_OVERDUE,
  SECOND_PROMPT_MOB_RAG_AMBER,
  THIRD_PROMPT_WEB,
  THIRD_PROMPT_MOB,
}

const translationPrefix = 'settings.reminder.prompts.';
export const reminderPromptMessagesTranslationObject = {
  [REMINDER_PROMPT_MESSAGE.ZERO_PROMPT_WEB]: `${translationPrefix}ZERO_PROMPT_WEB`,
  [REMINDER_PROMPT_MESSAGE.ZERO_PROMPT_MOB]: `${translationPrefix}ZERO_PROMPT_MOB`,
  [REMINDER_PROMPT_MESSAGE.FIRST_PROMPT_WEB_LOW]: `${translationPrefix}FIRST_PROMPT_WEB_LOW`,
  [REMINDER_PROMPT_MESSAGE.FIRST_PROMPT_WEB_MEDIUM]: `${translationPrefix}FIRST_PROMPT_WEB_MEDIUM`,
  [REMINDER_PROMPT_MESSAGE.FIRST_PROMPT_WEB_RAG_RED]: `${translationPrefix}FIRST_PROMPT_WEB_RAG_RED`,
  [REMINDER_PROMPT_MESSAGE.FIRST_PROMPT_WEB_RAG_OVERDUE]: `${translationPrefix}FIRST_PROMPT_WEB_RAG_OVERDUE`,
  [REMINDER_PROMPT_MESSAGE.FIRST_PROMPT_WEB_RAG_AMBER]: `${translationPrefix}FIRST_PROMPT_WEB_RAG_AMBER`,
  [REMINDER_PROMPT_MESSAGE.FIRST_PROMPT_MOB_LOW]: `${translationPrefix}FIRST_PROMPT_MOB_LOW`,
  [REMINDER_PROMPT_MESSAGE.FIRST_PROMPT_MOB_MEDIUM]: `${translationPrefix}FIRST_PROMPT_MOB_MEDIUM`,
  [REMINDER_PROMPT_MESSAGE.FIRST_PROMPT_MOB_RAG_RED]: `${translationPrefix}FIRST_PROMPT_MOB_RAG_RED`,
  [REMINDER_PROMPT_MESSAGE.FIRST_PROMPT_MOB_RAG_OVERDUE]: `${translationPrefix}FIRST_PROMPT_MOB_RAG_OVERDUE`,
  [REMINDER_PROMPT_MESSAGE.FIRST_PROMPT_MOB_RAG_AMBER]: `${translationPrefix}FIRST_PROMPT_MOB_RAG_AMBER`,
  [REMINDER_PROMPT_MESSAGE.SECOND_PROMPT_WEB_LOW]: `${translationPrefix}SECOND_PROMPT_WEB_LOW`,
  [REMINDER_PROMPT_MESSAGE.SECOND_PROMPT_WEB_MEDIUM]: `${translationPrefix}SECOND_PROMPT_WEB_MEDIUM`,
  [REMINDER_PROMPT_MESSAGE.SECOND_PROMPT_WEB_RAG_RED]: `${translationPrefix}SECOND_PROMPT_WEB_RAG_RED`,
  [REMINDER_PROMPT_MESSAGE.SECOND_PROMPT_WEB_RAG_OVERDUE]: `${translationPrefix}SECOND_PROMPT_WEB_RAG_OVERDUE`,
  [REMINDER_PROMPT_MESSAGE.SECOND_PROMPT_WEB_RAG_AMBER]: `${translationPrefix}SECOND_PROMPT_WEB_RAG_AMBER`,
  [REMINDER_PROMPT_MESSAGE.SECOND_PROMPT_MOB_LOW]: `${translationPrefix}SECOND_PROMPT_MOB_LOW`,
  [REMINDER_PROMPT_MESSAGE.SECOND_PROMPT_MOB_MEDIUM]: `${translationPrefix}SECOND_PROMPT_MOB_MEDIUM`,
  [REMINDER_PROMPT_MESSAGE.SECOND_PROMPT_MOB_RAG_RED]: `${translationPrefix}SECOND_PROMPT_MOB_RAG_RED`,
  [REMINDER_PROMPT_MESSAGE.SECOND_PROMPT_MOB_RAG_OVERDUE]: `${translationPrefix}SECOND_PROMPT_MOB_RAG_OVERDUE`,
  [REMINDER_PROMPT_MESSAGE.SECOND_PROMPT_MOB_RAG_AMBER]: `${translationPrefix}SECOND_PROMPT_MOB_RAG_AMBER`,
  [REMINDER_PROMPT_MESSAGE.THIRD_PROMPT_WEB]: `${translationPrefix}THIRD_PROMPT_WEB`,
  [REMINDER_PROMPT_MESSAGE.THIRD_PROMPT_MOB]: `${translationPrefix}THIRD_PROMPT_MOB`,
};

export enum REMINDER_PROMPT_ACTION {
  SETUP = 'setup',
  DECLINE = 'decline',
}

export enum REMINDER_PROMPT_THEME {
  LIGHT = 'light',
  DARK = 'dark',
}
