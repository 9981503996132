import { Injectable } from '@angular/core';
import { TenantUserPool } from 'src/app/shared/interface/common-model';
import Amplify from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class AmplifyConfigurationService {
  configAmplify(tenatsAuthConfigDetails: TenantUserPool): void {
    let amplifyAuthConfig;
    if (localStorage.getItem('isEso') === 'false') {
      amplifyAuthConfig = {
        // REQUIRED - Amazon Cognito Region
        region: tenatsAuthConfigDetails.region,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: tenatsAuthConfigDetails.poolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: tenatsAuthConfigDetails.clientId,
      };
    } else if (localStorage.getItem('isEso') === 'true') {
      amplifyAuthConfig = {
        // REQUIRED - Amazon Cognito Region
        region: tenatsAuthConfigDetails.region,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: tenatsAuthConfigDetails.poolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: tenatsAuthConfigDetails.clientId,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        // mandatorySignIn: false,

        oauth: {
          domain: tenatsAuthConfigDetails.domain,

          scope: [
            'email',
            'openid',
            'profile',
            'aws.cognito.signin.user.admin',
          ],

          redirectSignIn: tenatsAuthConfigDetails.redirectUrl,

          redirectSignOut: tenatsAuthConfigDetails.signOut,

          responseType: 'code', // or token
        },
      };
    }
    Amplify.configure(amplifyAuthConfig);
  }
}
