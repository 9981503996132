import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { USER_ROUTER_PATHS } from 'src/app/shared/enums/router-paths.enum';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { SCREEN_SIZES } from '../../enums/screen-size.enum';

@Component({
  selector: 'aftp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FooterComponent implements OnInit {
  @Input() isUnauthorizedFooterVisible = false;
  @Input() isTranscript: boolean;
  @Input() flatFotter = false;
  copyrightYear: any;
  FAQLink = `${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.FAQ}`;
  HelpLink = `${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.CONTACT_US}`;
  displayPrivacyStatement = false;
  isMobile = false;
  constructor(
    private router: Router,
    private userDetailsService: UserDetailsService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isMobile = window.innerWidth <= SCREEN_SIZES.mobileScreen;
  }
  ngOnInit(): void {
    this.userDetailsService.PrivacyStatement$.subscribe((val) => {
      this.displayPrivacyStatement = val;
    });
    this.isMobile = window.innerWidth <= SCREEN_SIZES.mobileScreen;
    this.copyrightYear = new Date().getFullYear();
  }
  navigateLink(value): void {
    if (value == 'FAQLink') {
      this.router.navigateByUrl(
        `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.FAQ}`
      );
      window.scrollTo(0, 0);
    }
    if (value == 'HelpLink') {
      this.router.navigateByUrl(
        `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.CONTACT_US}`
      );
      window.scrollTo(0, 0);
    }
  }
}
