import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'aftp-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @Input() isLinkInactive = false;
  @Input() titleLogo: string;
  @Input() logoSrc: string;
  @Input() logoLink: string;
  @Input() isUnauthorizedLogo = false;
  @Input() displayProgramName;
  @Input() isHome;
  get titleLogoName() {
    if (this.titleLogo === 'Build your Inclusion & Diversity Quotient I&DQ') {
      return true;
    } else {
      return false;
    }
  }
  get link(): string {
    return this.isLinkInactive ? null : this.logoLink;
  }
}
