export enum EXPLORE_LEARNING_PATH_SORT {
  DEFAULT = 'Default',
  ALPHABETICALLY = 'Alphabetically',
  MOST_COMPLETED = 'Most_Completed',
}

export enum EXPLORE_SUBJECT_LENGTH {
  ERROR_MESSAGE = 0,
  LEARNING_OBJECTS = 1,
}

export enum EXPLORE_LEARNING_OBJECT_SEARCH_PARAM {
  RELEVANCE = 'relevance',
  MOST_COMPLETED = 'mostCompleted',
  ALPHABETICALLY = 'alphabetically',
}

export enum EXPLORE_DATA_TYPES {
  CATEGORIES = 'Categories',
  SUBJECTS = 'Subjects',
  LEARNING_PATH = 'LearningPath',
}

export enum EXPLORE_DATA_AMOUNTS {
  ZERO_SKILL_LEVELS = 0,
  MIN_SKILL_LEVELS_AMOUNT = 1, //NOTE: this should always be a non-zero number
  MAX_LPS_PER_SECTION = 3,
  LO_AMOUNT_FOR_DEFAULT_SCREENS = 3,
  LO_AMOUNT_FOR_XL_SCREENS = 4,
}
