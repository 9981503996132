export enum WEEK_DAY {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export enum WEEK_DAY_NUMBER {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

export const weekDayToNumberMap: Record<WEEK_DAY, number> = {
  [WEEK_DAY.SUNDAY]: 0,
  [WEEK_DAY.MONDAY]: 1,
  [WEEK_DAY.TUESDAY]: 2,
  [WEEK_DAY.WEDNESDAY]: 3,
  [WEEK_DAY.THURSDAY]: 4,
  [WEEK_DAY.FRIDAY]: 5,
  [WEEK_DAY.SATURDAY]: 6,
};
