import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Endpoints } from 'src/app/shared/constants/endpoints.constant';
import {
  Certificate,
  CertificateListResponse,
  CertificatePayload,
  Configs,
  ContentListResponse,
  ContentPayload,
  ValidationCheckPayload,
  ValidationCheckResponse,
  CertificateStatusPayload,
  CertificateStatusResponse,
  UploadStatusPayload,
  UploadStatusResponse,
  CertificateDownloadResponse,
  CertificateDownloadPayload,
} from './manage-content.constants';
import { ApiConstants } from 'src/app/shared/constants/api.constants';

@Injectable({
  providedIn: 'root',
})
export class ManageContentService {
  s3Bucket: string;
  constructor(private httpClient: HttpClient) {}
  latestUpdatedFile$ = new BehaviorSubject<{
    fileID: string;
    status: string;
    fileCategory: string;
  }>(null);
  getConfigs(langID: string): Observable<Configs> {
    return this.httpClient.get<Configs>(Endpoints.CONTENT.getConfigs(langID));
  }
  getConfigsCertificate(langID: string): Observable<Certificate> {
    return this.httpClient.get<Certificate>(
      Endpoints.CONTENT.getConfigsCertificate(langID)
    );
  }
  InprogressListCall(): Observable<any> {
    return this.httpClient.put<any>(Endpoints.CONTENT.getInprogressFiles, {});
  }
  getFiles(payload: ContentPayload): Observable<ContentListResponse> {
    return this.httpClient.post<ContentListResponse>(
      Endpoints.CONTENT.getFiles,
      payload
    );
  }
  getCertificates(
    payload: CertificatePayload
  ): Observable<CertificateListResponse> {
    return this.httpClient.post<CertificateListResponse>(
      Endpoints.CERTIFICATE.getCertificates,
      payload
    );
  }

  uploadStatus(
    payload: UploadStatusPayload,
    showLoader = false
  ): Observable<UploadStatusResponse> {
    const headers = new HttpHeaders({
      [ApiConstants.SKIP_LOADING_HEADER.KEY]: showLoader ? 'false' : 'true',
    });
    return this.httpClient.put<UploadStatusResponse>(
      Endpoints.CONTENT.uploadStatus,
      payload,
      { headers }
    );
  }
  certificateStatus(
    payload: CertificateStatusPayload,
    showLoader = false
  ): Observable<CertificateStatusResponse> {
    const headers = new HttpHeaders({
      [ApiConstants.SKIP_LOADING_HEADER.KEY]: showLoader ? 'false' : 'true',
    });
    return this.httpClient.put<CertificateStatusResponse>(
      Endpoints.CERTIFICATE.certificateStatus,
      payload,
      { headers }
    );
  }
  certificateDownload(
    payload: CertificateDownloadPayload,
    showLoader = false
  ): Observable<CertificateDownloadResponse> {
    return this.httpClient.post<CertificateDownloadResponse>(
      Endpoints.CERTIFICATE.certificateDownload,
      payload
    );
  }
  validationCheckIfFileExists(
    payload: ValidationCheckPayload
  ): Observable<ValidationCheckResponse> {
    return this.httpClient.post<ValidationCheckResponse>(
      Endpoints.CONTENT.validationCheckIfFileExists,
      payload
    );
  }
}
