import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  SettingsList,
  Timezone,
} from 'src/app/shared/interface/languages.model';
import * as moment from 'moment-timezone';
import { LANGUAGE_CODE } from '../constants/language';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsStateService {
  private readonly _userSettingsState$ = new BehaviorSubject<SettingsList>(
    null
  );

  public readonly userSettings$ = this._userSettingsState$.asObservable();

  set userSettings(settingsList: SettingsList) {
    UserSettingsStateService.initMomentTimezone(
      settingsList?.data[0]?.timezone
    );
    this._userSettingsState$.next(settingsList);
  }

  get userSettings(): SettingsList {
    return this._userSettingsState$.getValue();
  }

  public static initMomentTimezone(timezone: Timezone): void {
    if (!timezone?.name) {
      moment.tz.setDefault();
      return;
    }
    moment.tz.setDefault(timezone.name);
  }

  get timezone(): Timezone {
    return this.userSettings?.data[0]?.timezone;
  }

  get languageCode(): LANGUAGE_CODE | undefined {
    // Converting backend values to FE language codes
    return this.userSettings?.data?.[0].lang?.lCode.toLowerCase() as LANGUAGE_CODE;
  }
}
