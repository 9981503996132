import { USER_ROUTER_PATHS } from 'src/app/shared/enums/router-paths.enum';
import { NOTIFICATION_TYPE } from 'src/app/shared/enums/notification-types.enum';
import { AppRoutes } from '@app/shared/constants/app-routes.constant';

//TODO The list will be expanded by the number of corresponding pages.
export const allowedTypesForRedirectAction: NOTIFICATION_TYPE[] = [
  NOTIFICATION_TYPE.CHANGE_SETTINGS,
  NOTIFICATION_TYPE.UPDATE_PROFILE_PHOTO,
  NOTIFICATION_TYPE.SKILL_ACCOMPLISHMENTS,
  NOTIFICATION_TYPE.PRE_SKILL_ASSESSMENT_AVAILABLE,
  NOTIFICATION_TYPE.PRE_SKILL_ASSESSMENT_EXPIRY,
  NOTIFICATION_TYPE.PRE_SKILL_ASSESSMENT_RESULT,
  NOTIFICATION_TYPE.NEW_LIKE_ON_POST,
  NOTIFICATION_TYPE.NEW_COMMENT_ON_POST,
  NOTIFICATION_TYPE.NEW_TAG_ON_POST,
  NOTIFICATION_TYPE.NEW_TAGS_ON_POST,
  NOTIFICATION_TYPE.NEW_LIKE_ON_COMMENT,
  NOTIFICATION_TYPE.LEARNING_PATH_COMPLETE,
];

export const avatarSupportedTypes: NOTIFICATION_TYPE[] = [
  NOTIFICATION_TYPE.NEW_COMMENT_ON_POST,
  NOTIFICATION_TYPE.NEW_TAG_ON_POST,
  NOTIFICATION_TYPE.NEW_TAGS_ON_POST,
  NOTIFICATION_TYPE.NEW_LIKE_ON_POST,
  NOTIFICATION_TYPE.NEW_LIKE_ON_COMMENT,
];

export const paramsSupportedTypes: NOTIFICATION_TYPE[] = [
  NOTIFICATION_TYPE.PRE_SKILL_ASSESSMENT_AVAILABLE,
  NOTIFICATION_TYPE.PRE_SKILL_ASSESSMENT_EXPIRY,
  NOTIFICATION_TYPE.PRE_SKILL_ASSESSMENT_RESULT,
  NOTIFICATION_TYPE.NEW_LIKE_ON_POST,
  NOTIFICATION_TYPE.NEW_COMMENT_ON_POST,
  NOTIFICATION_TYPE.NEW_TAG_ON_POST,
  NOTIFICATION_TYPE.NEW_LIKE_ON_COMMENT,
  NOTIFICATION_TYPE.NEW_TAGS_ON_POST,
];

export const typeToRouteMap = {
  [NOTIFICATION_TYPE.CHANGE_SETTINGS]: `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.SETTINGS}`,
  [NOTIFICATION_TYPE.UPDATE_PROFILE_PHOTO]: `/${AppRoutes.USER_PROFILE}`,
  [NOTIFICATION_TYPE.SKILL_ACCOMPLISHMENTS]: `/${AppRoutes.USER_PROFILE}`,
  [NOTIFICATION_TYPE.PRE_SKILL_ASSESSMENT_AVAILABLE]: `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.HOME}/${USER_ROUTER_PATHS.LEARNING_PATH}`,
  [NOTIFICATION_TYPE.PRE_SKILL_ASSESSMENT_EXPIRY]: `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.HOME}/${USER_ROUTER_PATHS.LEARNING_PATH}`,
  [NOTIFICATION_TYPE.PRE_SKILL_ASSESSMENT_RESULT]: `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.HOME}/${USER_ROUTER_PATHS.LEARNING_PATH}`,
  [NOTIFICATION_TYPE.NEW_LIKE_ON_POST]: `/${USER_ROUTER_PATHS.USER}`,
  [NOTIFICATION_TYPE.NEW_COMMENT_ON_POST]: `/${USER_ROUTER_PATHS.USER}`,
  [NOTIFICATION_TYPE.NEW_LIKE_ON_COMMENT]: `/${USER_ROUTER_PATHS.USER}`,
  [NOTIFICATION_TYPE.NEW_TAG_ON_POST]: `/${USER_ROUTER_PATHS.USER}`,
  [NOTIFICATION_TYPE.NEW_TAGS_ON_POST]: `/${USER_ROUTER_PATHS.USER}`,
  [NOTIFICATION_TYPE.LEARNING_PATH_COMPLETE]: `/${AppRoutes.USER_PROFILE}`,
};

export const scrollToViewByTypeValue = {
  [NOTIFICATION_TYPE.PRE_SKILL_ASSESSMENT_AVAILABLE]: `learningPathCardsSection`,
  [NOTIFICATION_TYPE.PRE_SKILL_ASSESSMENT_EXPIRY]: `learningPathCardsSection`,
  [NOTIFICATION_TYPE.PRE_SKILL_ASSESSMENT_RESULT]: `learningPathCardsSection`,
};
