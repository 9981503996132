import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NOTIFICATION_STATUS } from 'src/app/shared/enums/notification-types.enum';
import { NotificationListResponse } from '@app/shared/components/notification-panel/types/notification-card.interface';
import { ApiConstants } from '@app/shared/constants/api.constants';
import { Endpoints } from '@app/shared/constants/endpoints.constant';
import { PaginationConstants } from '@app/shared/constants/pagination.constants';
import { BaseApiService } from '@app/shared/services/base-api.service';
import { Observable } from 'rxjs';

enum QUERY_PARAMS {
  PAGE_SIZE = 'limit',
  LAST_NOTIFICATION_ID = 'lastNotificationID',
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiService extends BaseApiService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getPageableNotificationList(
    userId: string,
    lastNotificationID = PaginationConstants.NOTIFICATIONS
      .DEFAULT_NOTIFICATION_ID
  ): Observable<NotificationListResponse> {
    const params = new HttpParams({
      fromObject: {
        [QUERY_PARAMS.PAGE_SIZE]: String(
          PaginationConstants.NOTIFICATIONS.DEFAULT_PAGE_SIZE
        ),
        [QUERY_PARAMS.LAST_NOTIFICATION_ID]: String(lastNotificationID),
      },
    });
    return this.httpClient.get<NotificationListResponse>(
      Endpoints.NOTIFICATIONS.getList(userId),
      { params, headers: ApiConstants.SKIP_LOADING_HTTP_HEADERS }
    );
  }

  setNotificationStatus(
    userId: string,
    notificationId: string,
    body: NOTIFICATION_STATUS
  ): Observable<void> {
    return this.post<void, { status: NOTIFICATION_STATUS }>(
      Endpoints.NOTIFICATIONS.changeStatus(userId, notificationId),
      { status: body },
      ApiConstants.SKIP_LOADING_HTTP_HEADERS
    );
  }
}
