import { Injectable, OnDestroy } from '@angular/core';
import { NotificationsApiService } from '@app/shared/components/notification-panel/services/notifications-api.service';
import { NotificationCardDataItem } from '@app/shared/components/notification-panel/types/notification-card.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  concatMap,
  distinctUntilChanged,
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PaginationService implements OnDestroy {
  private readonly _ngUnsubscribe = new Subject<void>();
  private readonly _loading$ = new BehaviorSubject<boolean>(false);
  private readonly _scrolled$ = new Subject<string>();
  private _currentCards = new BehaviorSubject<NotificationCardDataItem[]>([]);
  private _isNextPage = true;
  private _userId = '';
  private _lastNotificationId = '';

  constructor(private _notificationService: NotificationsApiService) {}

  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  handleScrolledEvent(): void {
    this._scrolled$
      .pipe(
        distinctUntilChanged(),
        tap(() => this._loading$.next(true)),
        concatMap(() =>
          this._notificationService.getPageableNotificationList(
            this._userId,
            this._lastNotificationId
          )
        ),
        tap((value) => {
          this._isNextPage = value.data.isNextPage;
          this._lastNotificationId = value.data.lastNotificationID;
        }),
        map((notificationList) => notificationList?.data.items ?? []),
        takeUntil(this._ngUnsubscribe)
      )
      .subscribe(this._onCardsFetched.bind(this));
  }

  onScrolled(): void {
    if (this._isNextPage) {
      this._scrolled$.next(this._lastNotificationId);
    }
  }

  set currentCards(value: BehaviorSubject<NotificationCardDataItem[]>) {
    this._currentCards = value;
  }

  set userId(value: string) {
    this._userId = value;
  }

  set isNextPage(value: boolean) {
    this._isNextPage = value;
  }

  set lastNotificationId(value: string) {
    this._lastNotificationId = value;
  }

  get loading$(): BehaviorSubject<boolean> {
    return this._loading$;
  }

  private _onCardsFetched(loadedCards: NotificationCardDataItem[]): void {
    this._loading$.next(false);
    this._currentCards.next([...this._currentCards.getValue(), ...loadedCards]);
  }
}
