import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LO_BUTTONS_ACTIVE } from '../../../../../shared/enums/lo-statuses.enums';

@Injectable()
export class LearningObjectButtonsStateService {
  private _buttonsState$ = new BehaviorSubject(null);
  public showCreateButton$ = new BehaviorSubject(false);

  set buttonsState(val: LO_BUTTONS_ACTIVE) {
    this._buttonsState$.next(val);
  }

  buttonsState$ = this._buttonsState$.asObservable();
}
