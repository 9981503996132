import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApiService } from '@app/shared/services/base-api.service';
import { ClientThemeConfigurationConstant } from '../constants/global.constants';
@Injectable({
  providedIn: 'root',
})
export class ClientThemeConfigurationApiService extends BaseApiService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getClientThemeConfiguration(): Observable<any> {
    return this.httpClient.get<any>(
      ClientThemeConfigurationConstant.CLIENT_THEME_CONFIGURATION_API
    );
  }

  getClientTheme(url): Observable<any> {
    return this.httpClient.get<any>(url);
  }

  getClientThemeBanner(langID): Observable<any> {
    return this.httpClient.get<any>(
      `${ClientThemeConfigurationConstant.CLIENT_THEME_BANNER_API}?langID=${langID}`
    );
  }
}
