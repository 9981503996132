import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  SettingsList,
  SettingsSave,
  SubscriptionOnSave,
  Subscription,
} from 'src/app/shared/interface/languages.model';
import { HttpClient } from '@angular/common/http';
import { SettingsApiConstants } from 'src/app/shared/constants/settings-api.constants';
import { BaseApiService } from '@app/shared/services/base-api.service';
import { SessionService } from 'src/app/shared/services/session.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsPageApiService extends BaseApiService {
  constructor(
    protected httpClient: HttpClient,
    private sessionService: SessionService
  ) {
    super(httpClient);
  }
  get userTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  get userId(): string {
    return this.sessionService.userId;
  }
  getSettings(): Observable<SettingsList> {
    return this.get<SettingsList>(
      SettingsApiConstants.getSettingsUrl(this.userId)
    );
  }

  saveSettings(settings: SettingsSave): Observable<SettingsList> {
    return this.post<SettingsList, SettingsSave>(
      SettingsApiConstants.saveSettingsUrl(this.userId),
      settings
    );
  }

  saveSubscription(subscription: Subscription): Observable<SubscriptionOnSave> {
    return this.put<SubscriptionOnSave, Subscription>(
      SettingsApiConstants.saveSubscriptionUrl(
        this.userId,
        subscription.emailID
      ),
      subscription
    );
  }
}
