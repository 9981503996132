/* eslint @typescript-eslint/no-var-requires: "off" */
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { LogoutService } from '@app/shared/services/logout.service';
import { Subscription } from 'rxjs';
import { TenantUserPool } from 'src/app/shared/interface/common-model';
import { LoadingService } from './loading.service';
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

// TODO: We have two service for managing session/local storage. We should have only one
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  sessionId;
  accessTokenExpiry: number;
  subscription: Subscription;
  refreshToken;

  setSession(res: string): void {
    localStorage.setItem('sessionid', res);
  }

  getSession(): string {
    return localStorage.getItem('sessionid');
  }

  removeSession(): void {
    localStorage.removeItem('sessionid');
  }

  set accessToken(value: string) {
    localStorage.setItem('accessToken', value);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken');
  }

  set userId(value: string) {
    localStorage.setItem('userId', value);
  }

  get userId(): string {
    return localStorage.getItem('userId');
  }

  constructor(private logout: LogoutService, private loader: LoadingService) {
    this.subscription = this.logout._logoutStatus.subscribe((status) => {
      if (status === true) {
        clearTimeout(this.sessionId);
      }
    });
  }

  sessionInit(sessionTime: number) {
    const epochNow = Math.floor(new Date().getTime() / 1000.0);
    const diffTime = sessionTime - epochNow;
    if (diffTime > 0) {
      if (this.sessionId) {
        clearTimeout(this.sessionId);
        this.createSession(diffTime * 1000);
      } else {
        this.createSession(diffTime * 1000);
      }
    }
  }

  createSession(timeSession: number): void {
    this.sessionId = setTimeout(() => {
      this.createNewSessionwithRefreshToken();
    }, timeSession);
  }

  createNewSessionwithRefreshToken() {
    const tenatsAuthConfigDetails: TenantUserPool = JSON.parse(
      localStorage.getItem('tenatUserpoolDetails')
    );
    const poolData = {
      UserPoolId: tenatsAuthConfigDetails.poolId,
      ClientId: tenatsAuthConfigDetails.clientId,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    cognitoUser.refreshSession(
      this.refreshToken,
      () => {
        this.getuserActiveSession();
      },
      (error) => {
        this.loader.stopLoading();
      }
    );
  }

  async getuserActiveSession(): Promise<any> {
    this.loader.startLoading();
    return Promise.resolve(Auth.currentSession())
      .then((res) => {
        this.loader.stopLoading();
        this.accessToken = res.getAccessToken().getJwtToken();
        this.accessTokenExpiry = res.getAccessToken().getExpiration();
        this.refreshToken = res.getRefreshToken();
        this.sessionInit(res.getAccessToken().getExpiration() - 120);
        return this.accessToken;
      })
      .catch((err) => {
        this.loader.stopLoading();
        return err;
      });
  }

  generatNewSessionwithRefreshToken(
    tenatsAuthConfigDetails: TenantUserPool,
    refresh_token: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const poolData = {
        UserPoolId: tenatsAuthConfigDetails.poolId,
        ClientId: tenatsAuthConfigDetails.clientId,
      };
      const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      const cognitoUser = userPool.getCurrentUser();

      cognitoUser.refreshSession(refresh_token, (refErr, refSession) => {
        if (refErr) {
          reject(refErr);
        } else {
          const new_accessToken = refSession.getAccessToken().getJwtToken();
          resolve(new_accessToken);
        }
      });
    });
  }
}
