export enum ClientThemeConfigurationSpecs {
  primaryColor = '--primary-color',
  secondaryColor = '--secondary-color',
  logoWidthDesktop = '--logo-width-desktop',
  logoWidthMobile = '--logo-width-mobile',
  logoMarginTopDesktop = '--logo-margin-top-desktop',
  logoMarginLeftDesktop = '--logo-margin-left-desktop',
  logoMarginTopMobile = '--logo-margin-top-mobile',
  logoMarginLeftMobile = '--logo-margin-left-mobile',
  headerTitleFontSizeDesktop = '--header-title-font-size-desktop',
  headerTitleFontSizeMobile = '--header-title-font-size-mobile',
  homePageIconsColor = '--home-page-icons-color',
  bannerTitleColor = '--banner-title-color',
  logoTitleColor = '--logo-title-color',
  lpCardBottomGradientColor = '--lp-card-bottom-gradient-color',
}
