export type LANGUAGE_CODE = 'en' | 'de' | 'fr';

export class LanguageConstants {
  static readonly LANGUAGES: Record<string, LANGUAGE_CODE> = {
    EN: 'en',
    DE: 'de',
    FR: 'fr',
  };
  static readonly LANGUAGE_LIST = [
    LanguageConstants.LANGUAGES.EN,
    LanguageConstants.LANGUAGES.DE,
    LanguageConstants.LANGUAGES.FR,
  ];
  static readonly DEFAULT_LANGUAGE = LanguageConstants.LANGUAGES.EN;
  static readonly TRANSLATION_LOAD_LAG_TIME = 300;
}
