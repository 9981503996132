import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { NotificationsApiService } from '@app/shared/components/notification-panel/services/notifications-api.service';
import { PaginationService } from '@app/shared/components/notification-panel/services/pagination.service';
import {
  NotificationCardDataItem,
  NotificationListResponse,
} from '@app/shared/components/notification-panel/types/notification-card.interface';
import { InfiniteScrollConstants } from '@app/shared/constants/global.constants';
import { ConfirmationPopupService } from '@app/shared/services/confirmation-popup.service';
import { SessionService } from '@app/shared/services/session.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'aftp-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'notificationPanelComponent',
})
export class NotificationPanelComponent implements OnInit {
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;
  @ViewChild(MatMenuTrigger) notificationMenu: MatMenuTrigger;

  readonly INFINITE_SCROLL_CONSTANTS = InfiniteScrollConstants;
  readonly notificationCards$ = new BehaviorSubject<NotificationCardDataItem[]>(
    []
  );

  private _userId: string;

  constructor(
    private _sessionService: SessionService,
    private _changeDetection: ChangeDetectorRef,
    private _paginationService: PaginationService,
    private _notificationService: NotificationsApiService,
    private _confirmationPopupService: ConfirmationPopupService
  ) {}

  ngOnInit(): void {
    this.loadUserDetails();
    this.loadNotificationList()
      .pipe(finalize(() => this._changeDetection.detectChanges()))
      .subscribe(
        (response) => {
          this.notificationCards$.next(response.data.items);
          this.initializePagination(response.data.lastNotificationID);
          this._paginationService.isNextPage = response.data.isNextPage;
          this._paginationService.handleScrolledEvent();
        },
        () => this._confirmationPopupService.showErrorConfirmation()
      );
  }

  get isItemsListEmpty(): boolean {
    return this.notificationCards$.getValue().length === 0;
  }

  get userId(): string {
    return this._userId;
  }

  trackBy(card: NotificationCardDataItem): string {
    return card.notificationID;
  }

  updateViewAfterDeletion(): void {
    this._changeDetection.detectChanges();
  }

  onScrolled(): void {
    this._paginationService.onScrolled();
  }

  isShowLoader(): BehaviorSubject<boolean> {
    return this._paginationService.loading$;
  }

  afterRedirectHandling(): void {
    this.notificationMenu.closeMenu();
  }

  private initializePagination(lastNotificationId: string): void {
    this._paginationService.currentCards = this.notificationCards$;
    this._paginationService.lastNotificationId = lastNotificationId;
    this._paginationService.userId = this.userId;
  }

  private loadNotificationList(): Observable<NotificationListResponse> {
    return this._notificationService.getPageableNotificationList(this._userId);
  }

  private loadUserDetails(): void {
    this._userId = this._sessionService.userId;
  }
}
