import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarProfileComponent } from '@app/shared/components/avatar-profile/avatar-profile.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  declarations: [AvatarProfileComponent],
  exports: [AvatarProfileComponent],
})
export class AvatarProfileModule {}
