<div
  [matMenuTriggerFor]="panel"
  #notificationPanelTrigger="matMenuTrigger"
></div>
<mat-menu
  #panel="matMenu"
  class="notifications-container fix-with-container"
  xPosition="before"
  xmlns="http://www.w3.org/1999/html"
>
  <mat-list class="notification-list">
    <div
      class="scroll-container custom-scroll"
      infiniteScroll
      [infiniteScrollThrottle]="
        INFINITE_SCROLL_CONSTANTS.DEFAULT_INFINITE_SCROLL_THROTTLE_TIME
      "
      [infiniteScrollDistance]="
        INFINITE_SCROLL_CONSTANTS.DEFAULT_INFINITE_SCROLL_DISTANCE
      "
      [scrollWindow]="false"
      (scrolled)="onScrolled()"
    >
      <aftp-notification-card
        *ngFor="let item of notificationCards$ | async; trackBy: trackBy"
        [card]="item"
        [userId]="userId"
        (cardDeleted)="updateViewAfterDeletion()"
        (afterRedirect)="afterRedirectHandling()"
      ></aftp-notification-card>
    </div>
    <div class="empty-notification-list" *ngIf="isItemsListEmpty">
      <em>{{ "notificationPanel.emptyList" | translate }}</em>
    </div>
  </mat-list>
  <div *ngIf="isShowLoader() | async">
    <aftp-virtual-scroll-preloader></aftp-virtual-scroll-preloader>
  </div>
</mat-menu>
