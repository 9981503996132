<header class="error-page-header notranslate">
  <div class="aftp-container">
    <div class="header-inner">
      <div class="logo">
        <a class="logo-link">
          <img src="{{ logoImg }}" alt="Logo image" class="logo-image" />
        </a>
        <h1 class="logo-title">
          {{ headerTitle }}
        </h1>
      </div>
    </div>
  </div>
</header>

<div id="error-msg">
  <div class="bigErrorLeft">
    <h2 div class="errorTitle" *ngIf="doNotShowTable; else showDefault">
      {{ "sePopup.weAreSorry" | translate }}
    </h2>
    <ng-template #showDefault>
      <h2 div class="errorTitle">
        {{ "sePopup.errorTitle" | translate }}
      </h2>
    </ng-template>
    <div *ngIf="doNotShowTable; else default" class="errorMessage">
      {{ "editLearningPathMessages.Weresorry" | translate }}
      <a class="clickHere" (click)="onDoneAction()"
        >{{ "editLearningPathMessages.clickhere" | translate }}
      </a>
      {{ "editLearningPathMessages.explorelp" | translate }}
    </div>
    <ng-template class="errorMessage" #default>
      <span *ngIf="fromAssessment">
        {{ "manageAssessments.AssmtPresentInLP" | translate }}
      </span>
      <span *ngIf="!fromAssessment">
        {{ "sePopup.errorMessage" | translate }}
      </span>
      <table
        mat-table
        [dataSource]="errorObjects"
        aria-label="Error display table"
      >
        <!-- Skill Column -->
        <ng-container matColumnDef="skill">
          <th scope="col" *matHeaderCellDef>
            {{ "tableErrorPopup.skillLabel" | translate }}
          </th>
          <td *matCellDef="let element">{{ element.skill }}</td>
        </ng-container>

        <!-- Level Column -->
        <ng-container matColumnDef="level">
          <th scope="col" *matHeaderCellDef>
            {{ "tableErrorPopup.levelLabel" | translate }}
          </th>
          <td *matCellDef="let element">{{ element.level }}</td>
        </ng-container>

        <!-- Curator Column -->
        <ng-container matColumnDef="curator">
          <th scope="col" *matHeaderCellDef>
            {{ "tableErrorPopup.curatorLabel" | translate }}
          </th>
          <td *matCellDef="let element">
            {{ element.curator }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <a class="clickHere" (click)="onDoneAction()"
        >{{ "editLearningPathMessages.clickhere" | translate }}
      </a>
      {{ "editLearningPathMessages.errorpart2" | translate }}
    </ng-template>
  </div>
  <div class="bigErrorRight" *ngIf="!isMobile">
    <img
      src="../../assets/images/errorpopupimagecrop.1.png"
      alt="Error image"
    />
  </div>
  <div class="col-xs-12 bigErrorRight" *ngIf="isMobile">
    <img
      src="../../assets/images/errorpopupimagecrop.1.png"
      alt="Error image"
    />
  </div>
</div>
