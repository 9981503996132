import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OverdueBreakpoint } from 'src/app/shared/interface/learning-path-card-data.interface';
import { DateTimeHelper } from 'src/app/shared/helpers/date-time.helper';

@Injectable({
  providedIn: 'root',
})
export class HomePageStateService implements OnDestroy {
  private _breakpointsForLearningPath = new BehaviorSubject<OverdueBreakpoint>({
    overdueMaxPoint: DateTimeHelper.weekInMs,
    overdueMinPoint: DateTimeHelper.oneDayInMs,
  });

  set breakpointsForLearningPath(value: OverdueBreakpoint) {
    this._breakpointsForLearningPath.next(value);
  }

  get breakpointsForLearningPath(): OverdueBreakpoint {
    return this._breakpointsForLearningPath.value;
  }

  ngOnDestroy(): void {
    this._breakpointsForLearningPath.complete();
  }
}
