import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CollapsedSearchService {
  readonly isSearchResultsPanelOpened = new BehaviorSubject(true);
  readonly isSearchPageNavigation = new BehaviorSubject(false);
  readonly isFormatLabelsEqual = new BehaviorSubject(false);
}
