import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingScreenServiceService } from '@app/shared/services/loading-screen-service.service';
import { finalize } from 'rxjs/operators';
import { ApiConstants } from '@app/shared/constants/api.constants';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  activeRequests = 0;

  constructor(private loadingScreenService: LoadingScreenServiceService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // TODO: Angular 12 supports more elegant solution with request context
    // https://netbasal.com/new-in-angular-v12-passing-context-to-http-interceptors-308a1ca2f3dd
    const skipLoading =
      request.headers.get(ApiConstants.SKIP_LOADING_HEADER.KEY) !==
      ApiConstants.SKIP_LOADING_HEADER.VALUE;

    if (this.activeRequests === 0 && skipLoading) {
      this.loadingScreenService.startLoading();
    }

    // Don't add active requests if we are skipping loading
    if (skipLoading) {
      this.activeRequests++;
    }

    // Remove header that was used for skipping loading
    request = request.clone({
      headers: request.headers.delete(ApiConstants.SKIP_LOADING_HEADER.KEY),
    });

    return next.handle(request).pipe(
      finalize(() => {
        if (this.activeRequests > 0 && skipLoading) {
          this.activeRequests--;
        }
        if (this.activeRequests === 0) {
          this.loadingScreenService.stopLoading();
        }
      })
    );
  }
}
