import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReminderPromptState } from 'src/app/shared/interface/learning-path-card-data.interface';

@Injectable({
  providedIn: 'root',
})
export class ReminderPromptStateService {
  prompt$: Observable<ReminderPromptState>;

  private readonly _prompt$ = new BehaviorSubject<ReminderPromptState>({
    isLightTheme: false,
    isVisible: false,
    text: '',
  });

  constructor() {
    this.prompt$ = this._prompt$;
  }

  hidePrompt(): void {
    this._prompt$.next({
      ...this._prompt$.getValue(),
      isVisible: false,
    });
  }

  setPrompt(prompt: ReminderPromptState): void {
    this._prompt$.next(prompt);
  }

  getPrompt(): ReminderPromptState {
    return this._prompt$.getValue();
  }
}
