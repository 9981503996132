import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export abstract class SubscribingComponent implements OnDestroy {
  protected unsubscribe: Subject<void>;

  constructor() {
    this.initializeSubscribing();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected initializeSubscribing(): void {
    this.unsubscribe = new Subject<void>();
  }
}
