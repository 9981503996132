export enum LANGUAGES_LIST {
  EN = 'LANG1',
  FR = 'LANG2',
  DE = 'LANG3',
}
export enum LANGUAGES_LIST_SMALLCASE {
  en = 'LANG1',
  fr = 'LANG2',
  de = 'LANG3',
}

export enum SUBSCRIPTIONS {
  MY_LEARNING_ACCOMPLISHMENTS = '1',
  MY_LEARNING_IN_PROGRESS = '2',
  AWAY_UPDATES = '3',
  MENTION_IN_BOARD = '4',
}

export enum LIKE_LIST {
  LIMIT = 'limit',
  PAGE_NUMBER = 'pageNumber',
  LAST_USER_ID = 'lastUserId',
}
