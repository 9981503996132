import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import {
  allowedTypesForRedirectAction,
  avatarSupportedTypes,
  paramsSupportedTypes,
  scrollToViewByTypeValue,
  typeToRouteMap,
} from 'src/app/shared/constants/notifications.constants';
import {
  NOTIFICATION_STATUS,
  NOTIFICATION_TYPE,
} from 'src/app/shared/enums/notification-types.enum';
import { NotificationsApiService } from '@app/shared/components/notification-panel/services/notifications-api.service';
import { NotificationCardDataItem } from '@app/shared/components/notification-panel/types/notification-card.interface';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BackgroundPageErrorComponent } from 'src/app/layouts/user/pages/edit-learning-path/full-screen-error/error-popup-template/background-page-error.component';
import { HeaderDetailsApiService } from '../../header/services/header-details.api.service';
@Component({
  selector: 'aftp-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationCardComponent implements OnInit, OnChanges {
  @Input() card: NotificationCardDataItem;
  @Input() userId: string;
  public errorBoards = [];
  @Output() cardDeleted = new EventEmitter<string>();
  @Output() afterRedirect = new EventEmitter<void>();
  @ViewChild(MatMenuTrigger) itemMenuTrigger: MatMenuTrigger;
  private modalRef: NgbModalRef;
  cardData;
  isAnnouncement = false;

  constructor(
    private modal: NgbModal,
    private _router: Router,
    private _notificationService: NotificationsApiService,
    private headerDetailsApiService: HeaderDetailsApiService,
    private _changeDetection: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.headerDetailsApiService
      .getNotificationIconClicked()
      .subscribe((value) => {
        if (value) {
          if (this.cardData.type == 'ANCMNT_DATA') {
            this.isAnnouncement = true;
            this.seeMore();
            this._changeDetection.detectChanges();
          }
        } else {
          this.isAnnouncement = false;
        }
      });
    this.cardData = this.card;
    if (this.cardData.type == 'ANCMNT_DATA') {
      this.isAnnouncement = true;
      this.seeMore();
    } else {
      this.isAnnouncement = false;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.cardData = this.card;
    if (this.cardData.type == 'ANCMNT_DATA') {
      this.isAnnouncement = true;
      this.seeMore();
      this._changeDetection.detectChanges();
    }
  }
  seeMore() {
    this.cardData.innertext = this.cardData.body.replace(
      /<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g,
      ''
    );
    this.cardData.seeMore = this.cardData.innertext.length >= 140;
  }

  navigateByCard(event: MouseEvent, card: NotificationCardDataItem): void {
    if (allowedTypesForRedirectAction.includes(card.type)) {
      if (card.isLock) {
        this.modalRef = this.modal.open(BackgroundPageErrorComponent, {
          windowClass: 'modal-delete-full',
        });
        this.modalRef.componentInstance.errorboards = this.errorBoards;
        this.errorBoards = [];
      } else {
        this.redirectToByType(card.type);
        this.markAsRead(event, card.notificationID);
      }
    } else {
      event.stopPropagation();
    }
  }

  markAsRead(event: MouseEvent, notificationId: string): void {
    this.changeCardStatus(event, notificationId, NOTIFICATION_STATUS.READ);
  }

  markAsUnread(event: MouseEvent, notificationId: string): void {
    this.changeCardStatus(event, notificationId, NOTIFICATION_STATUS.UNREAD);
  }

  deleteNotification(event: MouseEvent, notificationId: string): void {
    this.changeCardStatus(
      event,
      notificationId,
      NOTIFICATION_STATUS.REMOVED,
      () => this.cardDeleted.emit(notificationId)
    );
    this.cardDeleted.emit(notificationId);
  }

  isReadAction(): boolean {
    return this.card.currentStatus === NOTIFICATION_STATUS.READ;
  }

  isAvatarAvailable(): boolean {
    return avatarSupportedTypes.includes(this.card.type);
  }

  isDynamicRedirectSupported(type: NOTIFICATION_TYPE): boolean {
    return paramsSupportedTypes.includes(type);
  }

  disableNavigationByMenuClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  private changeCardStatus(
    event: MouseEvent,
    notificationId: string,
    status: NOTIFICATION_STATUS,
    successAction?: (id: string) => void
  ): void {
    event.stopPropagation();
    this.itemMenuTrigger.closeMenu();
    const previousStatus = this.card.currentStatus;
    this.card.currentStatus = status;
    this._notificationService
      .setNotificationStatus(this.userId, notificationId, status)
      .subscribe(
        () => {
          successAction && successAction(notificationId);
        },
        () => {
          this.resetStatus(previousStatus);
        }
      );
  }

  private resetStatus(previousStatus: NOTIFICATION_STATUS): void {
    this.card.currentStatus = previousStatus;
  }

  private getUrlParams(type: NOTIFICATION_TYPE): string {
    if (this.isDynamicRedirectSupported(type)) {
      switch (type) {
        case NOTIFICATION_TYPE.PRE_SKILL_ASSESSMENT_AVAILABLE:
        case NOTIFICATION_TYPE.PRE_SKILL_ASSESSMENT_EXPIRY:
        case NOTIFICATION_TYPE.PRE_SKILL_ASSESSMENT_RESULT:
          return this.card.redirectUrl;
        case NOTIFICATION_TYPE.NEW_LIKE_ON_POST:
        case NOTIFICATION_TYPE.NEW_LIKE_ON_COMMENT:
        case NOTIFICATION_TYPE.NEW_COMMENT_ON_POST:
        case NOTIFICATION_TYPE.NEW_TAG_ON_POST:
        case NOTIFICATION_TYPE.NEW_TAGS_ON_POST:
          return `${this.card.redirectUrl}`;
        default:
          return '';
      }
    }

    return '';
  }

  private redirectToByType(type: NOTIFICATION_TYPE): void {
    this._router
      .navigateByUrl(`${typeToRouteMap[type]}${this.getUrlParams(type)}`)
      .then(); //removed empty arrow function
    const scrollToViewElementId = scrollToViewByTypeValue[type];

    if (scrollToViewElementId) {
      const learningPathCardsSection = document.getElementById(
        scrollToViewElementId
      );
      learningPathCardsSection.scrollIntoView();
    }
    this.afterRedirect.emit();
  }

  onShowingEntireText(event) {
    this.cardData.seeMore = false;
    event.stopPropagation();
  }
}
