import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '@app/shared/components/footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared/shared.module';
import { AftpContainerModule } from '@app/shared/components/aftp-content-wrapper/aftp-container.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    SharedModule,
    AftpContainerModule,
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule {}
