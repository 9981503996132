<div class="start-pre-assmnt-modal">
  <ng-container *ngIf="assessmentSummaryDetails">
    <div class="cursor-pointer" (click)="closeModal()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 close-btn"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </div>
    <div class="assessment-container">
      <div class="graphik-medium fs-26 text-darker-gray mb-4">
        <span> {{ "assessmentModal.titlePreAssessment" | translate }} </span>
      </div>
      <div class="graphik-medium fs-14 text-darker-gray mb-4">
        <span> {{ preAssessmentName }} </span>
      </div>
      <ng-container
        *ngIf="
          assessmentSummaryDetails.hasOwnProperty('noOfAttemptsLeft') &&
            assessmentSummaryDetails.noOfAttemptsLeft <= 0;
          else notDefinednoOfAttemptsLeft
        "
      >
      </ng-container>
      <ng-template #notDefinednoOfAttemptsLeft>
        <div
          *ngIf="
            assessmentSummaryDetails.allowRetake &&
            assessmentSummaryDetails.noOfAttempts >= 0
          "
          class="graphik fs-14 text-gray"
        >
          <span>
            {{ "assessmentModal.labels.remainingAttempts" | translate }}:
            <span
              class="graphik"
              *ngIf="
                assessmentSummaryDetails.noOfAttemptsLeft > 1 ||
                (!assessmentSummaryDetails.noOfAttemptsLeft &&
                  assessmentSummaryDetails.noOfAttempts > 1)
              "
            >
              {{
                assessmentSummaryDetails.hasOwnProperty("noOfAttemptsLeft")
                  ? assessmentSummaryDetails.noOfAttemptsLeft
                  : assessmentSummaryDetails.noOfAttempts
              }}
            </span>
            <span
              class="graphik error-color add-padding-left"
              *ngIf="
                assessmentSummaryDetails.noOfAttemptsLeft === 1 ||
                assessmentSummaryDetails.noOfAttempts === 1
              "
            >
              {{
                assessmentSummaryDetails.hasOwnProperty("noOfAttemptsLeft")
                  ? assessmentSummaryDetails.noOfAttemptsLeft
                  : assessmentSummaryDetails.noOfAttempts
              }}
            </span>
          </span>
        </div>
      </ng-template>
      <div class="graphik fs-14 text-gray mt-8">
        <span>
          {{ "assessmentModal.labels.passPercentage" | translate }}:
          <span class="graphik color-semi-black add-padding-left">
            <ng-container *ngIf="assessmentSummaryDetails.passScore !== 0">
              {{
                assessmentSummaryDetails.passPercentage % 1 === 0
                  ? assessmentSummaryDetails.passPercentage.toFixed(0)
                  : assessmentSummaryDetails.passPercentage.toFixed(1)
              }}%
            </ng-container>
            <ng-container *ngIf="assessmentSummaryDetails.passScore === 0">
              {{ "assessmentModal.notApplicable" | translate }}
            </ng-container>
          </span></span
        >
      </div>
      <div
        class="graphik fs-14 text-gray mt-8"
        *ngIf="assessmentSummaryDetails.isTimedAssmnt"
      >
        <span>
          {{ "assessmentModal.labels.timeLimit" | translate }}:
          <span class="graphik color-semi-black add-padding-left">
            {{ durationInMinutes }} mins</span
          ></span
        >
      </div>
      <div
        class="graphik fs-14 text-gray mt-8"
        *ngIf="!assessmentSummaryDetails.isTimedAssmnt"
      >
        <span>
          {{ "assessmentModal.labels.duration" | translate }}:
          <span class="graphik color-semi-black add-padding-left">
            {{ durationInMinutes }} mins</span
          ></span
        >
      </div>
      <div
        ngif="assessmentSummaryDetails?.assmntInstructions"
        [innerHtml]="assessmentSummaryDetails.assmntInstructions"
        class="graphik fs-14 light-dark mt-16 mt-lg-4"
      ></div>
      <div class="mt-24 d-flex justify-content-center">
        <aftp-button
          class="align-middle"
          buttonKind="primary"
          *ngIf="
            !assessmentSummaryDetails.hasOwnProperty('noOfAttemptsLeft') ||
            !assessmentSummaryDetails.completionStatus
          "
          [disabled]="assessmentSummaryDetails?.noOfAttemptsLeft === 0"
          (action)="onClickAction()"
        >
          <ng-container
            *ngIf="
              assessmentSummaryDetails.hasOwnProperty('completionStatus');
              else noAttempt
            "
          >
            <ng-container
              *ngIf="
                assessmentSummaryDetails.completionStatus;
                else notCompleted
              "
            >
              {{ "assessmentModal.button.review" | translate }}
            </ng-container>
            <ng-template #notCompleted>
              {{ "assessmentModal.button.retake" | translate }}
            </ng-template>
          </ng-container>
          <ng-template #noAttempt>
            {{ "assessmentModal.button.start" | translate }}
          </ng-template>
        </aftp-button>
      </div>
      <ng-container
        *ngIf="
          isNotYetCompleted &&
          (assessmentSummaryDetails?.noOfAttempts === 1 ||
            assessmentSummaryDetails?.noOfAttemptsLeft === 1)
        "
      >
        <div class="row">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 info-modal__icon bottom-icon"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#FFA26B"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <ng-container
            *ngIf="
              assessmentSummaryDetails?.noOfAttempts > 1 &&
              assessmentSummaryDetails?.noOfAttemptsLeft === 1
            "
          >
            <p class="graphik fs-12 semi-black remove-margin mt-lg-4">
              {{ "assessmentModal.warningMessage.lastAttempt" | translate }}
            </p>
          </ng-container>
          <ng-container *ngIf="assessmentSummaryDetails?.noOfAttempts === 1">
            <p class="graphik fs-12 semi-black remove-margin mt-lg-4">
              {{ "assessmentModal.warningMessage.onlyAttempt" | translate }}
            </p>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
