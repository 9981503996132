import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  signInUser: any;
  emailId: string;
  isTempPwdExpired = false;
  isPwdExpired = false;
  loginImageRotationNumber = 1;
  private headerdata$: BehaviorSubject<any> = new BehaviorSubject(null);
  discussionBoard$: BehaviorSubject<string> = new BehaviorSubject('');
  Like$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  SocialProof$: BehaviorSubject<number> = new BehaviorSubject(null);
  PeopleSearch$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  PrivacyStatement$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  setSigInUser(user): void {
    this.signInUser = user;
  }
  getSignedInUser(): any {
    return this.signInUser;
  }

  getSignInUserName(): string {
    if (localStorage.getItem('isEso') === 'true') {
      return this.signInUser.username.split('_')[1];
    } else {
      return this.signInUser?.attributes.email;
    }
  }
  setEmailId(email: string): void {
    this.emailId = email;
  }
  getEmailId(): string {
    return this.emailId.toLowerCase();
  }
  setHeaderData(headerdata: any) {
    this.headerdata$.next(headerdata);
  }

  getHeaderData(): Observable<any> {
    return this.headerdata$.asObservable();
  }
}
