<div class="skillcards-container">
  <div>
    <div class="skill-tile" tabindex="0">
      <div class="skill-image-container">
        <div class="skill-image">
          <img class="skill-image" src="{{ learningCard.imageSrc }}" />
        </div>
        <div class="proficiency-name-container">
          <span>{{ learningCard.prfcnc.name }}</span>
        </div>
      </div>
      <div class="skill-details-container">
        <p class="skill-name">{{ learningCard.skill.name }}</p>
      </div>
      <div class="status-level">
        <a class="btn-default status-button">{{
          "manageLearningPathCard." + learningCard.currSttsText | translate
        }}</a>
      </div>
    </div>
  </div>
</div>
