import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { LEARNING_PATH_CARD_DATE_TYPE } from 'src/app/shared/enums/date-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsStateService } from '@app/shared/services/user-settings-state.service';
import { DateTimeHelper } from 'src/app/shared/helpers/date-time.helper';
import { HomePageStateService } from '@app/layouts/user/pages/home-page/services/home-page-state.service';
import * as moment from 'moment';

@Component({
  selector: 'aftp-time-status',
  templateUrl: './time-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeStatusComponent implements OnInit {
  private _startDate: number;
  private _endDate: number;
  private _isLPCard = false;
  private _isLockedByStartDate = false;
  moment: any = moment;

  oneDayLeftValue = 1;
  maxStepForOverwriteMinutes = 59;
  DATE = LEARNING_PATH_CARD_DATE_TYPE;
  initialDate = DateTimeHelper.getCurrentTimestamp(
    this.userSettingsStateService.timezone.name
  );
  hoursFormatValue = '';
  minutesFormatValue = '';

  readonly minTimeBreakpoint =
    this.homePageStateService.breakpointsForLearningPath.overdueMinPoint;
  readonly maxTimeBreakpoint =
    this.homePageStateService.breakpointsForLearningPath.overdueMaxPoint;

  @Input()
  set isLockedByStartDate(value: boolean) {
    this._isLockedByStartDate = value;
  }

  get isLockedByStartDate(): boolean {
    return this._isLockedByStartDate;
  }

  @Input()
  set startDate(value: number) {
    this._startDate = value;
  }

  get startDate(): number {
    return this._startDate;
  }

  @Input()
  set endDate(value: number) {
    this._endDate = value;
  }

  get endDate(): number {
    return this._endDate;
  }

  @Input()
  set isLPCard(value: boolean) {
    this._isLPCard = value;
  }

  get isLPCard(): boolean {
    return this._isLPCard;
  }

  constructor(
    readonly translateService: TranslateService,
    readonly userSettingsStateService: UserSettingsStateService,
    readonly homePageStateService: HomePageStateService
  ) {}

  get timestampUntilOverdue(): number {
    return this.endDate - this.initialDate;
  }

  get timestampUntilOverdueInMinutes(): number {
    return Math.ceil(
      this.timestampUntilOverdue / DateTimeHelper.millisecondsInMinute
    );
  }

  get minHoursBreakpoint(): number {
    return this.minTimeBreakpoint / DateTimeHelper.hoursInMs;
  }

  get minDaysBreakpoint(): number {
    return Math.round(this.minTimeBreakpoint / DateTimeHelper.oneDayInMs);
  }

  get minMinutesBreakpoint(): number {
    return this.minTimeBreakpoint / DateTimeHelper.millisecondsInMinute;
  }

  get maxDaysBreakpoint(): number {
    return this.maxTimeBreakpoint / DateTimeHelper.oneDayInMs;
  }

  get isMinHoursBreakpointLessThanOneDay(): boolean {
    return this.minHoursBreakpoint < DateTimeHelper.hoursInOneDay;
  }

  get daysUntilOverdue(): number {
    return DateTimeHelper.calculateFullDaysBetweenTwoTimestamps(
      this.endDate,
      this.initialDate
    );
  }

  get hoursUntilOverdue(): number {
    return DateTimeHelper.calculateHoursBetweenTwoTimestamps(
      this.endDate,
      this.initialDate
    );
  }

  get isNotStarted(): boolean {
    return Date.now() < this.startDate && this.isLockedByStartDate;
  }

  get isDaysWithMonths(): boolean {
    return this.daysUntilOverdue > this.maxDaysBreakpoint;
  }

  get isTimestampUntilOverdueMoreThanMinTimeBreakpoint(): boolean {
    return this.timestampUntilOverdueInMinutes >= this.minMinutesBreakpoint;
  }

  get isDaysLeftValueShown(): boolean {
    return (
      this.daysUntilOverdue <= this.maxDaysBreakpoint &&
      this.isTimestampUntilOverdueMoreThanMinTimeBreakpoint &&
      !this.isOneDayLeft
    );
  }

  get daysLeftUntilOverdue(): string {
    return this.translateService.instant(
      this.daysUntilOverdue === 1 ? 'day' : 'days'
    );
  }

  get isOneDayLeft(): boolean {
    return (
      this.isMinHoursBreakpointLessThanOneDay &&
      this.timestampUntilOverdue < DateTimeHelper.oneDayInMs &&
      this.isTimestampUntilOverdueMoreThanMinTimeBreakpoint
    );
  }

  get minMinutesUnderOneBreakpoint(): number {
    return this.minMinutesBreakpoint - 1;
  }

  get isTimeLeftValueShown(): boolean {
    return (
      this.timestampUntilOverdueInMinutes <=
        this.minMinutesUnderOneBreakpoint && this.timestampUntilOverdue > 0
    );
  }

  get hoursAndMinutesLeftUntilOverdue(): string {
    const timeValue = moment.duration(this.timestampUntilOverdue);
    let hoursLeft = timeValue.hours();
    let minutesLeft = timeValue.minutes();
    const secondsLeft = timeValue.seconds();

    if (secondsLeft > 0) {
      minutesLeft = minutesLeft + 1;
    }

    if (minutesLeft === DateTimeHelper.secondsInMinute) {
      hoursLeft = hoursLeft + 1;
      minutesLeft = 0;
    }

    const hoursLeftValue = DateTimeHelper.transformTimeValue(hoursLeft);
    const minutesLeftValue = DateTimeHelper.transformTimeValue(minutesLeft);

    const hoursLeftValueShown = `${hoursLeftValue}${this.hoursFormatValue}`;
    const minutesLeftValueShown = `${minutesLeftValue}${this.minutesFormatValue}`;

    return hoursLeft > 0
      ? `${hoursLeftValueShown} ${minutesLeftValueShown}`
      : minutesLeftValueShown;
  }

  ngOnInit() {
    this.hoursFormatValue = this.translateService.instant('prepositions.hours');
    this.minutesFormatValue =
      this.translateService.instant('prepositions.mins');
  }
}
