import { Injectable } from '@angular/core';

// TODO: We have two service for managing session/local storage. We should have only one
@Injectable({
  providedIn: 'root',
})
export class WebStorageService {
  /**
   * Save to web storage
   * @param key string - object identifier
   * @param value any - data to save to storage
   * @param session boolean, default is true, if false, local storage will be used
   */
  setStorage(key, value, session = true) {
    session
      ? sessionStorage.setItem(key, value)
      : localStorage.setItem(key, value);
  }

  getStorage(key, session = true) {
    return session ? sessionStorage.getItem(key) : localStorage.getItem(key);
  }

  getLanguageID() {
    return this.getStorage('languageID', false) ?? 'en';
  }
}
