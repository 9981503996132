import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICONS } from 'src/app/shared/components/svg-icon/icons-list';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { AssessmentPageService } from 'src/app/layouts/user/pages/assessment-page/services/assessment-page.service';
import { LearningObjectDetailService } from 'src/app/layouts/user/pages/learning-object-detail/services/learning-object-detail.service';
import { DateTimeHelper } from 'src/app/shared/helpers/date-time.helper';
import { AssessmentSummaryData } from 'src/app/shared/interface/assessment-summary.model';
import { AdminAccessService } from 'src/app/layouts/user/services/admin-access.service';

@Component({
  selector: 'aftp-start-pre-assessment-modal',
  templateUrl: './start-pre-assessment-modal.component.html',
  styleUrls: ['./start-pre-assessment-modal.component.scss'],
})
export class StartPreAssessmentModalComponent implements OnInit {
  @Input() public assessmentSummaryDetails: AssessmentSummaryData;
  @Input() public learningPathID?: string;
  public ICONS = ICONS;
  isNotYetCompleted = false;
  preAssessmentName: string;
  constructor(
    private modal: NgbModal,
    private translate: TranslateService,
    private router: Router,
    private assessmentPageService: AssessmentPageService,
    private learningObjectDetailService: LearningObjectDetailService,
    private adminService: AdminAccessService
  ) {}

  ngOnInit(): void {
    if (
      this.assessmentSummaryDetails.completionStatus === undefined ||
      !this.assessmentSummaryDetails.completionStatus
    ) {
      this.isNotYetCompleted = true;
    }

    if (this.learningPathID) {
      this.assessmentPageService.activeLearningPath = this.learningPathID;
    }
    this.preAssessmentName = this.learningObjectDetailService.getLPTitle();
  }

  closeModal() {
    this.modal.dismissAll();
  }

  getLanguage(): void {
    const languageID = localStorage.getItem('languageID');
    this.translate.use(languageID ?? 'en');
  }

  onClickAction() {
    if (this.router.url.includes('explore-learning-paths')) {
      this.assessmentPageService.setpreAssessmentClickedFromExplore(true);
    } else if (this.router.url.includes('searchType=LP')) {
      this.assessmentPageService.setpreAssessmentClickedFromSearch(true);
    } else if (this.router.url.includes('home')) {
      this.assessmentPageService.setPreAssessmentClickedFromHome(true);
    } else if (this.router.url.includes('history')) {
      this.assessmentPageService.setpreAssessmentClickedFromHistory(true);
    } else if (this.router.url.includes('programs')) {
      this.adminService.currentPage = Number(
        localStorage.getItem('currentPage')
      );
      const myArray = this.router.url.split('/');
      this.assessmentPageService.programID = myArray[3];
      const groupID = myArray[4].split('?');
      this.assessmentPageService.groupID = groupID[0];
      this.assessmentPageService.type = groupID[1];
      this.assessmentPageService.setPreAssessmentClickedFromGroups(true);
    }
    if (
      !('noOfAttemptsLeft' in this.assessmentSummaryDetails) ||
      !this.assessmentSummaryDetails.completionStatus
    ) {
      this.assessmentPageService.preassessment = true;
      this.assessmentPageService.countAttempt = true;
      this.router.navigate([
        `user/assessment/take/${this.assessmentSummaryDetails.assessmentID}/${this.assessmentPageService.activeLearningPath}`,
      ]);
    } else {
      this.router.navigate([
        `user/view-assessments/${this.assessmentSummaryDetails.assessmentID}/${this.assessmentPageService.activeLearningPath}`,
      ]);
    }
    this.closeModal();
  }
  get durationInMinutes(): number {
    return DateTimeHelper.getMinutesFromSecondsRound(
      this.assessmentSummaryDetails.duration
    );
  }
}
