<ng-container *ngIf="isNotStarted && isLPCard; else started">
  <span *ngIf="isNotStarted">
    {{ "learningObject.startsOn" | translate | uppercase }}
    {{ startDate | date: DATE.DAYS_WITH_MONTHS | uppercase }}
  </span>
</ng-container>
<ng-template #started>
  <span *ngIf="isDaysWithMonths">
    {{ moment(endDate).utc().format("D MMM") | uppercase }}
  </span>
  <span *ngIf="isDaysLeftValueShown">
    {{ daysUntilOverdue }}
    {{ daysLeftUntilOverdue | uppercase }}
    {{ "learningObject.prepositions.left" | translate }}
  </span>
  <span *ngIf="isOneDayLeft">
    {{ oneDayLeftValue }}
    {{ "day" | translate | uppercase }}
    {{ "learningObject.prepositions.left" | translate }}
  </span>
  <span *ngIf="isTimeLeftValueShown">
    {{ hoursAndMinutesLeftUntilOverdue }}
    {{ "learningObject.prepositions.left" | translate }}
  </span>
</ng-template>
