export enum SCREEN_SIZES {
  mobileScreen = 576,
  mediumScreen = 768,
  largeScreen = 992,
  BSExtraLarge = 1200,
  desktopScreen = 1280,
  xlScreen = 1920,
}
export enum SCREEN_DEVICES {
  computer = 'Computer',
  mobile = 'Mobile',
  tablet = 'Tablet',
}

export enum HOME_POPUP_SIZE {
  DEFAULT_WIDTH = '34rem',
  DEFAULT_HEIGHT = 'auto',
  ACCENTURE_ACADEMY_WIDTH = '46.5rem',
  ACCENTURE_ACADEMY_HEIGHT = '37.8rem',
}

export enum ICON_HOME_SIZE {
  LG_SIZE_WIDTH = '18',
  LG_SIZE_HEIGHT = '20',
  XL_SIZE_WIDTH = '21',
  XL_SIZE_HEIGHT = '24',
}

export enum HEADER_ICON_SIZE {
  LARGE = '32px',
  MEDIUM = '21px',
  SMALL = '19px',
}
