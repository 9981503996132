<header class="aftp-header notranslate">
  <div class="aftp-container">
    <div class="header-inner">
      <div class="logo">
        <a class="logo-link">
          <img src="{{ logoImg }}" alt="Logo image" class="logo-image" />
        </a>
        <h1 class="logo-title">
          {{ headerTitle }}
        </h1>
      </div>
    </div>
  </div>
</header>

<div class="page-container">
  <div class="row gutter-2">
    <div class="col-sm-5 centerAlignImage" *ngIf="!isMobile">
      <img
        src="../../assets/images/FullPageError-Web.png"
        alt="Full page error"
        class="errorImage"
      />
    </div>
    <div class="col-xs-12 centerAlignImage" *ngIf="isMobile">
      <img
        src="../../assets/images/FullPageError-Mobile.png"
        alt="Full page error"
        class="errorImage"
      />
    </div>
    <ng-content> </ng-content>
  </div>
</div>
