<aftp-preloader *ngIf="'true'"></aftp-preloader>
<div id="certificate" class="outer-container">
  <div class="header">
    <img [src]="logoUrl" class="certificate-logo" alt="logo" />
    <span>Accenture Academy</span>
  </div>
  <div class="main">
    <h1>Acknowledgement of Completion</h1>
    <p class="content">{{ certificateObj?.courseName }}</p>
    <p class="content-label">Awarded to</p>
    <p class="content">{{ certificateObj?.userName }}</p>
    <p class="content-label">Completion Date</p>
    <p class="content">
      {{
        moment(+certificateObj?.completionDate)
          .utc()
          .format("DD-MMM-YYYY")
      }}
    </p>
    <p class="content-label">
      Continuing Professional Education Credits Earned Program Field of Study
    </p>
    <p class="content-label">
      In accordance with the standards of the National Registry of CPE Sponsors,
      CPE credits have been granted on a 50-minute hour.
    </p>
    <p class="content-label">
      National Registry of CPE Sponsors ID Number: 108812
    </p>
    <p class="content-label">Delivery Method: QAS Self Study</p>
    <img [src]="signatureUrl" alt="signature" />
    <p class="signature"></p>
    <p class="signature-details">{{ signatureDetails }}</p>
  </div>
</div>
