<div
  #dropdownRef
  class="custom-dropdown-parent"
  ngbDropdown
  (openChange)="checkDropDown($event)"
  *ngIf="!isGroup"
>
  <div
    id="typeDropDown"
    tabindex="0"
    (keyup.enter)="enterDropdown($event)"
    (keydown.tab)="tabDropdown()"
    (click)="toggleDropdown()"
    [ngClass]="{ disabled: disabled, error: error }"
    class="
      custom-dropdown-container
      w-100
      d-flex
      align-items-center
      justify-content-between
    "
  >
    <div
      class="fs-14 d-inline-flex dropdown-label graphikFont {{
        getValue() === placeholder ? 'text-dark-gray' : 'color-semi-black'
      }}"
      [matTooltip]="toolTipText()"
      matTooltipClass="customTooltip"
      [innerHTML]="getValue()"
    ></div>
    <svg
      *ngIf="disabled"
      width="14"
      height="14"
      class="flex-shrink-0"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#D0C9D6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
        d="M19 9l-7 7-7-7"
      />
    </svg>
    <hero-icon
      *ngIf="!disabled && allowClearSelection && getValue() !== placeholder"
      class="d-flex mr-1 ml-auto text-gray"
      name="x"
      (click)="clearSelected($event)"
    ></hero-icon>
    <svg
      class="{{ dropdown && dropdown.isOpen() ? 'open' : '' }} flex-shrink-0"
      *ngIf="!disabled"
      width="14"
      height="14"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      [attr.stroke]="strokeColor ? strokeColor : '#6F6680'"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
        d="M19 9l-7 7-7-7"
      />
    </svg>
  </div>
  <div ngbDropdownMenu aria-labelledby="typeDropDown">
    <div *ngIf="allowSearch" class="search mb-4">
      <input
        type="text"
        [(ngModel)]="search"
        (keyup)="searchEvents($event)"
        placeholder="{{ 'manageUsers.searchButton' | translate }}..."
        class="w-100 fs-14 bordered-1 rounded graphikFont input-text pr-4"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="custom-search-icon fill-semi-gray"
        height="20"
        width="20"
        viewBox="0 0 20 20"
      >
        <path
          fill-rule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
    <div
      (scroll)="scrollHandler($event)"
      class="items-container custom-scroll"
      [style.margin-bottom]="
        filteredList?.length > 0 || noResultFound ? '20px' : '0px'
      "
    >
      <div class="align-items-center listItem mb-4" *ngIf="showSelectAll">
        <label class="item custom-checkbox mb-0 pl-0 d-flex align-items-center">
          <input
            [checked]="selectAllChecked"
            (change)="selectAll($event)"
            type="checkbox"
          />
          <span class="checkmark double"></span>
          <span class="fs-14 dropdown-label graphikFont mx-2">{{
            "ManageReport.selectAll" | translate
          }}</span>
        </label>
      </div>

      <div
        *ngFor="let item of filteredList; let last = last; let i = index"
        class="d-flex align-items-center listItem"
        [ngClass]="{ 'mb-4': !last, 'mt-3': showSelectAll }"
      >
        <label
          (click)="
            item[select] == true ? '' : allowMultiple ? '' : itemClicked(item)
          "
          class="item custom-checkbox mb-0 pl-0 d-flex align-items-center"
        >
          <ng-container *ngIf="allowMultiple">
            <input
              [checked]="ifChecked(item)"
              (change)="itemClicked(item)"
              type="checkbox"
            />
            <span class="checkmark double"></span>
          </ng-container>
          <span
            [matTooltip]="toolTip && item[select] != true ? item[shownKey] : ''"
            [title]="shownKey == 'title' ? item.title : ''"
            [ngStyle]="{ cursor: item[select] ? 'text' : 'pointer' }"
            [ngClass]="{
              'ml-2': allowMultiple,
              'message-list-active': activeIndex === i,
              ellipsisCut: showEllipsisOnList
            }"
            class="fs-14 dropdown-label graphikFont ellipsisCut"
            id="list{{ i }}"
            >{{ item[shownKey] }}</span
          >
        </label>
      </div>
      <aftp-virtual-scroll-preloader
        *ngIf="scrollUpLoader"
      ></aftp-virtual-scroll-preloader>
      <div class="fs-14 graphikFont" *ngIf="noResultFound && !scrollUpLoader">
        {{ "historyPage.noResultsHistory" | translate }}
      </div>
    </div>
  </div>
</div>
<!-- ======================= -->

<div
  #dropdownRef
  class="custom-dropdown-parent"
  ngbDropdown
  (openChange)="checkDropDown($event)"
  *ngIf="isGroup"
>
  <div
    id="typeDropDown"
    tabindex="0"
    (keyup.enter)="enterDropdown($event)"
    (keydown.tab)="tabDropdown()"
    (click)="toggleDropdown()"
    [ngClass]="{ disabled: disabled, error: error }"
    class="
      custom-dropdown-container
      w-100
      d-flex
      align-items-center
      justify-content-between
    "
  >
    <div
      class="fs-14 d-inline-flex dropdown-label graphikFont {{
        getValue() === placeholder ? 'text-dark-gray' : 'color-semi-black'
      }}"
      [matTooltip]="toolTipText()"
      matTooltipClass="customTooltip"
      [innerHTML]="getValue()"
    ></div>
    <svg
      *ngIf="disabled"
      width="14"
      height="14"
      class="flex-shrink-0"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#D0C9D6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
        d="M19 9l-7 7-7-7"
      />
    </svg>
    <hero-icon
      *ngIf="!disabled && allowClearSelection && getValue() !== placeholder"
      class="d-flex mr-1 ml-auto text-gray"
      name="x"
      (click)="clearSelected($event)"
    ></hero-icon>
    <svg
      class="{{ dropdown && dropdown.isOpen() ? 'open' : '' }} flex-shrink-0"
      *ngIf="!disabled"
      width="14"
      height="14"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      [attr.stroke]="strokeColor ? strokeColor : '#6F6680'"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
        d="M19 9l-7 7-7-7"
      />
    </svg>
  </div>
  <div ngbDropdownMenu aria-labelledby="typeDropDown">
    <div *ngIf="allowSearch" class="search mb-4">
      <input
        type="text"
        [(ngModel)]="search"
        (keyup)="searchEvents($event)"
        placeholder="{{ 'manageUsers.searchButton' | translate }}..."
        class="w-100 fs-14 bordered-1 rounded graphikFont input-text pr-4"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="custom-search-icon fill-semi-gray"
        height="20"
        width="20"
        viewBox="0 0 20 20"
      >
        <path
          fill-rule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
    <div
      (scroll)="scrollHandler($event)"
      class="items-container custom-scroll"
      [style.margin-bottom]="
        filteredList?.length > 0 || noResultFound ? '20px' : '0px'
      "
    >
      <div class="align-items-center listItem mb-2" *ngIf="showSelectAll">
        <label class="item custom-checkbox mb-0 pl-0 d-flex align-items-center">
          <input
            [checked]="grpChecked"
            (change)="groupSelectAll($event)"
            type="checkbox"
          />
          <span class="checkmark double"></span>
          <span class="fs-14 dropdown-label graphikFont mx-2">{{
            "ManageReport.selectAll" | translate
          }}</span>
        </label>
      </div>
      <div
        class="align-items-center listItem mt-1"
        *ngFor="
          let grpItem of groupItems;
          let grplast = last;
          let grpIndex = index
        "
      >
        <label
          class="
            item
            custom-checkbox
            mb-0
            pl-0
            d-flex
            align-items-center
            font-weight-bold
            text-capitalize
          "
        >
          <span
            class="fs-14 graphikFont prgName ellipsisCut"
            [ngStyle]="{ cursor: 'text' }"
            [ngClass]="{ ellipsisCut: showEllipsisOnList }"
            >{{
              grpItem?.name === "Others"
                ? ("ManageReport.other" | translate)
                : grpItem?.name
            }}</span
          >
        </label>
        <div
          *ngFor="let item of grpItem[groupKey]; let last = last; let i = index"
          class="d-flex align-items-center listItem mt-2"
          [ngClass]="{ 'mb-4': !last }"
        >
          <label
            (click)="
              item[select] == true ? '' : allowMultiple ? '' : itemClicked(item)
            "
            class="item custom-checkbox mb-0 pl-0 d-flex align-items-center"
          >
            <ng-container *ngIf="allowMultiple">
              <input
                [checked]="ifChecked(item)"
                (change)="itemClicked(item)"
                type="checkbox"
              />
              <span class="checkmark double"></span>
            </ng-container>
            <span
              [matTooltip]="
                toolTip && item[select] != true ? item[shownKey] : ''
              "
              [title]="shownKey == 'title' ? item.title : ''"
              [ngStyle]="{ cursor: item[select] ? 'text' : 'pointer' }"
              [ngClass]="{
                'ml-2': allowMultiple,
                ellipsisCut: showEllipsisOnList
              }"
              class="fs-14 dropdown-label graphikFont ellipsisCut"
              id="list{{ i }}"
              >{{ item[shownKey] }}</span
            >
          </label>
        </div>
        <div
          class="d-flex align-items-center listItem mt-2"
          *ngIf="grpItem[groupKey]?.length >= 4"
        >
          <label
            class="item custom-checkbox mb-0 pl-0 d-flex align-items-center"
            (click)="onClickSeeMore(grpItem)"
          >
            <span class="fs-14 dropdown-label graphikFont seeMore">
              {{ "ManageReport.seeMore" | translate }}
            </span>
          </label>
        </div>
      </div>
      <div class="fs-14 graphikFont" *ngIf="noResultFound">
        {{ "historyPage.noResultsHistory" | translate }}
      </div>
    </div>
  </div>
</div>
