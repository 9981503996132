import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
  checkVal01(val) {
    if (val % 1 != 0) {
      if (val.toFixed(2) == 0) {
        return Math.ceil(val.toFixed(2));
      } else {
        return val.toFixed(2);
      }
    } else {
      return val;
    }
  }
  checkVal02(val, thousand) {
    if (val > 999) {
      return `1${thousand}`;
    } else if (val % 1 != 0) {
      return val.toFixed(1);
    } else {
      return val;
    }
  }

  checkVal03(val, bilion) {
    console.log(val);
    if (val % 1 != 0) {
      if (val.toString().indexOf('.') != -1) {
        if (val.toFixed(1).toString().split('.')[1] == 0) {
          val = val.toString().split('.');
          return `${val[0]}${bilion}`;
        } else {
          return `${val.toFixed(1)}${bilion}`;
        }
      }
    } else {
      return `${val}${bilion}`;
    }
  }
  checkVal04(val, milion, bilion) {
    if (val >= 999999999) {
      return `1${bilion}`;
    } else if (((val = val / Math.pow(10, 6)), val % 1 != 0)) {
      if (val.toString().indexOf('.') != -1) {
        if (val.toFixed(1).toString().split('.')[1] == 0) {
          val = val.toFixed(1).toString().split('.');
          return `${val[0]}${milion}`;
        } else {
          return `${val.toFixed(1)}${milion}`;
        }
      }
    } else {
      return `${val}${milion}`;
    }
  }
  checkVal05(val, thousand, milion) {
    if (val >= 999999) {
      return `1${milion}`;
    } else if (((val = val / Math.pow(10, 3)), val % 1 != 0)) {
      if (val.toString().indexOf('.') != -1) {
        if (val.toFixed(1).toString().split('.')[1] == 0) {
          val = val.toFixed(1).toString().split('.');
          return `${val[0]}${thousand}`;
        } else {
          return `${val.toFixed(1)}${thousand}`;
        }
      }
    } else {
      return `${val}${thousand}`;
    }
  }
  transform(val: any, args?: any): any {
    const thousand = 'K',
      milion = 'M',
      bilion = 'B';
    if (val == undefined) {
      return 0;
    }
    if (val >= 0 && val < 1) {
      return this.checkVal01(val);
    }
    if (val >= 1 && val < 1000) {
      return this.checkVal02(val, thousand);
    } else {
      val = val.toString().split('.');
      val = val[0];
      const absVal = Math.abs(val);
      if (absVal >= Math.pow(10, 9)) {
        val = val / Math.pow(10, 9);
        return this.checkVal03(val, bilion);
      } else if (absVal < Math.pow(10, 9) && absVal >= Math.pow(10, 6)) {
        return this.checkVal04(val, milion, bilion);
      } else if (absVal < Math.pow(10, 6) && absVal >= Math.pow(10, 3)) {
        return this.checkVal05(val, thousand, milion);
      }
    }
  }
}
