export enum HAMBURGER_MENU_ITEMS {
  MY_BOARDS = 'My Boards',
  WHAT_IS_NEW = "What's New",
  BOARDS_I_AM_FOLLOWING = "Boards I'm Following",
  LEARNING_ACTIVITIES = 'Learning Activities',
  POPULAR_BOARDS = 'Popular Boards',
  HELP = 'Help',
  LOGOUT = 'Logout',
  TERMS_OF_USE = 'Terms of use',
  PRIVACY_STATEMENT = 'Privacy Statement',
  CONTACT_US = 'Contact Us',
  SURVEY = 'Survey',
  COPYRIGHT_2017_ACCENTURE = 'Copyright Â© 2017 Accenture',
  LEARNINGS = 'Learnings',
  CURATORS = 'Curators',
  LEARNING_BOARDS = 'Learning Boards',
  HOME_PAGE = 'Home Page',
  IN_PROGRESS_BOARDS = 'In Progress Boards',
  MY_REPORTS = 'My Reports',
  CHANGE_THEME = 'Change Theme',
  MANAGE_TOPICS = 'Manage Topics',
  MANAGE_BADGES = 'Manage Badges',
  SELF_SERVICE = 'Self Service',
  EXPLORE_LEARNING_PATHS = 'Explore Learning Paths',
  MU_LEARNING_PATHS = 'My Learning Paths',
  MANAGE_LEARNING_PATHS = 'Manage Learning Paths',
  MANAGE_SKILLS_AND_ROLES = 'Manage Skills and Roles',
  TRACK_EVENTS = 'Track Events',
  MANAGE_USERS = 'Manage Users',
  MANAGE_BREACH = 'Manage Breach',
  NOTIFY_BREACH = 'Breach Notifications',
  MANAGE = 'Manage',
  SECURE = 'Secure',
  SUPPORT = 'Support',
  TRACK = 'Track',
  EXPLORE = 'Explore',
  DOWNLOAD_APP = 'Download App',
  MANAGE_ASSESSMENT = 'Manage Assessment',
  LEARNING_RECOMMENDATIONS = 'Learning Recommendations',
  PEOPLE = 'People',
  LEARNING_PATHS = 'Learning Paths',
  BADGES = 'Badges',
  SCHEDULED_EVENTS = 'Scheduled Events',
  DASHBOARDS = 'Dashboards',
  CONFIGURE = 'Configure',
  SETTINGS = 'Settings',
  COMPETENCY_MODEL = 'Competency Model',
  LEARNING_PRIORITIES = 'Learning Priorities',
  NOTEBOOK = 'Notebook',
  TRANSCRIPT = 'Transcript',
  SCORM = 'Scorm',
  MY_LEARNING = 'My Learning',
  HISTORY = 'History',
  DASHBOARD = 'Dashboard',
  REPORTS = 'Reports',
  ADMINISTER = 'Administer',
  USER = 'User',
  BREACHES = 'Breaches',
  LEARNING_OBJECTS = 'Learning Objects',
  ASSESSMENTS = 'Assessments',
  GROUPS = 'Groups',
  PROGRAMS = 'Programs',
  FILES = 'Files',
}
