import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from '@app/shared/components/notification/notification.component';
import { HamburgerMenuComponent } from '@app/shared/components/hamburger-menu/hamburger-menu.component';
import { ClickOutsideDirective } from '@app/shared/components/notification/clickoutside';
import { TruncateLeadingZero } from '@app/shared/pipes/truncate-leading-zero.pipe';
import { TranslateModule, TranslateStore } from '@ngx-translate/core';
import { OldHamburgerMenuComponent } from '@app/shared/components/old-hamburger-menu/old-hamburger-menu.component';
import { OldNotificationComponent } from '@app/shared/components/old-notification/old-notification.component';
import { FooterBeforeAuthComponent } from '@app/shared/components/footer-before-auth/footer-before-auth.component';
import { RouterModule } from '@angular/router';
import { ModalComponentComponent } from '@app/shared/components/modal-component/modal-component.component';
import { LoginHeaderComponent } from '@app/shared/components/login-header/login-header.component';
import { EditNoteConfirmPopUpComponent } from '@app/shared/components/edit-note-confirm-pop-up/edit-note-confirm-pop-up.component';
import { EditNotePopUpComponent } from '@app/shared/components/edit-note-pop-up/edit-note-pop-up.component';
import { AlertDialogComponent } from '@app/shared/components/alert-dialog/alert-dialog.component';
import { SkillLevelsPopUpComponent } from '@app/shared/components/skill-levels-pop-up/skill-levels-pop-up.component';
import { LearningPathComponent } from '@app/shared/components/learning-path/learning-path.component';
import { LearningObjectCardComponent } from '@app/shared/components/learning-object-card/learning-object-card.component';
import { SearchLearningPathComponent } from '@app/shared/components/search-learning-path/search-learning-path.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import {
  ButtonModule,
  IconModule,
  ModalModule,
  PreloaderModule,
  ToggleModule,
} from 'aftp-component-library';
import { EmptyPlaceholderComponent } from '@app/shared/components/empty-placeholder/empty-placeholder.component';
import { SelectComponent } from '@app/shared/components/select/select.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { ManageLearningPathComponent } from './components/manage-learning-path/manage-learning-path.component';
import { LearningActivityFormEditingHeaderComponent } from '@app/shared/components/learning-activity-form-editing-header/learning-activity-form-editing-header.component';
import {
  bell,
  calendar,
  emojiHappy,
  HeroIconsModule,
  menu,
  paperAirplane,
  pencil,
  search,
} from 'ng-heroicons';
import { SvgIconModule } from '@app/shared/components/svg-icon/svg-icon.module';
import { CustomDropdownComponent } from 'src/app/layouts/user/pages/scheduled-events-page/custom-dropdown/custom-dropdown.component';
import { CustomTextareaComponent } from '@app/layouts/user/pages/scheduled-events-page/custom-textarea/custom-textarea.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormErrorTitleComponent } from 'src/app/shared/components/form-error-title/form-error-title.component';
import { StartPreAssessmentModalComponent } from 'src/app/shared/components/modals/start-pre-assessment-modal/start-pre-assessment-modal.component';
import { BadgePopUpComponent } from './components/badge-pop-up/badge-pop-up.component';
import { PreSkillAssessmentModalComponent } from '@app/shared/components/modals/pre-skill-assessment-modal/pre-skill-assessment-modal.component';
import { LanguageIdService } from 'src/app/shared/services/language-id.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToggleWrapperComponent } from './components/toggle-wrapper/toggle-wrapper.component';
import { TimeStatusComponent } from '@app/shared/components/time-status/time-status.component';
import { TruncateByTheQuantityOfSymbolsPipe } from '@app/shared/pipes/truncate-by-the-quantity-of-symbols-pipe/truncate-by-the-quantity-of-symbols.pipe';
import { CounterDirective } from './directives/countdown-directive/counter.directive';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { LearningDurationAmountTransformPipe } from '@app/layouts/user/pages/profile-page/sections/user-details-section/components/learning-completed-counter/learning-duration-amount-transform.pipe';
import { ParseUrlPipe } from './pipes/parse-url.pipe';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { InlineToggleComponent } from './components/inline-toggle/inline-toggle.component';
import { EulaPopupComponent } from './eula-popup/eula-popup.component';
import { GroupCardComponent } from './components/group-card/group-card.component';
import { FaqCardComponent } from './components/faq-card/faq-card.component';
import { QaCardComponent } from './components/qa-card/qa-card.component';
import { CustomDateRangeComponent } from './components/custom-date-range/custom-date-range.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';

import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatIconModule } from '@angular/material/icon';
import { CertificateTemplateComponent } from './components/certificate-template/certificate-template.component';
import { CertificateIconComponent } from './components/certificate-icon/certificate-icon.component';
import { VirtualScrollPreloaderModule } from './components/virtual-scroll-preloader/virtual-scroll-preloader.module';
const components = [
  NotificationComponent,
  HamburgerMenuComponent,
  ClickOutsideDirective,
  CounterDirective,
  OldHamburgerMenuComponent,
  OldNotificationComponent,
  FooterBeforeAuthComponent,
  ModalComponentComponent,
  LoginHeaderComponent,
  EditNoteConfirmPopUpComponent,
  EditNotePopUpComponent,
  AlertDialogComponent,
  SkillLevelsPopUpComponent,
  LearningPathComponent,
  LearningObjectCardComponent,
  SearchLearningPathComponent,
  EmptyPlaceholderComponent,
  SelectComponent,
  ManageLearningPathComponent,
  LearningActivityFormEditingHeaderComponent,
  CustomDropdownComponent,
  CustomTextareaComponent,
  FormErrorTitleComponent,
  StartPreAssessmentModalComponent,
  PreSkillAssessmentModalComponent,
  ToggleWrapperComponent,
  BadgePopUpComponent,
  TimeStatusComponent,
  ErrorPageComponent,
  InlineToggleComponent,
  EulaPopupComponent,
  GroupCardComponent,
  FaqCardComponent,
  QaCardComponent,
  CustomDateRangeComponent,
  CertificateTemplateComponent,
  CertificateIconComponent,
];

const pipes = [
  TruncateLeadingZero,
  LearningDurationAmountTransformPipe,
  ParseUrlPipe,
  FormatNumberPipe,
];

@NgModule({
  declarations: [...components, ...pipes],
  exports: [...components, TranslateModule, ...pipes],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    AngularMyDatePickerModule,
    PreloaderModule,
    ButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatMomentDateModule,
    MatButtonToggleModule,
    MatSelectModule,
    IconModule,
    MatIconModule,
    HeroIconsModule.withIcons({
      pencil,
      search,
      bell,
      menu,
      emojiHappy,
      paperAirplane,
      calendar,
    }),
    SvgIconModule,
    NgbDropdownModule,
    ModalModule,
    MatTooltipModule,
    ToggleModule,
    MatTableModule,
    VirtualScrollPreloaderModule,
  ],
  providers: [
    TranslateStore,
    LanguageIdService,
    TruncateByTheQuantityOfSymbolsPipe,
  ],
})
export class SharedModule {}
