import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SessionGuardService } from 'src/app/shared/guards/session-guard.service';
import { UserLayoutModule } from '@app/layouts/user/user-layout.module';
import { AuthLayoutModule } from '@app/layouts/auth/auth-layout.module';
import {
  USER_ROUTER_PATHS,
  AUTH_ROUTER_PATHS,
} from 'src/app/shared/enums/router-paths.enum';
import { LoginGuardService } from 'src/app/shared/guards/login-guard.service';
import { PAGE_TITLE } from 'src/app/shared/enums/page-title.enum';
import { AppRoutes } from '@app/shared/constants/app-routes.constant';
import { SiteAccessLayoutModule } from '@app/layouts/site-access/site-access-layout.module';
import { EulaguardGuard } from './shared/guards/eulaguard.guard';

const routes: Routes = [
  {
    path: AppRoutes.DEFAULT,
    loadChildren: AuthLayoutModuleChildren(),
  },
  {
    path: 'user',
    canActivate: [SessionGuardService],
    loadChildren: (): Promise<UserLayoutModule> =>
      import('@app/layouts/user/user-layout.module').then(
        (m) => m.UserLayoutModule
      ),
  },
  {
    path: AppRoutes.ERRORS_BASE,
    loadChildren: () =>
      import('@app/layouts/error/error-layout.module').then(
        (m) => m.ErrorLayoutModule
      ),
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('@app/layouts/logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: 'ftp-app-gateway',
    loadChildren: () =>
      import('@app/layouts/ftp-app-gateway/ftp-app-gateway.module').then(
        (m) => m.HcmGatewayModule
      ),
  },
  {
    path: AUTH_ROUTER_PATHS.FTP_APP_EULA,
    canActivate: [EulaguardGuard],
    loadChildren: () =>
      import('@app/layouts/ftp-app-eula/ftp-app-eula.component.module').then(
        (m) => m.FtpAppEulaPageModule
      ),
  },
  {
    path: 'tenant-spec',
    loadChildren: () =>
      import(
        '@app/layouts/tenant-specific-url/tenant-specific-url.module'
      ).then((m) => m.TenantSpecificUrlModule),
  },
  {
    path: 'tenant-spec-sol1',
    loadChildren: () =>
      import(
        '@app/layouts/tenant-spec-url-sol1/tenant-spec-url-sol1.module'
      ).then((m) => m.TenantSpecificUrlSol1Module),
  },
  {
    path: 'aicclaunch',
    loadChildren: () =>
      import('@app/aicc/remote-launch.module').then(
        (m) => m.RemoteLaunchModule
      ),
  },
  {
    path: AUTH_ROUTER_PATHS.TERMS_AND_CONDITIONS,
    loadChildren: () =>
      import(
        '@app/layouts/terms-and-conditons/terms-and-conditons.module'
      ).then((m) => m.TermsAndConditonsModule),
  },
  {
    path: AUTH_ROUTER_PATHS.TERMS_AND_CONDITIONS_OHIO,
    loadChildren: () =>
      import(
        '@app/layouts/terms-and-conditons/terms-and-conditons.module'
      ).then((m) => m.TermsAndConditonsModule),
  },
  {
    path: AUTH_ROUTER_PATHS.TERMS_AND_CONDITIONS_BESTBUY,
    loadChildren: () =>
      import(
        '@app/layouts/terms-and-conditions-bestbuy/terms-and-conditons-bestbuy.module'
      ).then((m) => m.TermsAndConditonsBestbuyModule),
  },
  {
    path: AUTH_ROUTER_PATHS.TERMS_AND_CONDITIONS_ACCENTURE_ACADEMY,
    loadChildren: () =>
      import(
        '@app/layouts/terms-and-conditions-accenture-academy/terms-and-conditions-accenture-academy.module'
      ).then((m) => m.TermsAndConditonsAccentureAcademyModule),
  },
  {
    path: 'login',
    canActivate: [LoginGuardService],
    loadChildren: AuthLayoutModuleChildren(),
  },
  {
    path: 'site-access',
    canActivate: [SessionGuardService],
    loadChildren: (): Promise<SiteAccessLayoutModule> =>
      import('@app/layouts/site-access/site-access-layout.module').then(
        (m) => m.SiteAccessLayoutModule
      ),
  },

  {
    path: '**',
    data: { title: PAGE_TITLE.HOME },
    redirectTo: `${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.HOME}`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
function AuthLayoutModuleChildren(): import('@angular/router').LoadChildren {
  return (): Promise<AuthLayoutModule> =>
    import('@app/layouts/auth/auth-layout.module').then(
      (m) => m.AuthLayoutModule
    );
}
