import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
@Component({
  selector: 'aftp-manage-learning-path',
  templateUrl: './manage-learning-path.component.html',
  styleUrls: ['./manage-learning-path.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageLearningPathComponent {
  @Input() learningCard;
}
