import { Pipe, PipeTransform } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })

@Pipe({
  name: 'parseUrl',
})
export class ParseUrlPipe implements PipeTransform {
  urls =
    /(\b(https?|http|ftp|ftps|Https|rtsp|Rtsp):\/\/[A-Z0-9+&@#/%?=~_|!:,.;-]*[-A-Z0-9+&@#/%=~_|])/gim; // Find/Replace URL's in text

  transform(value: string): string {
    // Find/Replace URL's in text
    value = value.replace(/<\/?[^>]+(>|$)/g, '');
    if (this.urls.exec(value)) {
      value = value.replace(this.urls, function replacer($1, $2, $3) {
        const url: string = $1;
        const urlClean: string = url.replace('' + String($3) + '://', '');
        return (
          '<a href="' +
          url +
          '" target="_blank" rel="noopener noreferrer">' +
          urlClean +
          '</a>'
        );
      });
    }
    return value;
  }
}
