import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  userAvatar = new BehaviorSubject<string>('');

  constructor(private localStorageService: LocalStorageService) {}

  set avatarLocalStorage(value: string) {
    this.userAvatar.next(value);
  }

  get avatarLocalStorage() {
    return this.userAvatar.getValue();
  }
}
