<div
  [ngClass]="{
    'non-cat': !categorized,
    'first-non-cat': first,
    'faq-border': selected && index == selected.index
  }"
  class="faq-container-div rounded"
>
  <div class="rounded-lg border-0 p-0">
    <h5 class="mb-0" aria-hidden="true">
      <button
        (click)="clickedItem()"
        class="
          text-left
          rounded
          w-100
          question
          d-flex
          align-items-center
          justify-content-between
          border-0
          bg-semi-gray
        "
        type="button"
        data-toggle="collapse"
        [attr.data-target]="'#' + uid"
        aria-expanded="true"
        [attr.aria-controls]="uid"
      >
        <div class="pr-2">{{ data.q }}</div>
        <svg
          [ngClass]="{ 'active-qa': selected && index == selected.index }"
          class="flex-shrink-0"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#6F6680"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
    </h5>
  </div>
  <div [id]="uid" class="collapse" [attr.data-parent]="'#' + accordionID">
    <div
      (click)="listenToClick($event)"
      class="card-body graphik-regular border-0 rounded-bottom answer p-4"
      [innerHTML]="data.a"
    ></div>
  </div>
</div>
