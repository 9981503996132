import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'aftp-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
})
export class CustomDropdownComponent implements OnInit, OnChanges {
  activeIndex: number;
  constructor(public changeDetectorRef: ChangeDetectorRef) {}
  search: string;
  filteredList = [];
  loaded = false;
  @Input() grpChecked?: boolean = false;
  @Input() selectAllChecked = false;
  @Input() showEllipsisOnList = false;
  @Input() showSelectAll?: boolean = false;
  @Input() isGroup?: boolean;
  @Input() groupItems?: any;
  @Input() otherGroupList?: any;
  @Input() groupKey?: string;
  @Input() items; //Dropdown Items
  @Input() disabled = false;
  @Input() error = false;
  @Input() placeholder: string;
  @Input() toolTip: string;
  @Input() scrollUpLoader = false;
  @Input() initialValue = []; //Pre-selected items ( either string or array )
  @Input() allowMultiple = false;
  @Input() allowSearch = false;
  @Input() shownKey; //Key to bew shown
  @Input() select;
  @Input() toggle;
  @Input() allowClearSelection = false; //Show remove button
  @Input() stopItemSelection = false;
  @Input() strokeColor: string;
  @Input() makeApiCallOnSearch = false;
  @Input() toolTipPropertyName: string;
  @Output() clickAction = new EventEmitter();
  @Output() enterAction = new EventEmitter();
  @Output() crossAction = new EventEmitter();
  @Output() toggleEvent = new EventEmitter();
  @Output() clearSelectedEvent = new EventEmitter<void>();
  @Output() keySearch = new EventEmitter();
  @Output() scrollDropdown = new EventEmitter();
  @Output() clickSeeMore = new EventEmitter();
  @Output() clickGrpAllChecked = new EventEmitter();
  private _control: AbstractControl | FormControl;
  initialValueHolder = [];
  programGroupDetails = [];
  noResultFound;
  @ViewChild('dropdownRef', { static: false, read: NgbDropdown })
  dropdown: NgbDropdown;
  @Input()
  set control(val: AbstractControl | FormControl) {
    this._control = val;
  }
  get control(): AbstractControl | FormControl {
    return this._control;
  }
  ngOnInit(): void {
    if (this.isGroup) {
      this.groupItems = this.items;
    } else {
      this.filteredList = this.items;
    }
    this.loaded = true;
    this.activeIndex = 0;
  }
  @HostListener('scroll', ['$event'])
  scrollHandler(event): void {
    this.scrollDropdown.emit(event);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).includes('initialValue')) {
      this.initialValueHolder = Array.isArray(this.initialValue)
        ? this.initialValue.map((a) => Object.assign({}, a))
        : this.initialValue;
      if (!this.initialValueHolder?.length) {
        this.selectAllChecked = false;
        this.grpChecked = false;
        this.changeDetectorRef.detectChanges();
      }
    }
    this.makeApiCallOnSearchCheck();
    if (this.toggle) {
      this.dropdown.close();
    }
    this.isGroupCheck(changes);
  }

  makeApiCallOnSearchCheck() {
    if (this.makeApiCallOnSearch) {
      if (this.items?.length === 0) {
        if (this.search === '') {
          this.noResultFound = false;
        } else {
          this.noResultFound = true;
          this.filteredList = [];
        }
      } else {
        this.noResultFound = false;
        this.filteredList = this.items;
      }
    } else this.filteredList = this.items;
  }
  isGroupCheck(changes) {
    if (this.isGroup) {
      if (Object.keys(changes).includes('items')) {
        this.groupItems = changes?.items?.currentValue;
      }
      if (Object.keys(changes).includes('otherGroupList')) {
        this.otherGroupList = changes?.otherGroupList?.currentValue;
      }

      this.groupItems = [...this.groupItems, ...this.otherGroupList];

      this.groupItems = this.groupItems.filter(
        (obj, index) =>
          this.groupItems.findIndex((item) => item.name === obj.name) === index
      );
      this.items = this.groupItems;
      this.selectAllChecked =
        this.initialValueHolder?.length === this.items?.length ? true : false;
    }
  }
  getValue() {
    let selectedValue: string;
    if (Array.isArray(this.initialValueHolder)) {
      selectedValue =
        this.initialValueHolder.length > 0
          ? this.fnSelectedVal(this.initialValueHolder)
          : this.placeholder;
    } else {
      if (this.initialValueHolder) {
        selectedValue =
          this.initialValueHolder[this.shownKey] ?? this.placeholder;
      } else {
        selectedValue = this.placeholder;
      }
    }
    return selectedValue;
  }

  @HostListener('window:keydown', ['$event']) keyEvent(event: KeyboardEvent) {
    if (event.code == 'ArrowUp' && this.activeIndex > 0) {
      this.activeIndex--;
      event.stopPropagation();
      event.preventDefault();
      const elmnt = document.getElementById(`list${this.activeIndex}`);
      if (elmnt.parentElement.className.includes('custom-checkbox')) {
        elmnt.scrollIntoView(false);
      }
    }
    if (
      event.code == 'ArrowDown' &&
      this.activeIndex <= this.shownKey.length - 1
    ) {
      this.activeIndex++;
      event.stopPropagation();
      event.preventDefault();
      const elmnt = document.getElementById(`list${this.activeIndex}`);
      if (elmnt.parentElement.className.includes('custom-checkbox')) {
        elmnt.scrollIntoView(false);
      }
    }
    if (event.code == 'Enter' && this.activeIndex <= this.shownKey.length - 1) {
      this.onEnter();
    }
  }

  fnSelectedVal(i) {
    if (i.length > 1) {
      return `<div class="first-value">${
        i[0][this.shownKey]
      }</div>&nbsp;<span class="more-value flex-shrink-0">,+${
        i.length - 1
      } more&nbsp;</span>`;
    } else {
      return `<div class="first-value">${i[0][this.shownKey]}</div>`;
    }
  }
  toolTipText() {
    if (this.makeApiCallOnSearch) {
      if (Array.isArray(this.initialValueHolder)) {
        return this.initialValueHolder?.reduce((acc, curr, index) => {
          if (index !== this.initialValueHolder.length - 1) {
            return `${acc}` + ` ${curr[this.toolTipPropertyName]};`;
          }
          return `${acc}` + ` ${curr[this.toolTipPropertyName]}`;
        }, '');
      }
    }
  }
  itemClicked(item) {
    if (!this.disabled) {
      this.notDisabled(item);
      this.selectAllChecked =
        this.initialValueHolder?.length === this.items?.length ? true : false;
      this.grpChecked =
        this.getGrpCount() === this.initialValueHolder?.length ? true : false;
      this.clickAction.emit(this.initialValueHolder);
      this.clickGrpAllChecked.emit(this.grpChecked);
    }
  }

  notDisabled(item) {
    if (this.allowMultiple) {
      const itemIndex = this.initialValueHolder.findIndex(
        (el) => el[this.shownKey] === item[this.shownKey]
      );
      itemIndex > -1
        ? this.initialValueHolder.splice(itemIndex, 1)
        : this.initialValueHolder.push(item);
      if (!this.makeApiCallOnSearch && !this.isGroup) {
        this.initialValueHolder = this.items.filter((initialItem) => {
          return this.initialValueHolder.find((initialValue) => {
            return initialValue[this.shownKey] === initialItem[this.shownKey];
          });
        });
      }
    } else if (this.stopItemSelection) {
      this.clickAction.emit({ message: 'SHOW_WARNING', selectedItem: item });
      this.dropdown.close();
      return;
    } else {
      this.initialValueHolder = item;
      this.dropdown.close();
    }
  }
  onEnter() {
    this.enterAction.emit(this.search);
  }
  ifChecked(item) {
    return (
      this.initialValueHolder.findIndex(
        (el) => el[this.shownKey] === item[this.shownKey]
      ) > -1
    );
  }
  enterDropdown(event) {
    const stringclassName = event.target.__ngContext__[0].className;
    if (
      event.target.__ngContext__[0].className == 'status-dropdown mt-2' &&
      event.target.__ngContext__[27].className == 'dropdown-menu show'
    ) {
      this.itemClicked(this.filteredList[this.activeIndex]);
    } else if (
      event.target.__ngContext__[0].className == 'skill-level-dropdown mt-2' &&
      event.target.__ngContext__[27].className == 'dropdown-menu show'
    ) {
      this.itemClicked(this.filteredList[this.activeIndex]);
    } else if (
      (event.target.__ngContext__[0].className ==
        'language-settings-dropdown' ||
        event.target.__ngContext__[0].className == 'mapLPCustomDropdown' ||
        event.target.__ngContext__[0].className == 'sortCustomDropdown' ||
        stringclassName.split(' ')[0] == 'sortCustomDropdown' ||
        stringclassName.split(' ')[0] == 'skill-level-dropdown') &&
      event.target.__ngContext__[27].className == 'dropdown-menu show'
    ) {
      this.itemClicked(this.filteredList[this.activeIndex]);
    } else {
      if (!this.disabled) {
        this.dropdown.toggle();
      }
      this.activeIndex = 0;
    }
  }
  toggleDropdown() {
    this.activeIndex = 0;
    if (!this.disabled) {
      this.dropdown.toggle();
    }
  }
  tabDropdown() {
    this.activeIndex = 0;
    if (this.dropdown._open) {
      if (!this.disabled) {
        this.dropdown.toggle();
      }
    }
  }
  typeTimeOut: any; //For timeout
  searchEvents(event) {
    if (event.code === 'Space') {
      this.search = this.search ? `${this.search} ` : '';
    }
    if (this.makeApiCallOnSearch) {
      clearTimeout(this.typeTimeOut);
      this.typeTimeOut = setTimeout(() => {
        if (
          (this.search.length < 3 && event.code === 'Enter') ||
          this.search.length >= 3
        ) {
          this.keySearch.emit(this.search);
        } else {
          this.items = [];
          this.filteredList = [];
          this.noResultFound = false;
          this.keySearch.emit(this.search);
        }
        this.changeDetectorRef.detectChanges();
      }, 300);
    } else {
      clearTimeout(this.typeTimeOut);
      this.typeTimeOut = setTimeout(() => {
        if (this.isGroup) {
          if (this.search) {
            this.groupItems = [];
            this.items?.filter((element) => {
              const lists = element[this.groupKey].filter((e) =>
                e[this.shownKey]
                  .toLowerCase()
                  .includes(this.search.toLowerCase())
              );
              if (lists?.length > 0) {
                this.groupItems?.push({
                  name: element?.name,
                  [this.groupKey]: lists,
                });
              }
            });
          } else {
            this.groupItems = this.items;
          }
          if (this.groupItems.length === 0) {
            this.noResultFound = true;
          } else {
            this.noResultFound = false;
          }
        } else {
          this.filteredList = this.search
            ? this.items.filter((el) =>
                el[this.shownKey]
                  .toLowerCase()
                  .includes(this.search.toLowerCase())
              )
            : this.items;
          if (this.filteredList.length === 0) {
            this.noResultFound = true;
          } else {
            this.noResultFound = false;
          }
        }
        this.changeDetectorRef.detectChanges();
      }, 300);
    }
  }
  checkDropDown(event) {
    if (!event) {
      this.search = '';
      if (this.makeApiCallOnSearch) {
        this.filteredList = [];
      } else {
        this.filteredList = this.items;
        this.groupItems = this.items;
      }
      this.noResultFound = false;
    }
    this.toggleEvent.emit({
      isOpen: event,
      currentValue: this.initialValueHolder,
    });
  }

  clearSelected(event) {
    console.log(event, 'event');
    event.stopPropagation();
    event.preventDefault();
    this.crossAction.emit(true);
    this.initialValueHolder = [];
    if (this.dropdown.isOpen()) {
      this.dropdown.toggle();
    } else {
      this.checkDropDown(false);
    }
    this.clearSelectedEvent.emit();
    this.grpChecked = false;
  }
  onClickSeeMore(groupObj: any) {
    this.clickSeeMore.emit({ groupObj, grpChecked: this.grpChecked });
  }
  groupSelectAll(event) {
    this.grpChecked = false;
    this.initialValueHolder = [];
    if (event.target.checked === true) {
      this.groupItems.forEach((element) => {
        element[this.groupKey]?.forEach((ele) => {
          const itemIndex = this.initialValueHolder.findIndex(
            (el) => el[this.shownKey] === ele[this.shownKey]
          );
          itemIndex > -1
            ? this.initialValueHolder.splice(itemIndex, 1)
            : this.initialValueHolder.push(ele);
        });
      });
    } else {
      this.grpChecked = false;
      this.initialValueHolder = [];
    }
    this.grpChecked = this.initialValueHolder?.length > 0 ? true : false;
    this.clickAction.emit(this.initialValueHolder);
    this.clickGrpAllChecked.emit(this.grpChecked);
  }

  selectAll(event) {
    this.selectAllChecked = false;
    this.initialValueHolder = [];
    if (event.target.checked === true) {
      this.items.forEach((element) => {
        const itemIndex = this.initialValueHolder.findIndex(
          (el) => el[this.shownKey] === element[this.shownKey]
        );
        itemIndex > -1
          ? this.initialValueHolder.splice(itemIndex, 1)
          : this.initialValueHolder.push(element);
      });
    } else {
      this.initialValueHolder = [];
      this.selectAllChecked = false;
    }
    this.selectAllChecked = this.initialValueHolder?.length > 0 ? true : false;
    this.clickAction.emit(this.initialValueHolder);
  }

  getGrpCount() {
    let grpcount = 0;
    this.groupItems?.forEach((element) => {
      element[this.groupKey]?.forEach(() => {
        grpcount = +grpcount + 1;
      });
    });
    return grpcount;
  }
}
