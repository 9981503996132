export enum NOTIFICATION_STATUS {
  UNREAD = 'unread',
  READ = 'read',
  REMOVED = 'removed',
}

export enum NOTIFICATION_TYPE {
  UPDATE_PROFILE_PHOTO = 'UPDATE_PROFILE_PHOTO',
  CHANGE_SETTINGS = 'CHANGE_SETTINGS',
  SKILL_ACCOMPLISHMENTS = 'SKILL_ACCOMPLISHMENTS',
  NEW_COMMENT_ON_POST = 'NEW_COMMENT_ON_POST',
  NEW_LIKE_ON_COMMENT = 'NEW_LIKE_ON_COMMENT',
  NEW_TAG_ON_POST = 'NEW_TAG_ON_POST',
  NEW_TAGS_ON_POST = 'NEW_TAGS_ON_POST',
  NEW_LIKE_ON_POST = 'NEW_LIKE_ON_POST',
  PRE_SKILL_ASSESSMENT_AVAILABLE = 'PRE_SKILL_ASSESSMENT_AVAILABLE',
  PRE_SKILL_ASSESSMENT_EXPIRY = 'PRE_SKILL_ASSESSMENT_EXPIRY',
  PRE_SKILL_ASSESSMENT_RESULT = 'PRE_SKILL_ASSESSMENT_RESULT',
  NEW_FEEDBACK = 'NEW_FEEDBACK',
  FEEDBACK_ABOUT_EXPIRE = 'FEEDBACK_ABOUT_EXPIRE',
  PLANNED_MAINTENANCE = 'PLANNED_MAINTENANCE',
  LEARNING_PATH_COMPLETE = 'LEARNING_PATH_COMPLETE',
}
