import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { Service2siteAccessOptionsService } from '@app/shared/services/service-2site-access-options.service';

@Injectable({
  providedIn: 'root',
})
export class CommonHeaderService {
  constructor(
    private sessionService: SessionService,
    private siteAccessService: Service2siteAccessOptionsService
  ) {}

  getCommonHeaders(request?: HttpRequest<any>): HttpHeaders {
    const tenantID = localStorage.getItem('tenantID');
    const poolId = localStorage.getItem('PoolId');
    const userId = localStorage.getItem('userId');
    const isEso = localStorage.getItem('isEso');
    const isFTPAPPClient = localStorage.getItem('isFTPAPPClient');
    const eid = localStorage.getItem('eid');
    let requestHeaders = request ? request.headers : new HttpHeaders();
    requestHeaders = requestHeaders.append(
      'Authorization',
      `Bearer ${this.sessionService.accessToken}`
    );
    requestHeaders =
      request && request.body instanceof FormData
        ? requestHeaders.append('enctype', 'multipart/form-data')
        : requestHeaders.append(
            'Content-Type',
            'application/json; charset=utf-8'
          );

    if (tenantID) {
      requestHeaders = requestHeaders.append('TenantId', tenantID);
    }

    if (poolId) {
      requestHeaders = requestHeaders.append('PoolId', poolId);
    }

    if (userId) {
      requestHeaders = requestHeaders.append('userId', userId);
    }

    if (isEso) {
      requestHeaders = requestHeaders.append('isEso', isEso);
    }

    if (isFTPAPPClient) {
      requestHeaders = requestHeaders.append('isFTPAPPClient', isFTPAPPClient);
    }
    if (eid) {
      requestHeaders = requestHeaders.append('eId', eid);
    }

    return requestHeaders;
  }
}
