// =============== Modules ===============
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorInterceptor } from '@app/_core/interceptors/error.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgxLoadingModule } from 'ngx-loading';
import { AppRoutingModule } from './app-routing.module';
import { MatDialogModule } from '@angular/material/dialog';
import { LoadingInterceptor } from './_core/interceptors/loading.interceptor';
import { PreloaderModule } from 'aftp-component-library';
import { SharedModule } from '@app/shared/shared.module';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';

// =============== Services ===============
import { UserDetailsService } from '@app/shared/services/user-details.service';
import { SessionService } from '@app/shared/services/session.service';
import { LogoutService } from '@app/shared/services/logout.service';
import { SessionGuardService } from '@app/shared/guards/session-guard.service';
import { AmplifyConfigurationService } from 'src/app/_core/services/amplify-configuration.service';
import { UserService } from '@app/shared/services/user.service';
import { EulaServiceService } from '@app/shared/services/eula-service.service';
import { TokenInterceptor } from '@app/_core/interceptors/token.interceptor';
import { MissingTranslationService } from 'src/app/shared/services/translation/missing-translation/missing-translation.service';
import { LearningObjectDetailService } from '@app/layouts/user/pages/learning-object-detail/services/learning-object-detail.service';
// =============== Components ===============
import { AppComponent } from './app.component';
import { LanguageConstants } from '@app/shared/constants/language';

import { HeaderModule } from '@app/shared/components/header/header.module';
import { SettingsPageApiService } from '@app/layouts/user/pages/settings-page/services/settings-page-api.service';

import { FooterModule } from '@app/shared/components/footer/footer.module';
import { MentionModule } from 'angular-mentions';
import { GlobalSearchService } from '@app/shared/services/global-search.service';
import { AvatarService } from 'src/app/shared/services/avatar.service';
import { ReminderPromptApiService } from '@app/layouts/user/pages/home-page/services/reminder-prompt-api.service';
import { GlobalErrorHandlerService } from '@app/shared/services/global-error-handler-service.service';

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, '../assets/languages/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    AngularMyDatePickerModule,
    GoogleChartsModule,
    NgxLoadingModule.forRoot({}),
    SharedModule,
    MatDialogModule,
    PreloaderModule,
    FooterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: LanguageConstants.DEFAULT_LANGUAGE,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationService,
      },
    }),
    HeaderModule,
    MentionModule,
  ],
  providers: [
    UserDetailsService,
    SessionService,
    LogoutService,
    SessionGuardService,
    UserService,
    AmplifyConfigurationService,
    SettingsPageApiService,
    EulaServiceService,
    AvatarService,
    ReminderPromptApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    CookieService,
    LearningObjectDetailService,
    GlobalSearchService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
