import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { COMMON_APPLICATION_TITLE } from 'src/app/shared/enums/page-title.enum';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  constructor(
    private titleService: Title,
    private translateService: TranslateService
  ) {}

  setTitle(title: string): void {
    if (title) {
      title = this.translateTitle(title);
      const commonTitle = COMMON_APPLICATION_TITLE.COMMON_TITLE;
      this.titleService.setTitle(title + ' - ' + commonTitle);
    }
  }

  translateTitle(title: string): string {
    const languageID = localStorage.getItem('languageID');
    this.translateService.use(languageID ?? 'en');
    return this.translateService.instant('pageTitle.' + title);
  }
}
