import { HAMBURGER_MENU_ITEMS } from 'src/app/shared/enums/hamburger-menu-item.enum';
import { USER_ROUTER_PATHS } from 'src/app/shared/enums/router-paths.enum';
import { ICONS } from '@app/shared/components/svg-icon/icons-list';

export const MenuNameRouteMap: Map<HAMBURGER_MENU_ITEMS, USER_ROUTER_PATHS> =
  new Map([
    [HAMBURGER_MENU_ITEMS.HISTORY, USER_ROUTER_PATHS.HISTORY],
    [HAMBURGER_MENU_ITEMS.NOTEBOOK, USER_ROUTER_PATHS.NOTEBOOK],
    [HAMBURGER_MENU_ITEMS.DASHBOARD, USER_ROUTER_PATHS.DASHBOARD],
    [HAMBURGER_MENU_ITEMS.REPORTS, USER_ROUTER_PATHS.REPORTS],
    [HAMBURGER_MENU_ITEMS.ASSESSMENTS, USER_ROUTER_PATHS.MANAGE_ASSESSMENT],
    [HAMBURGER_MENU_ITEMS.LEARNING_OBJECTS, USER_ROUTER_PATHS.LEARNING_OBJECTS],
    [HAMBURGER_MENU_ITEMS.LEARNING_PATHS, USER_ROUTER_PATHS.LEARNING_PATHS],
    [HAMBURGER_MENU_ITEMS.MANAGE_LEARNING_PATHS, USER_ROUTER_PATHS.HOME], // TODO: ADD 'TRACK_EVENTS' ROUTE
    [
      HAMBURGER_MENU_ITEMS.MANAGE_ASSESSMENT,
      USER_ROUTER_PATHS.MANAGE_ASSESSMENT,
    ],
    [HAMBURGER_MENU_ITEMS.GROUPS, USER_ROUTER_PATHS.MANAGE_GROUPS],
    [HAMBURGER_MENU_ITEMS.PROGRAMS, USER_ROUTER_PATHS.MANAGE_PROGRAMS],
    [HAMBURGER_MENU_ITEMS.SCHEDULED_EVENTS, USER_ROUTER_PATHS.SCHEDULED_EVENTS],
    [HAMBURGER_MENU_ITEMS.FILES, USER_ROUTER_PATHS.CONTENT],
    [HAMBURGER_MENU_ITEMS.TRACK_EVENTS, USER_ROUTER_PATHS.HOME], // TODO: ADD 'TRACK_EVENTS' ROUTE
    [HAMBURGER_MENU_ITEMS.COMPETENCY_MODEL, USER_ROUTER_PATHS.COMPETENCY],
    [HAMBURGER_MENU_ITEMS.LEARNING_PRIORITIES, USER_ROUTER_PATHS.HOME], // TODO: ADD 'TRACK_EVENTS' ROUTE
    [HAMBURGER_MENU_ITEMS.USER, USER_ROUTER_PATHS.MANAGE_USER],
    [HAMBURGER_MENU_ITEMS.BREACHES, USER_ROUTER_PATHS.MANAGE_BREACHES],
    [HAMBURGER_MENU_ITEMS.SETTINGS, USER_ROUTER_PATHS.SETTINGS],
  ]);

export const MenuNameIconMap: Map<HAMBURGER_MENU_ITEMS, ICONS> = new Map([
  [HAMBURGER_MENU_ITEMS.HISTORY, ICONS.CLOCK],
  [HAMBURGER_MENU_ITEMS.NOTEBOOK, ICONS.DOCUMENT_TEXT],
  [HAMBURGER_MENU_ITEMS.DASHBOARD, ICONS.CHART_BAR],
  [HAMBURGER_MENU_ITEMS.REPORTS, ICONS.PIE],
  [HAMBURGER_MENU_ITEMS.SETTINGS, ICONS.COG],
  [HAMBURGER_MENU_ITEMS.LOGOUT, ICONS.LOGOUT],
]);
