<header class="error-page-header notranslate">
  <div tabindex="0" class="displaynone"></div>
  <div class="aftp-container">
    <div class="header-inner">
      <div class="logo">
        <a class="logo-link">
          <img src="{{ logoImg }}" alt="Logo image" class="logo-image" />
        </a>
        <h1 class="logo-title" *ngIf="displayProgramName">
          {{ headerTitle }}
        </h1>
      </div>
      <div class="d-flex justify-content-end">
        <svg-icon
          class="cross-icon-size"
          width="24px"
          height="24px"
          [name]="ICONS.CLOSE"
          (click)="closeTerms()"
        ></svg-icon>
      </div>
    </div>
  </div>
</header>
<div class="container-fluid">
  <div class="page-container">
    <div class="row gutter-2">
      <section class="col-12 eula-section">
        <div class="eulaHeading" role="heading" aria-level="2">
          {{ eula.headers[0] }} <br />
          {{ eula.headers[1] }}
        </div>
        <div class="eulaSubTitle">{{ eula.headers[2] }}</div>
        <div class="eulaTextArea mCustomScrollbar">
          <div class="terms-main">
            <p [innerHTML]="eula['terms_intro']"></p>
            <p class="terms-intro" [innerHTML]="eula['terms_intro1']"></p>
            <div class="terms-exhibits">
              <ul class="exhibits-main-ul">
                <li>
                  <h3
                    class="terms-subheadings exhibits-terms-headings"
                    [innerHtml]="eula.termsExhibitA.term"
                  ></h3>
                  <p
                    tabindex="-1"
                    [innerHtml]="eula.termsExhibitA.content.p1"
                  ></p>
                  <p [innerHtml]="eula.termsExhibitA.content.p2"></p>
                </li>
                <li>
                  <h3
                    class="terms-subheadings exhibits-terms-headings"
                    [innerHtml]="eula.termsExhibitB.term"
                  ></h3>
                  <p>{{ eula.termsExhibitB.content.p1 }}</p>
                  <table
                    class="incident-table"
                    aria-describedby="tabledescription"
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          {{ eula.termsExhibitB.table.Headers.row1 }}
                        </th>
                        <th scope="col">
                          {{ eula.termsExhibitB.table.Headers.row2 }}
                        </th>
                        <th scope="col">
                          {{ eula.termsExhibitB.table.Headers.row3 }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <strong>{{
                              eula.termsExhibitB.table.content.p1
                            }}</strong>
                          </div>
                          <ul class="exhibit-b" style="list-style-type: disc">
                            <li
                              *ngFor="
                                let p4 of eula.termsExhibitB.table.content.List
                              "
                              [innerHtml]="p4"
                            ></li>
                          </ul>
                        </td>
                        <td>
                          <ul class="exhibit-b" style="list-style-type: disc">
                            <li
                              *ngFor="
                                let p4 of eula.termsExhibitB.table.content.List2
                              "
                              [innerHtml]="p4"
                            ></li>
                          </ul>
                        </td>
                        <td rowspan="2">
                          <ul class="exhibit-b" style="list-style-type: disc">
                            <li
                              *ngFor="
                                let p4 of eula.termsExhibitB.table.content.List4
                              "
                              [innerHtml]="p4"
                            ></li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <strong>{{
                              eula.termsExhibitB.table.content.p2
                            }}</strong>
                          </div>
                          <ul class="exhibit-b" style="list-style-type: disc">
                            <li
                              *ngFor="
                                let p4 of eula.termsExhibitB.table.content.List1
                              "
                              [innerHtml]="p4"
                            ></li>
                          </ul>
                        </td>
                        <td>
                          <ul class="exhibit-b" style="list-style-type: disc">
                            <li
                              *ngFor="
                                let p4 of eula.termsExhibitB.table.content.List3
                              "
                              [innerHtml]="p4"
                            ></li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  <ul class="exhibit-b-list">
                    <li
                      *ngFor="let p4 of eula.termsExhibitB.table.content.List5"
                    >
                      <div class="row">
                        <div>
                          {{ eula.termsExhibitB.table.content.p3 }}
                        </div>
                        <div class="col-12">{{ p4 }}</div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3
                    class="terms-subheadings exhibits-terms-headings"
                    [innerHtml]="eula.termsExhibitC.term"
                  ></h3>

                  <p>{{ eula.termsExhibitC.content.p1 }}</p>
                  <p>{{ eula.termsExhibitC.content.p2 }}</p>
                  <h3
                    class="terms-subheadings exhibits-terms-headings"
                    [innerHtml]="eula.termsExhibitD.term"
                  ></h3>
                  <p [innerHtml]="eula.termsExhibitD.para"></p>
                </li>
                <li>
                  <h3
                    class="terms-subheadings exhibits-terms-headings"
                    [innerHtml]="eula.termsExhibitE.term"
                  ></h3>
                  <p>{{ eula.termsExhibitE.content.p1 }}</p>
                  <p>{{ eula.termsExhibitE.content.p2 }}</p>
                  <p [innerHtml]="eula.termsExhibitE.content.p3"></p>
                </li>
                <li>
                  <h3
                    class="terms-subheadings exhibits-terms-headings"
                    [innerHtml]="eula.termsExhibitF.term"
                  ></h3>
                  <p>{{ eula.termsExhibitF.content.p1 }}</p>
                </li>
                <li>
                  <ul class="exhibit-b-list">
                    <li
                      *ngFor="let p4 of eula.termsExhibitB.table.content.List6"
                    >
                      <div class="row">
                        <div
                          [innerHtml]="eula.termsExhibitB.table.content.p3"
                        ></div>
                        <div class="col-12" [innerHtml]="p4"></div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3
                    class="terms-subheadings exhibits-terms-headings"
                    [innerHtml]="eula.termsExhibitG.term"
                  ></h3>
                  <p [innerHtml]="eula.termsExhibitG.content.p1"></p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
<app-footer-before-auth class="nofous"></app-footer-before-auth>
