<mat-card class="card-container">
  <div
    class="notification-card-container"
    [ngClass]="card.currentStatus"
    (click)="navigateByCard($event, card)"
  >
    <div class="notification-card-content">
      <mat-list-item>
        <aftp-avatar-profile
          class="notificationProfile"
          *ngIf="isAvatarAvailable(); else showNotificationImage"
          matListAvatar
          [profileData]="card.actionedBy"
        ></aftp-avatar-profile>
        <ng-template #showNotificationImage>
          <img
            matListAvatar
            [src]="card.imageSrc"
            [ngClass]="{ announcementIcon: isAnnouncement }"
          />
        </ng-template>
        <div
          markdown
          matLine
          class="line-content multiline"
          *ngIf="!isAnnouncement"
        >
          {{ card.body }}
        </div>
        <div *ngIf="isAnnouncement" class="announcementText" id="announcement">
          <span
            *ngIf="cardData.seeMore"
            class="announcementEllipsis"
            [innerHTML]="cardData.body"
          >
          </span>
          <span
            *ngIf="cardData.seeMore"
            class="expand"
            (click)="onShowingEntireText($event)"
            >see more</span
          >
          <span *ngIf="!cardData.seeMore" [innerHTML]="cardData.body"> </span>
        </div>
        <button
          mat-icon-button
          [matMenuTriggerFor]="itemMenu"
          class="menu-button"
          (click)="disableNavigationByMenuClick($event)"
        >
          <hero-icon class="dot-menu" name="dots-vertical-solid"></hero-icon>
        </button>
      </mat-list-item>
    </div>

    <mat-menu #itemMenu="matMenu" class="menu-container">
      <button
        mat-menu-item
        (click)="markAsUnread($event, card.notificationID)"
        *ngIf="isReadAction(); else unreadButton"
      >
        {{ "notificationPanel.markAsUnread" | translate }}
      </button>
      <ng-template #unreadButton>
        <button mat-menu-item (click)="markAsRead($event, card.notificationID)">
          {{ "notificationPanel.markAsRead" | translate }}
        </button>
      </ng-template>
      <button
        mat-menu-item
        (click)="deleteNotification($event, card.notificationID)"
      >
        {{ "notificationPanel.delete" | translate }}
      </button>
    </mat-menu>
  </div>
</mat-card>
