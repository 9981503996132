import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '@app/shared/services/session.service';
import {
  AUTH_ROUTER_PATHS,
  SITE_ACCESS_PATHS,
  USER_ROUTER_PATHS,
} from 'src/app/shared/enums/router-paths.enum';
import { LoadingService } from '@app/shared/services/loading.service';
import { TenantUserPool } from '../interface/common-model';
import { LogoutService } from '../services/logout.service';
import { UserSettingsStateService } from '../services/user-settings-state.service';

@Injectable({
  providedIn: 'root',
})
export class SessionGuardService implements CanActivate {
  constructor(
    private router: Router,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private loader: LoadingService,
    private logout: LogoutService,
    private userSettingsStateService: UserSettingsStateService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.sessionService.getuserActiveSession().then((data) => {
      if (
        data === undefined ||
        data === null ||
        data === 'No current user' ||
        data.message == 'No User Pool in the configuration.'
      ) {
        // handling FTP session
        return this.checkFtpsession(state);
      } else if (
        state.url.includes(`/${SITE_ACCESS_PATHS.SITE_ACCESS}`) ||
        state.url.includes(
          `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.ERROR}`
        )
      ) {
        return true;
      } else if (
        state.url.includes(
          `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.EULA}`
        )
      ) {
        if (localStorage.getItem('isEulaAccepted') === 'true') {
          this.router.navigate([
            `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.HOME}`,
          ]);
        }
        return true;
      } else if (localStorage.getItem('isEulaAccepted') === null) {
        return true;
      } else if (localStorage.getItem('isEulaAccepted') === 'false') {
        this.router.navigate([
          `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.EULA}`,
        ]);
        return true;
      }
      return true;
    });
  }
  checkFtpsession(state: RouterStateSnapshot) {
    if (localStorage.getItem('isFTPAPPClient') == 'true') {
      this.loader.stopLoading();
      return true;
    } else {
      if (localStorage.getItem('isTenantSpecClient') === 'true') {
        const poolDetails: TenantUserPool = JSON.parse(
          localStorage.getItem('tenatUserpoolDetails')
        );
        this.logout.clearLocalStorage();
        this.loader.stopLoading();
        window.location.replace(poolDetails.signOut);
      } else {
        this.logout.clearLocalStorage();
        this.loader.stopLoading();
        this.router.navigate([`/${AUTH_ROUTER_PATHS.LOGIN}`], {
          queryParams: {
            return: state.url,
          },
        });
      }
      this.loader.stopLoading();
      return false;
    }
  }
}
