export enum CONTENT_FORMATS {
  CSV = 'CSV',
  EXCEL = 'Excel',
  HTML = 'HTML',
  JPG = 'JPG',
  PDF = 'PDF',
  PNG = 'PNG',
  POWERPOINT = 'PowerPoint',
  SCORM = 'SCORM',
  TEXT = 'Text',
  WEBPAGE = 'Web Page',
  WORD = 'Word',
  VIDEO = 'Video',
  VIDEOLIMELIGHT = 'Video-Limelight Player',
  IMAGE = 'Image',
  OTHERS = 'Others',
  EXTERNAL_LINK = 'External Link',
  UPLOADED_DOCUMENT = 'Uploaded Document',
}

export enum CONTENT_FORMATS_SKILL_SOFT {
  COURSE = 'Course',
  BOOK = 'Book',
  AUDIO_BOOK = 'Audiobook',
}

export enum SOURCES {
  ACCENTURE = 'Accenture',
  PUBLIC_WEB = 'Public Web',
  SIMPLE_LEARN = 'Simplilearn',
  INTERNAL = 'Internal',
  YOUTUBE = 'YouTube',
  ACCENTURE_ACADEMY = 'Accenture Academy',
  SKILLSOFT = 'Skillsoft',
  CONTENT_UPLOAD = 'Uploaded Content',
}

export enum UBIQUS_LANGUAGE {
  en = 'English',
  es = 'Spanish',
  fr = 'French',
  de = 'German',
  it = 'Italian',
  nl = 'Dutch (NL)',
  pl = 'Polish',
  ru = 'Russian',
  zh_simplified = 'chinese',
  ar = 'Arabic',
  pt = 'Portugese',
  ja = 'Japanese',
}

export enum CONTROL_TYPE {
  INPUT,
  CHECKBOX,
  TAGS,
  ACCENTURE_ACADEMY_CARD_INPUT,
  ACCENTURE_ACADEMY_CARD_INPUT_COURSEID,
  ACCENTURE_ACADEMY_CARD_INPUT_FORMATLABEL,
  URL,
}
