import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LANGUAGES_LIST } from 'src/app/shared/enums/languages-list.enum';
import { ApiConstants } from 'src/app/shared/constants/api.constants';
import { Endpoints } from 'src/app/shared/constants/endpoints.constant';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService
  ) {}

  userRoles = [];

  getUserRoles() {
    return this.userRoles;
  }

  getESOUserPoolInfoByDomain(email: string, caseSensitive?: boolean): any {
    let emailInLowercase = email.toLowerCase();
    if (caseSensitive) {
      emailInLowercase = email;
    }
    const skipLoading = true;
    const headers = new HttpHeaders({
      [ApiConstants.SKIP_LOADING_HEADER.KEY]: String(skipLoading),
    });
    const options = {
      headers: headers,
    };
    return this.httpClient.post(
      `${Endpoints.LOGIN.userPool}`,
      {
        email: emailInLowercase,
      },
      options
    );
  }

  getUserAccountStatus(email: string): Promise<any> {
    const emailInLowercase = email.toLowerCase();
    return this.httpClient
      .post(`${Endpoints.LOGIN.userAccountStatus}`, {
        email: emailInLowercase,
      })
      .toPromise();
  }
  getUserPwdAgeValidation(email: string): Observable<any> {
    const emailInLowercase = email.toLowerCase();
    const langID = LANGUAGES_LIST[this.translate.currentLang?.toUpperCase()];
    return this.httpClient.post(
      `${Endpoints.LOGIN.userPWDStatus}?langID=${langID}`,
      { email: emailInLowercase }
    );
  }
  getUserRolesAPI(email: string): Promise<any> {
    return this.httpClient
      .post(`${Endpoints.ROLES.userRoles}`, { email: email })
      .toPromise();
  }
}
