<div
  class="aftp-avatar-profile"
  [matTooltip]="
    isActive == 'true' ? '' : isActive == undefined ? '' : 'Inactive account'
  "
  [ngStyle]="{
    cursor:
      isActive == 'true'
        ? 'pointer'
        : isActive == undefined
        ? 'pointer'
        : 'not-allowed'
  }"
>
  <div
    *ngIf="avatarURL && isActive == 'true'; else profileInitials"
    class="profile-avatar"
    [style.backgroundImage]="avatarURL"
  ></div>

  <ng-template #profileInitials>
    <div
      class="profile-name"
      [style.background]="
        isActive == 'true'
          ? backgroundColor
          : isActive == undefined
          ? backgroundColor
          : 'red'
      "
    >
      {{ initials }}
    </div>
  </ng-template>
</div>
