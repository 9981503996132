export enum LO_STATUSES {
  DRAFT = 'ST1',
  PUBLISHED = 'ST2',
  UNPUBLISHED = 'ST3',
  DELETED = 'ST4',
}

export enum ACCENTURE_ACADEMY_STATUS {
  ATTACHED,
  NOT_ATTACHED,
}

export enum DEFAULT_IMAGE {
  LEARNING_ACTIVITY = 'assets/defaultNoImage.png',
}

export enum LO_BUTTONS_ACTIVE {
  SAVE = 'save',
  DELETE = 'delete',
  PREVIEW = 'preview',
  PUBLISH = 'publish',
  UNPUBLISH = 'unpublish',
}

export enum LA_PUBLISHING_STATUS {
  DRAFT = 'ST1',
  PUBLISHED = 'ST2',
  UNPUBLISHED = 'ST3',
  DELETED = 'ST4',
}

export enum LO_COMPLETIONS {
  LIMIT = 'limit',
  PAGE_NUMBER = 'pageNumber',
  LAST_USER_ID = 'lastUserId',
}
