import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpEvent,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonHeaderService } from '@app/shared/services/common-header.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { IdleService } from '../../shared/services/idleService';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private commonHeaderService: CommonHeaderService,
    private idleService: IdleService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const excludedURL = environment.apiURL.captcha;
    if (request.url.search(excludedURL) === -1) {
      request = request.clone({
        headers: this.commonHeaderService.getCommonHeaders(request),
      });
    }
    if (
      request.url.includes('ftp-app/acceptEula') ||
      request.url.includes('ftp-app/getEula')
    ) {
      request = request.clone({
        headers: new HttpHeaders(),
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.idleService.onInteraction();
        }
        return event;
      })
    );
  }
}
