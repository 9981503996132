import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'aftp-background-page-error',
  templateUrl: './background-page-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundPageErrorComponent implements OnDestroy {
  @Input() errorboards = [];
  constructor(public _activeModal: NgbActiveModal) {}

  onDoneAction(): void {
    this._activeModal.close();
  }

  ngOnDestroy(): void {
    this.errorboards = [];
  }
}
