import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '@app/shared/services/session.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuardService implements CanActivate {
  constructor(private route: Router, private sessionService: SessionService) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.sessionService.getuserActiveSession().then((data) => {
      if (
        data === undefined ||
        data === null ||
        data === 'No current user' ||
        data.message == 'No User Pool in the configuration.'
      ) {
        return true;
      }
      return false;
    });
  }
}
