import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchCollapsedComponent } from '@app/shared/components/search-collapsed/search-collapsed.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HeroIconsModule, search } from 'ng-heroicons';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ButtonModule } from 'aftp-component-library';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideDirective } from '@app/shared/directives/click-outside.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TemplateErrorPopupModule } from '@app/layouts/user/pages/edit-learning-path/full-screen-error/error-popup-template/template-error-popup.module';
@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    HeroIconsModule.withIcons({
      search,
    }),
    ReactiveFormsModule,
    MatInputModule,
    ButtonModule,
    TranslateModule,
    MatTooltipModule,
    TemplateErrorPopupModule,
  ],
  declarations: [SearchCollapsedComponent, ClickOutsideDirective],
  exports: [SearchCollapsedComponent],
})
export class SearchCollapsedModule {}
