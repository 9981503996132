import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Auth } from 'aws-amplify';
import { CrudService } from 'src/app/shared/services/crud.service';
import {
  AUTH_ROUTER_PATHS,
  SITE_ACCESS_PATHS,
  USER_ROUTER_PATHS,
} from 'src/app/shared/enums/router-paths.enum';
import { Endpoints } from 'src/app/shared/constants/endpoints.constant';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { RegexpConstants } from '@app/shared/constants/regexp.constants';
import { TranslateService } from '@ngx-translate/core';
import { LanguageIdService } from 'src/app/shared/services/language-id.service';
import { ContactUsConstants } from 'src/app/shared/constants/contact-us.constant';
import * as moment from 'moment';

@Component({
  selector: 'app-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss'],
})
export class ContactUsModalComponent implements OnInit {
  public contactUsFormGroup: FormGroup;
  isVisible: boolean;
  isSubmitted: boolean;
  isLoadingCompleted: boolean;
  isSuccess: boolean;
  isEmailDisabled: boolean;
  items: any;
  currentRoute: any;
  langId: string;
  subCategories: any;
  subCats = [];
  selected: any = null;
  userAgent: any;
  tem: any;
  matchTest: any;
  browserName = '';
  browserVersion = '';
  sendParams;
  todayDateTime = moment().format('DD-MM-YYYY hh:mm:s');
  constructor(
    public activeModal: NgbActiveModal,
    router: Router,
    private crudService: CrudService,
    private userDetailsService: UserDetailsService,
    private translate: TranslateService,
    private languageIdService: LanguageIdService
  ) {
    this.currentRoute = router.url;
  }

  ngOnInit(): void {
    this.initializeLangId();
    this.getLanguage();
    this.initializeFormGroup();
    this.getToken();
    this.browserName = this.detectBrowserName();
    this.browserVersion = this.detectBrowserVersion();
    this.translate.get('supportRequestModal').subscribe((res) => {
      this.subCategories = res;
      this.initializeData();
    });
    if (this.currentRoute.includes('/login')) {
      this.contactUsFormGroup
        .get('category')
        .setValue(this.subCategories.subcategories[0].subcatSntc);
      this.contactUsFormGroup
        .get('description')
        .setValue(this.subCategories.subcategories[0].subcatDesc);
    } else if (
      this.currentRoute.includes(`/${AUTH_ROUTER_PATHS.CHANGE_PASSWORD}`)
    ) {
      this.contactUsFormGroup
        .get('category')
        .setValue(this.subCategories.subcategories[0].subcatSntc);
      this.contactUsFormGroup
        .get('description')
        .setValue(this.subCategories.subcategories[0].subcatDesc);
      this.contactUsFormGroup
        .get('email')
        .setValue(
          this.userDetailsService.signInUser?.attributes?.email
            ? this.userDetailsService.signInUser?.attributes?.email
            : this.userDetailsService?.emailId
        );
      this.isEmailDisabled = true;
    } else if (this.currentRoute.includes(`/${AUTH_ROUTER_PATHS.MFA}`)) {
      this.contactUsFormGroup
        .get('categoryID')
        .setValue(this.subCategories.subcategories[2].subcatID);
      this.contactUsFormGroup
        .get('category')
        .setValue(this.subCategories.subcategories[2].subcatSntc);
      this.contactUsFormGroup
        .get('description')
        .setValue(this.subCategories.subcategories[2].subcatDesc);
      this.contactUsFormGroup
        .get('email')
        .setValue(
          this.userDetailsService.signInUser?.attributes?.email
            ? this.userDetailsService.signInUser?.attributes?.email
            : this.userDetailsService?.emailId
        );
      this.isEmailDisabled = true;
    } else if (
      this.currentRoute.includes(`/user/${USER_ROUTER_PATHS.EULA}`) ||
      this.currentRoute.includes(`/${SITE_ACCESS_PATHS.SITE_ACCESS}`)
    ) {
      this.contactUsFormGroup
        .get('categoryID')
        .setValue(this.subCategories.subcategories[3].subcatID);
      this.contactUsFormGroup
        .get('category')
        .setValue(this.subCategories.subcategories[3].subcatSntc);
      this.contactUsFormGroup
        .get('description')
        .setValue(this.subCategories.subcategories[3].subcatDesc);
      this.setEmail();
    } else {
      this.contactUsFormGroup
        .get('categoryID')
        .setValue(this.subCategories.subcategories[1].subcatID);
      this.contactUsFormGroup
        .get('category')
        .setValue(this.subCategories.subcategories[1].subcatSntc);
      this.contactUsFormGroup
        .get('description')
        .setValue(this.subCategories.subcategories[1].subcatDesc);
    }
  }

  detectBrowserName() {
    const GOOGLE_VENDOR_NAME = 'Google Inc.';
    const userAgent = window.navigator.userAgent;
    const vendor = window.navigator.vendor;
    switch (true) {
      case /Edge|Edg|EdgiOS/.test(userAgent):
        return 'Edge';
      case /OPR|Opera/.test(userAgent):
        return 'Opera';
      case /CriOS/.test(userAgent):
      case /Chrome/.test(userAgent) && vendor === GOOGLE_VENDOR_NAME:
        return 'Chrome';
      case /Firefox|FxiOS/.test(userAgent):
        return 'Firefox';
      case /Safari/.test(userAgent):
        return 'Safari';
      case /MSIE|Trident/.test(userAgent):
        return 'Internet Explorer';
      default:
        return 'Other';
    }
  }

  detectBrowserVersion() {
    this.userAgent = navigator.userAgent;
    this.matchTest =
      this.userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];

    if (/trident/i.test(this.matchTest[1])) {
      this.tem = /\brv[ :]+(\d+)/g.exec(this.userAgent) || [];
      return 'IE ';
    }

    if (this.matchTest[1] === 'Chrome') {
      this.tem = this.userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (this.tem != null)
        return this.tem.slice(1).join(' ').replace('OPR', 'Opera');
    }

    this.matchTest = this.matchTest[2]
      ? [this.matchTest[1], this.matchTest[2]]
      : [navigator.appName, navigator.appVersion, '-?'];

    if ((this.tem = this.userAgent.match(/version\/(\d+)/i)) != null)
      this.matchTest.splice(1, 1, this.tem[1]);
    return this.matchTest.join(' ');
  }

  async getToken() {
    await Auth.currentAuthenticatedUser();
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.isVisible = false;
  }

  initializeLangId() {
    this.langId = this.languageIdService.getLangID();
  }

  getLanguage() {
    const languageID = localStorage.getItem(
      ContactUsConstants.LANGUAGE.LANG_ID
    );
    this.translate.use(languageID ?? ContactUsConstants.LANG_TYPE.LANG_ID_TYPE);
  }

  initializeData() {
    this.isLoadingCompleted = true;
    this.subCats = this.subCategories.subcategories;
    this.selected = this.subCats[0];
    this.addCategory(this.selected);
  }

  initializeFormGroup() {
    this.contactUsFormGroup = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(RegexpConstants.EMAIL),
      ]),
      category: new FormControl('', [Validators.required]),
      categoryID: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
    });
  }

  setEmail() {
    const user = this.userDetailsService.getSignInUserName();
    this.contactUsFormGroup.get('email').setValue(user);
    this.isEmailDisabled = true;
  }

  setCategoryID(subcatSntc) {
    if (subcatSntc) {
      const data = this.items.find((item) => item.subcatSntc === subcatSntc);
      this.contactUsFormGroup.get('categoryID').setValue(data.subcatID);
    }
  }

  addCategory(item) {
    this.isVisible = !this.isVisible;
    this.contactUsFormGroup.get('categoryID').setValue(item.subcatID);
    this.contactUsFormGroup.get('category').setValue(item.subcatSntc);
    this.contactUsFormGroup.get('description').setValue(item.subcatDesc);
  }

  submit() {
    this.isSubmitted = true;
    if (this.contactUsFormGroup.valid) {
      const routeEula = '/user/eula';
      if (this.currentRoute.includes(routeEula)) {
        const userId = localStorage.getItem('userId');
        const tenentId = localStorage.getItem('tenantID');
        this.sendParams = {
          catID: 'Account',
          userid: userId,
          tenantid: tenentId,
          subcatID: this.contactUsFormGroup.get('categoryID').value,
          subcatDesc: this.contactUsFormGroup.get('description').value,
          email: this.contactUsFormGroup.get('email').value,
          browser: this.browserName,
          browserVer: this.browserVersion,
          incidentDateTime: this.todayDateTime,
        };
      } else {
        this.sendParams = {
          catID: 'Account',
          subcatID: this.contactUsFormGroup.get('categoryID').value,
          subcatDesc: this.contactUsFormGroup.get('description').value,
          email: this.contactUsFormGroup.get('email').value,
          browser: this.browserName,
          browserVer: this.browserVersion,
          incidentDateTime: this.todayDateTime,
        };
      }
      this.crudService
        .post(this.sendParams, `${Endpoints.CONTACT_US.submit}`, false)
        .subscribe((res) => {
          this.isSuccess = true;
        });
    }
  }

  ok() {
    this.activeModal.dismiss('Cross click');
  }
}
