import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Notif } from 'src/app/shared/interface/common-model';

declare const $: any;

@Component({
  selector: 'app-notification',
  templateUrl: './old-notification.component.html',
  styleUrls: ['./old-notification.component.scss'],
})
export class OldNotificationComponent implements OnInit {
  showNotification = false;
  notificationlist = [];
  show = {};
  showMenu = {};
  isMobile = false;

  constructor(@Inject(DOCUMENT) private _document: HTMLDocument) {}

  ngOnInit(): void {
    this.notificationlist = [
      {
        msg: 'Leverage the settings-page and explore the learning boards',
        read: false,
      },
      { msg: 'You have 2 courses pending', read: false },
      {
        msg: 'Pre-skill assessment for design thinking is now available. Take the assessment now',
        read: false,
      },
    ];

    for (const x of this.notificationlist) {
      this.show[x.msg] = false;
    }
    for (const x of this.notificationlist) {
      this.showMenu[x.msg] = false;
    }
  }
  openNotification(): void {
    $('#old-notification').collapse('hide');
  }
  // displayOptions(key: any, b: any): void {
  //   this.show[key.msg] = b;
  // }
  displayMenu(key: Notif): void {
    if (this.showMenu[key.msg] === false) {
      this.showMenu[key.msg] = true;
    } else {
      this.showMenu[key.msg] = false;
    }
  }
  remove(notif: Notif): void {
    const index = this.notificationlist.indexOf(notif);
    if (index !== -1) {
      this.notificationlist.splice(index, 1);
    }
  }
  hideMenu(key: Notif): void {
    this.showMenu[key.msg] = false;
  }
  changeReadStatus(notif: Notif): void {
    if (notif.read === false) {
      notif.read = true;
    } else {
      notif.read = false;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const mobileWidth = 765;
    if (window.innerWidth <= mobileWidth) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
