import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CrudService } from 'src/app/shared/services/crud.service';
import { Endpoints } from '../../constants/endpoints.constant';

@Component({
  selector: 'app-qa-card',
  templateUrl: './qa-card.component.html',
  styleUrls: ['./qa-card.component.scss'],
})
export class QaCardComponent {
  @Input() data;
  @Input() index = null;
  @Input() categorized = false;
  @Input() uid = null;
  @Input() parent = null;
  @Input() selected = null;
  @Input() accordionID = null;
  @Input() first = false;
  @Output() clickeditem = new EventEmitter();
  catIdCheck;
  constructor(private crudService: CrudService) {}

  clickedItem() {
    this.clickeditem.emit({
      index: this.index,
      category: this.data.category,
    });
  }

  listenToClick(event) {
    this.catIdCheck = event.target.className;
    if (
      event.target.parentElement.innerText === 'Click here' ||
      event.target.parentElement.innerText === 'Klicken Sie hier' ||
      event.target.parentElement.innerText === 'Cliquez ici'
    ) {
      event.preventDefault();
      this.requestFile();
    }
  }

  requestFile() {
    if (this.uid == 'nocat3') {
      window.location.href =
        'https://www.wikihow.com/Clear-Your-Browser%27s-Cookies ';
    } else if (this.catIdCheck == 'catId2') {
      window.location.href =
        'https://www.wikihow.tech/Add-a-Website-to-Trusted-Sites';
    } else if (this.catIdCheck == 'catId3') {
      window.location.href =
        'https://www.whatismybrowser.com/guides/how-to-enable-cookies/chrome';
    } else {
      this.crudService.get(Endpoints.FAQ.getGdprFile).subscribe((res: any) => {
        if (res.result) {
          window.location.href = res.url;
        }
      });
    }
  }
}
