import { Injectable } from '@angular/core';
import { LoadingScreenModel } from 'src/app/_core/abstract-models/loading-screen.model';

@Injectable({
  providedIn: 'root',
})
export class LoadingScreenServiceService extends LoadingScreenModel {
  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }
}
