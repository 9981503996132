<ng-container *ngIf="assessmentSummaryDetails">
  <div class="close-btn-container">
    <svg-icon
      class="cursor-pointer text-gray"
      (click)="closeModal()"
      width="1.625rem"
      height="1.625rem"
      [name]="ICONS.CLOSE"
    ></svg-icon>
  </div>
  <div class="assessment-container">
    <div class="graphik-medium assessment-modal-title color-semi-black mb-4">
      <span style="font-size: 16px !important">
        {{ "assessmentModal.title" | translate }}
      </span>
    </div>
    <div class="graphik-medium assessment-title color-semi-black mb-4">
      <span> {{ assessmentSummaryDetails.name }} </span>
    </div>
    <div
      *ngIf="displayRemainingAttempts"
      class="graphik text-gray contents mb-3"
    >
      <span>
        {{ "assessmentModal.labels.remainingAttempts" | translate }}:
        <span class="graphik-medium error-color">
          {{
            assessmentSummaryDetails.hasOwnProperty("noOfAttemptsLeft")
              ? assessmentSummaryDetails.noOfAttemptsLeft
              : assessmentSummaryDetails.noOfAttempts
          }}
        </span></span
      >
    </div>
    <div class="graphik contents text-gray mb-3">
      <span>
        {{ "assessmentModal.labels.passPercentage" | translate }}:
        <span
          *ngIf="assessmentSummaryDetails.passPercentage !== 0"
          class="graphik color-semi-black"
        >
          {{
            assessmentSummaryDetails.passPercentage == 0 ||
            assessmentSummaryDetails.passPercentage == 100
              ? assessmentSummaryDetails.passPercentage
              : assessmentSummaryDetails.passPercentage.toFixed(1)
          }}%
        </span>
        <span
          *ngIf="assessmentSummaryDetails.passPercentage === 0"
          class="graphik color-semi-black"
        >
          {{ "startAssessment.variables.notApplicable" | translate }}
        </span>
      </span>
    </div>
    <div
      *ngIf="assessmentSummaryDetails.hasOwnProperty('userScorePercentage')"
      class="graphik contents text-gray mb-3"
    >
      <span>
        {{ "assessmentModal.labels.lastAttemptScore" | translate }}:
        <span class="graphik color-semi-black">
          {{ assessmentSummaryDetails.userScorePercentage.toFixed(1) }}%
        </span></span
      >
    </div>
    <div class="graphik contents text-gray mb-3">
      <span>
        <ng-container
          *ngIf="assessmentSummaryDetails?.isTimedAssmnt; else durationLabel"
        >
          {{ "assessmentModal.labels.timeLimit" | translate }}:
        </ng-container>
        <ng-template #durationLabel>
          {{ "assessmentModal.labels.duration" | translate }}:
        </ng-template>
        <span class="graphik color-semi-black">
          {{ durationInMinutes }} mins</span
        >
      </span>
    </div>

    <div
      markdown
      ngPreserveWhitespaces
      *ngIf="
        assessmentSummaryDetails?.assmntInstructions &&
        !assessmentSummaryDetails?.durationLeft
      "
      [innerHtml]="
        assessmentPageService.convertLineBreaks(
          assessmentSummaryDetails.assmntInstructions
            ? assessmentSummaryDetails.assmntInstructions
            : ''
        )
      "
      class="graphik contents color-semi-black mt-4"
    ></div>
    <div
      class="mt-40 {{
        assessmentSummaryDetails?.noOfAttemptsLeft === 0 ? 'd-none' : 'd-flex'
      }} justify-content-center"
    >
      <aftp-button
        buttonKind="primary"
        [disabled]="assessmentSummaryDetails?.noOfAttemptsLeft === 0"
        (action)="
          isFTPAPPClient && !assessmentSummaryDetails?.completionStatus
            ? checkisFTPAPPClient()
            : onClickAction()
        "
        *ngIf="!assessmentSummaryDetails?.durationLeft"
      >
        <ng-container
          *ngIf="
            assessmentSummaryDetails.hasOwnProperty('completionStatus');
            else noAttempt
          "
        >
          <ng-container
            *ngIf="
              assessmentSummaryDetails?.completionStatus;
              else notCompleted
            "
          >
            {{ "assessmentModal.button.review" | translate }}
          </ng-container>
          <ng-template #notCompleted>
            <span>
              {{ "assessmentModal.button.retake" | translate }}
            </span>
          </ng-template>
        </ng-container>
        <ng-template #noAttempt>
          {{ "assessmentModal.button.start" | translate }}
        </ng-template>
      </aftp-button>
    </div>
    <div
      *ngIf="displayLastAttemptMessage"
      class="
        mt-4
        d-flex
        attempt-warning
        align-items-center
        justify-content-center
      "
    >
      <hero-icon class="pencil-icon d-flex" name="exclamation"></hero-icon>
      <span class="ml-2 graphik error-color">{{
        "assessmentModal.lastAttempt" | translate
      }}</span>
    </div>
    <div
      *ngIf="assessmentSummaryDetails?.noOfAttemptsLeft === 0"
      class="
        mt-4
        d-flex
        attempt-warning
        align-items-center
        justify-content-center
      "
    >
      <hero-icon class="cross-icon d-flex" name="xCircle"></hero-icon>
      <span class="ml-2 error-color">{{
        "assessmentModal.outOfAttempts" | translate
      }}</span>
    </div>
    <div
      *ngIf="
        assessmentSummaryDetails?.durationLeft &&
        (assessmentSummaryDetails?.noOfAttemptsLeft > 0 ||
          assessmentSummaryDetails?.noOfAttemptsLeft === -100)
      "
      class="d-flex align-items-center justify-content-center"
    >
      <span class="ml-2 error-color">
        <span *ngIf="assessmentSummaryDetails?.durationLeft > 1"
          >{{ assessmentSummaryDetails?.durationLeft }}
          {{
            "startAssessment.toastMessage.DurationBetAssmnt" | translate
          }}</span
        >
        <span *ngIf="assessmentSummaryDetails?.durationLeft === 1"
          >{{ assessmentSummaryDetails?.durationBetAssmnt }}
          {{
            "startAssessment.toastMessage.DurationBetAssmnt1" | translate
          }}</span
        >
      </span>
    </div>
  </div>
</ng-container>
