import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MatDateRangePicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import * as moment from 'moment';
import { Moment } from 'moment';

const DATE_FORMATS3 = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LLL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'aftp-custom-date-range',
  templateUrl: './custom-date-range.component.html',
  styleUrls: ['./custom-date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS3 },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
  ],
})
export class CustomDateRangeComponent {
  private _control: FormControl;
  private _minDate: Moment;
  private _maxDate: Moment;
  private _appearance: string;
  private _placeholder = 'Select';
  private _disabled = false;
  private _disableStartDate;
  private _disableEndDate;
  private selectedStartDate: number;
  private selectedEndDate: number;

  _showplaceholder = true;
  myFilter = (date: Date): boolean => {
    const startDate = moment(this._disableStartDate);
    const endDate = moment(new Date());
    return (
      moment(date).subtract(-1, 'days') > startDate &&
      moment(new Date(date)) < endDate
    );
  };
  constructor(private _adapter: DateAdapter<Moment>) {}

  // ngOnInit(): void {}
  @Input()
  set disableEndDate(date) {
    this._disableEndDate = date;
  }

  get disableEndDate() {
    return this._disableEndDate;
  }
  @Input()
  set disableStartDate(date) {
    this._disableStartDate = date;
  }

  get disableStartDate() {
    return this._disableStartDate;
  }

  @Input()
  set control(control: FormControl) {
    this._control = control;
  }

  get control(): FormControl {
    return this._control;
  }

  @Input()
  set minDate(minDate: Moment) {
    this._minDate = minDate;
  }

  get minDate(): Moment {
    return this._minDate;
  }

  @Input()
  set maxDate(maxDate: Moment) {
    this._maxDate = maxDate;
  }

  get maxDate(): Moment {
    return this._maxDate;
  }

  @Input()
  set appearance(appearance: string) {
    this._appearance = appearance;
  }

  get appearance(): string {
    return this._appearance;
  }

  @Input()
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
  }

  get placeholder(): string {
    return this._placeholder;
  }

  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set locale(locale: string) {
    this._adapter.setLocale(locale);
  }

  toggleDatePicker(datepicker: MatDateRangePicker<Moment>) {
    if (!this.disabled)
      datepicker.opened ? datepicker.close() : datepicker.open();
  }

  @Output() action = new EventEmitter<any>();

  onChangeValue(event: MatDatepickerInputEvent<Moment>, flag: string): void {
    this._showplaceholder = false;
    if (flag == 'StartDate') {
      this.selectedStartDate = moment(event.value.toDate()).unix() * 1000;
    } else {
      this.selectedEndDate = moment(event.value.toDate()).unix() * 1000;
    }
    this.action.emit({
      startDate: this.selectedStartDate,
      endDate: this.selectedEndDate,
    });
  }
}
