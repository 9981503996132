import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { backgroundProfile } from '@app/shared/components/avatar-profile/profile-background-library';
import { SubscribingComponent } from '@app/_core/abstract-components/subscribing.component';
import { AvatarProfile } from '@app/shared/components/avatar-profile/types/avatar-profile.type';

@Component({
  selector: 'aftp-avatar-profile',
  templateUrl: './avatar-profile.component.html',
  styleUrls: ['./avatar-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarProfileComponent
  extends SubscribingComponent
  implements OnInit
{
  @Input()
  set profileData(profileData: AvatarProfile) {
    this._profileDataSubject.next(profileData);
  }

  private _letterFirstName: string;
  private _letterLastName: string;
  private _profileDataSubject: BehaviorSubject<AvatarProfile> =
    new BehaviorSubject<AvatarProfile>({
      avatarURL: '',
      userFirstName: '',
      userLastName: '',
      isActive: '',
    });

  initials: string;
  fullName: string;
  avatarURL: string;
  backgroundColor: string;
  isActive: string;

  constructor(private changeDetection: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this._profileDataSubject
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result: AvatarProfile) => {
        this.setUserName(
          result.userFirstName,
          result.userLastName,
          result.isActive
        );
        this.setUserAvatar(result.avatarURL);
      });
    this.changeDetection.detectChanges();
  }

  setUserName(userFirstName, userLastName, isActive) {
    this.fullName = `${userFirstName} ${userLastName}`;
    this.isActive = isActive != undefined ? isActive.toString() : isActive;
    this._letterFirstName = userFirstName[0] || '';
    this._letterLastName = userLastName[0] || '';
    this.initials = `${this._letterFirstName}${this._letterLastName}`;
    this.backgroundColor = backgroundProfile.get(
      // TODO: this logic is some kind of hucky, we should change it in future (may be do it is with object or enum)

      // You can use the color randomizer service on the shared module, just pass any unique string parameter (e.g. userID or fName + lName)
      this._letterFirstName.toUpperCase()
    );
  }

  setUserAvatar(avatarURL: string): void {
    if (avatarURL && avatarURL != 'null') {
      this.avatarURL = `url('${avatarURL}')`;
    }
  }
}
