import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { HeaderComponent } from '@app/shared/components/header/header.component';
import { NotificationPanelModule } from '@app/shared/components/notification-panel/notification-panel.module';
import { SearchCollapsedModule } from '@app/shared/components/search-collapsed/search-collapsed.module';
import {
  bell,
  emojiHappy,
  HeroIconsModule,
  menu,
  paperAirplane,
  pencil,
  search,
} from 'ng-heroicons';
import { NewHamburgerMenuModule } from '@app/shared/components/new-hamburger-menu/new-hamburger-menu.module';
import { TranslateModule } from '@ngx-translate/core';
import { LogoModule } from '@app/shared/components/logo/logo.module';
import { RouterModule } from '@angular/router';
import { AvatarProfileModule } from '@app/shared/components/avatar-profile/avatar-profile.module';
import { ButtonModule } from 'aftp-component-library';
import { AftpContainerModule } from '@app/shared/components/aftp-content-wrapper/aftp-container.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LearningObjectButtonsStateService } from '@app/layouts/user/pages/learning-object-page/services/learning-object-buttons-state.service';

@NgModule({
  imports: [
    CommonModule,
    SearchCollapsedModule,
    HeroIconsModule.withIcons({
      pencil,
      search,
      bell,
      menu,
      emojiHappy,
      paperAirplane,
    }),
    NewHamburgerMenuModule,
    TranslateModule,
    LogoModule,
    RouterModule,
    AvatarProfileModule,
    NotificationPanelModule,
    AftpContainerModule,
    MatMenuModule,
    ButtonModule,
    MatBadgeModule,
    MatTooltipModule,
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  providers: [LearningObjectButtonsStateService],
})
export class HeaderModule {}
