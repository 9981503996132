import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateByTheQuantityOfSymbols',
})
export class TruncateByTheQuantityOfSymbolsPipe implements PipeTransform {
  transform(value: string, quantity: number, removeSlicedWord = false): string {
    if (value.length >= quantity) {
      const slicedValue = value.slice(0, quantity);

      if (removeSlicedWord) {
        const restValue = value.slice(quantity, value.length);
        const shouldRemoveLastWord =
          slicedValue[slicedValue.length - 1] !== ' ' && restValue[0] !== ' ';

        if (shouldRemoveLastWord) {
          const words = slicedValue.split(' ');

          const newValue = words
            .filter((item, index) => item && words.length - 1 !== index)
            .join(' ')
            .trim();

          return `${newValue}...`;
        }
      }

      return `${slicedValue.trim()}...`;
    }

    return value;
  }
}
