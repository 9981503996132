import * as moment from 'moment-timezone';
import { Moment } from 'moment';
import { WEEK_DAY } from 'src/app/shared/enums/week-day.enum';

export const TWELVE_HOUR_CLOCK_FORMAT_VALUE = 'hh:mm A';
export const TWENTY_FOUR_CLOCK_FORMAT_VALUE_COLON = 'HH:mm';
export const SECOND_VALUE = 'second';
export const MINUTE_VALUE = 'minute';
export const HOUR_VALUE = 'hour';
export const DAY_VALUE = 'day';
export const YEAR_VALUE = 'year';

export const MOMENT_TIMEZONE_NAMES_LIST = moment.tz.names();

export const TIME_COMPARE_WITH_MINUTE_GRANULARITY_FUNCTION = (
  optionValue: Moment,
  selectionValue: Moment
) => optionValue.isSame(selectionValue, MINUTE_VALUE);

export class DateTimeHelper {
  static readonly millisecondsInSecond = 1000;
  static readonly secondsInMinute = 60;
  static readonly millisecondsInMinute =
    DateTimeHelper.millisecondsInSecond * DateTimeHelper.secondsInMinute;
  static readonly minutesInHour = 60;
  static readonly hoursInMs =
    DateTimeHelper.millisecondsInMinute * DateTimeHelper.minutesInHour;
  static readonly hoursInOneDay = 24;
  static readonly oneDayInMs =
    DateTimeHelper.hoursInMs * DateTimeHelper.hoursInOneDay;
  static readonly daysInWeek = 7;
  static readonly weekInMs =
    DateTimeHelper.oneDayInMs * DateTimeHelper.daysInWeek;
  static readonly numberStartWithoutZeroOnFirstPosition = 10;
  static readonly secondsInHour = 3600;

  /**
   * Formats moment with the given format.
   *
   * @param value - moment time
   * @param format - format
   * @returns string - formatted moment
   */
  static momentAsString(value: Moment, format: string): string {
    return value.format(format);
  }

  static isMomentContainTimezoneName(timezoneName: string): boolean {
    return timezoneName
      ? MOMENT_TIMEZONE_NAMES_LIST.includes(timezoneName)
      : false;
  }

  /**
   * Converts moment time into different timezone but keeping the original time values.
   *
   * @param moment - moment time
   * @param initialTimezoneName - initial timezone name
   * @param targetTimezoneName - target timezone name
   * @returns Moment - moment in target timezone
   */
  static convertMomentToAnotherTimezoneSavingDate(
    momentparam: Moment,
    initialTimezoneName: string,
    targetTimezoneName: string
  ): Moment {
    return momentparam
      .tz(initialTimezoneName)
      .clone()
      .tz(targetTimezoneName, true);
  }

  static getOffsetWithZone(timezone: string): number {
    const currentOffset = moment
      .duration(new Date().getTimezoneOffset(), MINUTE_VALUE)
      .asHours();
    const userOffset = moment
      .duration(moment().tz(timezone).utcOffset(), MINUTE_VALUE)
      .asHours();
    return currentOffset + userOffset;
  }

  static getCurrentTimestamp(timezone: string): number {
    return (
      Date.now() +
      moment
        .duration(DateTimeHelper.getOffsetWithZone(timezone), HOUR_VALUE)
        .asMilliseconds()
    );
  }

  /**
   * Calculates difference between two timestamps in milliseconds in days.
   *
   * @param firstTimestamp accepts a timestamp in milliseconds to be subtracted from.
   * @param secondTimestamp accepts a timestamp in milliseconds which is subtracted from the first one.
   * @returns Subtraction result in days.
   */
  static calculateDaysBetweenTwoTimestamps(
    firstTimestamp,
    secondTimestamp
  ): number {
    return Math.ceil(
      Math.abs((firstTimestamp - secondTimestamp) / this.oneDayInMs)
    );
  }

  /**
   * Calculates difference between two timestamps in milliseconds in full days.
   *
   * @param firstTimestamp accepts a timestamp in milliseconds to be subtracted from.
   * @param secondTimestamp accepts a timestamp in milliseconds which is subtracted from the first one.
   * @returns Subtraction result in full days(in bigger side).
   */
  static calculateFullDaysBetweenTwoTimestamps(
    firstTimestamp,
    secondTimestamp
  ): number {
    return Math.floor((firstTimestamp - secondTimestamp) / this.oneDayInMs);
  }

  /**
   * Calculates difference between two timestamps in milliseconds in hours.
   *
   * @param firstTimestamp accepts a timestamp in milliseconds to be subtracted from.
   * @param secondTimestamp accepts a timestamp in milliseconds which is subtracted from the first one.
   * @returns Subtraction result in hours.
   */
  static calculateHoursBetweenTwoTimestamps(
    firstTimestamp,
    secondTimestamp
  ): number {
    return Math.floor((firstTimestamp - secondTimestamp) / this.hoursInMs);
  }

  static transformTimeValue(value: number): string {
    return value < DateTimeHelper.numberStartWithoutZeroOnFirstPosition
      ? `0${value}`
      : `${value}`;
  }

  static getWeekDaysFromString(
    weekDays: string,
    separator: string
  ): WEEK_DAY[] {
    return weekDays
      .split(separator)
      .filter((day) => day)
      .map((day) => WEEK_DAY[day.toUpperCase()]);
  }

  static getMinutesFromSeconds(seconds: number): number {
    return Math.ceil(seconds / this.secondsInMinute);
  }

  static getMinutesFromSecondsRound(seconds: number): number {
    return Math.round(seconds / this.secondsInMinute);
  }

  static getSecondsFromMinutes(value: number): number {
    return value * DateTimeHelper.secondsInMinute;
  }

  static getHoursFromSeconds(seconds: number): number {
    return seconds / this.secondsInHour;
  }

  static getHoursFromMinutes(minutes: number): string {
    const hrs = minutes / this.minutesInHour;
    return parseFloat(hrs.toString()).toFixed(1);
  }
}
