export enum LEARNING_PATH_SKILLS_SOURCE {
  LEARNING_OBJECT = 'LEARNING_OBJECT',
  ACCENTURE_ACADEMY = 'ACCENTURE_ACADEMY',
}

export enum LEARNING_PATH_SKILLS_RAG_COLORS {
  GREEN = 'GREEN',
  RED = 'RED',
  AMBER = 'AMBER',
  GRAY = 'GRAY',
}

export enum LEARNING_PATH_SKILLS_RAG_STATUS {
  OVERDUE = 'OVERDUE',
  COUNTDOWN = 'COUNTDOWN',
}

export enum LEARNING_PATH_SKILLS_TYPE {
  LEARNING_OBJECT = 'LEARNING_OBJECT',
  EVENT = 'EVENT',
  ASSESSMENT = 'ASSESSMENT',
  COMING_SOON = 'COMING_SOON',
  RAG = 'RAG',
  EXPLORE_SEARCH_LEARNING_OBJECT = 'EXPLORE_SEARCH_LEARNING_OBJECT',
}

export enum LEARNING_PATH_SKILLS_UNIT_TYPES {
  MONTH_DAYS = 'MONTH_DAYS',
  DAYS = 'DAYS',
  TIME = 'TIME',
}

export enum LP_COMPLETIONS {
  LIMIT = 'limit',
  PAGE_NUMBER = 'pageNumber',
  LAST_USER_ID = 'lastUserId',
}

export enum LP_SKILL_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  BLANK = 'BLANK',
  EXPIRED = 'EXPIRED',
  START = 'START',
  RETAKE = 'RETAKE',
  EXCEEDED = 'EXCEEDED',
  LOCKED = 'LOCKED',
  REVIEW = 'REVIEW',
  COMING_SOON = 'COMING_SOON',
  ASSESSMENT_PENDING = 'ASSESSMENT_PENDING',
  OVERDUE = 'OVERDUE',
}

export enum STATUS_LOCKED_LEARNING_PATH {
  LOCKED = 1,
  UN_LOCKED = 0,
}
