import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { USER_ROUTER_PATHS } from 'src/app/shared/enums/router-paths.enum';
import { HamburgerMenuData } from 'src/app/shared/components/header/types/hamburger-menu-response.interface';
import { HAMBURGER_MENU_ITEMS } from 'src/app/shared/enums/hamburger-menu-item.enum';
import { MenuNameRouteMap } from 'src/app/shared/components/new-hamburger-menu/utils/hamburger-menu-serializer';
import { ErrorMessagesService } from 'src/app/shared/services/full-screen-error/error-messages.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { environment } from 'src/environments/environment';
import { DefaultErrorTypes } from 'src/app/layouts/error/error-types.provider';
import { ActiveLPRouted } from '../../../shared/interface/learning-path-card-data.interface';
import { SearchFilters } from '../../../shared/interface/learning-paths.interface';

@Injectable({
  providedIn: 'root',
})
export class AdminAccessService {
  public menuList$: Observable<HamburgerMenuData[]>;
  useCaseId: string;
  loading = true;
  private _hamburgerMenuData$: BehaviorSubject<HamburgerMenuData[]> =
    new BehaviorSubject(null);
  lpClickedFromHome: boolean;
  lpClickedFromSearch: boolean;
  lpClickedFromExplore: boolean;
  loClickedFromSearch: boolean;
  loClickedFromDedicatedGroup: boolean;
  loClickedFromHistory: boolean;
  backToExploreDetails: string;
  activeLPRouted: ActiveLPRouted;
  backtoExploreSubjectCategories: string;
  backToHistory: boolean;
  lpClickedFromviewAll: boolean;
  loClickedFromHistoryLP = false;
  historyFromHamBurger: boolean;
  loClickedFromExplore: boolean;
  loClickedFromGlobalSearch: boolean;
  previousUrl: string;
  backUrl: string;
  filters = new BehaviorSubject<SearchFilters>({});
  private headerdata$: BehaviorSubject<any> = new BehaviorSubject(null);
  currentPage: number;
  constructor(
    private setErrorMessage: ErrorMessagesService,
    private router: Router,
    private userDetailsService: UserDetailsService,
    private httpClient: HttpClient
  ) {}

  private LODataEmptyCheck = new BehaviorSubject<any>({});
  LODataEmptyArray = this.LODataEmptyCheck.asObservable();

  sethamburgerMenuData(hamburgerMenuData: HamburgerMenuData[]) {
    this._hamburgerMenuData$.next(hamburgerMenuData);
  }

  private gethamburgerMenuData(): Observable<HamburgerMenuData[]> {
    return this._hamburgerMenuData$.asObservable();
  }

  setHeaderData(headerdata: any) {
    this.headerdata$.next(headerdata);
  }

  getHeaderData(): Observable<any> {
    return this.headerdata$.asObservable();
  }

  checkRouteIfAccessible() {
    let allowedAccess = false;

    this.gethamburgerMenuData().subscribe((routeList: HamburgerMenuData[]) => {
      const allRoutes = routeList;
      if (routeList) {
        routeList.forEach((route: HamburgerMenuData) => {
          if (route?.children?.length) {
            route.children.forEach((childRoute) => {
              allRoutes.push(childRoute);
            });
          }
        });

        allRoutes
          .filter((route) => {
            return MenuNameRouteMap.get(HAMBURGER_MENU_ITEMS[route.menuKey]);
          })
          .map(
            (route) =>
              `/user/${MenuNameRouteMap.get(
                HAMBURGER_MENU_ITEMS[route.menuKey]
              )}`
          )
          .forEach((route) => {
            const regex = new RegExp(route.replace('/', '\\/'));
            if (regex.test(this.router.url)) {
              allowedAccess = true;
            }
          });

        const regex2 = new RegExp('/user/home');
        const regex3 = new RegExp('/user/faq');
        const regex4 = new RegExp('user/contact-us');
        if (
          regex2.test(this.router.url) ||
          regex3.test(this.router.url) ||
          regex4.test(this.router.url)
        ) {
          allowedAccess = true;
        }

        if (!allowedAccess) {
          this.router.navigate([`${USER_ROUTER_PATHS.ERROR}`], {
            queryParams: {
              type: DefaultErrorTypes.ACCESS_DENIED,
            },
          });
        } else {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    });
  }

  getAdminId(useCaseId: string): any {
    const tenatBaseAPIURL = environment.apiURL.tenantManagement;
    const email = this.userDetailsService.getSignInUserName().toLowerCase();
    const isEso = localStorage.getItem('isEso');
    const useCaseID = useCaseId;
    const apiURL = `${tenatBaseAPIURL}`;
    return this.httpClient.post(apiURL, {
      email: email,
      useCaseId: useCaseID,
      eso: isEso,
    });
  }

  setLpClickedFromHome(val: boolean): void {
    this.lpClickedFromHome = val;
  }

  getLpClickedFromHome(): boolean {
    return this.lpClickedFromHome;
  }

  setLpClickedFromSearch(val: boolean): void {
    this.lpClickedFromSearch = val;
  }

  getLpClickedFromSearch(): boolean {
    return this.lpClickedFromSearch;
  }
  getLoClickedFromDedicatedGroup(): boolean {
    return this.loClickedFromDedicatedGroup;
  }
  setLoClickedFromDedicatedGroup(val: boolean): void {
    this.loClickedFromDedicatedGroup = val;
  }
  setLoClickedFromSearch(val: boolean): void {
    this.loClickedFromSearch = val;
  }
  setLoClickedFromHistory(val: boolean): void {
    this.loClickedFromHistory = val;
  }
  setGoingBackToHistory(val: boolean): void {
    this.backToHistory = val;
  }
  GetGoingBackToHistory(): boolean {
    return this.backToHistory;
  }
  getLoClickedFromHistory(): boolean {
    return this.loClickedFromHistory;
  }
  getClickedFromviewAll(): boolean {
    return this.lpClickedFromviewAll;
  }
  getCurrentpageNumber(): number {
    return this.currentPage;
  }
  setCurrentpageNumber(val: number): void {
    this.currentPage = val;
  }
  setClickedFromviewAll(val: boolean): void {
    this.lpClickedFromviewAll = val;
  }

  getLoClickedFromSearch(): boolean {
    return this.loClickedFromSearch;
  }

  setLpClickedFromExplore(val: boolean): void {
    this.lpClickedFromExplore = val;
  }

  setLoClickedFromExplore(val: boolean): void {
    this.loClickedFromExplore = val;
  }

  getLoClickedFromExplore(): boolean {
    return this.loClickedFromExplore;
  }

  getLpClickedFromExplore(): boolean {
    return this.lpClickedFromExplore;
  }

  setExploreDetailsURL(val: string): void {
    this.backToExploreDetails = val;
  }

  getExploreDetailsURL(): string {
    return this.backToExploreDetails;
  }

  setActiveLPRouted(object: ActiveLPRouted) {
    this.activeLPRouted = object;
  }

  getActiveLPRouted(): ActiveLPRouted {
    return this.activeLPRouted;
  }

  setExploreSubjectsURL(val: string): void {
    this.backtoExploreSubjectCategories = val;
  }

  getExploreBackURL(): string {
    return this.backUrl;
  }

  setExploreBackURL(val: string): void {
    this.backUrl = val;
  }

  getExploreSubjectsURL(): string {
    return this.backtoExploreSubjectCategories;
  }
  clearLoDetailNavigators() {
    this.setLpClickedFromHome(false);
    this.setLpClickedFromSearch(false);
    this.setLoClickedFromSearch(false);
    this.setLpClickedFromExplore(false);
    this.setLoClickedFromHistory(false);
  }
  setfiltersInformation(data: any): void {
    this.filters.next(data);
  }

  getfiltersInformation(): Observable<any> {
    return this.filters.asObservable();
  }
  sendlearningObjectData(newValue) {
    this.LODataEmptyCheck.next(newValue);
  }
}
