<div class="w-100">
  <ng-container *ngIf="categorized; else notcategorized">
    <div id="accordion">
      <div
        *ngFor="let item of finalArray; let f = first"
        class="card {{ f ? 'first-category' : 'mt-4' }} border-0 bg-transparent"
      >
        <a
          data-toggle="collapse"
          [href]="'#' + createUID(item.category.normalize().replace('\'', '-'))"
          role="button"
          (click)="
            clickItem(item, true);
            closeAllAccordion(
              createUID(item.category.normalize().replace('\'', '-'))
            )
          "
          class="
            cursor-pointer
            text-decoration-none
            category
            d-flex
            align-items-center
            pb-0
          "
          >{{ item.category }}
          <svg
            class="ml-3"
            [ngClass]="{
              'active-cat':
                selectedItem &&
                selectedItem.category.normalize().replace('\'', '-') ==
                  item.category.normalize().replace('\'', '-')
            }"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#6F6680"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </a>
        <div
          [id]="createUID(item.category.normalize().replace('\'', '-'))"
          class="collapse"
          data-parent="#accordion"
        >
          <div
            class="card-body p-0 border-0"
            [id]="
              'child' + createUID(item.category.normalize().replace('\'', '-'))
            "
          >
            <div
              *ngFor="let d of item.items; let i = index; let first = first"
              class="card border-0 bg-transparent {{ first ? 'pt-3' : 'pt-4' }}"
            >
              <app-qa-card
                [categorized]="true"
                [accordionID]="
                  'child' +
                  createUID(item.category.normalize().replace('\'', '-'))
                "
                [parent]="
                  createUID(item.category.normalize().replace('\'', '-'))
                "
                [uid]="createUID(d.category.normalize().replace('\'', '-'), i)"
                [selected]="selectedItem"
                (clickeditem)="clickItem($event)"
                [categorized]="categorized"
                [data]="d"
                [index]="i"
              ></app-qa-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #notcategorized>
    <div [id]="'accordion'">
      <div class="card border-0 bg-transparent">
        <app-qa-card
          [first]="f"
          [accordionID]="'accordion'"
          [parent]="createUID('', 1000)"
          [uid]="createUID('', i)"
          *ngFor="let item of finalArray; let i = index; let f = first"
          [selected]="selectedItem"
          (clickeditem)="clickItem($event)"
          [categorized]="categorized"
          [data]="item"
          [index]="i"
        ></app-qa-card>
      </div>
    </div>
  </ng-template>
</div>
