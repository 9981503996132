export enum AUTH_ROUTER_PATHS {
  EMPTY = '',
  LOGIN = 'login',
  LOGOUT = 'logout',
  FORGOT_PASSWORD = 'forgot-password',
  CHANGE_PASSWORD = 'change-password',
  MFA = 'mfa',
  ERROR = 'error',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  TERMS_AND_CONDITIONS_OHIO = 'terms-and-conditions-1-Oct-2020',
  TERMS_AND_CONDITIONS_BESTBUY = 'terms-and-conditions-15-Feb-2020',
  TERMS_AND_CONDITIONS_ACCENTURE_ACADEMY = 'terms-and-conditions-Accenture-Academy',
  FTP_APP_EULA = 'ftp-app-eula',
}

export enum USER_ROUTER_PATHS {
  NEW = 'new',
  USER = 'user',
  USER_ERROR = 'user/error',
  TAKE_ASSESSMENT = 'take',
  SUMMARY = 'summary',
  SETTINGS = 'settings',
  PROFILE = 'profile',
  SPECIFIC_USER_PROFILE = 'profile/:userId',
  HOME = 'home',
  EXPLORE = 'explore',
  EXPLORE_DETAILS = 'explore-details',
  EXPLORE_LEARNING_PATHS = 'explore-learning-paths',
  ERROR = 'error',
  ADD_SKILLS_ROLES = 'add-skills-roles',
  SEARCH = 'search',
  FAQ = 'faq',
  CONTACT_US = 'contact-us',
  NOTEBOOK = 'notebook',
  HISTORY = 'history',
  EULA = 'eula',
  DASHBOARD = 'dashboard',
  REPORTS = 'reports',
  MANAGE_USER = 'administer',
  MANAGE_BREACHES = 'manage-breaches',
  COMPETENCY = 'competency',
  LEARNING_OBJECTS = 'learning-objects',
  LEARNING_OBJECTS_EDIT = 'learning-objects/:loId',
  LEARNING_PATHS = 'learning-paths',
  LEARNING_PATH = 'learningPathID/',
  // MAP_LEARNING_PATH = 'map-learning-path',
  TRANSCRIPT_PATH = 'transcript',
  LEARNING_OBJECT_DETAIL = 'learning-object-detail',
  MANAGE_ASSESSMENT = 'assessment',
  SCHEDULED_EVENTS = 'scheduled-events',
  EDIT_LEARNINGPATH = 'edit-learningpath',
  REVIEW_ASSESSMENT_RESULT = 'view-assessments',
  PREVIEW_BOARD = 'preview-board',
  TRACK_EVENTS = 'track-events',
  MANAGE_SKILL_ACCESS = 'competency/manage-skill-access',
  MANAGE_GROUPS = 'manage-groups',
  SKILL_GROUP = 'skill-group',
  SKILL_PROGRAM = 'skill-program',
  MANAGE_PROGRAMS = 'manage-programs',
  PROGRAM_HOME_PAGE = 'program-home-page',
  GROUPS = 'programs/groups',
  PROGRAM = 'programs',
  CONTENT = 'manage-files',
  CERTIFICATE = 'certificate',
  SQMaintanance = 'SQMaintanance',
}

export enum SITE_ACCESS_PATHS {
  SITE_ACCESS = 'site-access',
}
export enum FTP_APP_GATEWAY_PATH {
  FTP_APP_GATEWAY = 'ftp-app-gateway',
}

export enum HOME_PAGE_ROUTE_SELECTOR {
  LEARNING_PATH_ID = 'learningPathID',
}
