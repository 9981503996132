<div class="ham-menu-container">
  <svg-icon
    [name]="ICONS.MENU"
    [matTooltip]="'icon.title.menu' | translate"
    [matTooltipDisabled]="showMenu.value === true ? true : false"
    tabIndex="0"
    (keyup.enter)="showMenu.next(!showMenu.value)"
    role="button"
    class="hamburger-icon"
    (click)="showMenu.next(!showMenu.value); $event.stopPropagation()"
  ></svg-icon>
  <span *ngIf="isMobileView && badgentfcndot" class="ntfcn-dot"></span>
  <div
    [style.display]="showMenu.value === true ? '' : 'none'"
    clickOutside
    (clickOutside)="showMenu.next(false)"
    class="menu"
    (click)="showMenu.next(!showMenu.value)"
  >
    <button mat-menu-item (click)="profileRoute()" *ngIf="isMobileView">
      <aftp-avatar-profile
        style="
          height: 1.875rem;
          display: flex;
          align-items: center;
          margin-right: 0.5rem;
        "
        [profileData]="profileData$ | async"
        tabindex="0"
        (click)="profileRoute()"
        (keyup.enter)="profileRoute()"
        aria-label="Profile"
        role="link"
        class="avatar-icon"
        [matTooltip]="userName"
      ></aftp-avatar-profile>
      <span class="overflow" style="text-transform: capitalize">{{
        userFirstName
      }}</span>
    </button>
    <div (keydown.tab)="$event.stopPropagation()">
      <ng-container *ngFor="let item of data; let parentIndex = index">
        <div
          *ngIf="
            (item.menuKey !== notebook &&
              item.menuKey !== manage &&
              item.menuKey !== configure &&
              item.menuKey !== adminster &&
              isMobileView) ||
            !isMobileView
          "
        >
          <button mat-menu-item (click)="onClick($event, item, parentIndex)">
            <svg-icon
              [name]="getMenuIconName(item)"
              [ngStyle]="{
                transform:
                  getMenuIconName(item) === ICONS.LOGOUT ||
                  item === activeMenuItem[parentIndex]
                    ? 'rotate(180deg)'
                    : null
              }"
            ></svg-icon>
            <span>{{ getDisplayName(item.menuKey) | translate }}</span>
          </button>
        </div>
        <ng-container
          *ngIf="item.children && item.children.length"
          (click)="onClick($event, item, parentIndex)"
        >
          <button
            *ngFor="
              let child of activeMenuItem[parentIndex]?.children;
              let childIndex = index
            "
            (click)="onClick($event, child, childIndex)"
            class="child"
            mat-menu-item
          >
            <span>{{ getDisplayName(child.menuKey) | translate }}</span>
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
