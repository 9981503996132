import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LearningsResponse } from 'src/app/shared/interface/learning-object-full.interfaces';

@Injectable({
  providedIn: 'root',
})
export class LearningObjectFormService {
  formatLabelValueSubj = new BehaviorSubject<string>('');
  loFormPristine$ = new BehaviorSubject(true);
  editingLaCard$ = new BehaviorSubject<LearningsResponse | null>(null);
  titleValueSubj = new BehaviorSubject<boolean>(false);
  leftSectionChanged = new BehaviorSubject<boolean>(false);
  isSkillSoft: boolean;
}
