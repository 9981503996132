import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import {
  AUTH_ROUTER_PATHS,
  SITE_ACCESS_PATHS,
  USER_ROUTER_PATHS,
} from 'src/app/shared/enums/router-paths.enum';
import { LogoutService } from './logout.service';
import { SessionExpiryService } from './session-expiry.service';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TenantUserPool } from '../interface/common-model';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  hcmTokenValidityInterval;
  public idle$: Subject<boolean> = new Subject();
  interval: any;
  isIdle = false;
  private idleAfterSeconds = 3600; // 60 * 60 secs
  private countDown;
  subscription: Subscription;
  bc = new BroadcastChannel('AFTP_SESSION_EXPIRY_CHANNEL');
  gatewayBc = new BroadcastChannel('FTP_GATEWAY_SESSION_EXPIRY_CHANNEL');
  poolDetails: TenantUserPool;
  isWarningPopUpShown = false;
  constructor(
    private sessionExpiry: SessionExpiryService,
    private route: Router,
    private logout: LogoutService,
    private dialog: MatDialog,
    private modalService: NgbModal
  ) {
    this.idle$.subscribe((res) => {
      const isFTPAPPClient = localStorage.getItem('isFTPAPPClient');
      if (
        isFTPAPPClient !== 'true' &&
        !this.route.url.includes(`${AUTH_ROUTER_PATHS.LOGIN}`) &&
        !this.route.url.includes(`${AUTH_ROUTER_PATHS.MFA}`) &&
        !this.route.url.includes(`${AUTH_ROUTER_PATHS.TERMS_AND_CONDITIONS}`) &&
        !this.route.url.includes(
          `${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.TRANSCRIPT_PATH}/`
        ) &&
        !this.route.url.includes(`${AUTH_ROUTER_PATHS.ERROR}`) &&
        !this.route.url.includes(`${SITE_ACCESS_PATHS.SITE_ACCESS}`) &&
        !this.route.url.includes('aicclaunch') &&
        !this.route.url.includes(`${AUTH_ROUTER_PATHS.LOGOUT}`)
      ) {
        this.showSessionExtensionConfirmationPopUp();
      }
    });
    this.subscription = this.logout._logoutStatus.subscribe((status) => {
      if (status === true) {
        this.clearIdleTimeOut();
      }
    });
  }

  clearIntravel() {
    clearInterval(this.interval);
  }
  onInteraction(): void {
    if (this.isIdle) {
      this.isIdle = false;
    }
    const sessionTimeout = localStorage.getItem('sessionTimeout')
      ? parseInt(localStorage.getItem('sessionTimeout')) * 60
      : this.idleAfterSeconds;
    // User interaction, reset start-idle-timer
    this.clearIdleTimeOut();
    this.countDown = setTimeout(() => {
      // Countdown done without interaction - emit Idle
      this.isIdle = true;
      this.idle$.next(true);
    }, (sessionTimeout - 120) * 1000);
  }

  clearIdleTimeOut(): void {
    clearTimeout(this.countDown);
  }

  showSessionExtensionConfirmationPopUp(): void {
    this.sessionExpiry.showSessionExtensionConfirmationPopUp(
      () => {
        // YES
        this.onInteraction();
      },
      () => {
        // time Elapsed
        this.poolDetails = JSON.parse(
          localStorage.getItem('tenatUserpoolDetails')
        );
        setTimeout(() => {
          sessionStorage.setItem('shownPopup', 'true');
          this.bc.postMessage('showPopup');
        }, 10);
        this.logout.killsession();
        this.sessionExpiry.showsessionExpiredConfirmationPopUp(
          this.navigateToLogin.bind(this)
        );
      },
      () => {
        // NO
        this.killSession();
      }
    );
  }

  navigateToLogin(): void {
    window.location.replace(this.poolDetails.signOut);
  }

  isAuthenticatedPage() {
    return (
      !this.route.url.includes(`${AUTH_ROUTER_PATHS.LOGIN}`) &&
      !this.route.url.includes(`${AUTH_ROUTER_PATHS.MFA}`) &&
      !this.route.url.includes(`${AUTH_ROUTER_PATHS.TERMS_AND_CONDITIONS}`) &&
      !this.route.url.includes(
        `${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.TRANSCRIPT_PATH}/`
      ) &&
      !this.route.url.includes(`${AUTH_ROUTER_PATHS.ERROR}`) &&
      !this.route.url.includes(`${SITE_ACCESS_PATHS.SITE_ACCESS}`) &&
      !this.route.url.includes('aicclaunch') &&
      !this.route.url.includes(`${AUTH_ROUTER_PATHS.LOGOUT}`)
    );
  }
  killSession(): void {
    this.dialog.closeAll();
    this.modalService.dismissAll();
    this.clearIdleTimeOut();

    if (localStorage.getItem('isTenantSpecClient') === 'true') {
      const poolDetails: TenantUserPool = JSON.parse(
        localStorage.getItem('tenatUserpoolDetails')
      );
      this.logout.logoutTenantSpecificUser(poolDetails.signOut); // navigate to Client specific logout page
    } else {
      this.logout.logout();
    }
  }
  checkIfToShowWarningPopUp(sessionTimeout: number, expiresIn: number): void {
    if (
      new Date().getTime() < expiresIn &&
      !this.isWarningPopUpShown &&
      new Date().getTime() > expiresIn - sessionTimeout &&
      localStorage.getItem('isFTPAPPClientWarningPopUpShown') !== 'true' &&
      this.isAuthenticatedPage()
    ) {
      this.sessionExpiry.isTimerPopupshown = true;
      this.isWarningPopUpShown = true;
      this.dialog.closeAll();
      this.modalService.dismissAll();
      this.sessionExpiry.showWarningWithTimer(
        Math.ceil((expiresIn - new Date().getTime()) / 1000) //milisec to sec
      );
    }
  }
  checkHcmTokenValidity(): void {
    this.hcmTokenValidityInterval = setInterval(() => {
      if (
        localStorage.getItem('hcmTokenValidity') != null &&
        localStorage.getItem('hcmTokenValidity') != undefined &&
        new Date().getTime() >= Number(localStorage.getItem('hcmTokenValidity'))
      ) {
        this.sessionExpiryRoute();
      }
    }, 4000);
  }
  sessionExpiryRoute() {
    this.route.navigateByUrl(
      `/${AUTH_ROUTER_PATHS.LOGOUT}?type=ftp-app-session-expired`
    );
    this.logout.clearLocalStorage();
    this.clearHcmSession();
  }
  clearHcmSession() {
    if (this.hcmTokenValidityInterval)
      clearInterval(this.hcmTokenValidityInterval);
    localStorage.removeItem('hcmTokenValidity');
  }

  countFtpAPPSession(): void {
    if (localStorage.getItem('isFTPAPPClient') === 'true') {
      const sessionTimeOut =
        60 *
        1000 *
        Number(localStorage.getItem('FTPAPPsessionExpiryWarningTimer')); //in milisecs
      this.interval = setInterval(() => {
        if (
          localStorage.getItem('FTPAPPsessionExpires') != undefined ||
          localStorage.getItem('FTPAPPsessionExpires') != null
        ) {
          const expiresIn =
            Number(localStorage.getItem('FTPAPPsessionExpires')) - 5;
          this.checkIfToShowWarningPopUp(sessionTimeOut, expiresIn);
          if (new Date().getTime() >= expiresIn) {
            sessionStorage.setItem('ftpSessionExipred', 'true');
            this.gatewayBc.postMessage('sessionexpired');
            this.logout.deleteCookies();
            this.logout.logoutAfterSessionTimeout();
            this.clearIntravel();
          }
        }
      }, 4000);
    }
  }
}
