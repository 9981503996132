import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  CookieResponse,
  LoginHistoryResponse,
  RegisterEsoUserResponse,
  SiteAccessLists,
  UserStatusForSpecificTenant,
} from 'src/app/shared/interface/common-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@env/environment';
import { UserDetailsService } from './user-details.service';
import { ErrorMessagesService } from 'src/app/shared/services/full-screen-error/error-messages.service';
import {
  SITE_ACCESS_PATHS,
  USER_ROUTER_PATHS,
} from 'src/app/shared/enums/router-paths.enum';
import {
  Cookies,
  Endpoints,
} from 'src/app/shared/constants/endpoints.constant';
import { ERROR_USECASE_ID } from 'src/app/shared/enums/error-usecase-ids';
import { SessionService } from './session.service';
import {
  SCREEN_DEVICES,
  SCREEN_SIZES,
} from '@app/shared/enums/screen-size.enum';
import { LoadingService } from '@app/shared/services/loading.service';
import { LogoutService } from '@app/shared/services/logout.service';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class Service2siteAccessOptionsService {
  isMFAVerificationDone = false;
  tenantName: string;
  isMFAenabled: boolean;
  tenatBaseAPIURL = environment.apiURL.tenantManagement;
  private randomGenerator = new BehaviorSubject<any>(0);
  useCaseId: string;
  accessToMultipleSites: boolean;
  selectedSite: SiteAccessLists;
  siteAccessList: SiteAccessLists[];
  userId: string;
  isNewLogin = false;
  isAiccClient: boolean;
  timeZone;
  randomGeneratedNumber(): Observable<any> {
    return this.randomGenerator.asObservable();
  }
  generationOfRandomValue(value: number): void {
    this.randomGenerator.next(value);
  }
  setMFAenablement(mfa: boolean): void {
    this.isMFAenabled = mfa;
    localStorage.setItem('isMFAEnabled', String(this.isMFAenabled));
  }
  getMFAenablement(): string {
    return localStorage.getItem('isMFAEnabled');
  }
  setAccessToMultipleSites(accessAvailable: boolean): void {
    this.accessToMultipleSites = accessAvailable;
  }
  getAccessToMultipleSites(): boolean {
    return this.accessToMultipleSites;
  }
  constructor(
    private httpClient: HttpClient,
    private route: Router,
    private userDetailsService: UserDetailsService,
    private setErrorMessage: ErrorMessagesService,
    private sessionService: SessionService,
    private loader: LoadingService,
    private logoutService: LogoutService
  ) {}

  getAccessableSites(email: string): Promise<any> {
    const isTenantSpecClient = localStorage.getItem('isTenantSpecClient');
    const tenant = localStorage.getItem('tenantName');
    if (isTenantSpecClient === 'true') {
      return this.httpClient
        .post(Endpoints.SITE_ACCESS.userTenant, {
          email: email.toLocaleLowerCase(),
          tenant: tenant,
        })
        .toPromise();
    } else {
      return this.httpClient
        .post(Endpoints.SITE_ACCESS.userTenant, {
          email: email.toLocaleLowerCase(),
        })
        .toPromise();
    }
  }

  setSelectedSite(siteName: SiteAccessLists): void {
    this.selectedSite = siteName;
  }
  getSelectedSite(): SiteAccessLists {
    return this.selectedSite;
  }

  getTenantName(): string {
    return this.tenantName;
  }

  getUserId(): string {
    return this.userId;
  }

  getUserStatusBySite(tenantName: string): any {
    return this.httpClient.post(Endpoints.SITE_ACCESS.siteStatus, {
      email: this.userDetailsService.getSignInUserName().toLowerCase(),
    });
  }

  registerUserDetails(): Observable<RegisterEsoUserResponse> {
    return this.httpClient.post<RegisterEsoUserResponse>(
      Endpoints.SITE_ACCESS.registerUser,
      {
        email: this.userDetailsService.getSignInUserName().toLowerCase(),
      }
    );
  }

  nextStepAfterAuthentication(redirectURL?: string): void {
    this.loader.startLoading();
    this.isMFAVerificationDone = true;
    localStorage.setItem(
      'isMFAVerificationDone',
      this.isMFAVerificationDone.toString()
    );
    const isEso = localStorage.getItem('isEso');
    const email = this.userDetailsService.getSignInUserName();
    if (this.getSiteAccessList()) {
      this.loader.stopLoading();
      if (redirectURL !== undefined) {
        this.redirectTositeAccess(redirectURL);
      } else {
        this.redirectTositeAccess();
      }
    } else {
      this.getAccessableSites(email)
        .then(
          (response) => {
            this.loader.stopLoading();
            this.setSiteAccessList(response.data);
            if (redirectURL !== undefined) {
              this.redirectTositeAccess(redirectURL);
            } else {
              this.redirectTositeAccess();
            }
          },
          (error) => {
            this.stopLoadingCode(isEso);
          }
        )
        .catch((err) => {
          this.stopLoadingCode(isEso);
        });
    }
  }
  stopLoadingCode(isEso) {
    this.loader.stopLoading();
    if (isEso) {
      this.useCaseId = ERROR_USECASE_ID.TechnicalGlitch;
      this.setErrorMessage.setErrorValues('site-access', this.useCaseId);
    }
  }
  redirectTositeAccess(redirectURL?: string): void {
    if (this.siteAccessList.length == 0) {
      this.useCaseId = ERROR_USECASE_ID.noSiteAccess;
      this.setErrorMessage.setErrorValues('site-access', this.useCaseId);
    } else if (this.siteAccessList.length === 1) {
      this.tenantName = this.siteAccessList[0].tenantId;
      this.setAccessToMultipleSites(false);
      if (redirectURL !== undefined) {
        this.nextStepsAfterSiteSelection(
          this.siteAccessList[0].tenantId,
          this.siteAccessList[0].label,
          redirectURL
        );
      } else {
        this.nextStepsAfterSiteSelection(
          this.siteAccessList[0].tenantId,
          this.siteAccessList[0].label
        );
      }
    } else if (this.siteAccessList.length > 1) {
      this.setAccessToMultipleSites(true);
      if (redirectURL !== undefined) {
        this.route.navigate([`/${SITE_ACCESS_PATHS.SITE_ACCESS}`], {
          queryParams: { return: redirectURL },
        });
      } else {
        this.route.navigateByUrl(`/${SITE_ACCESS_PATHS.SITE_ACCESS}`);
      }
    }
  }
  setSiteAccessList(data: [SiteAccessLists]): void {
    this.siteAccessList = data;
  }
  getSiteAccessList(): SiteAccessLists[] {
    return this.siteAccessList;
  }

  setAiccClient(val: boolean): void {
    localStorage.setItem('isAiccClient', String(val));
  }

  getAiccClient(): string {
    return localStorage.getItem('isAiccClient');
  }

  nextStepsAfterSiteSelection(
    tenantName: string,
    tenantlabel: string,
    redirectURL?: string
  ): void {
    localStorage.setItem('isEulaAccepted', 'false');
    if (redirectURL === undefined) {
      redirectURL = `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.HOME}`;
    }
    if (!localStorage.getItem('tenantID')) {
      this.isNewLogin = true;
    }
    this.tenantName = tenantName;
    localStorage.setItem('tenantID', tenantName);
    localStorage.setItem('tenantName', tenantlabel);
    this.getUserStatusBySite(tenantName).subscribe((response) => {
      this.loader.stopLoading();
      const userStatusByTenatDetails: UserStatusForSpecificTenant =
        response.data;
      this.userId = userStatusByTenatDetails?.userId
        ? userStatusByTenatDetails.userId
        : '';
      if (!userStatusByTenatDetails.isAicc) {
        userStatusByTenatDetails.isAicc = false;
      }
      this.setAiccClient(userStatusByTenatDetails.isAicc);
      localStorage.setItem(
        'sessionTimeout',
        userStatusByTenatDetails.sessionTimeout.toString()
      );
      localStorage.setItem(
        'isNewHomePage',
        String(userStatusByTenatDetails.isNewHomePage)
      );
      if (localStorage.getItem('isNewHomePage') === 'true') {
        redirectURL = `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.PROGRAM_HOME_PAGE}`;
      }
      if (localStorage.getItem('tenantName') == 'SQ') {
        redirectURL = `/${USER_ROUTER_PATHS.SQMaintanance}`;
      }
      // Settings userId from DynamoDB to local storage
      this.sessionService.userId = this.userId;
      if (userStatusByTenatDetails.isAutoRegEnabled) {
        if (userStatusByTenatDetails.isUserDetailsSavedinSystem) {
          this.checkValidity(userStatusByTenatDetails, redirectURL);
        } else {
          this.registerUser(userStatusByTenatDetails, redirectURL);
        }
      } else {
        this.checkValidity(userStatusByTenatDetails, redirectURL);
      }
    });
  }

  checkValidity(userStatusByTenatDetails, redirectURL?: string) {
    if (
      userStatusByTenatDetails.isSiteValid &&
      this.isValidTillInFuture(userStatusByTenatDetails.validTill)
    ) {
      this.checkEULAAndNavigate(userStatusByTenatDetails, redirectURL);
    } else {
      // single error page -- when user is not valid error page or site is not valid
      this.useCaseId = ERROR_USECASE_ID.siteExpired;
      this.setErrorMessage.setErrorValues('site-access', this.useCaseId);
    }
  }

  registerUser(userStatusByTenatDetails, redirectURL?: string) {
    // first time user is login through the site
    if (userStatusByTenatDetails.isSubscriptionAvailable) {
      //  call API to save user details
      this.registerUserDetails().subscribe((res) => {
        this.userId = res.data.userID;
        this.sessionService.userId = this.userId;

        Auth.currentSession().then((session) => {
          this.sessionService
            .generatNewSessionwithRefreshToken(
              JSON.parse(localStorage.getItem('tenatUserpoolDetails')),
              session.getRefreshToken()
            )
            .then(() => {
              this.checkEULAAndNavigate(userStatusByTenatDetails, redirectURL);
            });
        });
      });
    } else {
      // single error page -- for subscription limit is over
      this.useCaseId = ERROR_USECASE_ID.subscriptionExpired;
      this.setErrorMessage.setErrorValues('site-access', this.useCaseId);
    }
  }

  private isValidTillInFuture(userValidTill) {
    return new Date(userValidTill) > new Date();
  }

  private checkEULAAndNavigate(
    userStatusByTenatDetails: UserStatusForSpecificTenant,
    redirectURL: string
  ) {
    if (this.isNewLogin) {
      this.sessionService.getuserActiveSession().then((res) => {
        this.storeLoginInfo().subscribe(() => {
          this.isNewLogin = false;
        });
      });
    }
    if (userStatusByTenatDetails.isEulaEnabled) {
      if (userStatusByTenatDetails.isEulaAccepted) {
        localStorage.setItem('isEulaAccepted', 'true');
        this.route.navigateByUrl(redirectURL);
      } else {
        localStorage.setItem('isEulaAccepted', 'false');
        this.route.navigateByUrl(
          `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.EULA}`
        );
      }
    } else {
      localStorage.setItem('isEulaAccepted', 'true');
      this.route.navigateByUrl(redirectURL);
    }
  }

  private storeLoginInfo(): Observable<LoginHistoryResponse> {
    let req_body;
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (this.timeZone == 'Asia/Calcutta') {
      this.timeZone = 'Asia/Kolkata';
    }
    if (window.innerWidth <= SCREEN_SIZES.mobileScreen) {
      req_body = {
        device: SCREEN_DEVICES.mobile,
        timeZone: this.timeZone,
      };
    } else if (
      window.innerWidth > SCREEN_SIZES.mobileScreen &&
      window.innerWidth < SCREEN_SIZES.largeScreen
    ) {
      req_body = {
        device: SCREEN_DEVICES.tablet,
        timeZone: this.timeZone,
      };
    } else {
      req_body = {
        device: SCREEN_DEVICES.computer,
        timeZone: this.timeZone,
      };
    }
    return this.httpClient.post<LoginHistoryResponse>(
      `${Endpoints.LOGIN.histoty}`,
      req_body
    );
  }
  getCookieInfo(): Observable<CookieResponse> {
    return this.httpClient.get<CookieResponse>(`${Cookies.getCookies}`, {
      withCredentials: true,
    });
  }
}
