import { Injectable } from '@angular/core';
import { Endpoints } from 'src/app/shared/constants/endpoints.constant';
import { CrudService } from 'src/app/shared/services/crud.service';

import { Location } from '@angular/common';
import { AssessmentSummary } from 'src/app/shared/interface/assessment-summary.model';
@Injectable({
  providedIn: 'root',
})
export class AssessmentPageService {
  preAssessmentClickedFromBanner: boolean;
  preAssessmentClickedFromGroups: boolean;
  type: string;
  countAttempt = false;
  constructor(private apiService: CrudService, private location: Location) {}
  _assessmentID: string;
  _activeLearningPath: string;
  _lpTitle: string;
  _assessmentTitle: string;
  programID: string;
  groupID: string;
  unlimitedAttempts: boolean;
  preAssessmentClickedFromHome: boolean;
  preAssessmentClickedFromSearch: boolean;
  preAssessmentClickedFromExplore: boolean;
  preAssessmentClickedFromHistory: boolean;
  AssessmentClickedFromGroups: boolean;
  takeAssmntPage = false;

  _expandLP: boolean;
  _explorePath: string;

  set expandLP(value: boolean) {
    this._expandLP = value;
  }

  get expandLP(): boolean {
    return this._expandLP;
  }
  set explorePath(value: string) {
    this._explorePath = value;
  }

  get explorePath(): string {
    return this._explorePath;
  }

  set assessmentID(value: string) {
    this._assessmentID = value;
  }

  get assessmentID(): string {
    return this._assessmentID;
  }

  set activeLearningPath(value: string) {
    this._activeLearningPath = value;
  }

  get activeLearningPath(): string {
    return this._activeLearningPath;
  }

  preassessment = false;
  isLPCompleted: boolean;

  getAssessmentDetails(assessmentID: string) {
    this.apiService
      .get(
        `${Endpoints.ASSESSMENT_LEARNER.assessmentDetails}?assessmentID=${assessmentID}`
      )
      .subscribe((response) => {
        console.log('response', response);
      });
  }

  getAssessmentSummary(
    assessmentID: string,
    learnID: string,
    learningPath = true
  ) {
    this._activeLearningPath = learnID;
    return new Promise((resolve, reject) => {
      this.apiService
        .get(
          `${
            Endpoints.ASSESSMENT_LEARNER.base
          }/${assessmentID}/attempt/summary?${
            learningPath ? 'learningPathID' : 'learningID'
          }=${learnID}`
        )
        .subscribe(
          (response: AssessmentSummary) => {
            if (response.result) {
              resolve(response.data);
              this.setAssessmentName(response.data.name);
            } else {
              reject(response);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  convertLineBreaks(content: string): string {
    return content.replace(/\\n/g, '<br/>');
  }

  checkIfAssessmentIDEmpty() {
    if (!this.assessmentID) {
      this.location.back();
    }
  }

  setPreAssessmentClickedFromGroups(val: boolean) {
    this.preAssessmentClickedFromGroups = val;
  }

  getPreAssessmentClickedFromGroups(): boolean {
    return this.preAssessmentClickedFromGroups;
  }

  setPreAssessmentClickedFromHome(val: boolean) {
    this.preAssessmentClickedFromHome = val;
  }

  getPreAssessmentClickedFromHome(): boolean {
    return this.preAssessmentClickedFromHome;
  }
  setpreAssessmentClickedFromSearch(val: boolean) {
    this.preAssessmentClickedFromSearch = val;
  }

  getpreAssessmentClickedFromSearch(): boolean {
    return this.preAssessmentClickedFromSearch;
  }
  setpreAssessmentClickedFromExplore(val: boolean) {
    this.preAssessmentClickedFromExplore = val;
  }

  getpreAssessmentClickedFromExplore(): boolean {
    return this.preAssessmentClickedFromExplore;
  }
  setpreAssessmentClickedFromHistory(val: boolean) {
    this.preAssessmentClickedFromHistory = val;
  }
  getAssessmentClickedFromGroups(): boolean {
    return this.AssessmentClickedFromGroups;
  }
  setAssessmentClickedFromGroups(val: boolean) {
    this.AssessmentClickedFromGroups = val;
  }

  getpreAssessmentClickedFromHistory(): boolean {
    return this.preAssessmentClickedFromHistory;
  }
  setPreAssessmentBannerClicked(value) {
    this.preAssessmentClickedFromBanner = value;
  }
  getPreAssessmentBannerClicked() {
    return this.preAssessmentClickedFromBanner;
  }
  setLPName(data: string): void {
    this._lpTitle = data;
    localStorage.setItem('PALpTitle', data);
  }

  getLPName(): string {
    if (this._lpTitle == '' || this._lpTitle == undefined) {
      this._lpTitle = localStorage.getItem('PALpTitle');
      return this._lpTitle;
    } else {
      return this._lpTitle;
    }
  }
  setAssessmentName(data: string): void {
    this._assessmentTitle = data;
  }

  getAssessmentName(): string {
    return this._assessmentTitle;
  }
}
