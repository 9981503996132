export enum COMMON_APPLICATION_TITLE {
  COMMON_TITLE = 'Future Talent Platform',
}

export enum PAGE_TITLE {
  LOGIN = 'login',
  HOME = 'home',
  CONTACT_US = 'contactUs',
  REPORTS = 'reports',
  EXPLORE = 'explore',
  PROFILE = 'viewProfile',
  HISTORY = 'history',
  NOTEBOOK = 'notes',
  SETTINGS = 'settings',
  DASHBOARD = 'dashboard',
  CONFIGURE_COMPETENCY_MODEL = 'configureCompetencyModel',
  MANAGE_LEARNING_OBJECTS = 'manageLearningObjects',
  CREATE_LEARNING_OBJECT = 'createLearningBoard',
  MANAGE_LEARNING_PATHS = 'manageLearningPaths',
  CREATE_LEARNING_PATH = 'createLearningPath',
  MANAGE_ASSESSMENTS = 'manageAssessments',
  CREATE_ASSESSMENT = 'createanAssessment',
  MANAGE_SCHEDULED_EVENTS = 'manageScheduledEvents',
  TRACK_EVENTS = 'trackEvents',
  MANAGE_USER = 'manageUsers',
  MANAGE_BREACHES = 'manageBreaches',
  CREATE_BREACH = 'createBreach',
  SEARCH = 'search',
  ASSESSMENT = 'assessment',
  LEARNING_OBJECT_DETAIL = 'learning-object-detail',
  MANAGE_SKILL_ACCESS = 'skill-access',
  TERMS_AND_CONDITIONS = 'Terms & Conditions - Future Talent Platform',
  MANAGE_GROUPS = 'manageGroups',
  SKILL_GROUP = 'skillGroup',
  EDIT_SKILL_GROUP = 'editSkillGroup',
  SKILL_PROGRAM = 'skillProgram',
  EDIT_SKILL_PROGRAM = 'editSkillProgram',
  MANAGE_PROGRAMS = 'managePrograms',
  PROGRAM_HOME = 'programHomePage',
  GROUPS = 'Groups',
  CONTENT = 'manageContent',
  SQMaintanance = 'Maintanance',
}
