export enum DATE_TYPE {
  DAYS_WITH_MONTHS = 'd MMM',
  DAYS = 'd',
  DAY_MONTH_YEAR = 'dd/MM/yyyy',
}

export enum LEARNING_PATH_CARD_DATE_TYPE {
  DAYS_WITH_MONTHS = 'd MMM',
  DAYS = 'd',
  HOURS = 'hh',
  MINUTES = 'm',
  DAY_MONTH_YEAR = 'dd/MM/yyyy',
  DAY_WITH_MONTH_AND_YEAR = 'dd MMM yyyy',
}
