import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VirtualScrollPreloaderComponent } from './virtual-scroll-preloader.component';
import { PreloaderModule } from 'aftp-component-library';

@NgModule({
  declarations: [VirtualScrollPreloaderComponent],
  imports: [CommonModule, PreloaderModule],
  exports: [VirtualScrollPreloaderComponent],
})
export class VirtualScrollPreloaderModule {}
