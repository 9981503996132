import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  CONFIRMATION_DIALOG_TYPE,
  ConfirmationPopupComponent,
  ConfirmationPopupComponentData,
} from 'aftp-component-library';
import { DIALOG_OPTIONS } from 'src/app/shared/enums/global.enums';
import { Size } from 'src/app/shared/constants/size.constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoutService } from './logout.service';
import { USER_ROUTER_PATHS } from '../enums/router-paths.enum';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SessionExpiryService {
  bcFTPAPPClientWarningPopUp = new BroadcastChannel(
    'AFTP_FTP_APP_CLIENTS_WARNING_POPUP'
  );
  Translations = {
    text: {
      en: 'Hey! Your session expires in',
      fr: 'Hé! Votre session expirera dans',
      de: 'Hey! Ihre Sitzung läuft ab in',
    },
    subText: {
      en: 'Please re-login to continue learning.',
      fr: 'Veuillez vous reconnecter pour continuer à apprendre.',
      de: 'Bitte melden Sie sich erneut an, um mit dem Lernen fortzufahren.',
    },
    yes: {
      en: 'OK',
      fr: "D'ACCORD",
      de: 'OK',
    },
  };
  autoplayLoAssessment = false;
  isTimerPopupshown = false;
  assessmentSummarypopupOpened = false;
  constructor(
    private modal: NgbModal,
    private _dialog: MatDialog,
    private logoutService: LogoutService,
    private router: Router
  ) {}
  showConfirmation(
    data: ConfirmationPopupComponentData
  ): MatDialogRef<ConfirmationPopupComponent> {
    return this._dialog.open(ConfirmationPopupComponent, {
      width: Size.CONFIRMATION_POPUP_LARGE_SIZE, //this.getDialogSize(),
      disableClose: true,
      data,
      ariaDescribedBy: 'confirmationDesc',
    });
  }

  showConfirmationWithTimer(
    data: ConfirmationPopupComponentData,
    onApply: () => void | Promise<void>,
    onTimerCompleted: () => void | Promise<void>,
    declineAction?: () => void
  ): void {
    const dialogRef = this.showConfirmation(data);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === DIALOG_OPTIONS.YES_OPTION) {
        onApply();
      } else if (result === DIALOG_OPTIONS.TIMER_COMPLETED) {
        this._dialog.closeAll();
        onTimerCompleted();
      } else if (declineAction) {
        this.modal.dismissAll();
        this._dialog.closeAll();
        declineAction();
      }
    });
  }
  showWarningWithTimer(timeInSecs: number): void {
    const lang = localStorage.getItem('languageID')?.toLowerCase();
    const data: ConfirmationPopupComponentData = {
      text: this.Translations.text[lang],
      type: CONFIRMATION_DIALOG_TYPE.WARNING,
      yes: this.Translations.yes[lang],
      popupTimer: {
        subText: this.Translations.subText[lang],
        counter: timeInSecs,
      },
    };
    const dialogRef = this.showConfirmation(data);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === DIALOG_OPTIONS.TIMER_COMPLETED) {
        this._dialog.closeAll();
        this.logoutService.logoutAfterSessionTimeout();
      }
      this.isTimerPopupshown = false;
      localStorage.setItem('isFTPAPPClientWarningPopUpShown', 'true');
      this.bcFTPAPPClientWarningPopUp.postMessage('close');
      if (this.autoplayLoAssessment || this.assessmentSummarypopupOpened) {
        this.router.navigateByUrl(`${USER_ROUTER_PATHS.HOME}`);
        this.autoplayLoAssessment = false;
        this.assessmentSummarypopupOpened = false;
      }
    });
  }

  showSessionExtensionConfirmationPopUp(
    actionwithYes: () => void,
    actionWithTimerElapsed: () => void,
    actionWithNo: () => void
  ): void {
    const data: ConfirmationPopupComponentData = {
      text: 'Hey! Your session is about to expire in',
      type: CONFIRMATION_DIALOG_TYPE.WARNING,
      yes: 'yes',
      no: 'no',
      popupTimer: {
        subText: 'How about extending this session?',
        counter: 120,
      },
    };
    this.showConfirmationWithTimer(
      data,
      actionwithYes,
      actionWithTimerElapsed,
      actionWithNo
    );
  }

  showConfirmationWithActionOnClose(
    data: ConfirmationPopupComponentData,
    onApply: () => void
  ): void {
    const dialogRef = this.showConfirmation(data);
    dialogRef.afterClosed().subscribe(() => {
      onApply();
    });
  }

  showsessionExpiredConfirmationPopUp(action: () => void): void {
    const data: ConfirmationPopupComponentData = {
      type: CONFIRMATION_DIALOG_TYPE.ERROR,
      text: 'Oh no! Your session has expired. Please login again.',
      no: 'LOGIN',
    };
    this.showConfirmationWithActionOnClose(data, action);
  }
}
