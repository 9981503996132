import { BehaviorSubject } from 'rxjs';

export abstract class LoadingScreenModel {
  protected _loading = new BehaviorSubject(false);

  protected set loading(value: boolean) {
    this._loading.next(value);
  }

  protected get loading(): boolean {
    return this._loading.value;
  }

  loading$ = this._loading.asObservable();

  abstract startLoading(): void;
  abstract stopLoading(): void;
}
