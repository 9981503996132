import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'learningDurationAmountTransform',
})
export class LearningDurationAmountTransformPipe implements PipeTransform {
  transform(learningDuration: number): number {
    return Math.round((learningDuration / 60) * 10) / 10;
  }
}
