import {
  Component,
  OnDestroy,
  OnInit,
  Inject,
  HostListener,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import Amplify, { Auth } from 'aws-amplify';
import { AmplifyConfigurationService } from 'src/app/_core/services/amplify-configuration.service';
import { SessionService } from '@app/shared/services/session.service';
import { Service2siteAccessOptionsService } from '@app/shared/services/service-2site-access-options.service';
import {
  TenantUserPool,
  ClientThemeBannerSpecsDataResponse,
  ClientThemeBannerConfiguration,
} from 'src/app/shared/interface/common-model';
import {
  AUTH_ROUTER_PATHS,
  SITE_ACCESS_PATHS,
  USER_ROUTER_PATHS,
  FTP_APP_GATEWAY_PATH,
} from 'src/app/shared/enums/router-paths.enum';
import { UserDetailsService } from '@app/shared/services/user-details.service';
import { LoadingScreenServiceService } from '@app/shared/services/loading-screen-service.service';
import { delay, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { noMfa } from '@app/shared/constants/global.constants';
import { LearningObjectDetailService } from '@app/layouts/user/pages/learning-object-detail/services/learning-object-detail.service';
import { HamburgerMenuData } from '@app/shared/components/header/types/hamburger-menu-response.interface';
import { UserLayoutApiService } from '@app/layouts/user/services/user-layout-api.service';
import { SettingsPageApiService } from '@app/layouts/user/pages/settings-page/services/settings-page-api.service';
import { UserSettingsStateService } from '@app/shared/services/user-settings-state.service';
import { TranslateService } from '@ngx-translate/core';
import { PageTitleService } from '@app/shared/services/page-title.service';
import { AdminAccessService } from './layouts/user/services/admin-access.service';
import { LanguageConstants } from './shared/constants/language';
import { ReminderPromptApiService } from '@app/layouts/user/pages/home-page/services/reminder-prompt-api.service';
import { ReminderPromptStateService } from '@app/shared/services/reminder-prompt-state.service';
import {
  REMINDER_PROMPT_THEME,
  REMINDER_PROMPT_MESSAGE,
  reminderPromptMessagesTranslationObject,
} from 'src/app/shared/enums/reminder-prompt-message.enum';
import { LoadingService } from '@app/shared/services/loading.service';
import { ClientThemeConfigurationApiService } from '@app/shared/services/client-theme-configuration-api.service';
import { ClientThemeConfigurationService } from '@app/shared/services/client-theme-configuration.service';
import { ClientThemeConfigurationSpecs } from '@app/shared/enums/client-theme-configuration-specs.enum';
import { environment } from 'src/environments/environment';
import { imageUrls } from '@app/shared/constants/imageUrls';
import { SCREEN_SIZES } from '@app/shared/enums/screen-size.enum';
import { LogoutService } from '@app/shared/services/logout.service';
import { LANGUAGES_LIST_SMALLCASE } from './shared/enums/languages-list.enum';
import { SessionExpiryService } from '@app/shared/services/session-expiry.service';
import { Endpoints } from './shared/constants/endpoints.constant';
import { HeaderDetailsApiService } from './shared/components/header/services/header-details.api.service';
import { IdleService } from './shared/services/idleService';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  private currentRoute: any;
  isAICCPage: boolean;
  showBackgroundImage = false;
  loAssessmentPage: boolean;
  imageUrl: string;
  showErrorLandScape = false;
  showErrorPortrait = false;
  hideContentLandscape = true;
  public hamburgerMenuTree$: Observable<HamburgerMenuData[]>;
  public hamburgerMenuData: HamburgerMenuData[];
  private appThemeSetFlg = false;
  langID: string;
  lang: string;
  headerLogo = '../assets/images/headerLogo.png';
  isOrientationMobile = false;
  screenOrientationResize;
  windowOrientation;
  scriptTag: HTMLScriptElement;
  bodyElement: HTMLElement = this.document.body;
  tenantspec;
  FTPAPPGatewayuser = false;
  private _hamMenuData$: BehaviorSubject<HamburgerMenuData[]> =
    new BehaviorSubject(null);
  initSecureCaptcha() {
    this.scriptTag = document.createElement('script');
    this.scriptTag.type = 'text/javascript';
    this.scriptTag.src = Endpoints.CAPTCHA.getCaptchaJS;
    this.scriptTag.integrity =
      'sha384-QRXaXmkHyULjvs5cUfIhKllUeEYMB2ffl/DlUdGeSHwkgevB49CoF0W5CbD7Pqkr';
    this.scriptTag.crossOrigin = 'anonymous';
    this.elementRef.nativeElement.appendChild(this.scriptTag);
  }
  poolDetails: TenantUserPool;

  constructor(
    private accessService: Service2siteAccessOptionsService,
    private router: Router,
    private sessionService: SessionService,
    private config: AmplifyConfigurationService,
    private userDetailsService: UserDetailsService,
    private location: Location,
    private loadingScreenService: LoadingScreenServiceService,
    private loDetailService: LearningObjectDetailService,
    private userLayoutApiService: UserLayoutApiService,
    private settingsPageApiService: SettingsPageApiService,
    private userSettingsStateService: UserSettingsStateService,
    private reminderPromptApiService: ReminderPromptApiService,
    private reminderPromptStateService: ReminderPromptStateService,
    private loader: LoadingService,
    private pageTitleService: PageTitleService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private adminAccessService: AdminAccessService,
    private clientThemeConfigurationApiService: ClientThemeConfigurationApiService,
    private clientThemeConfigurationService: ClientThemeConfigurationService,
    private logoutService: LogoutService,
    private sessionExpiry: SessionExpiryService,
    private elementRef: ElementRef,
    private headerDetailsApiService: HeaderDetailsApiService,
    private idleService: IdleService,
    private _dialog: MatDialog,
    private modal: NgbModal,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    router.events.subscribe((event) => {
      if (window.location.href.includes('tenant-spec')) {
        this.tenantspec = true;
      } else {
        this.tenantspec = false;
      }
      if (window.location.href.includes('ftp-app-gateway')) {
        this.FTPAPPGatewayuser = true;
      } else {
        this.FTPAPPGatewayuser = false;
      }
    });
    router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        tap((_) => {
          if (this.isGoingToUserLayout) {
            this.initUserLayoutData();
          }
          this.isUserLayoutPage = this.router.url.includes(
            `/${USER_ROUTER_PATHS.USER}`
          );
          this.isAICCPage = this.router.url.includes('aicclaunch');
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.currentRoute = this.getActivatedRoute(this.activatedRoute);
        this.currentRoute.data.subscribe((data) => {
          this.pageTitleService.setTitle(data.title);
        });
        if (this.router.url === `/${AUTH_ROUTER_PATHS.LOGIN}`) {
          document.getElementsByTagName('link')[0]['href'] = this.headerLogo;
          this.hamburgerMenuTree$ = null;
        }
        if (
          this.router.url === `${AUTH_ROUTER_PATHS.EMPTY}` ||
          this.router.url === `/` ||
          this.isAICCPage ||
          this.router.url.includes(`${FTP_APP_GATEWAY_PATH.FTP_APP_GATEWAY}`) ||
          this.router.url.includes('tenant-spec')
        ) {
          this.isHeaderVisible = false;
          this.showBackgroundImage = false;
          this.footerVisibility = false;
        } else if (
          this.router.url === `/${AUTH_ROUTER_PATHS.LOGIN}` ||
          this.router.url.includes(`/${AUTH_ROUTER_PATHS.LOGIN}?`) ||
          this.router.url === `/${SITE_ACCESS_PATHS.SITE_ACCESS}` ||
          this.router.url.includes(`/${SITE_ACCESS_PATHS.SITE_ACCESS}?`)
        ) {
          this.checkLoginImageRotationNumber();
        } else if (
          this.router.url ===
            `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.ERROR}` ||
          this.router.url.includes(USER_ROUTER_PATHS.TRANSCRIPT_PATH) ||
          this.router.url.includes(
            `${AUTH_ROUTER_PATHS.TERMS_AND_CONDITIONS}`
          ) ||
          this.router.url.includes(AUTH_ROUTER_PATHS.LOGOUT) ||
          this.router.url.includes(
            `${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.ERROR}`
          ) ||
          (this.router.url.includes(USER_ROUTER_PATHS.TAKE_ASSESSMENT) &&
            !this.router.url.includes(USER_ROUTER_PATHS.SUMMARY)) ||
          this.router.url.includes(USER_ROUTER_PATHS.SQMaintanance)
        ) {
          this.isHeaderVisible = true;
          this.showBackgroundImage = false;
          this.footerVisibility = false;
        } else {
          this.isHeaderVisible = true;
          this.showBackgroundImage = false;
          this.footerVisibility = true;
        }
        if (this.isUserLayoutPage && !this.appThemeSetFlg) {
          this.setClientThemeToApp();
        }
      });
  }
  // remove user/home after successful creation on new home page
  flatFotter(): boolean {
    return (
      this.router.url.includes('program-home-page') ||
      this.router.url.includes('programs')
    );
  }
  checkLoginImageRotationNumber() {
    this.isHeaderVisible = true;
    let randomValue;
    if (localStorage.getItem('loginImageRotationNumber') === null) {
      randomValue = Math.floor(Math.random() * 5);
      this.userDetailsService.loginImageRotationNumber = randomValue;
    } else {
      randomValue = parseInt(localStorage.getItem('loginImageRotationNumber'));
    }
    const randomImage = imageUrls.randomWebImage[randomValue].imageUrl;
    if (window.innerWidth > SCREEN_SIZES.mobileScreen) {
      this.imageUrl = `url(${randomImage} )`;
    } else {
      this.imageUrl = null;
    }
    this.showBackgroundImage = true;
    this.footerVisibility = false;
  }

  getActivatedRoute(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getActivatedRoute(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
  /*
   The main reason for the usage of this api call
   is to specify the user's timezone in Moment.js.
   Without it - the unexpected behaviour may occur
   e.x. in case of comparing two moments:
   first - without timezone
   second - with timezone
   It is specified at root level to make sure
   that everyone who uses Moment.js at any level
   won't face such problems.

   Probably the better solution would be to use localStorage
   like it was with language.
   */
  private fetchUserSettings(): void {
    this.settingsPageApiService.getSettings().subscribe((settings) => {
      this.userSettingsStateService.userSettings = settings;
      this.setTranslationLanguage();
      this.getClientThemeBanner();
    });
  }
  private fetchReminderPromptDetails(): void {
    this.reminderPromptApiService.getPrompt().subscribe(({ data }) => {
      if (data) {
        const { message, theme } = data;
        this.reminderPromptStateService.setPrompt({
          isVisible: true,
          isLightTheme: theme === REMINDER_PROMPT_THEME.LIGHT,
          text: this.translateService.instant(
            reminderPromptMessagesTranslationObject[
              REMINDER_PROMPT_MESSAGE[message]
            ]
          ),
        });
        return;
      }

      this.reminderPromptStateService.hidePrompt();
    });
  }

  private initUserLayoutData(): void {
    this.fetchUserSettings();
    /**commented this fetchReminderPromptDetails() method for announcement banner in new home page */

    this.setHeaderData();
    this.accessService.getCookieInfo().subscribe();
    this.hamburgerMenuTree$ = this.adminAccessService.menuList$ =
      this.userLayoutApiService.getHamburgerMenuTree().pipe(
        map(({ data }) => data),
        take(1)
      );
  }

  setHeaderData() {
    this.headerDetailsApiService.getHeaderDetails().subscribe((response) => {
      localStorage.setItem(
        'displayProgramName',
        String(response.data.displayProgramName)
      );
      localStorage.setItem('ProgramName', response.data.programName);
      localStorage.setItem(
        'isManageFilesPage',
        String(response.data.isManageFilesPage)
      );
      this.userDetailsService.setHeaderData(response);
    });
  }

  title = 'aftp-angular-journey';
  isHeaderVisible = false;
  footerVisibility = false;
  isUserLayoutPage = false;
  showOverlayHttpReq$ = this.loadingScreenService.loading$.pipe(delay(0));
  showOverlay$ = this.loader.loading$.pipe(delay(0));

  private setTranslationLanguage() {
    let languageID = localStorage.getItem('languageID');
    const lsLanguageDoesNotMatchUserSettings =
      this.userSettingsStateService.languageCode &&
      this.userSettingsStateService.languageCode !== languageID;

    // Language is not set to local storage or local storage does not match value from server
    if (!languageID || lsLanguageDoesNotMatchUserSettings) {
      const languageCode =
        this.userSettingsStateService.languageCode ??
        LanguageConstants.DEFAULT_LANGUAGE;
      localStorage.setItem('languageID', languageCode);

      languageID = languageCode;
    }

    this.lang = localStorage.getItem('languageID');
    this.initializeLangId();
    this.translateService.use(languageID);
  }
  bc = new BroadcastChannel('AFTP_SESSION_EXPIRY_CHANNEL');
  bcs = new BroadcastChannel('AFTP_BROWSER_SESSION_EXPIRY_CHANNEL');
  bcFTPAPPClientSession = new BroadcastChannel(
    'AFTP_FTP_APP_CLIENTS_SESSION_EXPIRY_CHANNEL'
  );
  bcFTPAPPClientWarningPopup = new BroadcastChannel(
    'AFTP_FTP_APP_CLIENTS_WARNING_POPUP'
  );
  bcFTPNewSessionInitiated = new BroadcastChannel('NEW_SESSION');
  gatewayBc = new BroadcastChannel('FTP_GATEWAY_SESSION_EXPIRY_CHANNEL');
  eulaBroadCastChanne = new BroadcastChannel('EULA_CHECKED');
  navigateToLogin(): void {
    if (this.poolDetails?.signOut) {
      window.location.replace(this.poolDetails.signOut);
    } else {
      this.router.navigateByUrl(`/${AUTH_ROUTER_PATHS.EMPTY}`);
    }
  }
  ngOnInit(): void {
    this.onResize();
    this.loader.startLoading();
    this.bc.onmessage = (event) => {
      console.log('broadcast out');
      if (sessionStorage.getItem('shownPopup') != 'true' && !this.isLoginPage) {
        this.logoutService.killsession();
        this.sessionExpiry.showsessionExpiredConfirmationPopUp(
          this.navigateToLogin.bind(this)
        );
        this.bodyElement.click();
        console.log('broadcast in');
      }
    };

    this.validatesession();

    this.bcFTPAPPClientSession.onmessage = (event) => {
      if (
        sessionStorage.getItem('FTPAPPClientshownPopupLogout') != 'true' &&
        !this.isLoginPage
      ) {
        this.idleService.clearIntravel();
        this._dialog.closeAll();
        this.modal.dismissAll();
        this.router.navigateByUrl(
          `/${AUTH_ROUTER_PATHS.LOGOUT}?type=ftp-app-logout`
        );
        this.bodyElement.click();
      }
    };
    this.initSecureCaptcha();
    document.documentElement.setAttribute('timestamp', environment.timeStamp);
    this.getClientThemeConfiguration();
    this.isAICCPage = window.location.href.includes('aicclaunch');
    this.setTranslationLanguage();
    this.tenantUserPoolDetails();

    if (!this.isAICCPage) {
      this.loDetailService
        .getAutoplayLOStatus()
        .subscribe((autoPlayLoStatus: boolean) => {
          if (autoPlayLoStatus) {
            this.footerVisibility = false;
          }
        });
      this.loDetailService
        .getLearningActivityType()
        .subscribe((courseType: number) => {
          this.loAssessmentPage = courseType === 3;
          if (this.loAssessmentPage) {
            this.isHeaderVisible = true;
          }
        });
    }
    this.loader.stopLoading();
  }

  validatesession() {
    this.eulaBroadCastChanne.onmessage = (e) => {
      if (sessionStorage.getItem('FtpEulaAccepted') != 'true') {
        if (localStorage.getItem('isFTPAPPClient') == 'true') {
          this.modal.dismissAll();
          this._dialog.closeAll();
          this.idleService.isWarningPopUpShown = false;
          this.idleService.countFtpAPPSession();
        }
        this.router
          .navigateByUrl(`/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.HOME}`)
          .then(() => {
            this.bodyElement.click();
            console.log(this.bodyElement);
            this._changeDetectorRef.detectChanges();
          });
      }
    };
    this.gatewayBc.onmessage = () => {
      if (sessionStorage.getItem('ftpSessionExipred') != 'true') {
        this.logoutService.deleteCookies();
        this.logoutService.logoutAfterSessionTimeout();
        this.idleService.clearIntravel();
        this.router
          .navigateByUrl(
            `/${AUTH_ROUTER_PATHS.LOGOUT}?type=ftp-app-session-expired`
          )
          .then(() => {
            this.bodyElement.click();
            window.location.reload();
          });
        this.bodyElement.click();
      }
    };
    this.bcFTPAPPClientWarningPopup.onmessage = (e) => {
      this._dialog.closeAll();
    };
    this.bcFTPNewSessionInitiated.onmessage = () => {
      if (sessionStorage.getItem('ftpNewSession') != 'true') {
        this.modal.dismissAll();
        this._dialog.closeAll();
        this.idleService.isWarningPopUpShown = false;
        this.idleService.countFtpAPPSession();
      }
    };
    this.bcs.onmessage = (event) => {
      if (
        sessionStorage.getItem('shownPopupLogout') != 'true' &&
        !this.isLoginPage
      ) {
        this.logoutService.killsession();
        this.bodyElement.click();
        this.sessionExpiry.showsessionExpiredConfirmationPopUp(
          this.navigateToLogin.bind(this)
        );
      }
    };
  }
  isTabletDevice = (): boolean => {
    const userAgent: string = navigator.userAgent;
    const regex =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
    return regex.test(userAgent.toLowerCase());
  };
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange() {
    this.onResize();
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowOrientation = window?.orientation;
    if (this.windowOrientation != undefined) {
      if (this.windowOrientation == 90 || this.windowOrientation == -90) {
        this.showErrorLandScape = true;
        this.hideContentLandscape = false;
      } else {
        this.showErrorLandScape = false;
        this.hideContentLandscape = true;
      }
    }
    this.screenOrientationResize = screen?.orientation.angle;
    if (this.screenOrientationResize != undefined) {
      this.checkTab();
    }
  }

  checkTab() {
    const tab = this.isTabletDevice();
    if (
      this.screenOrientationResize == 90 ||
      this.screenOrientationResize == -90
    ) {
      if (!tab) {
        this.showErrorPortrait = false;
        this.showErrorLandScape = true;
        this.hideContentLandscape = false;
      } else {
        this.showErrorPortrait = false;
        this.showErrorLandScape = false;
        this.hideContentLandscape = true;
      }
    } else {
      if (tab) {
        this.showErrorPortrait = true;
        this.showErrorLandScape = false;
        this.hideContentLandscape = false;
      } else {
        this.showErrorPortrait = false;
        this.showErrorLandScape = false;
        this.hideContentLandscape = true;
      }
    }
  }
  tenantUserPoolDetails() {
    if (localStorage.getItem('isFTPAPPClient') == 'true') {
      if (window.location.href.includes(`ftp-app-gateway`)) {
        this.idleService.clearIntravel();
        return true;
      } else if (
        localStorage.getItem('FTPAPPsessionExpires') != undefined ||
        localStorage.getItem('FTPAPPsessionExpires') != null
      ) {
        const expiresIn = Number(localStorage.getItem('FTPAPPsessionExpires'));
        console.log('app expires in');
        if (new Date().getTime() > expiresIn) {
          this.logoutService.clearLocalStorage();
          this.router.navigate([`/${AUTH_ROUTER_PATHS.LOGIN}`]);
          return false;
        } else {
          setTimeout(() => {
            this.idleService.countFtpAPPSession();
          }, 1000);
        }
      }
      if (
        localStorage.getItem('hcmTokenValidity') != undefined ||
        localStorage.getItem('hcmTokenValidity') != null
      ) {
        setTimeout(() => {
          this.idleService.checkHcmTokenValidity();
        }, 1000);
      }
    } else if (
      localStorage.getItem('tenatUserpoolDetails') &&
      !this.isAICCPage
    ) {
      this.poolDetails = JSON.parse(
        localStorage.getItem('tenatUserpoolDetails')
      );
      this.config.configAmplify(
        JSON.parse(localStorage.getItem('tenatUserpoolDetails'))
      );
      this.checksession();
    }
  }

  checksession() {
    this.sessionService.getuserActiveSession().then((session) => {
      if (session) {
        this.loader.startLoading();
        this.currentAuthenticationUser();
      }
    });
  }
  currentAuthenticationUser() {
    Auth.currentAuthenticatedUser().then((user) => {
      const isEso = localStorage.getItem('isEso');
      if (isEso === 'false' && user.preferredMFA === noMfa) {
        if (this.accessService.getMFAenablement() === 'true') {
          this.loader.stopLoading();
          this.router.navigateByUrl(`/${AUTH_ROUTER_PATHS.MFA}`);
        } else {
          this.userDetailsService.setSigInUser(user);
          this.checkPathAndAuthenticate();
        }
      } else {
        this.userDetailsService.setSigInUser(user);
        this.checkPathAndAuthenticate();
      }
    });
  }

  checkPathAndAuthenticate(): void {
    const currentPath = this.location.path();
    if (
      currentPath === AUTH_ROUTER_PATHS.EMPTY ||
      currentPath === '/' ||
      currentPath.includes(`/${AUTH_ROUTER_PATHS.LOGIN}`) ||
      currentPath === 'tenant-spec/bestbuy'
    ) {
      this.accessService.nextStepAfterAuthentication();
    } else if (
      currentPath.includes(`/${FTP_APP_GATEWAY_PATH.FTP_APP_GATEWAY}`)
    ) {
      this.accessService.nextStepAfterAuthentication();
    } else {
      this.loader.stopLoading();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.currentRoute?.unsubscribe();
    this.bcFTPAPPClientWarningPopup.close();
  }

  configAmplify(tenatsAuthConfigDetails: TenantUserPool): void {
    const amplifyAuthConfig = {
      // REQUIRED- Amazon Cognito Region
      region: tenatsAuthConfigDetails.region,

      // OPTIONAL- Amazon Cognito User Pool ID
      userPoolId: tenatsAuthConfigDetails.poolId,

      // OPTIONAL- Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: tenatsAuthConfigDetails.clientId,
    };
    Amplify.configure(amplifyAuthConfig);
  }

  get isHomePage(): boolean {
    if (localStorage.getItem('isNewHomePage') == 'true') {
      return false;
    } else {
      return this.router.url.includes(`user/${USER_ROUTER_PATHS.HOME}`);
    }
  }
  get route() {
    return this.router.url;
  }
  get isLoginPage(): boolean {
    return this.router.url.includes(AUTH_ROUTER_PATHS.LOGIN);
  }
  get isSiteLoginPage(): boolean {
    return this.router.url.includes(SITE_ACCESS_PATHS.SITE_ACCESS);
  }
  get isUnauthorizedFooterVisible(): boolean {
    const routesForUnauthorizedFooter = [
      `/${AUTH_ROUTER_PATHS.CHANGE_PASSWORD}`,
      `/${AUTH_ROUTER_PATHS.FORGOT_PASSWORD}`,
      `/${AUTH_ROUTER_PATHS.MFA}`,
      `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.EULA}`,
      `/${AUTH_ROUTER_PATHS.FTP_APP_EULA}`,
    ];
    return routesForUnauthorizedFooter.includes(this.router.url);
  }

  get isTranscriptPage(): boolean {
    return this.router.url.includes(USER_ROUTER_PATHS.TRANSCRIPT_PATH);
  }
  get isErrorPage(): boolean {
    return (
      this.router.url.includes(USER_ROUTER_PATHS.ERROR) ||
      this.router.url.includes(AUTH_ROUTER_PATHS.TERMS_AND_CONDITIONS)
    );
  }

  get isUnauthorizedHeaderVisible(): boolean {
    const routesForUnauthorizedHeader = [
      `/${AUTH_ROUTER_PATHS.CHANGE_PASSWORD}`,
      `/${AUTH_ROUTER_PATHS.FORGOT_PASSWORD}`,
      `/${SITE_ACCESS_PATHS.SITE_ACCESS}`,
      `/${AUTH_ROUTER_PATHS.MFA}`,
      `/${AUTH_ROUTER_PATHS.LOGIN}`,
      `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.EULA}`,
      `/${AUTH_ROUTER_PATHS.ERROR}`,
      `/${AUTH_ROUTER_PATHS.TERMS_AND_CONDITIONS}`,
      `/${AUTH_ROUTER_PATHS.LOGOUT}`,
      `/${USER_ROUTER_PATHS.SQMaintanance}`,
      `/ftp-app-eula`,
    ];
    if (this.router.url.includes(`/${AUTH_ROUTER_PATHS.LOGIN}`)) {
      return true;
    } else if (
      this.router.url.includes(
        `/${AUTH_ROUTER_PATHS.CHANGE_PASSWORD}?inSiteLevel=true`
      )
    ) {
      return false;
    } else {
      return routesForUnauthorizedHeader.findIndex((element) =>
        this.router.url.includes(element)
      ) >= 0
        ? true
        : false;
    }
  }

  get isGoingToUserLayout(): boolean {
    return (
      !this.isUserLayoutPage &&
      this.router.url.includes(`/${USER_ROUTER_PATHS.USER}`)
    );
  }

  get isAssessmentPage(): boolean {
    return (
      this.router.url.includes(USER_ROUTER_PATHS.TAKE_ASSESSMENT) &&
      !this.router.url.includes(USER_ROUTER_PATHS.SUMMARY)
    );
  }

  getHamburgerMenuData() {
    this.userLayoutApiService
      .getHamburgerMenuTree()
      .pipe(
        map(({ data }) => data),
        take(1)
      )
      .subscribe((data: HamburgerMenuData[]) => {
        this.hamburgerMenuData = data;
        this.adminAccessService.sethamburgerMenuData(data);
      });
  }

  sethamburgerMenuData(hamburgerMenuData: HamburgerMenuData[]) {
    this._hamMenuData$.next(hamburgerMenuData);
  }

  getClientThemeConfiguration() {
    this.clientThemeConfigurationApiService
      .getClientThemeConfiguration()
      .subscribe((data: any) => {
        this.clientThemeConfigurationService.setClientThemeConfiguration(data);
      });
  }

  setClientThemeToApp() {
    this.clientThemeConfigurationService
      .getClientThemeConfiguration()
      .subscribe((data: any) => {
        if (data) {
          const tenantSpecsObj =
            data[localStorage.getItem('tenantName')?.toLowerCase()];
          let key = '';
          for (key in tenantSpecsObj) {
            this.appThemeSetFlg = true;
            this.document.documentElement.style.setProperty(
              ClientThemeConfigurationSpecs[key],
              tenantSpecsObj[key]
            );
          }
        }
      });
  }

  getClientThemeBanner() {
    this.clientThemeConfigurationApiService
      .getClientThemeBanner(this.langID)
      .subscribe(
        (
          clientThemeBannerSpecsDataResponse: ClientThemeBannerSpecsDataResponse
        ) => {
          if (clientThemeBannerSpecsDataResponse.data) {
            const clientThemeBannerConfiguration: ClientThemeBannerConfiguration =
              clientThemeBannerSpecsDataResponse.data;
            this.clientThemeConfigurationService.setClientThemeBannerConfiguration(
              clientThemeBannerSpecsDataResponse.data
            );
            localStorage.setItem(
              'colored_logo',
              clientThemeBannerSpecsDataResponse.data.logo_colored
            );
            localStorage.setItem(
              'logoWidthDesktop',
              clientThemeBannerSpecsDataResponse.data.logoWidthDesktop
            );
            localStorage.setItem(
              'logoWidthMobile',
              clientThemeBannerSpecsDataResponse.data.logoWidthMobile
            );
            localStorage.setItem(
              'headerlogo',
              clientThemeBannerSpecsDataResponse.data.headerLogo
            );
            const gradient =
              clientThemeBannerSpecsDataResponse?.data?.gradientColor;
            const tagLineColor =
              clientThemeBannerSpecsDataResponse?.data?.tagLineColor;
            const ProgramNameColor =
              clientThemeBannerSpecsDataResponse?.data?.programNameColor;
            const HomeIconsColor =
              clientThemeBannerSpecsDataResponse?.data?.homeIconsColor;
            const logoWidthDesktop =
              clientThemeBannerSpecsDataResponse?.data?.logoWidthDesktop;
            const logoWidthMobile =
              clientThemeBannerSpecsDataResponse?.data?.logoWidthMobile;

            if (logoWidthDesktop) {
              this.document.documentElement.style.setProperty(
                '--logo-width-desktop',
                logoWidthDesktop
              );
            }
            if (logoWidthMobile) {
              this.document.documentElement.style.setProperty(
                '--logo-width-mobile',
                logoWidthMobile
              );
            }
            if (gradient) {
              this.document.documentElement.style.setProperty(
                '--lp-card-bottom-gradient-color',
                gradient
              );
            }
            if (tagLineColor) {
              this.document.documentElement.style.setProperty(
                '--banner-title-color',
                tagLineColor
              );
            }
            if (ProgramNameColor) {
              this.document.documentElement.style.setProperty(
                '--logo-title-color',
                ProgramNameColor
              );
            }
            if (HomeIconsColor) {
              this.document.documentElement.style.setProperty(
                '--home-page-icons-color',
                HomeIconsColor
              );
            }
            const primaryColor =
              clientThemeBannerSpecsDataResponse?.data?.primaryColor;
            this.document.documentElement.style.setProperty(
              '--primary-color',
              primaryColor
            );
            document.getElementsByTagName('link')[0]['href'] =
              clientThemeBannerConfiguration.headerLogo;
          }
        }
      );
  }

  initializeLangId() {
    this.langID = LANGUAGES_LIST_SMALLCASE[this.lang];
  }
}
