import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  /**
   * Download from url and set new filename to file.
   *
   * @param url - string date-time representation
   * @param filename - new file name
   */
  downloadUrl(url: string, filename: string): void {
    const a = document.createElement('a');
    a.setAttribute('style', 'display: none');
    document.body.appendChild(a);
    a.href = url;
    a.download = filename;
    a.click();
    a.remove();
  }

  /**
   * Converts base64 data url to the JS File
   *
   * @param base64DataUrl - base 64 data url
   * @param filename - name of the converted file
   * @returns
   */
  dataURLtoFile(base64DataUrl: string, filename: string): File {
    const arr = base64DataUrl.split(','),
      mime = /:(.*?);/.exec(arr[0])[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  /**
   * Converts base64 data to the object url
   *
   * @param base64Data - base 64 data
   * @param type - content type
   * @returns object url
   */
  getObjectUrlFromBase64(base64Data: string, type: string): string {
    const binaryString = atob(base64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes.buffer], { type: type });
    return URL.createObjectURL(blob);
  }

  /**
   * Converts File or Url to base64
   *
   * @param target - url or file
   * @returns string base 64 data
   */
  async getBase64Image(target: string | File): Promise<string> {
    let blob: Blob | File;
    if (typeof target === 'string') {
      const response = await fetch(target);
      blob = await response.blob();
    } else {
      blob = target;
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          if (typeof reader.result !== 'string') return reject('Invalid data');

          resolve(reader.result);
        },
        false
      );
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  convertBytesToMegabytes(bytes: number): number {
    return bytes / (1024 * 1024);
  }

  /**
   * Downloads base64 data with given content type and filename
   *
   * @param buffer - string base 64 data
   * @param contentType - string content type
   * @param filename -  string filename
   * @returns
   */
  downloadBase64File(
    buffer: string,
    contentType: string,
    filename: string
  ): void {
    const objectUrlFromBase64 = this.getObjectUrlFromBase64(
      buffer,
      contentType
    );
    this.downloadUrl(objectUrlFromBase64, filename);
  }
}
