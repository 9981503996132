import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  readonly setItem = <T>(key: string, item: T): void => {
    localStorage.setItem(key, JSON.stringify(item));
  };
  readonly getItem = <T = string>(key: string): T | null => {
    return JSON.parse(localStorage?.getItem(key));
  };
}
