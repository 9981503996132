import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'aftp-inline-toggle',
  templateUrl: './inline-toggle.component.html',
  styleUrls: ['./inline-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineToggleComponent {
  @Input() public isActive: boolean;
  @Output() toggleTrigger = new EventEmitter();
  public toggle(): void {
    this.isActive = !this.isActive;
    this.toggleTrigger.emit(this.isActive);
  }
}
