import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorValues } from 'src/app/shared/interface/common-model';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { USER_ROUTER_PATHS } from 'src/app/shared/enums/router-paths.enum';
import { UserDetailsService } from '@app/shared/services/user-details.service';
import { LANGUAGES_LIST } from 'src/app/shared/enums/languages-list.enum';
import { TranslateService } from '@ngx-translate/core';
import { ERROR_USECASE_ID } from 'src/app/shared/enums/error-usecase-ids';
import { LoadingService } from '../loading.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessagesService {
  errorValues: ErrorValues;
  pageName: string;
  langID: any;
  constructor(
    private route: Router,
    private httpClient: HttpClient,
    private userDetailsService: UserDetailsService,
    private translate: TranslateService,
    private loader: LoadingService
  ) {}
  getErrorValues(): ErrorValues {
    return this.errorValues;
  }
  routeToErrorPage(): void {
    this.route
      .navigateByUrl(`/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.ERROR}`)
      .then(() => this.loader.stopLoading());
  }
  /* To be implemented once APi is available */
  getAdminId(useCaseId: string): any {
    const tenatBaseAPIURL = environment.apiURL.tenantManagement;
    const email = this.userDetailsService.getSignInUserName();
    const isEso = localStorage.getItem('isEso');
    this.initializeLangId();
    const useCaseID = useCaseId;
    const apiURL = `${tenatBaseAPIURL}/error?langID=${this.langID}`;
    return this.httpClient.post(apiURL, {
      email: email,
      useCaseId: useCaseID,
      eso: isEso,
    });
  }
  // set Error values method implementation api
  setErrorValues(
    pageName: string,
    useCaseID?: string,
    staticResponse?: ErrorValues
  ): void {
    // call api subscribe, set the values and call route method
    this.pageName = pageName;
    if (pageName === 'site-access') {
      if (useCaseID === ERROR_USECASE_ID.TechnicalGlitch) {
        this.errorValues = {
          msg: this.translate.instant('errorMessages.errorTechnicalGlitch'),
          name: '',
          email: '',
          pageName: 'site-access',
          istechnicalglitch: true,
        };
        this.errorValues.pageName = 'site-access';
        this.routeToErrorPage();
      } else {
        this.getAdminId(useCaseID).subscribe((response) => {
          this.errorValues = response.data;
          this.errorValues.istechnicalglitch = false;
          this.errorValues.pageName = 'site-access';
          switch (useCaseID) {
            case ERROR_USECASE_ID.noSiteAccess:
              this.errorValues.errorTitleSorry = this.translate.instant(
                'errorMessages.errorNoAccess'
              );
              break;
            case ERROR_USECASE_ID.subscriptionExpired:
              this.errorValues.errorTitleSorry = this.translate.instant(
                'errorMessages.errorSubscriptionLimit'
              );
              break;
            case ERROR_USECASE_ID.siteExpired:
              this.errorValues.errorTitleSorry = this.translate.instant(
                'errorMessages.errorsiteExpired'
              );
              break;
            default:
              this.errorValues.errorTitleSorry = this.translate.instant(
                'errorMessages.errorTitleSorry'
              );
          }
          this.routeToErrorPage();
        });
      }
    } else if (pageName === 'group-based') {
      this.errorValues = staticResponse;
      this.errorValues.pageName = 'group-based';
      this.routeToErrorPage();
    } else if (pageName === 'group-based-curator') {
      this.errorValues = staticResponse;
      this.errorValues.pageName = 'group-based-curator';
      this.routeToErrorPage();
    } else if (pageName === 'profile-page-error') {
      this.errorValues = staticResponse;
      this.errorValues.pageName = 'profile-page-error';
      this.routeToErrorPage();
    } else if (pageName === 'program-page-error') {
      this.errorValues = staticResponse;
      this.errorValues.pageName = 'program-page-error';
      this.routeToErrorPage();
    } else if (pageName === 'FTP_APP_ERROR') {
      this.errorValues = staticResponse;
      this.errorValues.pageName = 'FTP_APP_ERROR';
      switch (useCaseID) {
        case ERROR_USECASE_ID.subscriptionExpired:
          this.errorValues.errorTitleSorry = this.translate.instant(
            'errorMessages.errorSubscriptionLimit'
          );
          break;
        case ERROR_USECASE_ID.siteExpired:
          this.errorValues.errorTitleSorry = this.translate.instant(
            'errorMessages.errorsiteExpired'
          );
          break;
        case ERROR_USECASE_ID.tokenInvalid:
          this.errorValues.errorTitleSorry = this.translate.instant(
            'errorMessages.weAreSorry'
          );
          break;
        default:
          this.errorValues.errorTitleSorry = this.translate.instant(
            'errorMessages.errorTitleSorry'
          );
      }
      this.routeToErrorPage();
    }
  }
  initializeLangId() {
    this.langID = LANGUAGES_LIST[this.translate.currentLang?.toUpperCase()];
    console.log(this.langID);
  }
}
