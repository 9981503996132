import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { ManageContentService } from 'src/app/layouts/user/pages/manage-user-content/manage-content.service';
import { LanguageIdService } from '../../services/language-id.service';
import { ConfirmationPopupService } from '../../services/confirmation-popup.service';

@Component({
  selector: 'aftp-certificate-icon',
  templateUrl: './certificate-icon.component.html',
  styleUrls: ['./certificate-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificateIconComponent implements OnInit {
  langId: string;
  width: number;
  @Input() showCertificateAnimation = false; ///value of this needs to toggled by parent comp to show and hide on hover
  @Input() isDownloadable = false;
  @Input() cardData: any;
  @Input() LEID: string;
  @Input() certificateId: string;
  @Input() type: string;
  @Input() iconWidth = 52.01;
  @Input() iconHeight = 40.263;
  @Input() isNew = false;
  @Output() updateCertificate = new EventEmitter<{
    certificateId: string;
    isNew: boolean;
  }>();
  certificateDetails;
  constructor(
    private manageContentService: ManageContentService,
    private languageIdService: LanguageIdService,
    private _confirmationPopupService: ConfirmationPopupService
  ) {}
  ngOnInit(): void {
    this.langId = this.languageIdService.getLangID();
    this.width = window.innerWidth;
  }
  downloadCertificate() {
    const params = {
      LEID: this.LEID,
      type: this.type,
      certificateId: this.certificateId,
      langId: this.langId,
    };
    if (!this.LEID && !this.certificateId && !this.type) return;
    this.manageContentService.certificateDownload(params).subscribe(
      (result) => {
        const { courseName, name, dateOfCompletion, isNew } = result.data;
        if (this.isNew && isNew === false) {
          this.updateCertificate.emit({
            certificateId: this.certificateId,
            isNew,
          });
        }
        this.certificateDetails = {
          courseName: courseName,
          userName: name,
          completionDate: dateOfCompletion,
        };
        if (courseName && name && dateOfCompletion) {
          const win = window.open(
            window.location.origin + '/user/certificate',
            '_blank',
            'width=1,height=1'
          );
          console.log('win', win);
          win['params'] = this.certificateDetails;
          console.log('winparams', win['params']);
        } else {
          this.callErrorhandler();
        }
      },
      () => {
        this.callErrorhandler();
      }
    );
  }
  callErrorhandler() {
    this._confirmationPopupService.showErrorConfirmation(
      'setTargetDate.errorMessageTarget'
    );
  }
}
