<div class="empty-placeholder">
  <img *ngIf="!isSearchIcon" src="assets/images/empty.svg" alt="empty" />
  <img *ngIf="isSearchIcon" src="assets/images/search-icon.svg" alt="empty" />
  <p class="heading" *ngIf="heading" [innerHTML]="heading"></p>
  <p
    class="empty-placeholder__text"
    (click)="redirect($event)"
    [innerHTML]="text"
  ></p>
</div>
