import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import {
  AUTH_ROUTER_PATHS,
  USER_ROUTER_PATHS,
} from '../enums/router-paths.enum';

@Injectable({
  providedIn: 'root',
})
export class EulaguardGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (localStorage.getItem('isFTPAPPClient') === 'true') {
      const hcmTokenValidity = localStorage.getItem('hcmTokenValidity');
      if (hcmTokenValidity != undefined && hcmTokenValidity != null) {
        return true;
      } else {
        const access = localStorage.getItem('accessToken');
        if (access === undefined || access === null) {
          this.router.navigateByUrl(`/${AUTH_ROUTER_PATHS.LOGIN}`);
          return false;
        } else {
          this.router.navigateByUrl(
            `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.HOME}`
          );
          return false;
        }
      }
    } else {
      if (localStorage.getItem('isEulaAccepted') === 'true') {
        this.router.navigateByUrl(
          `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.HOME}`
        );
        return false;
      } else if (localStorage.getItem('isEulaAccepted') === 'false') {
        return true;
      } else {
        this.router.navigateByUrl(`/${AUTH_ROUTER_PATHS.LOGIN}`);
        return false;
      }
    }
  }
}
