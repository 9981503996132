<footer
  *ngIf="!isUnauthorizedFooterVisible; else unauthorizedFooter"
  class="aftp-footer"
  [class.non-printable]="isTranscript"
  [class.largerHeight]="isMobile && displayPrivacyStatement"
>
  <!-- home page footer with wave image removing -->
  <ng-container *ngIf="false">
    <img
      src="assets/images/footer/footer-wave-xl.png"
      class="wave-image xl"
      alt="wave"
    />

    <img
      src="assets/images/footer/footer-wave-lg.png"
      class="wave-image lg"
      alt="wave"
    />

    <img
      src="assets/images/footer/footer-wave-xs.png"
      class="wave-image xs"
      alt="wave"
    />
  </ng-container>

  <!-- for program home page without wave image -->
  <ng-container>
    <img
      src="assets/images/footer/new-footer-xl.png"
      class="wave-image xl"
      alt="flat footer"
    />
    <img
      src="assets/images/footer/new-footer-lg.png"
      class="wave-image lg"
      alt="flat footer"
    />
    <img
      src="assets/images/footer/new-footer-xs.png"
      class="wave-image xs"
      alt="flat footer"
    />
  </ng-container>

  <div class="footer-context">
    <aftp-container>
      <div class="footer-inner">
        <div class="footer-left">
          <a
            (click)="navigateLink('FAQLink')"
            aria-hidden="true"
            class="footer-link footer-faqs"
            >{{ "footer.links.faqs" | translate }}</a
          >
          <a
            (click)="navigateLink('FAQLink')"
            class="footer-link footer-faqs"
            id="faqshidden"
          >
            {{ "footer.links.faqsNvdaReaderText" | translate }}
          </a>

          <a class="footer-link" (click)="navigateLink('HelpLink')">{{
            "footer.links.contactUs" | translate
          }}</a>
        </div>

        <div class="footer-right">
          <a
            class="footer-link footer-privacyStatement"
            *ngIf="displayPrivacyStatement"
            tabindex="-1"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.accenture.com/us-en/about/privacy-policy"
            >{{ "footer.links.privacyStatement" | translate }}</a
          >
          <span class="copyright-text"
            >Copyright © {{ copyrightYear }} Accenture</span
          >
        </div>
      </div>
    </aftp-container>
  </div>
</footer>
<ng-template #unauthorizedFooter>
  <app-footer-before-auth></app-footer-before-auth>
</ng-template>
