export class ContactUsConstants {
  static readonly BROWSER_TYPE = {
    GOOGLE_CHROME: 'google chrome',
    MICROSOFT_EDGE: 'microsoft edge',
    FIREFOX: 'mozilla firefox',
    SAFARI: 'safari',
    INTERNET_EXPLORER: 'internet explorer',
    OTHERS: 'others',
  };
  static readonly LANGUAGE = {
    LANG_ID: 'languageID',
  };
  static readonly LANG_TYPE = {
    LANG_ID_TYPE: 'en',
  };
  static readonly MODAL_INDICATE = {
    INFO_MODAL: 'info',
  };

  static readonly ACCEPTED_FILE_TYPES = [
    'pdf',
    'doc',
    'docx',
    'jpeg',
    'png',
    'jpg',
  ];
}
