import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AftpcontainerComponent } from '@app/shared/components/aftp-content-wrapper/aftp-container.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AftpcontainerComponent],
  exports: [AftpcontainerComponent],
})
export class AftpContainerModule {}
