import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConstants } from '@app/shared/constants/api.constants';
import { BaseApiService } from '@app/shared/services/base-api.service';
import { Endpoints } from '@app/shared/constants/endpoints.constant';
import {
  HeaderDetailsListResponse,
  NotificationsDetailsResponse,
} from '@app/shared/components/header/types/header-details.type';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseResponse } from 'src/app/shared/interface/base-response.interface';
@Injectable({
  providedIn: 'root',
})
export class HeaderDetailsApiService extends BaseApiService {
  private notificationIconClicked: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getHeaderDetails(): Observable<BaseResponse<HeaderDetailsListResponse>> {
    return this.get<BaseResponse<HeaderDetailsListResponse>>(
      Endpoints.HEADER.headerDetails
    );
  }

  getCountOfNotifications(
    userId: string
  ): Observable<NotificationsDetailsResponse> {
    return this.get<NotificationsDetailsResponse>(
      Endpoints.NOTIFICATIONS.getNonViewedCount(userId)
    );
  }

  resetCounter(userId: string): Observable<void> {
    return this.get<void>(
      Endpoints.NOTIFICATIONS.resetCounter(userId),
      null,
      ApiConstants.SKIP_LOADING_HTTP_HEADERS
    );
  }

  setNotificationIconClicked(val: boolean): void {
    this.notificationIconClicked.next(val);
  }

  getNotificationIconClicked(): Observable<boolean> {
    return this.notificationIconClicked.asObservable();
  }
}
