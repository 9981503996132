import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Endpoints } from '@app/shared/constants/endpoints.constant';
import { BaseApiService } from '@app/shared/services/base-api.service';
import { BadgePopupDetailsResponse } from '@app/shared/components/badge-pop-up/types/badge-popup-details.type';
import { ApiConstants } from 'src/app/shared/constants/api.constants';

@Injectable({
  providedIn: 'root',
})
export class BadgePopupApiService extends BaseApiService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getUnseenBadgeDetails(
    langId: string,
    skiploder?: boolean
  ): Observable<BadgePopupDetailsResponse> {
    let skipLoading = false;
    if (skiploder) {
      skipLoading = true;
    }
    const headers = new HttpHeaders({
      [ApiConstants.SKIP_LOADING_HEADER.KEY]: String(skipLoading),
    });
    return this.get<BadgePopupDetailsResponse>(
      Endpoints.BADGES.getUnseenBadge(langId),
      null,
      headers
    );
  }

  getUnseenBadgeforLOCompletion(
    langId: string,
    skiploder?: boolean,
    lpID?: string
  ): Observable<BadgePopupDetailsResponse> {
    let skipLoading = false;
    if (skiploder) {
      skipLoading = true;
    }
    const headers = new HttpHeaders({
      [ApiConstants.SKIP_LOADING_HEADER.KEY]: String(skipLoading),
    });
    return this.get<BadgePopupDetailsResponse>(
      Endpoints.BADGES.getUnseenBadgeforLOCompletion(langId, lpID),
      null,
      headers
    );
  }

  getRealTimebadgeDetails(skiploder?: boolean): Observable<any> {
    let skipLoading = false;
    if (skiploder) {
      skipLoading = true;
    }
    const headers = new HttpHeaders({
      [ApiConstants.SKIP_LOADING_HEADER.KEY]: String(skipLoading),
    });
    return this.get<any>(Endpoints.BADGES.getRealtimeBadge, null, headers);
  }
}
