<header
  class="aftp-header notranslate"
  [class.transparent]="isTransparent"
  [class.login-transparent]="isLoginTransparent"
  [class.site-transparent]="isSiteLoginTransparent"
  [class.search-expanded]="isSearchExpanded"
  [class.non-printable]="isTranscript"
  [class.aftp-header-noborder]="noBorder()"
>
  <aftp-container>
    <div class="header-inner">
      <aftp-logo
        (click)="logoNavigate()"
        [logoLink]="isTranscript || isError ? null : linkToHomePage"
        [logoSrc]="logoImg"
        [titleLogo]="headerTitle$ | async"
        [isLinkInactive]="isError"
        [isUnauthorizedLogo]="isUnauthorizedHeaderVisible"
        [ngClass]="{ 'search-icon-mobile': isSearchExpanded }"
        [displayProgramName]="displayProgramName"
        [isHome]="isHome"
      >
      </aftp-logo>

      <ul
        class="header-control-list align-items-center"
        *ngIf="
          !(
            isTranscript ||
            isUnauthorizedHeaderVisible ||
            isError ||
            isAssessmentPage
          )
        "
      >
        <div
          class="search-opened-mobile-container visible"
          *ngIf="isAiccClient != 'true'"
        >
          <li class="header-control–item search" tabindex="-1">
            <aftp-search-collapsed
              [matTooltip]="'icon.title.search' | translate"
              [searchControl]="searchControl"
              [matTooltipDisabled]="searchControl.value == '' ? false : true"
              [resultList]="resultList"
              [isShowAllResultsVisible]="isShowAllResultsVisible"
              [placeholder]="
                ('header.search.placeholder' | translate).length == 45
                  ? ('header.search.placeholder' | translate | slice: 0:40) +
                    '...'
                  : ('header.search.placeholder' | translate)
              "
              [noResultsText]="'header.search.noLoFound' | translate"
              [showNoResultsLabel$]="showNoResultsLabel$"
              [specialRoute]="USER_ROUTER_PATHS.SEARCH"
              (search)="search($event)"
              (searchControlEvent)="navigateToSearchPage()"
              (sectItem)="navigateToLoPage($event)"
              (toggleSearchContainer)="toggleSearchContainer($event)"
              class="item-search"
            ></aftp-search-collapsed>
          </li>
          <li
            class="header-control–item"
            id="header-control–item-explore"
            [ngClass]="{ 'search-icon-mobile': isSearchExpanded }"
            (click)="exploreRoute()"
          >
            <hero-icon
              [matTooltip]="'icon.title.explore' | translate"
              class="header-icon header-control-airplane"
              name="paperAirplane"
              id="paperAirplane"
              tabindex="0"
              [attr.aria-label]="'icon.title.explore' | translate"
              role="link"
              (keyup.enter)="exploreRoute()"
            ></hero-icon>
          </li>
        </div>

        <div
          class="search-opened-mobile-container"
          [ngClass]="{ 'search-icon-mobile': isSearchExpanded }"
        >
          <li class="header-control–item" *ngIf="isAiccClient != 'true'">
            <hero-icon
              [matTooltip]="'icon.title.notifications' | translate"
              [matMenuTriggerFor]="notificationComponent.menu"
              [matBadge]="notViewedNotificationsCount"
              [matBadgeHidden]="isBadgeHidden()"
              matBadgeSize="small"
              matBadgeColor="warn"
              class="header-icon notification-icon"
              name="bell"
              tabindex="0"
              #menuTrigger="matMenuTrigger"
              (click)="resetCounter()"
              (keyup.enter)="menuTrigger.toggleMenu()"
              (keydown.tab)="closeMenu()"
              [attr.aria-label]="'icon.title.notifications' | translate"
              role="button"
            ></hero-icon>
            <aftp-notification-panel
              #notificationComponent="notificationPanelComponent"
            ></aftp-notification-panel>
          </li>
          <li
            class="header-control–item display"
            id="prof"
            [class.header-control-profile]="!isMobile"
            [attr.aria-label]="'icon.title.explore' | translate"
            *ngIf="isAiccClient != 'true' && !isMobileView"
          >
            <aftp-avatar-profile
              [profileData]="profileData$ | async"
              tabindex="0"
              (click)="profileRoute()"
              (keyup.enter)="profileRoute()"
              aria-label="Profile"
              role="link"
              class="avatar-icon"
              [matTooltip]="userName"
            ></aftp-avatar-profile>
            <span *ngIf="badgentfcndot" class="ntfcn-dot"></span>
          </li>
          <div *ngIf="showbadgentfcn" class="v2-badge-container">
            <div class="ntfcn-achieved-badge">
              <img
                class="ntfcn-achieved-badge-img"
                [src]="badgeimageUrl"
                alt="badge"
              />
            </div>

            <div class="ntfcn-achvd-badge-content">
              <div class="ntfcn-wapper">
                <!--TODO The Static Data to be replaced with translated data-->
                <span *ngIf="badgeInformation?.badgeCount === 1">{{
                  "badgePopUp.earnedbadge" | translate
                }}</span>
                <span *ngIf="badgeInformation?.badgeCount > 1">{{
                  multiplebadges
                }}</span>
              </div>
            </div>
          </div>
          <li class="header-control–item header-control-menu">
            <aftp-new-hamburger-menu
              [data]="menu"
              [iconSize]="iconSize"
              [attr.aria-label]="'icon.title.menu' | translate"
              role="tooltip"
              class="hamburger-icon"
              [badgentfcndot]="badgentfcndot"
            ></aftp-new-hamburger-menu>
          </li>
        </div>
      </ul>
    </div>
  </aftp-container>
</header>
