import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpStatusesCode } from '@app/shared/enums/http-statuses.enum';
import { DefaultErrorTypes } from '@app/layouts/error/error-types.provider';
import { Endpoints } from '@app/shared/constants/endpoints.constant';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LogoutService } from '@app/shared/services/logout.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly codeToType = {
    [HttpStatusesCode.FORBIDDEN]: DefaultErrorTypes.ACCESS_DENIED,
    [HttpStatusesCode.NOT_FOUND]: DefaultErrorTypes.NOT_FOUND,
  };

  constructor(private router: Router, private logout: LogoutService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (Endpoints.isSecured(request.url)) {
      return next.handle(request);
      // .pipe(
      //   tap({
      //     error: (error: unknown) => {
      //       if (
      //         error instanceof HttpErrorResponse &&
      //         this.isMappingExists(error.status)
      //       ) {
      //         this.router.navigate([AppRoutes.ERRORS_BASE], {
      //           queryParams: { type: this.codeToType[error.status] },
      //         });
      //       }
      //     },
      //   })
      // );
    }
    return next.handle(request);
  }

  private isMappingExists(code: HttpStatusesCode): boolean {
    return this.codeToType[code];
  }
}
