import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'aftp-custom-textarea',
  templateUrl: './custom-textarea.component.html',
  styleUrls: ['./custom-textarea.component.scss'],
})
export class CustomTextareaComponent implements OnChanges {
  @Input() control: FormControl;
  @Input() placeholder: string;
  @Input() maxlength: number;
  @Input() error: boolean;
  textareaValue: string;
  initial = true;
  @ViewChild('textarea') textarea: ElementRef;

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).includes('control')) {
      this.textareaValue = this.control.value;
      const timeInterval = setInterval(() => {
        if (this.textareaValue) {
          this.contentChanged(this.textarea.nativeElement);
          clearInterval(timeInterval);
        }
      }, 100);
    }
  }

  contentChanged(textarea: HTMLElement) {
    this.control.setValue(this.textareaValue);
    this.initial ? (this.initial = false) : this.control.markAsDirty();
  }

  adjustHeight(textarea: HTMLElement) {
    textarea.style.height = 'auto';
    textarea.style.height =
      textarea.scrollHeight > 94 ? `${textarea.scrollHeight + 2}px` : '96px';
  }
}
