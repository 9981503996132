import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES_LIST } from 'src/app/shared/enums/languages-list.enum';

@Injectable({
  providedIn: 'root',
})
export class LanguageIdService {
  languageID: string;

  constructor(private translationService: TranslateService) {}

  getLangID() {
    return LANGUAGES_LIST[this.translationService.currentLang.toUpperCase()];
  }
}
