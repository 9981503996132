export type BaseResponse<T> = Required<BaseNullableResponse<T>>;

export interface BaseNullableResponse<T> {
  data?: T;
  result: boolean;
  message: string;
  from: number;
  isNextPage: true;
  scrollId: string[] | null;
}
export interface Skill {
  skillId: string;
  displayOrder: number;
}
export interface Group {
  isActive?: boolean;
  default?: boolean;
  groupID: string;
  order?: number;
  name: string;
  desc?: string;
  imgUrl: string;
  createdDate?: number;
  grpType: string;
  currSttsID: string;
  curatorID?: string;
  skills?: Skill[];
  prgmType?: string;
}
export interface GroupsPayload {
  groupStatus: string[] | string;
  groupType: string[] | string;
  scrollID: string | any[];
  maxSize: number;
  from: number;
}

export enum GroupStatus {
  DRAFT = 'ST1',
  PUBLISHED = 'ST2',
  UNPUBLISHED = 'ST3',
  ALL = '',
}
