import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from '@app/shared/services/base-api.service';
import { Endpoints } from '@app/shared/constants/endpoints.constant';
import { ApiConstants } from '@app/shared/constants/api.constants';
import {
  LearningObjectsSearchPayload,
  LearningObjectsSearchResult,
} from '@app/shared/components/header/types/learning-objects-search.type';
import { HeaderConstants } from 'src/app/shared/constants/global.constants';
import {
  SearchFiltersRequest,
  GetDropdownFiltersResponseData,
} from 'src/app/shared/interface/learning-paths.interface';
import { SEARCH_TYPES } from 'src/app/shared/enums/search-types';
import { BaseResponse } from 'src/app/shared/interface/base-response.interface';

@Injectable({
  providedIn: 'root',
})
export class LearningObjectsSearchApiService extends BaseApiService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getLearningObjectsSearchResults(
    filters: SearchFiltersRequest,
    preloader = false
  ): Observable<LearningObjectsSearchResult> {
    const payload: LearningObjectsSearchPayload = {
      maxSize: HeaderConstants.LO_GLOBAL_SEARCH_MAX_SIZE,
      isGlobalSearch: true,
      ...filters,
    };

    return this.post<LearningObjectsSearchResult, LearningObjectsSearchPayload>(
      Endpoints.SEARCH_API.globalSearchLearningObject,
      payload,
      !preloader && ApiConstants.SKIP_LOADING_HTTP_HEADERS
    );
  }

  //autosuggestions
  getLearningObjectsSearchResultsAutosuggestions(
    filters: SearchFiltersRequest,
    preloader = false
  ): Observable<LearningObjectsSearchResult> {
    const payload: LearningObjectsSearchPayload = {
      maxSize: HeaderConstants.LO_GLOBAL_SEARCH_MAX_SIZE,
      isGlobalSearch: false,
      ...filters,
    };

    return this.post<LearningObjectsSearchResult, LearningObjectsSearchPayload>(
      Endpoints.SEARCH_API.searchAutosuggestion,
      payload,
      !preloader && ApiConstants.SKIP_LOADING_HTTP_HEADERS
    );
  }

  getLearningObjectsDropDowns(
    type: SEARCH_TYPES
  ): Observable<BaseResponse<GetDropdownFiltersResponseData>> {
    return this.get<BaseResponse<GetDropdownFiltersResponseData>>(
      Endpoints.SEARCH_API.getSearchDropDowns(type)
    );
  }
}
