<div class="rounded w-100 bg-white p-3 p-4">
  <div class="modal-container">
    <span
      class="text-semi-gray close-btn fs-24 cursor-pointer absolute"
      (click)="navigateToHome()"
      aria-hidden="true"
      >&times;
    </span>
    <span class="header-modal">{{ "assessmentModal.title" | translate }}</span>
    <!-- translate -->
    <div class="body-modal">
      <ng-container *ngIf="!assessmentID">
        <div class="summary-title">{{ responseData.data.name }}</div>
        <div class="summary-body">
          <div class="summary-content">
            <div class="row">
              <div class="col-sm-7">
                <span class="summary-content__label"
                  >{{
                    "assessmentModal.labels.passPercentage" | translate
                  }}: </span
                ><!-- translate -->
                <span
                  class="summary-content__val"
                  *ngIf="responseData.data.passPercentage !== 0"
                  >{{ responseData.data.passPercentage?.toFixed(1) }}%</span
                >
                <span
                  class="summary-content__val"
                  *ngIf="responseData.data.passPercentage === 0"
                  >{{
                    "assessmentModal.labels.notapplicable" | translate
                  }}</span
                >
              </div>
              <div class="col-sm-5 assessment-status">
                <span class="summary-content__label"
                  >{{ "assessmentModal.labels.status" | translate }}:
                </span>
                <!-- translate -->
                <span
                  *ngIf="
                    responseData.data.userScorePercentage >=
                    responseData.data.passPercentage
                  "
                  class="summary-content__val green"
                  >{{ "assessmentModal.labels.passed" | translate }}</span
                >
                <span
                  *ngIf="
                    responseData.data.userScorePercentage <
                    responseData.data.passPercentage
                  "
                  class="summary-content__val red"
                  >{{ "assessmentModal.labels.failed" | translate }}</span
                >
              </div>
            </div>
          </div>
          <div class="summary-content">
            <span class="summary-content__label"
              >{{ "assessmentModal.labels.yourScore" | translate }}:
            </span>
            <!-- translate -->
            <span class="summary-content__val"
              >{{ responseData.data.userScorePercentage?.toFixed(1) }}%</span
            >
          </div>
          <div class="summary-content">
            <span class="summary-content__label"
              >{{ "assessmentModal.labels.correctRes" | translate }}:
            </span>
            <!-- translate -->
            <span class="summary-content__val"
              >{{ responseData.data.userScore }} of
              {{ responseData.data.noOfQuestion }}</span
            >
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="assessmentID && isSummaryDataLoaded">
        <div class="summary-title">{{ summaryData.data.name }}</div>
        <div class="summary-body">
          <div class="summary-content">
            <div class="row">
              <div class="col-sm-7">
                <span class="summary-content__label"
                  >{{
                    "assessmentModal.labels.passPercentage" | translate
                  }}: </span
                ><!-- translate -->
                <span
                  class="summary-content__val"
                  *ngIf="summaryData.data.passPercentage !== 0"
                  >{{
                    summaryData.data.passPercentage % 1 === 0
                      ? summaryData.data.passPercentage
                      : summaryData.data.passPercentage?.toFixed(1)
                  }}%</span
                >
                <span
                  class="summary-content__val"
                  *ngIf="summaryData.data.passPercentage === 0"
                  >{{
                    "assessmentModal.labels.notapplicable" | translate
                  }}</span
                >
              </div>
              <div class="col-sm-5 assessment-status">
                <span class="summary-content__label"
                  >{{ "assessmentModal.labels.status" | translate }}:
                </span>
                <!-- translate -->
                <span
                  *ngIf="
                    summaryData.data.userScorePercentage >=
                    summaryData.data.passPercentage
                  "
                  class="summary-content__val green"
                  >{{ "assessmentModal.labels.passed" | translate }}</span
                >
                <span
                  *ngIf="
                    summaryData.data.userScorePercentage <
                    summaryData.data.passPercentage
                  "
                  class="summary-content__val red"
                  >{{ "assessmentModal.labels.failed" | translate }}</span
                >
              </div>
            </div>
          </div>
          <div class="summary-content">
            <span class="summary-content__label"
              >{{ "assessmentModal.labels.yourScore" | translate }}:
            </span>
            <!-- translate -->
            <span class="summary-content__val"
              >{{
                summaryData.data.userScorePercentage % 1 === 0
                  ? summaryData.data.userScorePercentage
                  : summaryData.data.userScorePercentage?.toFixed(1)
              }}%</span
            >
          </div>
          <div class="summary-content">
            <span class="summary-content__label"
              >{{ "assessmentModal.labels.correctRes" | translate }}:
            </span>
            <!-- translate -->
            <span class="summary-content__val"
              >{{ summaryData.data.userScore }} of
              {{ summaryData.data.noOfQuestion }}</span
            >
          </div>
        </div>
      </ng-container>
    </div>
    <div class="footer-modal">
      <div class="button-container d-flex justify-content-center">
        <aftp-button
          *ngIf="!fromHome"
          class="custom-btn mr-3"
          (click)="navigateToHome()"
          [buttonKind]="'secondary'"
          [title]="'assessmentModal.button.complete' | translate"
        >
          {{ "assessmentModal.button.complete" | translate }}
        </aftp-button>
        <aftp-button
          *ngIf="
            summaryData?.data?.completionStatus ||
            responseData?.data?.completionStatus
          "
          class="custom-btn"
          (click)="navigateToReviewPage()"
          #review
          [title]="'assessmentModal.button.review' | translate"
        >
          {{ "assessmentModal.button.review" | translate }}
        </aftp-button>
      </div>
    </div>
  </div>
</div>
