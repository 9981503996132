import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Size } from 'src/app/shared/constants/size.constant';
import { DIALOG_OPTIONS } from 'src/app/shared/enums/global.enums';
import { LanguageConstants } from '@app/shared/constants/language';
import { TranslateService } from '@ngx-translate/core';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import {
  CONFIRMATION_DIALOG_TYPE,
  ConfirmationPopupComponent,
  ConfirmationPopupComponentData,
} from 'aftp-component-library';

type ErrorException = {
  messageKey?: string;
} | null;

@Injectable({
  providedIn: 'root',
})
export class ConfirmationPopupService {
  constructor(
    private _dialog: MatDialog,
    private _translateService: TranslateService,
    private _webStorageService: WebStorageService
  ) {
    this.getDialogSize();
  }

  async catchServerError(callback: () => Promise<unknown>) {
    try {
      await callback();
    } catch (exception) {
      const { messageKey } = exception as ErrorException;
      this.showErrorConfirmation(messageKey);
    }
  }

  showErrorConfirmation(key = 'confirmation.errorMessage'): void {
    this.showConfirmation(this.errorConfirmationData(key));
  }

  showSuccessConfirmation(
    userMessage: string
  ): MatDialogRef<ConfirmationPopupComponent> {
    return this.showConfirmation(this.successConfirmationData(userMessage));
  }

  showSuccessConfirmationWithTranslation(key: string): void {
    this.showSuccessConfirmation(this._translateService.instant(key));
  }

  showWarningConfirmation(userMessage: string): void {
    this.showConfirmation(
      this.warningConfirmationWithSingleButton(userMessage)
    );
  }

  showWarningConfirmationWithTranslation(key: string): void {
    this.showConfirmation(
      this.warningConfirmationWithSingleButton(
        this._translateService.instant(key)
      )
    );
  }

  showSuccessWithAction(userMessage: string, action: () => void): void {
    this.showConfirmationWithActionOnClose(
      this.successConfirmationData(userMessage),
      action
    );
  }

  showErrorWithAction(key: string, action: () => void): void {
    this.showConfirmationWithActionOnClose(
      this.errorConfirmationData(key),
      action
    );
  }

  showErrorWithActionwithConfirmButton(
    key: string,
    confirmButtonTex: string,
    action: () => void
  ): void {
    const data: ConfirmationPopupComponentData = {
      type: CONFIRMATION_DIALOG_TYPE.ERROR,
      text: this._translateService.instant(key)
        ? this._translateService.instant(key)
        : key,
      no: this._translateService.instant(confirmButtonTex)
        ? this._translateService.instant(confirmButtonTex)
        : confirmButtonTex,
    };
    this.showConfirmationWithActionOnClose(data, action);
  }

  showSuccessWithActionWithTranslation(key: string, action: () => void): void {
    this.showConfirmationWithActionOnClose(
      this.successConfirmationData(this._translateService.instant(key)),
      action
    );
  }

  showWarningWithAction(
    userMessage: string,
    action: () => void,
    declineAction?: () => void,
    confirmButtonTex?: string,
    declineButtonText?: string
  ): void {
    this.showConfirmationWithActionWhenAccept(
      this.warningConfirmationData(
        userMessage,
        confirmButtonTex,
        declineButtonText
      ),
      action,
      declineAction
    );
  }

  showWarningWithActionWithTranslation(
    key: string,
    action: () => void | Promise<void>,
    declineAction?: () => void
  ): void {
    this.showConfirmationWithActionWhenAccept(
      this.warningConfirmationData(this._translateService.instant(key)),
      action,
      declineAction
    );
  }

  showConfirmationWithActionWhenAccept(
    data: ConfirmationPopupComponentData,
    onApply: () => void | Promise<void>,
    declineAction?: () => void
  ): void {
    const dialogRef = this.showConfirmation(data);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === DIALOG_OPTIONS.YES_OPTION) {
        onApply();
      } else if (declineAction) {
        declineAction();
      }
    });
  }

  showConfirmationWithActionOnClose(
    data: ConfirmationPopupComponentData,
    onApply: () => void
  ): void {
    const dialogRef = this.showConfirmation(data);
    dialogRef.afterClosed().subscribe(() => {
      onApply();
    });
  }

  showConfirmation(
    data: ConfirmationPopupComponentData
  ): MatDialogRef<ConfirmationPopupComponent> {
    return this._dialog.open(ConfirmationPopupComponent, {
      width: this.getDialogSize(),
      disableClose: true,
      data,
      ariaDescribedBy: 'confirmationDesc',
    });
  }

  //TODO should make it with _translateService.onLangChange(), but it still not working correctly.
  //TODO need to fix prettier styles to avoid it.
  private getDialogSize(): string {
    if (
      this._webStorageService.getLanguageID() !== LanguageConstants.LANGUAGES.EN
    ) {
      return Size.CONFIRMATION_POPUP_LARGE_SIZE;
    }
    return Size.CONFIRMATION_POPUP_MEDIUM_SIZE;
  }

  private errorConfirmationData(key: string): ConfirmationPopupComponentData {
    return {
      type: CONFIRMATION_DIALOG_TYPE.ERROR,
      text: this._translateService.instant(key)
        ? this._translateService.instant(key)
        : key,
      no: this._translateService.instant('button.ok'),
    };
  }

  private warningConfirmationData(
    userMessage: string,
    confirmButtonText = 'button.yes',
    declineButtonText = 'button.no'
  ): ConfirmationPopupComponentData {
    return {
      type: CONFIRMATION_DIALOG_TYPE.WARNING,
      text: userMessage,
      yes: this._translateService.instant(confirmButtonText),
      no: this._translateService.instant(declineButtonText),
    };
  }

  private warningConfirmationWithSingleButton(
    userMessage: string
  ): ConfirmationPopupComponentData {
    return {
      type: CONFIRMATION_DIALOG_TYPE.WARNING,
      text: userMessage,
      no: this._translateService.instant('button.ok'),
    };
  }

  private successConfirmationData(
    userMessage: string
  ): ConfirmationPopupComponentData {
    return {
      type: CONFIRMATION_DIALOG_TYPE.SUCCESS,
      text: userMessage,
      no: this._translateService.instant('button.ok'),
    };
  }
}
