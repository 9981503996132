import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { UserDetailsService } from './user-details.service';
import { Observable } from 'rxjs';
import {
  AUTH_ROUTER_PATHS,
  USER_ROUTER_PATHS,
} from 'src/app/shared/enums/router-paths.enum';
import { CookieService } from 'ngx-cookie-service';
import { IdleService } from './idleService';
import {
  CookieResponse,
  LoginHistoryResponse,
} from '../interface/common-model';
import { Cookies, Endpoints } from '../constants/endpoints.constant';
import { SCREEN_DEVICES, SCREEN_SIZES } from '../enums/screen-size.enum';
import { UserService } from './user.service';
import { ErrorMessagesService } from './full-screen-error/error-messages.service';
import { LoadingScreenServiceService } from './loading-screen-service.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class EulaServiceService {
  tenatBaseAPIURL = environment.apiURL.tenantManagement;
  baseUrl = environment.apiURL.baseUrl;
  eulaBroadCastChannel = new BroadcastChannel('EULA_CHECKED');
  constructor(
    private http: HttpClient,
    private route: Router,
    private userDetailsService: UserDetailsService,
    private cookieService: CookieService,
    private idleService: IdleService,
    private httpClient: HttpClient,
    private userService: UserService,
    private errorMessagesService: ErrorMessagesService,
    private translate: TranslateService,
    private loader: LoadingScreenServiceService
  ) {}

  agreeToEula(): void {
    this.acceptEula().subscribe((res) => {
      if (res.result) {
        localStorage.setItem('isEulaAccepted', 'true');
        sessionStorage.setItem('FtpEulaAccepted', 'true');
        this.eulaBroadCastChannel.postMessage('EULA_CHECKED');
        if (localStorage.getItem('isNewHomePage') === 'true') {
          this.route.navigateByUrl(
            `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.PROGRAM_HOME_PAGE}`
          );
        } else {
          this.route.navigateByUrl(
            `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.HOME}`
          );
        }
      }
    });
  }
  acceptEula(): Observable<any> {
    const email = this.userDetailsService.getSignInUserName().toLowerCase();
    const apiUrl = `${this.tenatBaseAPIURL}/site/eula`;
    return this.http.post(apiUrl, { eula: true, email: email });
  }
  ftpAppUseragreeToEula() {
    const expires = Number(
      new Date(new Date().getTime() + 60 * 60 * 24 * 1000).toJSON()
    );
    this.ftpAppUserAcceptEula()
      .then((res) => {
        this.cookieService.set(
          'gatewayResponse',
          JSON.stringify(res),
          expires,
          '/',
          res.params.domain,
          true,
          'None'
        );
        this.checkFtpLogic(res);
      })
      .catch((err) => {
        console.log('err =====', err);
      });
  }
  checkFtpLogic(res) {
    if (res !== '' && res != undefined && res != null) {
      const gatewayResponse = res;
      if (gatewayResponse.result === true) {
        localStorage.setItem(
          'FTPAPPsessionExpires',
          gatewayResponse.params.expiresIn
        );
        localStorage.setItem('tenantName', gatewayResponse.params.tenantLabel);
        localStorage.setItem('accessToken', gatewayResponse.params.token);
        localStorage.setItem('tenantID', gatewayResponse.params.tenantId);
        localStorage.setItem('PoolId', gatewayResponse.params.poolId);
        localStorage.setItem('userId', gatewayResponse.params.userId);
        localStorage.setItem('eid', gatewayResponse.params.eId);
        localStorage.setItem(
          'FTPAPPsessionExpiryWarningTimer',
          gatewayResponse.params.sessionTimeOut
        );
        this.getCookieInfo().subscribe();
        this.checkgatewayResponse(res);
      } else {
        localStorage.setItem('accessToken', gatewayResponse.access_token);
        localStorage.setItem('tenantID', gatewayResponse.tenantid);
        if (gatewayResponse.error.error_case === 'SUBS_LIMIT_OVER') {
          this.errorMessagesService.setErrorValues(
            'FTP_APP_ERROR',
            'SUBS_LIMIT_OVER',
            gatewayResponse.error
          );
        } else if (gatewayResponse.error.error_case === 'SITE_EXPIRED') {
          this.errorMessagesService.setErrorValues(
            'FTP_APP_ERROR',
            'SITE_EXPIRED',
            gatewayResponse.error
          );
        } else if (gatewayResponse.error.error_case === 'TOKEN_INVALID') {
          const errorMsg = {
            msg: this.translate.instant('errorMessages.errorTechnicalGlitch'),
          };
          this.errorMessagesService.setErrorValues(
            'FTP_APP_ERROR',
            'FTP_APP_TOKEN_INVALID',
            errorMsg
          );
        } else {
          this.route
            .navigateByUrl(`/${AUTH_ROUTER_PATHS.ERROR}?type=ftp-app-error`)
            .then(() => this.loader.stopLoading());
        }
      }
    } else {
      this.route
        .navigateByUrl(`/${AUTH_ROUTER_PATHS.ERROR}?type=ftp-app-error`)
        .then(() => this.loader.stopLoading());
    }
  }

  checkgatewayResponse(res) {
    const gatewayResponse = res;
    if (gatewayResponse.params.internalUser) {
      this.userService
        .getESOUserPoolInfoByDomain(gatewayResponse.params.eId, true)
        .subscribe((response) => {
          localStorage.setItem(
            'tenatUserpoolDetailsForHCM',
            JSON.stringify(response.data.poolDetail)
          );
          this.navigateToFTPlandingpAge();
        });
    } else {
      this.navigateToFTPlandingpAge();
    }
  }

  ftpAppUserAcceptEula(): Promise<any> {
    const accesstoken = localStorage.getItem('accessToken');
    const tenantId = localStorage.getItem('tenantID');
    const apiUrl = `${this.baseUrl}/ftp-app/acceptEula`;
    const headers = new HttpHeaders();
    const options = {
      headers: headers,
    };
    return this.http
      .post(
        apiUrl,
        {
          access_token: accesstoken,
          tenantid: tenantId,
        },
        options
      )
      .toPromise();
  }
  eulaBranding() {
    const accesstoken = localStorage.getItem('accessToken');
    const tenantId = localStorage.getItem('tenantID');
    const apiUrl = `${this.baseUrl}/ftp-app/getEula`;
    const headers = new HttpHeaders();
    const options = {
      headers: headers,
    };
    return this.http.post(
      apiUrl,
      {
        access_token: accesstoken,
        tenantid: tenantId,
      },
      options
    );
  }
  navigateToFTPlandingpAge(): void {
    this.storeLoginInfo().subscribe();
    this.idleService.countFtpAPPSession();
    this.idleService.clearHcmSession();
    this.route.navigateByUrl(
      `/${USER_ROUTER_PATHS.USER}/${USER_ROUTER_PATHS.HOME}`
    );
    sessionStorage.setItem('FtpEulaAccepted', 'true');
    this.eulaBroadCastChannel.postMessage('EULA_CHECKED');
  }
  getCookieInfo(): Observable<CookieResponse> {
    return this.httpClient.get<CookieResponse>(`${Cookies.getCookies}`, {
      withCredentials: true,
    });
  }
  private storeLoginInfo(): Observable<LoginHistoryResponse> {
    let req_body;
    if (window.innerWidth <= SCREEN_SIZES.mobileScreen) {
      req_body = {
        device: SCREEN_DEVICES.mobile,
      };
    } else if (
      window.innerWidth > SCREEN_SIZES.mobileScreen &&
      window.innerWidth < SCREEN_SIZES.largeScreen
    ) {
      req_body = {
        device: SCREEN_DEVICES.tablet,
      };
    } else {
      req_body = {
        device: SCREEN_DEVICES.computer,
      };
    }
    return this.httpClient.post<LoginHistoryResponse>(
      `${Endpoints.LOGIN.histoty}`,
      req_body
    );
  }
}
