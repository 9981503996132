import { environment } from '@env/environment';
import { SUBJECT_REQUEST_ENTITY_PARAMS } from 'src/app/shared/enums/global.enums';
import {
  DROPDOWN_PAGE_TYPES,
  SEARCH_TYPES,
} from 'src/app/shared/enums/search-types';
import {
  DEFAULT_HOMEPAGE_LP_LIMIT,
  DEFAULT_HOMEPAGE_LP_OFFSET,
} from 'src/app/shared/constants/slider-size.constants';
import { ExploreLearningPathParameters } from '@app/layouts/user/pages/explore-learning-paths-page/types/explore-learning-path-parameters';
import { SOURCES } from 'src/app/shared/enums/content-formats.enum';

class User {
  static readonly base = `${environment.apiURL.baseUrl}/user`;
}

enum ReminderQueryParams {
  REPEAT_TYPE = 'rType',
  REPEAT_DAYS = 'rDays',
  CURRENT_TIME = 'currentTime',
  DELIVERY_TYPE = 'delivery',
}

class Reminder {
  static readonly QUERY_PARAMS = ReminderQueryParams;
  static readonly base = `${User.base}`;
  static readonly retrieveInitial = `${Reminder.base}/config/reminder/initial/data`;
  static readonly retrieveDetails = (userId: string) =>
    `${Reminder.base}/${userId}/reminder/data`;
  static readonly setReminder = (userId: string) =>
    `${Reminder.base}/${userId}/reminder/set`;
  static readonly retrieveInitialLp = (learningPathId: string) =>
    `${Reminder.base}/reminder/path/${learningPathId}/initial/get`;
  static readonly retrieveDetailsLp = (learningPathId: string) =>
    `${Reminder.base}/reminder/path/${learningPathId}/get`;
  static readonly setReminderLp = (learningPathId: string) =>
    `${Reminder.base}/reminder/path/${learningPathId}/set`;
  static readonly retrieveReminderActivityState = `${Reminder.base}/config/reminder/check/status`;
}

enum ReminderPromptQueryParams {
  ACTION = 'action',
}

class ReminderPrompt {
  static readonly QUERY_PARAMS = ReminderPromptQueryParams;
  static readonly base = User.base;
  static readonly retrievePrompt = (userId: string) =>
    `${ReminderPrompt.base}/${userId}/prompt`;
  static readonly setPrompt = (userId: string) =>
    `${ReminderPrompt.base}/${userId}/prompt/confirm-action`;
}

class SkillLevel {
  static readonly base = `${environment.apiURL.baseUrl}/skill`;
  static readonly retrieveProficiencyList = `${SkillLevel.base}/proficiency/list`;
  static readonly updateProficiency = `${SkillLevel.base}/proficiency/update`;
}

export class Tenant {
  static readonly base = `${environment.apiURL.baseUrl}/tenant`;
}

export class SiteAccess {
  static readonly registerUser = `${Tenant.base}/site/register`;
  static readonly siteStatus = `${Tenant.base}/site/status`;
  static readonly userTenant = `${Tenant.base}/site/access`;
}

export class Cookies {
  static readonly base = `${environment.apiURL.baseUrl}`;
  static readonly getCookies = `${Cookies.base}/content/getCookie`;
  static readonly deleteCookies = `${Cookies.base}/content/deleteCookies`;
}
export class Login {
  static readonly base = `${environment.apiURL.baseUrl}/login`;
  static readonly userAccountStatus = `${Login.base}/user-status`;
  static readonly userPool = `${Tenant.base}/userpool`;
  static readonly getTenantUserPool = `${Login.userPool}`;
  static readonly histoty = `${Login.base}/history`;
  static readonly userPWDStatus = `${Login.base}/password-age-validation`;
}

class Admin {
  static readonly base = `${environment.apiURL.baseUrl}/admin`;
  static readonly addUser = `${Admin.base}/user/add`;
  static readonly editUser = `${Admin.base}/user/update`;
  static readonly downloadTemplate = `${Admin.base}/user/template/download`;
  static readonly downloadUserRecords = `${Admin.base}/user/download`;
  static readonly retrieveRoles = `${Admin.base}/role/mapping`;
  static readonly retrieveSubscriptions = `${Admin.base}/sub/details`;
  static readonly retrieveGroups = `${Admin.base}/group/list`;
  static readonly retrievePrograms = `${Admin.base}/program/list`;
  static readonly retrieveConsent = `${Admin.base}/message/consent`;
  static readonly acceptConsent = `${Admin.base}/consent/update`;
  static readonly spareFieldList = `${Admin.base}/spare/display/list`;
  static readonly spareFieldValues = `${Admin.base}/spare/field/list`;
  static readonly bulkUpload = `${Admin.base}/user/upload/bulk`;
  static readonly tableData = `${Admin.base}/user/upload/list`;
  static readonly downloadFiles = `${Admin.base}/user/processedfile/download`;
  static readonly getStatus = `${Admin.base}/user/upload/`;
}

class Roles {
  static readonly base = `${environment.apiURL.baseUrl}/user`;
  static readonly userRoles = `${Roles.base}/roles`;
}
class ContactUs {
  static readonly base = `${environment.apiURL.baseUrl}/contact-us`;
  static readonly categories = `${ContactUs.base}/categories`;
  static readonly holidays = `${ContactUs.base}/holidays`;
  static readonly submit = `${ContactUs.base}/submit`;
}

class AftpSearch {
  static readonly base = `${environment.apiURL.baseUrl}/aftpsearch`;
}

class LearningObject {
  static readonly base = `${environment.apiURL.baseUrl}/learning`;
  static readonly searchBase = `${environment.apiURL.baseUrl}/aftpsearch`;
  static readonly getLearningObjectList = `${LearningObject.searchBase}/learning-object/list`;
  static readonly getLearningObjectExploreList = `${LearningObject.searchBase}/learning-object/explore-by-subject`;
  static readonly deleteById = (loId: string) =>
    `${AftpSearch.base}/learning-object/${loId}`;
  static readonly publishById = (loId: string) =>
    `${LearningObject.base}/object/${loId}/publish`;
  static readonly unPublishById = (loId: string) =>
    `${LearningObject.base}/object/${loId}/unpublish`;
  static readonly getCompletions = (loId: string) =>
    `${LearningObject.base}/lo-completions/${loId}`;
  static readonly getEventCompletions = (loId: string) =>
    `${LearningObject.base}/event-completions/${loId}`;
  static readonly getEditGroupPermissions = (loId: string) =>
    `${LearningObject.searchBase}/learning-object/${loId}/permission-delete-group`;

  static LEARNING_OBJECT_SME_SEARCH(searchValue: string): string {
    return `${User.base}/list?type=sme&search=${searchValue}`;
  }

  static LEARNING_OBJECT_DELEGATE_SEARCH(searchValue: string): string {
    return `${User.base}/list?type=delegator&search=${searchValue}`;
  }

  static LEARNING_OBJECT_TAGS_SEARCH = `${AftpSearch.base}/tag`;
  static LEARNING_OBJECT_USERS_SEARCH = `${AftpSearch.base}/users`;
  static GET_GROUPS_BY_LOID(loID: string): string {
    return `${LearningObject.base}/learning-object/groups?loID=${loID}`;
  }
}

enum LearningActivityQueryParams {
  LA_ID = 'laid',
  LO_ID = 'loid',
  SOURCE_ID = 'sourceID',
  URL_LINK = 'urlLink',
  COURSE_ID = 'courseID',
}

const sourceUniqueFieldQueryParamMap = {
  [SOURCES.YOUTUBE]: LearningActivityQueryParams.URL_LINK,
  [SOURCES.ACCENTURE_ACADEMY]: LearningActivityQueryParams.COURSE_ID,
  [SOURCES.ACCENTURE]: LearningActivityQueryParams.URL_LINK,
  [SOURCES.PUBLIC_WEB]: LearningActivityQueryParams.URL_LINK,
  [SOURCES.SKILLSOFT]: LearningActivityQueryParams.COURSE_ID,
  [SOURCES.SIMPLE_LEARN]: LearningActivityQueryParams.URL_LINK,
  [SOURCES.INTERNAL]: LearningActivityQueryParams.URL_LINK,
};

class LearningActivity {
  static readonly QUERY_PARAMS = LearningActivityQueryParams;
  static readonly SOURCE_UNIQUE_QUERY_PARAMS = sourceUniqueFieldQueryParamMap;
  static readonly base = `${LearningObject.base}/activity`;
  static readonly update = `${LearningActivity.base}/update`;
  static readonly publish = `${LearningActivity.base}/publish`;
  static readonly unPublish = `${LearningActivity.base}/unpublish`;
  static readonly delete = `${LearningActivity.base}/delete`;
  static readonly getCompletionStatistics = (
    activityId: string,
    loId?: string
  ) =>
    `${LearningActivity.base}/completion/statistics?learningActivityID=${activityId}&loID=${loId}`;
  static readonly getLOCompletionStatistics = (activityId: string) =>
    `${LearningObject.base}/object/completion/statistics?loID=${activityId}`;

  static readonly getExistingActivity = `${LearningActivity.base}/get`;
  static readonly mapToLo = `${LearningActivity.base}/map`;
  static readonly updateOrder = `${LearningActivity.base}/orders/update`;
}

enum LearningObjectTypesSearchQueryParams {
  SEARCH = 'search',
  PAGE_TOKEN = 'pageToken',
  START = 'start',
  COURSE_ID = 'courseid',
  FORMAT_LABEL_ID = 'formatID',
}

class LearningObjectTypesSearch {
  static readonly QUERY_PARAMS = LearningObjectTypesSearchQueryParams;
  static readonly SEARCH_LEARNINGS_YOUTUBE = `${LearningObject.base}/search-learnings-youtube`;
  static readonly SEARCH_LEARNINGS_YOUTUBE_DETAILS = `${LearningObject.base}/get-youtube-item/`;
  static readonly SEARCH_LEARNINGS_GOOGLE = `${LearningObject.base}/search-learnings-google`;
  static readonly SEARCH_LEARNINGS_ACCENTURE_ACADEMY = `${LearningObject.base}/search-learnings-academy`;
  static readonly SEARCH_LEARNINGS_ACCENTURE_ACADEMY_DETAILS = `${LearningObject.base}/get-academy-item/`;

  static searchLearningsAccentureAcademyDetails = (searchQuery: string) =>
    `${LearningObjectTypesSearch.SEARCH_LEARNINGS_ACCENTURE_ACADEMY_DETAILS}${searchQuery}`;

  static searchLearningsYoutubeDetails(searchQuery: string): string {
    return `${LearningObjectTypesSearch.SEARCH_LEARNINGS_YOUTUBE_DETAILS}${searchQuery}`;
  }
}
class Groups {
  static readonly base = `${LearningObject.base}/group`;
  static readonly retrieveLearningGroupsList = (
    langId: string,
    loId: string,
    checkPage: boolean
  ) =>
    `${Groups.base}/split/list?langId=${langId}&learningId=${loId}&isLp=${checkPage}`;
  static readonly getGroupDetails = `${environment.apiURL.baseUrl}/aftpsearch/group/list`;
}

class Programs {
  static readonly base = `${LearningObject.base}/program`;
  static readonly retrieveLearningProgramsList = (
    langId: string,
    loId: string
  ) => `${Programs.base}/split/list?langId=${langId}&loId=${loId}`;
  static readonly getProgramDetails = `${environment.apiURL.baseUrl}/aftpsearch/program/list`;
  static readonly getOtherGroupDetails = `${environment.apiURL.baseUrl}/aftpsearch/others-groups`;
  static readonly getGroupProgramDetails = `${environment.apiURL.baseUrl}/aftpsearch/program-mapped-groups`;
  static readonly getGroupList = (prgmId: string) =>
    `${environment.apiURL.baseUrl}/aftpsearch/group/list?programid=${prgmId}`;
}
class Contents {
  static readonly getConfigs = (langID: string) =>
    `${environment.apiURL.baseUrl}/contentUpload/getConfigs?langId=${langID}`;
  static readonly getConfigsCertificate = (langID: string) =>
    `${environment.apiURL.baseUrl}/contentUpload/getConfigs?langId=${langID}&category=certificate`;
  static readonly validationCheckIfFileExists = `${environment.apiURL.baseUrl}/aftpsearch/contentUpload/searchCurator`;
  static readonly getFiles = `${environment.apiURL.baseUrl}/aftpsearch/contentUpload/list`;
  static readonly getInprogressFiles = `${environment.apiURL.baseUrl}/contentUpload/process/status`;
  static readonly uploadStatus = `${environment.apiURL.baseUrl}/contentUpload/status`;
}
class Certificates {
  static readonly getCertificates = `${environment.apiURL.baseUrl}/aftpsearch/certificate`;
  static readonly certificateStatus = `${environment.apiURL.baseUrl}/contentUpload/certificateStatus`;
  static readonly certificateDownload = `${environment.apiURL.baseUrl}/contentUpload/download/Certificate`;
}

class FaqPage {
  static readonly getGdprFile = `${environment.apiURL.baseUrl}/faq/GDPR_DOC.pdf`;
}

class Captcha {
  static readonly getCaptcha = `${environment.apiURL.captcha}/securimage/securimage_show.php`;
  static readonly ValidateCaptcha = `${environment.apiURL.captcha}/securimage/captcha_check.php`;
  static readonly getCaptchaJS = `${environment.apiURL.captcha}/securimage/securimage.js`;
}

class ScheduledEvents {
  static readonly base = `${environment.apiURL.baseUrl}/scheduled-event/offline-course`;
  static readonly addScheduledEvent = `${ScheduledEvents.base}/add`;
  static readonly editScheduledEvent = `${ScheduledEvents.base}/edit`;
  static readonly deleteScheduledEvent = `${ScheduledEvents.base}/delete`;
  static readonly getScheduledEventTypes = (langID: string) =>
    `${ScheduledEvents.base}/type/list?langID=${langID}`;
  static readonly downloadTemplate = `${ScheduledEvents.base}/template/download`;
  static readonly complete = `${ScheduledEvents.base}/complete`;
}

class TrackEvents {
  static readonly base = `${environment.apiURL.baseUrl}/scheduled-event/track-events`;
  static readonly getUploadsList = `${TrackEvents.base}/uploads-list`;
  static readonly upload = `${TrackEvents.base}/upload`;
  static readonly download = `${TrackEvents.base}/download`;
}

class ManageAssessment {
  static readonly base = `${environment.apiURL.baseUrl}/assessment`;
  static readonly addAssessment = `${ManageAssessment.base}/add`;
  static readonly editAssessment = `${ManageAssessment.base}/update`;
  static readonly getAssessmentDetails = `${ManageAssessment.base}/details`;
}

class SearchApi {
  static readonly base = `${environment.apiURL.baseUrl}/aftpsearch`;
  static readonly searchUser = `${SearchApi.base}/user`;
  static readonly searchAssessment = `${SearchApi.base}/assessment`;
  static readonly searchLearningPath = `${SearchApi.base}/learning-path`;
  static readonly searchOfflineCourse = (langID: string) =>
    `${SearchApi.base}/offline-course?langID=${langID}`;
  static readonly globalSearchLearningObject = `${SearchApi.base}/learning-object`;
  static readonly getSearchDropDowns = (
    type: SEARCH_TYPES,
    pageType = DROPDOWN_PAGE_TYPES.SEARCH
  ) =>
    `${SearchApi.base}/learning-object/get-dropdowns?type=${type}&pageType=${pageType}`;
  static readonly searchPeople = `${SearchApi.base}/users`;
  static readonly searchLearnerHistory = `${SearchApi.base}/history`;
  static readonly searchAutosuggestion = `${SearchApi.base}/learning-object/autosuggest`;
  static readonly managerEmailID = `${SearchApi.base}/user/manager/search`;
}

class AssessmentLearner {
  static readonly base = `${environment.apiURL.baseUrl}/assessment`;
  static readonly assessmentDetails = `${AssessmentLearner.base}/details`;
  static readonly submit = `${AssessmentLearner.base}/attempt/submit`;
}

class Header {
  static readonly hamburgerMenu = `${environment.apiURL.baseUrl}/menu/get`;
  static readonly base = `${environment.apiURL.baseUrl}/menu`;
  static readonly headerDetails = `${Header.base}/details`;
}

class LearningPaths {
  static readonly base = `${environment.apiURL.baseUrl}/learning/path`;
  static readonly searchBase = `${environment.apiURL.baseUrl}/aftpsearch`;
  static readonly getLearningPathList = `${LearningPaths.searchBase}/learning-path/list`;
  static readonly getCompletions = (lpSkillId: string) =>
    `${LearningPaths.base}/${lpSkillId}/getCompletions`;
  static getLearningLikeList(learningPathId: string): string {
    return `${LearningPaths.base}/${learningPathId}/like/list`;
  }
  static postLearningLikeList(learningPathId: string): string {
    return `${LearningPaths.base}/${learningPathId}/like/update`;
  }
  static putLearningPathView(): string {
    return `${LearningPaths.base}/increment-view`;
  }
  static readonly seeLearningPathSkill = `${LearningPaths.base}/seen`;
  static readonly learningObjectsByLp = (learningPathId: string) => {
    return `${environment.apiURL.baseUrl}/learning/path/${learningPathId}/details
	     `;
  };
}

class Notifications {
  static readonly base = (userId: string) =>
    `${User.base}/${userId}/notifications`;
  static readonly getList = (userId: string) =>
    `${Notifications.base(userId)}/list`;
  static readonly changeStatus = (userId: string, notificationId: string) =>
    `${Notifications.base(userId)}/${notificationId}/change-status`;
  static readonly getNonViewedCount = (userId: string) =>
    `${Notifications.base(userId)}/non-viewed`;
  static readonly resetCounter = (userId: string) =>
    `${Notifications.base(userId)}/change-viewed-status`;
}

class HomePage {
  static baseUrl = environment.apiURL.baseUrl;
  static getLearningObject(locked: number, learningPathId: string): string {
    return `${HomePage.baseUrl}/learning/path/${learningPathId}/details?isLocked=${locked}`;
  }
  static readonly RESUME_SECTION = `${HomePage.baseUrl}/learning/path/resume/details`;
  static readonly LEARNING_PATH = `${HomePage.baseUrl}/learning/path/homepage-cards/list?limit=${DEFAULT_HOMEPAGE_LP_LIMIT}&offset=${DEFAULT_HOMEPAGE_LP_OFFSET}`;
  static GroupLearningPaths(groupId: string): string {
    return `${HomePage.baseUrl}/homepage/${groupId}/lpCards`;
  }
  /**
   * getGroupDetailsByID
   *
   * @param programID string
   * @param groupID string
   * @returns object
   */
  static getGroupDetailsByID(programID: string, groupID: string): string {
    return `${HomePage.baseUrl}/homepage/group/${programID}?groupId=${groupID}`;
  }

  /**
   * getGroupsList
   *
   * @param programID string
   * @returns Array object
   */
  static getGroupsList(programID: string): string {
    return `${HomePage.baseUrl}/homepage/program/${programID}/groups`;
  }
  /**
   * getProgramDetails
   *
   * @param programID string
   * @returns Array object
   */
  static getProgramDetails(programID: string): string {
    return `${HomePage.baseUrl}/homepage/programDetail?prmgId=${programID}`;
  }
}

class Notes {
  static readonly base = `${environment.apiURL.baseUrl}/notes`;
  static readonly learningObject = `${Notes.base}/learning-object`;
  static readonly getLearningObjects = `${Notes.learningObject}/list`;
  static readonly getNotes = `${Notes.learningObject}/details/list`;
  static readonly getAllNotes = `${Notes.base}/learning-object/details/list`;
  static readonly deleteNote = `${Notes.base}/delete`;
  static readonly updateNote = `${Notes.base}/update`;
}

class Discussion {
  static readonly base = `${environment.apiURL.baseUrl}/discussion-board`;
  static readonly postConfig = `${Discussion.base}/post/config`;
  static readonly likePost = `${Discussion.base}/post/like`;
  static readonly addPost = `${Discussion.base}/post/add`;
  static readonly editPost = `${Discussion.base}/post/edit`;
  static readonly deletePost = `${Discussion.base}/post/delete`;
  static readonly baseCommment = `${Discussion.base}/post/comment`;
  static readonly addComment = `${Discussion.baseCommment}/add`;
  static readonly editComment = `${Discussion.baseCommment}/update`;
  static readonly deleteComment = `${Discussion.baseCommment}/delete`;
  static readonly likeComment = `${Discussion.baseCommment}/like`;
  static readonly postById = (loID: string, postID: string) =>
    `${Discussion.base}/posts/popup/${loID}?postID=${postID}`;
}

class Explore {
  static readonly base = `${environment.apiURL.baseUrl}/learning/explore`;
  static readonly baseSubjects = `${Explore.base}/subjects/list?entity=`;
  static readonly subjectCategories = `${Explore.baseSubjects}${SUBJECT_REQUEST_ENTITY_PARAMS.SUBJECT_CATEGORY}`;
  static readonly subjects = `${Explore.baseSubjects}${SUBJECT_REQUEST_ENTITY_PARAMS.SUBJECT}`;
  static readonly subjectsByCategoryId = (categoryId: string) =>
    `${Explore.baseSubjects}${SUBJECT_REQUEST_ENTITY_PARAMS.SUBJECT}&subjectCategoryID=${categoryId}`;
  static readonly skillCategories = `${Explore.base}/skill-category/list`;
}

class ExploreLearningPath {
  static readonly learningPaths = ({
    categoryIds,
    sort,
    skillIds,
    limit,
    startKey,
  }: ExploreLearningPathParameters) => {
    return `${environment.apiURL.baseUrl}/learning/explore/learning-path/list?skillCategoryIDs=${categoryIds}&sort=${sort}&skillLevelIDs=${skillIds}&limit=${limit}&startKey=${startKey}
    `;
  };
}

export class Badges {
  static readonly base = `${environment.apiURL.baseUrl}/badges`;
  static readonly getUnseenBadge = (langId: string) =>
    `${Badges.base}/unseenbadge?langID=${langId}`;
  static readonly getUnseenBadgeforLOCompletion = (
    langId: string,
    lpID: string
  ) => `${Badges.base}/unseenbadge?langID=${langId}&lpID=${lpID}`;
  static readonly getRealtimeBadge = `${Badges.base}/realtimebadges`;
}

class ExploreLearningPathsBySkillCategory {
  static readonly learningPaths = (skillCategoryId: string) => {
    return `${environment.apiURL.baseUrl}/learning/explore/learning-path/list?skillCategoryIDs=${skillCategoryId}
	     `;
  };
}

class Reports {
  static readonly base = `${environment.apiURL.baseUrl}/reports`;
  static readonly getReports = `${Reports.base}/list`;
  static readonly downloadReport = `${Reports.base}/download`;
  static readonly generateReport = `${Reports.base}/generate/team-reports`;
  static readonly seenStatus = `${Reports.base}/update/seenStatus`;
  static readonly teamReportStatus = `${Reports.base}/team-reports/status`;
  static readonly getCustomReports = `${Reports.base}/getCustomReports`;
  static readonly dateRangeFilter = (langID: string) =>
    `${Reports.base}/date-range-filter?langId=${langID}`;
  static readonly downloadCustomReports = `${Reports.base}/custom/download`;
  static readonly submitCustomReport = `${Reports.base}/custom/submit`;
}

class History {
  static readonly base = `${SearchApi.searchLearnerHistory}`;
  static readonly getDropdownOptions = `${History.base}/type`;
  static readonly getLearningObjects = `${History.base}/learning-object`;
  static readonly getLearningPaths = `${History.base}/learning-path`;
  static readonly LEARNING_PATH_HISTORY = `${HomePage.baseUrl}/learning/learning-path/history?limit=${DEFAULT_HOMEPAGE_LP_LIMIT}&offset=${DEFAULT_HOMEPAGE_LP_OFFSET}`;
}

class ManageBreaches {
  static readonly base = `${environment.apiURL.baseUrl}/breach`;
  static readonly getBreachesList = `${ManageBreaches.base}/list`;
  static readonly saveBreach = `${ManageBreaches.base}/save`;
  static readonly deleteBreach = `${ManageBreaches.base}/delete`;
  static readonly addUpdateBreachQuestion = `${ManageBreaches.base}/question/save`;
  static readonly getBreachDetails = (breachId: string) =>
    `${ManageBreaches.base}/details?breachId=${breachId}`;
  static readonly uploadBreachFile = (breachId: string, tenantid: string) =>
    `${ManageBreaches.base}/uploadbreaches?breachId=${breachId}&tenantid=${tenantid}`;
  static readonly getBreachFile = (breachId: string, tenantid: string) =>
    `${ManageBreaches.base}/getbreachfile?breachId=${breachId}&tenantid=${tenantid}`;
}

export class Endpoints {
  static readonly REMINDER = Reminder;
  static readonly REMINDER_PROMPT = ReminderPrompt;
  static readonly SKILL_LEVEL = SkillLevel;
  static readonly SITE_ACCESS = SiteAccess;
  static readonly LOGIN = Login;
  static readonly ADMIN = Admin;
  static readonly FAQ = FaqPage;
  static readonly CONTACT_US = ContactUs;
  static readonly CAPTCHA = Captcha;
  static readonly TENANT = Tenant;
  static readonly MANAGE_ASSESSMENT = ManageAssessment;
  static readonly GROUPS = Groups;
  static readonly PROGRAMS = Programs;
  static readonly CONTENT = Contents;
  static readonly CERTIFICATE = Certificates;
  static readonly LEARNING_OBJECT = LearningObject;
  static readonly LEARNING_ACTIVITY = LearningActivity;
  static readonly SCHEDULED_EVENTS = ScheduledEvents;
  static readonly LEARNING_OBJECT_TYPES_SEARCH = LearningObjectTypesSearch;
  static readonly TRACK_EVENTS = TrackEvents;
  static readonly SEARCH_API = SearchApi;
  static readonly ASSESSMENT_LEARNER = AssessmentLearner;
  static readonly HEADER = Header;
  static readonly LEARNING_PATHS = LearningPaths;
  static readonly NOTIFICATIONS = Notifications;
  static readonly HOME_PAGE = HomePage;
  static readonly NOTES = Notes;
  static readonly DISCUSSION = Discussion;
  static readonly EXPLORE = Explore;
  static readonly EXPLORE_LEARNING_PATH = ExploreLearningPath;
  static readonly BADGES = Badges;
  static readonly EXPLORE_LEARNING_PATHS_BY_SKILL_CATEGORY =
    ExploreLearningPathsBySkillCategory;
  static readonly REPORTS = Reports;
  static readonly HISTORY = History;
  static readonly MANAGE_BREACHES = ManageBreaches;
  static readonly ROLES = Roles;
  static readonly HCMGATEWAY = 'hcm/gateway';
  static readonly HCMUSERCREATE = 'hcm/user/create';
  //TODO: need to set all not secured urls cc: Vlad Batsenko
  static readonly notSecuredEndpoints = [Login.base];

  static isNotSecured(url: string): boolean {
    return Endpoints.notSecuredEndpoints.some(
      (endpoint) => url.indexOf(endpoint) >= 0
    );
  }

  static isSecured(url: string): boolean {
    return !Endpoints.isNotSecured(url);
  }
}
