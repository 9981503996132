export enum UserProfileConfigs {
  DISPLAY_LEADERBOARD_CONFIG_NAME = 'displayLeaderBoard',
  DISPLAY_ROLES_ELIGIBLE_CONFIG_NAME = 'EligibleRoles',
  DISPLAY_PATH_COMPLETED_OR_SKILLS_ACQUIRED_CONFIG_NAME = 'ProfileLPHeader',
}
export enum UserProfileConfigValues {
  DISPLAY_PATH_COMPLETED = 'path-completed',
  DISPLAY_SKILLS_ACQUIRED = 'skill-acquired',
}

export enum UserProfileAcquiredSkillsSectionTitleTranslations {
  LEARNING_PATHS_COMPLETED_TITLE = 'userProfile.learningPaths.title',
  SKILLS_ACQUIRED_TITLE = 'userProfile.skillsAcquiredTitle',
}

export enum USER_ROLE {
  CURATOR = 'Curator',
}
