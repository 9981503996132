import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
/* constantss interface */
import * as Moment from 'moment';
import { jsPDF } from 'jspdf';
import { Router } from '@angular/router';
export interface CertificateParams {
  backgroundUrl?: string;
  logoUrl?: string;
  signatureUrl?: string;
  signatureDetails?: any;
  courseName?: string;
  userName?: string;
  completionDate?: number;
  subject?: string;
  hours?: string;
}

@Component({
  selector: 'aftp-certificate-template',
  templateUrl: './certificate-template.component.html',
  styleUrls: ['./certificate-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificateTemplateComponent implements OnInit, OnChanges {
  moment = Moment;

  intitalData = {
    courseName: '{courseName}',
    userName: '{userName}',
    completionDate: '{completionDate}',
  };
  logoUrl = '../../../../assets/images/certificate-template/logo.png';
  signatureUrl = '../../../../assets/images/certificate-template/signature.png';
  signatureDetails =
    'Robert J. Kuehnau Jr., Director 161 Clark St. Chicago, IL 60601';
  certificateObj = this.intitalData;
  constructor(private changedetect: ChangeDetectorRef, private router: Router) {
    console.log(window['params']);
  }

  ngOnInit(): void {
    this.certificateObj = this.intitalData;
    this.downloadCertificate();
  }
  downloadCertificate() {
    const details = window['params'];
    if (
      details != undefined &&
      details.courseName &&
      details.userName &&
      details.completionDate
    ) {
      console.log('localstorage', window.localStorage);
      console.log('winparams', window['params']);
      this.certificateObj = window['params'];
      const completionDate = this.moment(window['params']?.completionDate)
        .utc()
        .format('DD-MMM-YYYY');
      this.changedetect.detectChanges();
      console.log(window['params']);
      const elementToPrint = document.getElementById('certificate');
      const doc = new jsPDF();
      doc.html(elementToPrint, {
        callback: function (pdf) {
          pdf.save(
            `Certificate_${window['params']?.userName}_${window['params']?.courseName}_${completionDate}`
          );
          window.close();
        },
        x: 10,
        y: 10,
        width: 155, //target width in the PDF document
        windowWidth: 650, //window width in CSS pixels
      });
    } else {
      this.router.navigateByUrl('/program-home-page');
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).includes('certificateObj')) {
      this.certificateObj = changes.certificateObj.currentValue;
    }
  }
}
