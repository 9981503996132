import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  LearningActivity,
  LoDetailsAPISchema,
  BoardModel,
  CompletionPopupSchema,
} from 'src/app/shared/interface/learning-activity-data-model';

import { LearningObject } from '../../../../../shared/interface/learning-object.model';
import { AssessmentSummary } from 'src/app/shared/interface/assessment-summary.model';

@Injectable({
  providedIn: 'root',
})
export class LearningObjectDetailService {
  private _learning$: BehaviorSubject<LearningActivity> = new BehaviorSubject(
    null
  );
  private _externalClick$: Subject<boolean> = new Subject();
  private _pauseVideo$: Subject<boolean> = new Subject<boolean>();
  private _showNotes$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _showTranscript$: Subject<boolean> = new Subject();
  private _loSummary$: Subject<AssessmentSummary> = new BehaviorSubject(null);
  private _animation$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  videoTimeStamp;
  private _LODetails;
  private _completionTime$: BehaviorSubject<number> = new BehaviorSubject(null);
  private _autoplayLA$: Subject<boolean> = new Subject();
  private _autoplayLO$: Subject<boolean> = new Subject();
  private _notesEntered$: BehaviorSubject<string> = new BehaviorSubject(null);
  private _updateCurrentLA$: Subject<LearningActivity> = new Subject();
  private _passAssessment$: Subject<boolean> = new Subject();
  private _boardModel$: Subject<BoardModel> = new Subject();
  private _laType$: BehaviorSubject<number> = new BehaviorSubject(null);
  private showAssessmentTimer$: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  );
  private _completionUsers$: Subject<CompletionPopupSchema> = new Subject();
  private _startAssessment$: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  );
  private _toastMessage$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _fromAssessment$: BehaviorSubject<string> = new BehaviorSubject(null);
  private _CtaButtonValue$: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  );
  private _nextLOdetails$: BehaviorSubject<string> = new BehaviorSubject(null);
  private _retakeAssessment$: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  );
  private _usrResumeDuration$: BehaviorSubject<number> = new BehaviorSubject(
    null
  );
  private _closePlalist$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private isExploreOrProfileBtnClicked$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  private _usrClickedNote: LearningObject = null;
  private _isRoutedFromNotebook = false;
  private isSurveyLoaded: boolean;
  private isClicked: boolean;
  private _loID: string;
  private lpTitle: string;
  private surveyStatus: boolean;
  continueClicked: boolean;
  programID: string;
  groupID: string;
  //hideElementsOnMobile: boolean;

  setCurrentLA(learning: LearningActivity) {
    this._updateCurrentLA$.next(learning);
  }

  getUpdatedCurrentLA(): Observable<LearningActivity> {
    return this._updateCurrentLA$.asObservable();
  }

  updatCurrentLearning(learningActivity: LearningActivity): void {
    this._learning$.next(learningActivity);
  }

  getCurrentLearning(): Observable<LearningActivity> {
    return this._learning$.asObservable();
  }

  emitPauseVideo(val: boolean): void {
    this._pauseVideo$.next(val);
  }

  getPausedVideo(): Observable<boolean> {
    return this._pauseVideo$.asObservable();
  }
  setLearningActivityDetails(details: LoDetailsAPISchema): void {
    this._LODetails = details;
  }

  getLearningActivityDetails(): LoDetailsAPISchema {
    return this._LODetails;
  }

  getStatusOfNotesPanel(): Observable<boolean> {
    return this._showNotes$.asObservable();
  }
  setStatusOfNotesPanel(val: boolean): void {
    this._showNotes$.next(val);
  }
  getStatusOfTranscriptPanel(): Observable<boolean> {
    return this._showTranscript$.asObservable();
  }
  setStatusOfTranscriptPanel(val: boolean): void {
    this._showTranscript$.next(val);
  }
  setCompletionTime(time: number): void {
    this._completionTime$.next(time);
  }
  getCompletionTime(): Observable<number> {
    return this._completionTime$.asObservable();
  }
  getAutoplayLAStatus(): Observable<boolean> {
    return this._autoplayLA$.asObservable();
  }
  setAutoplayLAStatus(val: boolean): void {
    this._autoplayLA$.next(val);
  }
  getAutoplayLOStatus(): Observable<boolean> {
    return this._autoplayLO$.asObservable();
  }
  setAutoplayLOStatus(val: boolean): void {
    this._autoplayLO$.next(val);
  }
  setExternalClicked(val: boolean): void {
    this._externalClick$.next(val);
  }
  getExternalClicked(): Observable<boolean> {
    return this._externalClick$.asObservable();
  }
  updateAssessmentStatus(val: boolean): void {
    this._passAssessment$.next(val);
  }
  getAssessmentStatus(): Observable<boolean> {
    return this._passAssessment$.asObservable();
  }

  setBoardModel(boardModel: BoardModel): void {
    this._boardModel$.next(boardModel);
  }
  getBoardModel(): Observable<BoardModel> {
    return this._boardModel$.asObservable();
  }
  setLearningActivityType(type: number): void {
    this._laType$.next(type);
  }
  getLearningActivityType(): Observable<number> {
    return this._laType$.asObservable();
  }
  setCompletionUsers(completionPopupSchema: CompletionPopupSchema): void {
    this._completionUsers$.next(completionPopupSchema);
  }
  getCompletionUsers(): Observable<CompletionPopupSchema> {
    return this._completionUsers$.asObservable();
  }
  setSummaryData(response: AssessmentSummary): void {
    this._loSummary$.next(response);
  }
  getSummaryData(): Observable<AssessmentSummary> {
    return this._loSummary$.asObservable();
  }
  setShowAssessmentTimer(visibility: boolean): void {
    this.showAssessmentTimer$.next(visibility);
  }
  getShowAssessmentTimer(): Observable<boolean> {
    return this.showAssessmentTimer$;
  }
  setSurveyAvailable(value: boolean): void {
    this._startAssessment$.next(value);
  }
  getSurveyAvailable(): Observable<boolean> {
    return this._startAssessment$.asObservable();
  }
  setToastMessage(value: boolean): void {
    this._toastMessage$.next(value);
  }
  getToastMessage(): Observable<boolean> {
    return this._toastMessage$;
  }
  setAssessmentToOtherPages(value: string): void {
    this._fromAssessment$.next(value);
  }
  getAssessmentToOtherPages(): Observable<string> {
    return this._fromAssessment$;
  }
  setCtaValue(value: boolean): void {
    this._CtaButtonValue$.next(value);
  }
  getCtaValue(): Observable<boolean> {
    return this._CtaButtonValue$;
  }
  setNextLODetails(value: string): void {
    this._nextLOdetails$.next(value);
  }
  getNextLODetails(): Observable<string> {
    return this._nextLOdetails$;
  }
  setAnimation(value: boolean): void {
    this._animation$.next(value);
  }
  getAnimation(): Observable<boolean> {
    return this._animation$;
  }
  setRetakeAssessment(value: boolean): void {
    this._retakeAssessment$.next(value);
  }
  getRetakeAssessment(): Observable<boolean> {
    return this._retakeAssessment$.asObservable();
  }
  setEnteredNotes(value: string): void {
    this._notesEntered$.next(value);
  }
  getEnteredNotes(): Observable<string> {
    return this._notesEntered$.asObservable();
  }
  setUserResumeDuration(time: number) {
    this._usrResumeDuration$.next(time);
  }
  getUserResumeDuration(): Observable<number> {
    return this._usrResumeDuration$.asObservable();
  }
  setSurveyLoaded(value: boolean): void {
    this.isSurveyLoaded = value;
  }
  getSurveyLoaded(): boolean {
    return this.isSurveyLoaded;
  }
  setBackButtonClicked(val): void {
    this.isClicked = val;
  }
  getBackButtonClicked(): boolean {
    return this.isClicked;
  }
  setClickedNote(note: LearningObject) {
    this._isRoutedFromNotebook = true;
    this._usrClickedNote = note;
  }
  getClickedNote(): LearningObject {
    return this._usrClickedNote;
  }
  setRoutedFromNotebook(value: boolean) {
    this._isRoutedFromNotebook = value;
  }
  getRoutedFromNotebook(): boolean {
    return this._isRoutedFromNotebook;
  }
  setLOId(value: string) {
    this._loID = value;
  }
  getLOId(): string {
    return this._loID;
  }
  setLPTitle(value) {
    this.lpTitle = value;
  }
  getLPTitle() {
    return this.lpTitle;
  }
  setSurveyStatus(val: boolean) {
    this.surveyStatus = val;
  }
  getSurveyStatus(): boolean {
    return this.surveyStatus;
  }
  setClosePlalist(val: boolean) {
    this._closePlalist$.next(val);
  }
  getClosePlayList(): Observable<boolean> {
    return this._closePlalist$.asObservable();
  }
  setExploreOrProfileBtnClicked(val: boolean) {
    this.isExploreOrProfileBtnClicked$.next(val);
  }
  getExploreOrProfileBtnClicked(): Observable<boolean> {
    return this.isExploreOrProfileBtnClicked$.asObservable();
  }
  // setHideElementsForScorm(val: boolean){
  //     this.hideElementsOnMobile = val
  // }
  // getHideElementsForScorm():boolean {
  //    return this.hideElementsOnMobile
  // }

  setProgramId(val: string) {
    this.programID = val;
  }

  setGroupId(groupId: string) {
    this.groupID = groupId;
  }

  clearProgramOrGroupId() {
    delete this.programID;
    delete this.groupID;
  }
}
