import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class MissingTranslationService implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    console.error(
      `'${params.key}' is missing in '${params.translateService.currentLang}' locale`
    );
    return null;
  }
}
