import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-learning-path',
  templateUrl: './learning-path.component.html',
  styleUrls: ['./learning-path.component.scss'],
})
export class LearningPathComponent {
  @Input()
  LearningPathObject;
  items1: any = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }];
  courses = [1, 2, 3, 4];
  counter = 0;
  completed: boolean;
  lpCardClicked(item: number): void {
    this.counter = item;
    this.completed = true;
  }
}
